import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-changes-dialog',
  template: `
    <h1 mat-dialog-title>WARNING</h1>
    <div mat-dialog-content>
      <p>WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or Discard to discard these changes.</p>
    </div>
    <div mat-dialog-actions align="end">
      <button type="button" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
      <button type="button" mat-raised-button color="warn" style="margin-left: 5px;" (click)="discard()">Discard</button>
    </div>
  `,
  styles: []
})
export class PendingChangesDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PendingChangesDialogComponent>) { }

  ngOnInit(): void { }

  cancel(): void {
    this.dialogRef.close(false); 
  }

  discard(): void {
    this.dialogRef.close(true);
  }
}
