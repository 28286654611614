import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Lookup } from 'src/app/core/models/common-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ComponentListDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';
import { UpdateCompListDialogComponent } from '../update-comp-list-dialog/update-comp-list-dialog.component';
import { RearrangeSequenceNbrComponent } from './rearrange-sequence-nbr/rearrange-sequence-nbr.component';

@Component({
  selector: 'app-manage-component-list-dialog',
  templateUrl: './manage-component-list-dialog.component.html',
  styleUrls: ['./manage-component-list-dialog.component.scss'],
  providers: [AdministrationService]
})
export class ManageComponentListDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<ComponentListDTO>([]);
  dataColumns: String[] = ['id', 'value', 'componentId', 'seqNbr', 'description', 'uom', 'minQty', 'maxQty', 'createdByName', 'createdTimestamp', 'changedByName', 'changedTimestamp', 'productType', 'canBid', 'negativeBid', 'active'];
  displayedColumns = this.dataColumns;

  totalRows!: number;
  swatchUomList!: Lookup[];

  constructor(public dialogRef: MatDialogRef<ManageComponentListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adminService: AdministrationService,
              public dialog: MatDialog,
              private snackService: SnackBarService,
              public commonService: CommonService
  ) { }

  
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getSwatchUomList();
    this.getComponentListById();
  }

  getSwatchUomList() {
    this.commonService.getSwatchUomList().subscribe((response: any) => {
      this.swatchUomList = response;
    },
    (e) => {
      this.snackService.error('An error occurred while retrieving group id list. If problem persist, please contact support.');
      console.error(e);
    })
  }

  getComponentListById() {
    this.adminService.getComponentListsById(this.data.componentId).subscribe((response: any) => {
      this.dataSource.data = response
      this.totalRows = this.dataSource.data.length;
    });
  }

  createComponentList(form: NgForm) {
    this.adminService.createComponentList(form.value).subscribe((response: any) => {
      this.snackService.success('Component list was successfully created.');
      this.getComponentListById();
    },
    (e: any) => {
      this.snackService.error('An error occurred while creating component list. If problem persist, please contact support.');
      console.error(e);
    });
  }

  openUpdateCompListDialog(data: ComponentListDTO) {
    
    const dialogRef = this.dialog.open(UpdateCompListDialogComponent, {
      data: {
        id: data.id,
        value: data.value,
        active: data.active,
        seqNbr: data.seqNbr,
        description: data.description,
        uom: data.uom,
        componentId: data.componentId,
        minQty: data.minQty,
        maxQty: data.maxQty,
        productType: data.productType,
        canBid: data.canBid,
        negativeBid: data.negativeBid,
        compReferenceId: this.data.compReferenceId,
        componentName: this.data.componentName,
        listLength: this.totalRows,
      }
    });
    
    dialogRef.afterClosed().subscribe(() => {
      this.getComponentListById();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  openRearrangeSeqDialog() {
    const dialogRef = this.dialog.open(RearrangeSequenceNbrComponent, {
      maxWidth: '75vw',
      minWidth: '40vw',
      data: {
        tableData: this.dataSource.data
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getComponentListById();
    });
  }
}