<mat-toolbar style="width: 100%;" color="primary">
    <span>Create Vendor</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div mat-dialog-content class="example-form dialog-update">
    <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text mt-15">
        <mat-form-field appearance="outline">
            <mat-label>Enter Supplier ID</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="supplierId" name="supplierId" required>
        </mat-form-field>

        <div mat-dialog-actions align="center">
            <button mat-raised-button color="accent" [disabled]="supplierId === ''" (click)="checkVendorExists()">Search</button>
        </div>

        <div [hidden]="triggerVendorOptions == false">
            <br>
            <mat-divider></mat-divider>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
                <mat-checkbox class='pr-3' [(ngModel)]="assembly" color="accent" name="assembly">Assembly</mat-checkbox>
                <mat-checkbox class='pl-3'[(ngModel)]="bindery" color="accent" name="bindery">Bindery</mat-checkbox>
            </div>
            <div fxLayoutAlign="center" class="center-text mt-20">
                <button mat-raised-button color="accent" (click)="addVendor()">Create</button>
            </div>
        </div>
    </mat-card-content>

</div>