import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { Category } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';


@Component({
  selector: 'app-admin-update-dialog',
  templateUrl: './admin-update-dialog.component.html',
  styleUrls: ['./admin-update-dialog.component.scss'],
  providers: [AdministrationService]
})
export class AdminUpdateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdminUpdateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adminService: AdministrationService,
              private snackService: SnackBarService
    ) { }

  ngOnInit(): void {
  }

  updateCategory(form: NgForm) {
    this.adminService.updateCategory(form.value).subscribe((response: Category) => {
      this.snackService.success('Category was successfully updated.');
    },
    (e: any) => {
      this.snackService.error('An error occurred while updating category. If problem persist, please contact support.');
      console.error(e);
    },
    () => {
      this.close
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
