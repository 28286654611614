<h1 mat-dialog-title>Mass Bid</h1>

<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
        <mat-radio-group (change)="change($event)" [(ngModel)]="selection">
            <mat-radio-button value="noBid" class="mr-3">No Bid</mat-radio-button>
            <mat-radio-button value="increase" class="mr-3">Increase</mat-radio-button>
            <mat-radio-button value="percent" class="mr-3">Percent</mat-radio-button>
            <mat-radio-button value="static" class="mr-3">Static Price</mat-radio-button>
        </mat-radio-group>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
        <mat-form-field appearance="outline" class="mb-10">
            <mat-label>Amount</mat-label>
            <input matInput type="text" [(ngModel)]="amount" positiveNumber [disabled]="noBid" (keyup)="keyup()" required>
            <mat-hint *ngIf="percentError" style="color: red;">{{ percentErrorMsg }}</mat-hint>
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button type="button" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button type="button" mat-raised-button color="warn" style="margin-left: 5px;" (click)="update()" [disabled]="(!noBid && !amount) || percentError">Update</button>
</div>