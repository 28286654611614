import { Directive, ElementRef, HostListener, Input, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";
import DirectiveUtils from "./directive-utils.model";

@Directive({
    selector: '[positiveNumber]'
})
export class PositiveNumberDirective {
  @Input() allowDecimal: boolean = true;
  @Input() allowNegative: boolean = false;

  inputElement: HTMLInputElement;
  
  constructor(public el: ElementRef, @Optional() private ngControl: NgControl) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if(DirectiveUtils.isValidKeyBoardEvent(e)) {
      return; //Allows keypress 
    }

    //This will be true when the input is blank (On entering first digit)
    let isAllSelected = this.inputElement.selectionStart === 0 && this.inputElement.selectionEnd === this.inputElement.value.length; 

    if (this.allowDecimal && e.key === '.' && !this.inputElement.value.includes('.')) {
      let num: any = this.inputElement.value + '.'; 
      if(this.inputElement.value.length !== 0 && isNaN(num)) {
        e.preventDefault(); 
      }
      return;  // Allow it
    }

    if(this.allowNegative && e.key === '-' && isAllSelected) {
      return; //Allow negative value
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {    
    let pasteData = e.clipboardData?.getData('text/plain');

    if(!pasteData || isNaN(Number(pasteData))) {
      e.preventDefault();
      return;
    }

    if( !this.allowNegative && Number(pasteData) < 0 ) {
      e.clipboardData?.setData('text/plain', String(Number(pasteData) * -1)); 
      // this.inputElement.value = String(Number(pasteData) * -1);
      // e.preventDefault(); 
      // return;
    }
  }

  @HostListener('blur', ['$event']) blur() {    
    if(this.ngControl) {
      this.handleFormValue(); 
    } else if(this.inputElement) {
      this.handleInputValue(); 
    }
  }

  private handleFormValue(): void { 
    const inputValue = <number>this.ngControl.value;     

    if(inputValue && ((inputValue > 0 && this.allowNegative) || (inputValue < 0 && !this.allowNegative))) {
      this.ngControl.control?.setValue(inputValue * -1);
    }
  }

  private handleInputValue(): void {    
    const inputValue = this.inputElement.value; 
    const strLen = inputValue?.trim().length || 0; 

    if(strLen > 0) 
    {
      if(this.allowNegative && !inputValue.startsWith('-')) {
        this.inputElement.value = '-' + inputValue;
      } else if(!this.allowNegative && inputValue.startsWith('-')) {
        this.inputElement.value = inputValue.replace('-',''); 
      }
    }
  }
}