import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';

import { PricingResponse } from '../models/spec-library-pricing.models';

@Component({
  selector: 'app-spec-library-pricing-table',
  templateUrl: './spec-library-pricing-table.component.html',
  styles: [
  ]
})
export class SpecLibraryPricingTableComponent {
  @ViewChild(MatSort) sort!: MatSort;
  
  @Input() set pricingData(pricing: PricingResponse[]) {
    this.pricing = pricing.slice(); 

    const specBasedOnCount = this.pricing.filter(price => price.specBasedOnVendorName && price.specBasedOnVendorName.length > 0).length; 

    if(specBasedOnCount > 0) {
      this.displayColumns.push('basedOn'); 
    }

    if(this.sort) {
      this.sort.sort({id: '', start: 'asc', disableClear: false}); 
    } else {
      const data = this.pricing.slice(); 
      this.pricing = data.sort((a,b) => this.compare(a.unitPrice, b.unitPrice, 'asc')); 
    }
  }

  pricing: PricingResponse[] = []; 
  displayColumns: string[] = ['vendor', 'unitPrice', 'diffUnitPrice', 'totalPrice', 'diffTotalPrice'];

  constructor() { }

  public handleSort(sort: Sort): void {
    const data = this.pricing.slice(); 

    if(!sort.active || sort.direction === '') {
      this.pricing = data.sort((a,b) => this.compare(a.unitPrice, b.unitPrice, 'asc'));
    }

    this.pricing = data.sort((a,b) => {
      switch(sort.active) {
        case 'vendorName': return this.compare(a.vendorName, b.vendorName, sort.direction); 
        case 'unitPrice': return this.compare(a.unitPrice, b.unitPrice, sort.direction); 
        case 'totalPrice': return this.compare(a.totalPrice, b.totalPrice, sort.direction); 
        default: return 0;
      }
    })
  }

  private compare(a: number | string | undefined, b: number | string | undefined, direction: string): number {
    const isAsc = direction === 'asc';
    const multiplier: number = isAsc ? 1 : -1;

    //If the field is null or equals 0 then put it at the bottom
    if(a === b) return 0
    if(!a || a === 0) return 1;
    if(!b || b === 0) return -1;
    
    //Fields are not null and don't equal 0, so compare them
    if(a < b) return (-1 * multiplier); 
    if(a > b) return (1 * multiplier);

    return 0;
  }
}