import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FormGroup } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { PrintFolderSpec } from '../core/models/print-folder.model';
import { PhotoPackService } from '../photopack/services/photo-pack.service';
import { FolderService } from './services/folder.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',  
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ],
})
export class FolderComponent implements OnInit, OnDestroy {
  @Input() folderLists!: ComponentLists;
  @Input() photopackLists!: ComponentLists;

  @Output() submitForm = new EventEmitter();

  readonly destroy$: Subject<void> = new Subject<void>();
  readonly maxAccessories: number = 10000;

  specReview$: Observable<PrintFolderSpec>;
  isPhotopackValid: boolean = false;

  detailForm!: FormGroup;
  headerForm!: FormGroup;
  ppDetailForm!: FormGroup; 

  constructor(
    public folderService: FolderService, 
    public photopackService: PhotoPackService,
    private snackService: SnackBarService
  ) { 
    this.specReview$ = this.folderService.reviewSpec$;
  }

  ngOnInit(): void {   
    this.detailForm = this.folderService.detailForm;
    this.headerForm = this.folderService.headerForm;
    this.ppDetailForm = this.photopackService.detailForm;

    this.photopackService.isDetailFormInvalid$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isInvalid => {
        if(this.folderService.isPhotopack) { 
          this.isPhotopackValid = !isInvalid;
        } else {
          this.isPhotopackValid = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addAccessory(): void {
    this.folderService.addAccessory();    
  }

  removeAccessory(idx: number) {
    this.folderService.removeAccessory(idx);
  }

  submit(): void {
    if(this.folderService.headerForm.invalid || this.folderService.detailForm.invalid || (this.folderService.isPhotopack && !this.isPhotopackValid) ){
      this.folderService.headerForm.markAllAsTouched();
      this.folderService.detailForm.markAllAsTouched();
      this.photopackService.detailForm.markAllAsTouched();
      this.snackService.error('Cannot submit spec until invalid fields are corrected');
      return;
    }
    
    this.submitForm.emit();
  }
}
