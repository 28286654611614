import { SpecRoutingComponent } from "src/app/routing-tool/spec-routing/spec-routing.component";
import { SwatchRoutingComponent } from "src/app/routing-tool/swatch-routing/swatch-routing.component";
import { CreateBackerBoardComponent } from "src/app/spec-collection/backer-board/create-backer-board/create-backer-board.component";
import { EditBackerBoardComponent } from "src/app/spec-collection/backer-board/edit-backer-board/edit-backer-board.component";
import { CreateFolderComponent } from "src/app/spec-collection/folder/create-folder/create-folder.component";
import { EditFolderComponent } from "src/app/spec-collection/folder/edit-folder/edit-folder.component";
import { CreateMultiSpecComponent } from "src/app/spec-collection/multi-spec/create-multi-spec/create-multi-spec.component";
import { EditMultiSpecComponent } from "src/app/spec-collection/multi-spec/edit-multi-spec/edit-multi-spec.component";
import { CreatePhotopackComponent } from "src/app/spec-collection/photopack/create-photopack/create-photopack.component";
import { EditPhotopackComponent } from "src/app/spec-collection/photopack/edit-photopack/edit-photopack.component";
import { CreateSwatchComponent } from "src/app/spec-collection/swatching/create-swatch/create-swatch.component";
import { EditSwatchComponent } from "src/app/spec-collection/swatching/edit-swatch/edit-swatch.component";
import { BackerBoardHistoryComponent } from "src/app/spec-history/spec-detail-history-components/backer-board-history.component";
import { FolderHistoryComponent } from "src/app/spec-history/spec-detail-history-components/folder-history.component";
import { MultiSpecHistoryComponent } from "src/app/spec-history/spec-detail-history-components/multi-spec-history.component";
import { PhotopackHistoryComponent } from "src/app/spec-history/spec-detail-history-components/photopack-history.component";
import { SwatchHistoryComponent } from "src/app/spec-history/spec-detail-history-components/swatch-history.component";
import { AssemblyPricingComponent } from "src/app/spec-library-pricing/assembly-pricing/assembly-pricing.component";
import { BinderyPricingComponent } from "src/app/spec-library-pricing/bindery-pricing/bindery-pricing.component";

export class CategoryComponents 
{
    public static CATEGORY_MAP: Map<string, any> = new Map<string, any>([
        ['card', {
            editComponent: EditMultiSpecComponent, 
            createComponent: CreateMultiSpecComponent, 
            historyComponent: MultiSpecHistoryComponent,
            routingComponent: SpecRoutingComponent,
            data: {screenTitle: 'Print Card'}
        }],
        ['folder', {
            editComponent: EditFolderComponent,
            createComponent: CreateFolderComponent,
            historyComponent: FolderHistoryComponent,
            routingComponent: SpecRoutingComponent
        }],
        ['deckboard', {
            editComponent: EditMultiSpecComponent, 
            createComponent: CreateMultiSpecComponent,           
            historyComponent: MultiSpecHistoryComponent, 
            routingComponent: SpecRoutingComponent,
            data: {screenTitle: 'Deck Board'}
        }],
        ['backerboard', {
            editComponent: EditBackerBoardComponent,
            createComponent: CreateBackerBoardComponent,
            historyComponent: BackerBoardHistoryComponent,
            routingComponent: SpecRoutingComponent
        }],
        ['csf', {
            editComponent: EditMultiSpecComponent, 
            createComponent: CreateMultiSpecComponent,
            historyComponent: MultiSpecHistoryComponent,
            routingComponent: SpecRoutingComponent,
            data: {screenTitle: 'CSF'}
        }],
        ['swatching', {
            editComponent: EditSwatchComponent,
            createComponent: CreateSwatchComponent,
            historyComponent: SwatchHistoryComponent,
            routingComponent: SwatchRoutingComponent
        }],
        ['photopack', {
            editComponent: EditPhotopackComponent,
            createComponent: CreatePhotopackComponent,
            historyComponent: PhotopackHistoryComponent,
            routingComponent: SpecRoutingComponent
        }],
        ['printsku', {
            routingComponent: BinderyPricingComponent
        }],
        ['swatchsku', {
            routingComponent: AssemblyPricingComponent
        }],
    ]);
} 