import { Component, Input, OnInit } from '@angular/core';

import { EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { Spec, SpecRequestDto } from 'src/app/core/models/spec-models';
import { BackerBoardFormValue } from '../models/backer-board.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { BackerBoardService } from '../services/backer-board.service';

@Component({
  selector: 'app-edit-backer-board',
  templateUrl: './edit-backer-board.component.html',
  providers: [BackerBoardService]
})
export class EditBackerBoardComponent implements OnInit {
  @Input() specId: number = 0;
  @Input() categoryId: number = 0; 
  @Input() isCopySpec: boolean = false; 

  componentLists: ComponentLists = {} as ComponentLists;

  constructor(
    private specService: SpecBackendService,
    public backerService: BackerBoardService
  ) { }

  ngOnInit(): void {
    this.specService.getComponetListByCategoryId(this.categoryId)
      .pipe(
        tap(lists => {
          this.componentLists = lists;
          this.backerService.setFinisingOptions(this.componentLists.finishing);
        }),
        concatMap(_ => this.specId > 0 ? this.specService.getSpecById(this.specId) : EMPTY)
      )
      .subscribe(data => {
        if(data) {
          if(this.isCopySpec) {
            this.backerService.copySpec(data);
          } else {
            this.backerService.setForm(data);
          }
        }
      });
  }

  submitForm(): void {
    const request = new SpecRequestDto(<Spec>this.backerService.headerForm.value);

    if(request.id === 0 && !this.isCopySpec) {
      request.id = this.specId;
    }

    if(request.categoryId === 0) {
      request.categoryId = this.categoryId;
    }
    
    request.setBackerBoardDetails(<BackerBoardFormValue>this.backerService.detailForm.value);

    if(this.isCopySpec) {
      // this.copySpec(); 
      this.specService.createSpec(request);
    } else {
      // this.updateSpec(); 
      this.specService.updateSpec(request);
    }
  }

  close(): void {
    this.specService.closeEditDialog();
  }

  // private copySpec(): void {
  //   const request = new SpecRequestDto(<Spec>this.backerService.headerForm.value);

  //   if(request.id === 0 || request.categoryId === 0) {
  //     request.categoryId = this.categoryId;
  //   }

  //   request.setBackerBoardDetails(<BackerBoardFormValue>this.backerService.detailForm.value);
  //   this.specService.createSpec(request);
  // }

  // private updateSpec(): void {
  //   // const request = new SpecRequestDto(this.backerService.getLoadedSpec() || <Spec>this.backerService.headerForm.value);
  //   const request = new SpecRequestDto(<Spec>this.backerService.headerForm.value);

  //   if(request.id === 0 || request.categoryId === 0) {
  //     request.id = this.specId; 
  //     request.categoryId = this.categoryId;
  //   }

  //   request.setBackerBoardDetails(<BackerBoardFormValue>this.backerService.detailForm.value);
  //   this.specService.updateSpec(request);
  // }
}
