<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100">

        <mat-card class="mt-20">

            <div class="table-container-600 mat-elevation-z2">
                  
                    <table mat-table style="width: 100%;" [dataSource]="swatchData">

                        <ng-container matColumnDef="swatchType" sticky>
                            <th mat-header-cell *matHeaderCellDef class="w-150">Swatch Type</th>
                            <td mat-cell *matCellDef="let element">{{ element.type }}</td>
                        </ng-container>

                        <ng-container matColumnDef="name" sticky>
                            <th mat-header-cell *matHeaderCellDef class="w-300">Description</th>
                            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="uom" sticky>
                            <th mat-header-cell *matHeaderCellDef class="w-100">Unit of Measure</th>
                            <td mat-cell *matCellDef="let element">{{ element.uom }}</td>
                        </ng-container>

                        <ng-container matColumnDef="qty" sticky>
                            <th mat-header-cell *matHeaderCellDef class="w-100">Quantity</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-form-field style="width: 80px;" class="qty-field" appearance="outline" [style.fontSize.px]="'12'">
                                    <input type="number" matInput min="1" positiveNumber [(ngModel)]="element.quantity" autocomplete="off" (blur)="updatePricing(element)" [disabled]="!qty">
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="unitPrice" sticky>
                            <td mat-footer-cell *matFooterCellDef>Unit Price</td>
                        </ng-container>

                        <ng-container matColumnDef="totalPrice" sticky>
                            <td mat-footer-cell *matFooterCellDef>Total Price</td>
                        </ng-container>

                        <ng-container matColumnDef="emptyFooter" sticky>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container *ngFor="let vendor of vendors; let i = index; trackBy: trackByFn">
                            <ng-container  matColumnDef="{{ i.toString() }}">
                                <th mat-header-cell *matHeaderCellDef 
                                    [class.min-price-cell]="vendor.unitPrice && !vendor.diffUnitPrice">{{ vendor.vendorName }}</th>
                                <td mat-cell *matCellDef="let element; let idx = index" 
                                    [style.color]="(!element.pricing?.get(vendor.vendorId)?.price && element.pricing?.get(vendor.vendorId)?.priceStatus) ? 'red' : '' ">
                                        {{ (element.pricing?.get(vendor.vendorId)?.price | currency) || element.pricing?.get(vendor.vendorId)?.priceStatus || '-' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="{{ ('upc-' + i.toString()) }}">
                                <td mat-footer-cell *matFooterCellDef 
                                    [class.min-price-cell]="vendor.unitPrice && !vendor.diffUnitPrice">{{ (vendor.unitPrice | currency) || vendor.priceText }}</td>
                            </ng-container>
                    
                            <ng-container matColumnDef="{{ ('tpc-' + i.toString()) }}">
                                <td mat-footer-cell *matFooterCellDef 
                                    [class.min-price-cell]="vendor.totalPrice && !vendor.diffTotalPrice">{{ (vendor.totalPrice | currency) || '-' }}</td>
                            </ng-container>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true;" class="mat-table-header-primary"></tr>
                    
                        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

                        <tr mat-footer-row *matFooterRowDef="unitPriceColumns; sticky: true;" class="mat-table-header-primary"></tr>
                        <tr mat-footer-row *matFooterRowDef="totalPriceColumns; sticky: true;" class="mat-table-header-primary"></tr>
                    </table>

            </div>

        </mat-card>

    </div>
</div>