import { Component, Input, OnInit } from '@angular/core';
import { ComponentLists } from 'src/app/core/models/common-models';
import { SpecRequestDto } from 'src/app/core/models/spec-models';
import { BackerBoardFormValue } from '../models/backer-board.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { BackerBoardService } from '../services/backer-board.service';

@Component({
  selector: 'app-create-backer-board',
  templateUrl: './create-backer-board.component.html',
  providers: [BackerBoardService]
})
export class CreateBackerBoardComponent implements OnInit {
  @Input() categoryId: number = 0; 

  componentLists: ComponentLists = {} as ComponentLists;

  constructor(
    private specService: SpecBackendService,
    public backerService: BackerBoardService
  ) { }

  ngOnInit(): void {
    this.specService.getComponetListByCategoryId(this.categoryId)
      .subscribe(lists => {
        this.componentLists = lists;
        this.backerService.setFinisingOptions(this.componentLists.finishing);
      });
  }

  submitForm(): void {
    const request = new SpecRequestDto(this.backerService.headerForm.value);

    if(request.categoryId === 0) {
      request.categoryId = this.categoryId;
    }

    request.setBackerBoardDetails(<BackerBoardFormValue>this.backerService.detailForm.value);
    this.specService.createSpec(request);
  }

}
