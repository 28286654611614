<div fxLayout="row wrap" fxLayoutAlign="center" *ngIf="appStatus$ | async as status">
    <div fxFlex="96" fxFlex.xs="100">

        <mat-toolbar style="width: 100%;" color="primary">
            <span>SAVE Application Status</span>
            <span class="example-spacer"></span>
            <span>API URL: {{ apiUrl }}</span>
        </mat-toolbar>

        <mat-card>
            <div fxLayout="row wrap" fxLayoutAlign="center" style="font-size: 18px;">
                <div style="font-weight: 500;">Active Profiles:</div>&nbsp;{{ status.profiles }}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center" style="font-size: 18px;">
                <div style="font-weight: 500;">App Version:</div>&nbsp;{{ status.version }}
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center" style="font-size: 18px;">
                <div style="font-weight: 500;">Sql Server Status:</div>&nbsp;{{ status.sqlServerStatus }}
            </div>

            <mat-toolbar style="width: 100%; margin-top: 20px; margin-bottom: 20px;" color="accent">
                <span>SAVE Application Settings</span>            
            </mat-toolbar>

            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="center" fxLayoutGap="10px grid">
                <div fxFlex="calc(25%-10px)" fxFlex.md="calc(50%-10px)">
                    <mat-card class="mat-elevation-z6">
                        <mat-card-title-group>
                            <mat-card-title>
                                <mat-icon>gavel</mat-icon> Vendor Bidding - ({{ config.isVendorBidding ? 'Unlocked' : 'Locked' }})
                            </mat-card-title>
                            <mat-slide-toggle [(ngModel)]="config.isVendorBidding" (change)="toggleVendorBidding()" [disabled]="!isEditAdmin"></mat-slide-toggle>
                        </mat-card-title-group>
                        <mat-card-content>
                            <p>
                                Lock/Unlock the ability for users to change bids. When unlocked, users with edit bid permissions 
                                will be able to create and edit vendor bids. When locked, users with edit bid permissions will only 
                                be able to add missing vendor bids. Users with the Master Bid permission are unaffected by this setting. 
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div fxFlex="calc(25%-10px)" fxFlex.md="calc(50%-10px)">
                    <mat-card class="mat-elevation-z6">
                        <mat-card-title-group>
                            <mat-card-title>
                                <mat-icon>cached</mat-icon> Clear User Cache
                            </mat-card-title>
                            <button type="button" mat-flat-button color="primary" style="line-height: 30px;" (click)="clearUserCache()" [disabled]="!isEditAdmin">Clear</button>
                        </mat-card-title-group>

                        <mat-card-content class="mt-10">
                            <p>
                                This option will clear the current user cache. This is useful 
                                if you've recently made changes to users roles or permissions. Note: This function 
                                will only affect authorization for making requests. To update authorization within 
                                the web app, the affected users will have to refresh their web app. 
                            </p>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

        </mat-card>

    </div>
</div>