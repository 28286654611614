import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

import { Subject, throwError } from 'rxjs';
import { concatMap, catchError, takeUntil, filter } from 'rxjs/internal/operators';

import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  template: `
    <app-side-nav *ngIf="isLoaded" [isLoaded]="isLoaded" [isAuthorized]="isAuthorized">
      <div style="margin-top: 10px">
        <router-outlet></router-outlet>
      </div>
    </app-side-nav>`,
  styles: [], 
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  title = 'Shaw SAVE';
  isIframe = false;

  isUserSetup: boolean = false;
  isLoaded: boolean = false;
  isAuthorized: boolean = false;

  constructor(
    private router: Router,
    private msalBroadcaseService: MsalBroadcastService, 
    private userService: UserService
  ) { }

  ngOnInit() { 
    this.isIframe = window !== window.parent && !window.opener; 
    this.isLoaded = false;
    this.isAuthorized = false;

    this.msalBroadcaseService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        concatMap(() => this.userService.fetchUser$),
        catchError(e => {
          this.router.initialNavigation(); 
          if(e instanceof HttpErrorResponse && e.url?.includes('/user')) {
            this.router.navigate(['unauthorized']);
          }          
          this.isLoaded = true;
          return throwError(e);
        }), 
        takeUntil(this.destroy$)
      )
      .subscribe(user => {      
        this.router.initialNavigation();   
        //If user is vendor role, but profile doesn't have vendor ID, then we lock them out.
        if( !this.userService.isValidUser) {
          this.router.navigate(['invalid-user']); 
        } else {
          this.isAuthorized = true;
        }

        this.isLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete();
  }
}