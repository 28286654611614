import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import DirectiveUtils from "./directive-utils.model";

@Directive({
    selector: '[alphaNumeric]'
})
export class AlphaNumericDirective {
    @Input() allowSpace: boolean = false; 

    inputElement: HTMLInputElement; 

    constructor(public el: ElementRef) {
        this.inputElement = el.nativeElement; 
    }

    @HostListener('keydown', ['$event']) 
    onKeyDown(e: KeyboardEvent) {
        const key = e.key.toLowerCase(); 

        if(key.length !== 1) { return; }
        if(DirectiveUtils.isValidKeyBoardEvent(e)) { return; }
        if(DirectiveUtils.isAlphaNumericKey(e)) { return; }
        
        //If space is an allowed character
        if(this.allowSpace && /^ /.test(key)) { return; }

        e.preventDefault(); 
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        let pasteData = e.clipboardData?.getData('text'); 

        if(!pasteData || DirectiveUtils.isAlphaNumericString(pasteData) === false) { 
            e.preventDefault(); 
            return; 
        }
    }
}