import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';

import { tap } from 'rxjs/internal/operators';

import { ColDef, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AppSettingsService } from 'src/app/main/app-settings/app-settings.service';
import { UserService } from 'src/app/core/services/user.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { VendorBidService } from '../services/vendor-bid.service';
import { Bid, BidComponent, MassBid, VendorBid } from '../models/vendor-bid-models';
import { MassBidDialogComponent } from '../mass-bid-dialog/mass-bid-dialog.component';


@Component({
  selector: 'app-bid-ag-grid',
  templateUrl: './bid-ag-grid.component.html',
  styles: [], 
  providers: [DatePipe, CurrencyPipe, AppSettingsService]
})
export class BidAgGridComponent implements OnInit {
  @Input() categoryId: number = 0; 
  @Input() vendorId: number = 0;

  public canEdit: boolean = false;
	public isGlobalBidding: boolean = false;
  public isMassBid: boolean = false;

  public isRowSelectable: any; 

  private gridApi!: GridApi<any>;

  columnDefs: ColDef[] = [
    { 
      headerName: 'Last Updated', 
      pinned: 'left', 
      minWidth: 230,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      valueGetter: (params) => {
        if(params.data.changedTimestamp) {
          return this.datePipe.transform(params.data.changedTimestamp, 'medium');
        }

        return ''; 
      }
    },
    { field: 'name', headerName: 'Section', pinned: 'left', sortable: true, filter: true, minWidth: 250},
    { field: 'value', headerName: 'Description', pinned: 'left', sortable: true, filter: true, minWidth: 500, flex: 1 },
  ];

  rowData: BidComponent[] | undefined = []; 
  response: VendorBid | undefined = undefined;

  constructor(
    private vendorBidSrv: VendorBidService, 
    private datePipe: DatePipe, 
    private currencyPipe: CurrencyPipe,
    private snackService: SnackBarService,
    private settingsService: AppSettingsService,
    private userService: UserService,
    public dialog: MatDialog,
    public themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.isRowSelectable = (rowNode: RowNode) => {
      if(this.canEdit && this.isGlobalBidding) {
        return true;
      }
      return rowNode.data.bids?.some((bid: Bid) => bid.missingBid); 
    };
    
    if(this.categoryId > 0 && this.vendorId > 0) {
      this.vendorBidSrv.getVendorBidInfo(this.categoryId, this.vendorId)
        .subscribe((response) => this.handleLoadedBids(response));
    }

    this.settingsService.getAppSettings()
      .pipe(
        tap(settings => {
          this.isGlobalBidding = (settings.vendorBidding === 'on' || this.userService.hasPermission('EDIT_MASTER_BID')); 
          this.canEdit = this.userService.hasAnyPermission(['EDIT_VENDOR_BID', 'EDIT_MASTER_BID']); 
        })
      ).subscribe();
  }

  /** Change event to track count of selected rows so we know whether to display mass bid button or not */
  selectionChange(event: any): void {
    this.isMassBid = this.gridApi.getSelectedNodes().length > 0; 
  }

  /** Provides row ID for rows in AG grid table. Used in HTML on ag-grid-angular element */
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.componentListId;

  /** Ready event for AG grid table. Used in HTML on ag-grid-angular element */
  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  public exportCSV(): void {
		this.vendorBidSrv.downloadVendorBidInfoCSV(this.categoryId, this.vendorId); 
	}

  public update(): void {
    let updated = this.rowData?.map((comp: BidComponent) => { return <BidComponent> {
        ...comp, 
        bids: comp.bids?.filter(bid => bid.isChanged)
    }}).filter(comp => comp.bids && comp.bids.length > 0);

    if(!updated || updated.length === 0) {
      this.snackService.info('No bid information changed or entered. There\'s nothing to submit.'); 
      return;
    } 

    this.vendorBidSrv.updateVendorBid(this.vendorId, updated).subscribe((response) => {
      if(response && response.components) {
        response.components.forEach((comp: BidComponent) => {
          let matchingComp = this.rowData?.find(c => c.componentListId === comp.componentListId);

          comp.bids?.forEach((bid: Bid) => { 
            if(!matchingComp) {
              return;
            }

            let matchingBid = matchingComp.bids?.find(b => b.quantityFactorId === bid.quantityFactorId);

            if(matchingBid) {
              if(bid.error) {
                matchingBid.error = bid.error; 
                return;
              }

              Object.assign(matchingBid, {
                origPrice: bid.price, 
                price: bid.price, 
                noBid: bid.noBid, 
                missingBid: bid.missingBid, 
                isChanged: false
              }); 
              
              if(!matchingBid.componentBidId && bid.componentBidId) {
                matchingBid.componentBidId = bid.componentBidId;
              }

              if(!matchingComp.changedTimestamp || (bid.changedTimestamp && bid.changedTimestamp > matchingComp.changedTimestamp)) {
                matchingComp.changedTimestamp = bid.changedTimestamp;
              }
            }
          });

          if(matchingComp) {
            this.gridApi.getRowNode(String(matchingComp.componentListId))?.setData(matchingComp);
          }
        });
      }
    });
  }

  /** Selected rows and user clicked the mass bid button. Opens the mass bid dialog */
  public massBid(): void {
    const dialogRef = this.dialog.open(MassBidDialogComponent, {
      width: '500px', 
    });

    dialogRef.afterClosed().subscribe((result: MassBid | undefined) => {
      if(result) {
        this.applyMassBid(result);
      }
    });    
  }

  /** Resets any changed bids on the screen */
  public resetBids(): void {
    this.gridApi.deselectAll();

    this.rowData?.forEach((comp: BidComponent) => {
      let i = 0; 
      comp.bids?.forEach((bid: Bid) => {
        bid.price = bid.origPrice;
        bid.isChanged = false;
        bid.noBid = !bid.price && bid.componentBidId ? true : false; 
        i++; 
      });

      if(i > 0) {
        this.gridApi.getRowNode(String(comp.componentListId))?.setData(comp);
      }
    });
  }

  private preventEditBid(bid: Bid): boolean {
    return !this.canEdit || (!this.isGlobalBidding && (!bid.missingBid && !bid.isChanged)); 
  }

  /** Applys the mass bid changes that were selected. */
  private applyMassBid(options: MassBid): void {
    const selected = this.gridApi.getSelectedNodes();

    selected.forEach((node) => {
      node.data.bids?.forEach((bid: Bid) => {
        if(this.preventEditBid(bid)) {
          return;
        }

        if(options.noBid && bid.noBid) {
          return;
        }

        if(options.noBid) {
          bid.noBid = true; 
          bid.price = undefined;
          bid.isChanged = true;
        } else if(options.operation === 'increase' || options.operation === 'percent') {          
          if(options.amount !== undefined) {
            if(options.operation === 'increase') {
              bid.price = (bid.price || 0) + Number(options.amount);
            } else if(bid.price && bid.price > 0) {
              bid.price = bid.price + (bid.price * (Number(options.amount) / 100));
            }
          }

          bid.isChanged = bid.price !== bid.origPrice;          
        } else if(options.operation === 'static') {
          bid.price = Number(options.amount);
          bid.isChanged = bid.price !== bid.origPrice;  
        }

        if(bid.isChanged && !options.noBid) {
          bid.noBid = false;
        }
      });
      node.setData(node.data);
    });

    this.gridApi.deselectAll();
  }

  /** Loads the initial table data and builds the columns for AG grid table */
  private handleLoadedBids(vendorBids: VendorBid): void {
    if(!vendorBids) {
      return;
    }

    this.rowData = vendorBids.components;

    //Set original bid and changed timestamp for bids/components
    this.rowData?.forEach((comp: BidComponent) => {
      comp.bids?.forEach((bid: Bid) => {
        bid.origPrice = bid.price;
        if(!comp.changedTimestamp || (bid.changedTimestamp && bid.changedTimestamp > comp.changedTimestamp)) {
          comp.changedTimestamp = bid.changedTimestamp;
        }
      });
    });

    //Set up column definitions for bids 
    if(this.rowData && this.rowData[0].bids) {
      for(let i = 0; i < this.rowData[0].bids.length; i++) {
        let header = 'Current Bid'; 

        if(this.rowData[0].bids.length > 1) {
          let bid = this.rowData[0].bids[i];
          header = bid.minQty + (bid.maxQty ? '-' + bid.maxQty : '+'); 
        }

        this.columnDefs.push({
          valueGetter: (params) => {
            if(!params.data.bids[i].price || params.data.bids[i].noBid) {
              return params.data.bids[i].noBid ? 'No Bid' : 'Missing';
            }
            return params.data.bids[i].price;
          }, 
          valueFormatter: (params) => {
            if(isNaN(+params.value)) {
              return params.value;
            }

            if(+params.value < 0) {
              return '(' + this.currencyPipe.transform(+params.value * -1, 'USD') + ')';
            }

            return this.currencyPipe.transform(+params.value, 'USD');
          },
          valueSetter: (params) => {                        
            if(params.newValue === params.oldValue) {
              return false;
            }

            if(isNaN(+params.newValue)) { 
              this.snackService.warn('Invalid bid amount entered. Please enter a valid number.');
              return false;
            }            

            if(+params.newValue < 0 && !params.data.negativeBid) {
              this.snackService.warn('Negative bids are not allowed for the selected component.'); 
              return false;
            }

            const bid = params.data.bids[i];

            bid.noBid = +params.newValue === 0; 
            bid.price = bid.noBid ? undefined : +params.newValue;
            bid.isChanged = bid.price !== bid.origPrice;

            return true;
          }, 
          rowDrag: false,
          headerName: header,
          editable: (params) => {
            if(this.preventEditBid(params.data.bids[i])) {
              return false;
            }

            return true;
          }, 
          singleClickEdit: true,
          flex: 1,
          minWidth: 150,
          cellClassRules: {
            'missing-bid-text': (params) => { return !params.data.bids[i].price && !params.data.bids[i].noBid; },
            'no-bid-text': (params) => { return params.data.bids[i].noBid; },
            'changed-bid-cell': (params) => { return params.data.bids[i].isChanged; }
          }, 
          
        });
      }

      this.gridApi.setColumnDefs(this.columnDefs);
    }
  }
}
