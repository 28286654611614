import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private snackService: SnackBarService) { }

  handleError(e: any): void {
    console.error(e);

    if(e instanceof HttpErrorResponse) {
      this.handleErrorResponse(e);
      return;
    }

    this.snackService.error('An unknown error occurred while performing the requested operation. If the problem persist, please contact support for assistance.');
  }

  private handleErrorResponse(e: HttpErrorResponse): void {
    this.snackService.error(e.error.message);
  }
}
