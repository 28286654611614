import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { ComponentList } from 'src/app/core/models/common-models';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html'
})
export class AccessoriesComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() maxAccessories: number = 10000;
  @Input() accList!: ComponentList[] | undefined;

  @Output() deleteAccessory = new EventEmitter<number>();
  @Output() addAccessory = new EventEmitter();

  get accessoriesArray(): FormArray {
    return this.group.get('accessories') as FormArray;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
