import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators";

import { SnackBarService } from "../services/snack-bar.service";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    private readonly unknownError = 'An unknown error occurred while processing request. Please contact support if the problem persist.';
    constructor(private snackService: SnackBarService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((e: any) => {
                console.error(e);

                if(e instanceof HttpErrorResponse) {
                    // if(e.status !== 401 && !e.url?.includes('/users')) {
                        this.snackService.error(e.error.message || this.unknownError);
                    // }

                    return throwError(e);
                }

                this.snackService.error(this.unknownError);
                return throwError(e);
            })
        );
    }
}