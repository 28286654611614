import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/internal/operators';

import { UpdateUserProfilesComponent } from './update-user-profiles/update-user-profiles/update-user-profiles.component';
import { SpecActions } from '../core/models/common-models';
import { UserProfiles } from './models/user-profiles.models';
import { UserProfilesService } from './services/user-profiles.service';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { ConfirmDeleteUserComponent } from './confirm-delete-user/confirm-delete-user.component';

@Component({
  selector: 'app-user-profiles',
  templateUrl: './user-profiles.component.html',
  styles: [`
    table th, table td {
      padding: 5px;
    }
  `]
})
export class UserProfilesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource<UserProfiles>();
  displayedColumns = ['id', 'objectId', 'upn', 'name', 'preferredName', 'email', 'supplierID', 'supplierName', 'vendorId', 'active', 'updateButton'];
  filterValue: string = ''; 

  constructor(
    private userProfilesService: UserProfilesService,
    private title: Title,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.title.setTitle('User Profiles | SAVE');
    this.getUserProfiles();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getUserProfiles() {
    this.userProfilesService.getUserProfiles()
      .subscribe((res: any) => this.dataSource.data = res);
  }

  applyFilter(): void {
    this.dataSource.filter = this.filterValue.trim().toLowerCase(); 
  }

  search() {
    return this.userProfilesService.getUserProfiles();
  }

  updateUserProfiles(data: UserProfiles) {
    const dialogRef = this.dialog.open(UpdateUserProfilesComponent, {
      width: '600px',
      height: '400px',
      data: data
    });

    dialogRef.afterClosed()
      .pipe(switchMap(response => (response && response===SpecActions.Updated) ? this.search() : EMPTY))
      .subscribe(response => this.dataSource.data = response)
  }

  deleteUser(data: UserProfiles): void {
    const dialogRef = this.dialog.open(ConfirmDeleteUserComponent, {
      data: {
        userName: data.name
      }
    }); 

    dialogRef.afterClosed()
      .pipe(
        switchMap(response => response && response === SpecActions.Deleted ? this.userProfilesService.deleteUserProfile(data.id) : EMPTY),
        switchMap(() => this.search().pipe(tap(data => this.dataSource.data = data)))
      )
      .subscribe(); 
  }
}