<mat-stepper orientation="horizontal">

    <mat-step>
        <ng-template matStepLabel>{{ parentSpec.categoryName }}</ng-template>
        <ng-template matStepContent>
            <app-spec-library-pricing-spec-table *ngIf="parentSpec && parentSpec.specComponents && parentSpec.specComponents.length > 0 " [data]="parentSpec.specComponents" [isAssembly]="true"
                ></app-spec-library-pricing-spec-table>
        </ng-template>
    </mat-step>

    <ng-container *ngIf="childSpecs && childSpecs.length > 0">
        <mat-step *ngFor="let childSpec of childSpecs">
            <ng-template matStepLabel>{{ childSpec.categoryName }}</ng-template>
            <ng-template matStepContent>
                <app-spec-library-pricing-spec-table *ngIf="childSpec.specComponents && childSpec.specComponents.length > 0" [data]="childSpec.specComponents"></app-spec-library-pricing-spec-table>
            </ng-template>
        </mat-step>
    </ng-container>

</mat-stepper>