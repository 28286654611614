import { BackerBoardFormValue } from "src/app/spec-collection/backer-board/models/backer-board.model";
import { PhotopackFormValue, PhotopackOption } from "src/app/spec-collection/core/models/photopack.model";
import { FolderFormValue } from "src/app/spec-collection/core/models/print-folder.model";
import { MultiSpecFormValue } from "src/app/spec-collection/multi-spec/models/multi-spec.model";

export interface SpecRequest extends Spec {
    specDetails?: SpecDetail[];
}

export interface Spec {
    id?: number;
    categoryId: number;
    changedBy?: number;
    changedTimestamp?: string;
    createdBy?: number;
    createdTimestamp?: string;
    customerNbr?: string;
    deliveryVehicleNbr?: string;
    sequenceNbr?: number;
    styleNbr?: string;
    skuNbr?: string;
    supplierId?: number;
    supplierName?: number;
}

export interface SpecDetail {
    id?: number;
    createdBy?: number;
    createdTimestamp?: string;
    changedBy?: string;
    changedTimestamp?: string;
    childSpecId?: number;
    comments1?: string;
    comments2?: string;
    componentListId: number;
    qty?: number;
    specId?: number;
    sequence?: number;
}

export interface Accessory {
    accessory: number | string;
    qty?: number;
    comment1?: string;
    comment2?: string;
}

export interface FinishingOptions {
    display: string; 
    selected: boolean;
    value: number;
}

export interface Swatch {
    id: number;
    name: string;
    quantity?: number;
    uom: string;
    description: string;
    type?: string;
    compType?: string;
}

export interface SwatchPricing extends Swatch {
    pricing?: Map<number, {
        price: number | undefined; 
        isMissingBid: boolean; 
        isNoBid: boolean;
        priceStatus: string;
    }>;
}

export interface FlipUpOutComponent extends BaseComponents {
    countQty: {
        count: number | string; 
        qty: number | undefined; 
    }; 
    attachmentMethod: number | string; 
}

export interface BaseComponents {
    boardWeight: number | string;
    coverStock: number | string;
    coverColors: number | string;
    coverFilm: number | string;
    linerStock: number | string;
    linerColors: number | string;
    linerFilm: number | string;
}


export interface SpecDTO extends Spec {
  changeUser?: any;
  createUser?: string;
  categoryType?: string;
  categoryRefId?: string;
  newCompListId?: number;
  specDetails: SpecDetailDTO[];
}

export interface SpecDetailDTO extends SpecDetail {
  changeUser?: any;
  createUser?: any;
  description?: string;
  value?: string;
  uom?: string;
  componentRefId?: string;
  parentId?: number;
  componentName?: string;
}

export class SpecRequestDto {
    id: number = 0; 
    categoryId: number = 0;     

    styleNbr: string; 
    skuNbr: string;
    deliveryVehicleNbr: string;
    customerNbr: string; 
    sequenceNbr: number;
    supplierId: number;

    specDetails: SpecDetail[] = [];

    constructor(value: Spec) {
        if(value.id && value.id > 0) {
            this.id = value.id;
        }
        if(value.categoryId && value.categoryId > 0) {
            this.categoryId = value.categoryId;
        }
        this.styleNbr = value.styleNbr || ''; 
        this.skuNbr = value.skuNbr || '';
        this.deliveryVehicleNbr = value.deliveryVehicleNbr || '';
        this.customerNbr = value.customerNbr || '';
        this.sequenceNbr = value.sequenceNbr || 0;
        this.supplierId = value.supplierId || 0;
    }

    public pushSpecDetail(value: SpecDetail) {
        this.specDetails.push(value);
    }

    public setMultiSpecDetails(value: MultiSpecFormValue) {
        this.setComponents(value.baseComponent);
        this.specDetails.push({componentListId: value.finishedSize});
        this.setAccessories(value.accessories);
        this.setFinishingOptions(value.finishingOptions);
    }

    public setBackerBoardDetails(value: BackerBoardFormValue) {
        this.specDetails.push({componentListId: value.boardWeight});
        this.specDetails.push({componentListId: value.finishedSize});
        this.setAccessories(value.accessories);
        this.setFinishingOptions(value.finishingOptions);
    }

    public setPhotopackDetails(value: PhotopackFormValue) {
        this.specDetails.push({componentListId: value.coverStock});
        this.specDetails.push({componentListId: value.coverColors});
        this.specDetails.push({componentListId: value.coverFilm});
        this.specDetails.push({componentListId: value.pageStock});
        this.specDetails.push({componentListId: value.pageColors});
        this.specDetails.push({componentListId: value.pageFilm});
        this.specDetails.push({componentListId: value.finishedSize});
        this.specDetails.push({componentListId: value.type});
    }

    public setSwatching(value: Swatch[]) {
        if(!value || value.length === 0) {
            return;
        }

        this.specDetails = value.map(swatch => <SpecDetail>{componentListId: swatch.id, qty: swatch.quantity});
    }

    public setFolderDetails(value: FolderFormValue) {
        this.setComponents(value.baseComponent);
        if(value.flipUpOutComponent) {
            this.setComponents(value.flipUpOutComponent);
        }
        this.specDetails.push({componentListId: value.finishedSize});
        this.setAccessories(value.accessories);
        this.setFinishingOptions(value.finishingOptions);
    }

    public setPhotopack(photopack: PhotopackOption) {
        this.specDetails.push({componentListId: <number>photopack.finishing1, sequence: 1});
        if(photopack.finishing2) { 
            this.specDetails.push({componentListId: <number>photopack.finishing2, sequence: 2});
        }
        this.specDetails.push({componentListId: <number>photopack.pageCount});
    }

    private setComponents(value: FlipUpOutComponent | BaseComponents) {
        if((<FlipUpOutComponent>value).countQty || (<FlipUpOutComponent>value).attachmentMethod) {
            const countQty = (<FlipUpOutComponent>value).countQty; 
            this.specDetails.push({componentListId: <number>countQty.count, qty: countQty.qty});
            this.specDetails.push({componentListId: <number>(<FlipUpOutComponent>value).attachmentMethod});
        }
        this.specDetails.push({componentListId: <number>value.boardWeight})
        this.specDetails.push({componentListId: <number>value.coverStock});
        this.specDetails.push({componentListId: <number>value.coverColors});
        this.specDetails.push({componentListId: <number>value.coverFilm});
        this.specDetails.push({componentListId: <number>value.linerStock});
        this.specDetails.push({componentListId: <number>value.linerColors});
        this.specDetails.push({componentListId: <number>value.linerFilm});
    }

    private setAccessories(value: Accessory[] | undefined): void {
        if(value && value.length > 0) {
            value.forEach(acc => {
                this.specDetails.push({
                    componentListId: <number>acc.accessory,
                    qty: acc.qty,
                    comments1: acc.comment1, 
                    comments2: acc.comment2
                })
            });
        }
    }

    private setFinishingOptions(value: FinishingOptions[] | undefined) {
        if(value && value.length > 0) {
            value.forEach(finishing => {
                if(finishing.selected) {
                    this.specDetails.push({componentListId: finishing.value});
                }
            });
        }
    }
}

export interface SpecRequestWithChildDto {
    parent: SpecRequestDto; 
    childListId: number; 
    children: SpecRequestDto[]; 
}