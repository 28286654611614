<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" class="center-text">
        <mat-card>
            <mat-form-field>
                <mat-label>Category Type</mat-label>
                <mat-select (selectionChange)="headerChange()" name="selectCat" [(ngModel)]="selectedCategory" [disabled]="hasChanges">
                    <mat-option [value]="0">None</mat-option>
                    <mat-option *ngFor="let option of categoryList" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;&nbsp;
            <!-- @todo  for shaw users only --> 
            <mat-form-field *ngIf="!isVendor" >
                <mat-label>Vendor</mat-label>
                <mat-select (selectionChange)="headerChange()" name="selectVen" [(ngModel)]="selectedVendor" [disabled]="hasChanges" [disabled]="selectedCategory == 0">
                    <mat-option [value]="0">None</mat-option>
                    <mat-option *ngFor="let option of filteredVendorList" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card>
    </div>

</div>

<ng-template componentHost></ng-template>
