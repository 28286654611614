<mat-toolbar style="width: 100%;" color="primary">
    <span>Component Table</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>

    <div mat-dialog-content class="example-form dialog-update"> 
            <mat-card-content fxLayout="column" fxLayoutAlign="center">
             
                <mat-form-field fxLayoutAlign="center" appearance="standard">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filter Category" #input>
                </mat-form-field>
                
                <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element;">{{ element.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="referenceId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.referenceId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="categoryId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.parentId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="categoryName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category ID Name</th>
                        <td mat-cell *matCellDef="let element;">{{ element.categoryName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="groupId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.groupId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element;">
                            <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                        </td>
                    </ng-container>
        

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
                </table>
        
                <mat-paginator #paginator
                                   [pageSize]="15"
                                   [pageSizeOptions]="[5, 15, 25, 50, 100]"
                                   [showFirstLastButtons]="true">
                </mat-paginator>

            </mat-card-content>
    </div>
