import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import {FormBuilder, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/internal/operators';

import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { Role } from '../models/role-permission-model';
import { RolePermissionService } from '../services/role-permission.service';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ],
})
export class CreateRoleComponent implements OnInit {
  @Output() getRolePermissionDto: EventEmitter<any> = new EventEmitter();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Input() role!: Role;
  @Input() isEdit: boolean = false;

  nameCreationForm = this._formBuilder.group({
    roleName: ['', Validators.required],
    displayName: ['', Validators.required],
  }); 
  viewPermissionsForm = this._formBuilder.group({
    viewVendorProfile: [false, Validators.required],
    viewVendorBid: [false, Validators.required],
    viewSpecLibraryPricing: [false, Validators.required],
    viewManualSpecPricing: [false, Validators.required],
    viewSpec: [false, Validators.required],
    viewSecurity: [false, Validators.required],
    viewUserProfile: [false, Validators.required],
    viewAdmin: [false, Validators.required],
  });
  editPermissionsForm = this._formBuilder.group({
    editVendorProfile: [false, Validators.required],
    editVendorBid: [false, Validators.required],
    editSpecLibraryPricing: [false, Validators.required],
    editManualSpecPricing: [false, Validators.required],
    editSpec: [false, Validators.required],
    editSecurity: [false, Validators.required],
    editUserProfile: [false, Validators.required],
    editAdmin: [false, Validators.required],
    editMasterBid: [false, Validators.required]
  });

  private readonly destroy$: Subject<void> = new Subject();

  roleName: string = '';
  displayName: string = '';

  constructor(
    private snackService: SnackBarService,
    private _formBuilder: FormBuilder,
    private rolePermissionService: RolePermissionService,
    private dialog: MatDialog,
  ) {
      merge(this.nameCreationForm.statusChanges, this.viewPermissionsForm.statusChanges, this.editPermissionsForm.statusChanges)
      .pipe(
        takeUntil(this.destroy$),
        map(_ => (this.nameCreationForm.dirty || this.viewPermissionsForm.dirty || this.editPermissionsForm.dirty))
      )
      .subscribe(isPending => this.rolePermissionService.isPendingChanges = isPending);
    }


  ngOnInit(): void {
    if(!this.isEdit) return;
    this.nameCreationForm.setValue({roleName: this.role.roleName, displayName: this.role.displayName});
    this.viewPermissionsForm.setValue({
      viewVendorProfile: this.role.viewVendorProfile, 
      viewVendorBid: this.role.viewVendorBid,
      viewSpecLibraryPricing: this.role.viewSpecLibraryPricing,
      viewManualSpecPricing: this.role.viewManualSpecPricing,
      viewSpec: this.role.viewSpec,
      viewSecurity: this.role.viewSecurity,
      viewUserProfile: this.role.viewUserProfile,
      viewAdmin: this.role.viewAdmin,
    });
    this.editPermissionsForm.setValue({
      editVendorProfile: this.role.editVendorProfile, 
      editVendorBid: this.role.editVendorBid,
      editSpecLibraryPricing: this.role.editSpecLibraryPricing,
      editManualSpecPricing: this.role.editManualSpecPricing,
      editSpec: this.role.editSpec,
      editSecurity: this.role.editSecurity,
      editUserProfile: this.role.editUserProfile,
      editAdmin: this.role.editAdmin,
      editMasterBid: this.role.editMasterBid,
    });
  }

  stringNameFormatting() {
    if(this.nameCreationForm.value.roleName === null) this.nameCreationForm.value.roleName = '';
    this.nameCreationForm.value.roleName = this.nameCreationForm.value.roleName.trim();

    if (this.nameCreationForm.value.roleName === '') return;
    

    let newFixedString: string;
    const fixedString = this.nameCreationForm.value.roleName.split(" ");

    if (fixedString.length > 1) {
      this.nameCreationForm.value.roleName = fixedString.map((str:string) => {
        return str[0].toUpperCase() + str.substring(1);
      }).join(" ");
    }

    else {
      newFixedString = fixedString.join(" ");
      this.nameCreationForm.value.roleName = newFixedString.charAt(0).toUpperCase() + newFixedString.slice(1);
    }

    this.nameCreationForm.value.displayName = this.nameCreationForm.value.roleName;
    this.displayName = this.nameCreationForm.value.displayName.replace(/_/g," ");
    
    this.nameCreationForm.value.roleName = this.nameCreationForm.value.roleName.replace(/ /g,"_");
    this.roleName = this.nameCreationForm.value.roleName;

    this.nameCreationForm.setValue({roleName: this.roleName, displayName: this.displayName});
  }

  submitLogic() {
    if(this.isEdit) this.submitEdit();
    else this.submitInsert();
  }

  submitEdit() {
    if(this.nameCreationForm.invalid) {
      this.nameCreationForm.markAllAsTouched();
      this.snackService.error('Required fields are missing for role creation.')
    }

    else {
      let obj = {...this.nameCreationForm.value, ...this.viewPermissionsForm.value, ...this.editPermissionsForm.value};

      this.rolePermissionService.updateRoleInfo(obj, this.role.id).subscribe(response => {
        this.snackService.success(response.successMessage);
      },
      (e: HttpErrorResponse) => {
        this.snackService.error(e.error.localizedMessage);
      },
      () => {
        this.closeDialog.emit();
      });
    }
  }

  submitInsert() {
    if(this.nameCreationForm.invalid) {
      this.nameCreationForm.markAllAsTouched();
      this.snackService.error('Required fields are missing for role creation.')
    } 

    else {
      let obj = {...this.nameCreationForm.value, ...this.viewPermissionsForm.value, ...this.editPermissionsForm.value};

      this.rolePermissionService.createRole(obj).subscribe(response => {
        this.snackService.success(response.successMessage);
        this.getRolePermissionDto.emit();
      },
      (e: HttpErrorResponse) => {
        this.snackService.error(e.error.localizedMessage);
      },
      () => {

      });
    }
  }

}
