import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs/internal/Subject';

import { ComponentList, ComponentLists } from 'src/app/core/models/common-models';
import { SpecDetailDTO, SpecDTO, Swatch } from 'src/app/core/models/spec-models';
import { ALPHA_NUMERIC_REGEX, CUST_NBR_REGEX } from 'src/app/core/models/validation-models';
import { UserService } from 'src/app/core/services/user.service';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { MAX_SWATCH_QTY_VALUE } from '../models/swatching.model';

@Injectable()
export class SwatchingService implements OnDestroy {
  private readonly destory$: Subject<void> = new Subject(); 

  public headerForm!: FormGroup;
  public swatchType: Swatch[] = [];
  public cutAddOns: Swatch[] = [];
  public finishing: Swatch[] = [];
  public boxing: Swatch[] = [];

  public backerBoardLists: ComponentLists = {} as ComponentLists;
  public backerBoardCompId: number = 0; 

  public selectedSwatches: Swatch[] = [];

  public cutAddOnsFiltered: Swatch[] = []; 
  public finishingFiltered: Swatch[] = [];
  public boxingFiltered: Swatch[] = [];

  public isSwatchError: boolean = false;
  public isCutAddOnsError: boolean = false;
  public isFinishingError: boolean = false;
  public isBoxingError: boolean = false;

  public isPanelBackerBoard: boolean = false;

  private _spec!: SpecDTO;

  get spec(): SpecDTO | undefined { return this._spec; }

  get concatSwatchArr(): Swatch[] {
    return [...this.swatchType, ...this.cutAddOns, ...this.finishing, ...this.boxing]; 
  }

  constructor(private fb: FormBuilder, private userService: UserService, private specService: SpecBackendService) { 
    this.init();

    this.headerForm.statusChanges.pipe(takeUntil(this.destory$))
      .subscribe(() => this.checkPendingData());
  }

  ngOnDestroy(): void {
    this.destory$.next(); 
    this.destory$.complete(); 
  }

  public checkPendingData(): void {
    const count = this.concatSwatchArr.filter(value => value && value.quantity).length;
    this.selectedSwatches = this.concatSwatchArr.filter(value => value && value.quantity); 
    this.specService.isPendingChanges = count > 0 || this.headerForm.dirty;
  }

  public loadSwatchTypes(componentList: ComponentLists) {
    this.swatchType = this.mapDataToSwatchList(componentList.swatchType, 'Swatch Type');
    this.cutAddOns = this.mapDataToSwatchList(componentList.cutAddOns, 'Cut Add-Ons');
    this.finishing = this.mapDataToSwatchList(componentList.finishing, 'Finishing / Extras');
    this.boxing = this.mapDataToSwatchList(componentList.boxingPackaging, 'Boxing / Packaging');

    this.backerBoardCompId = componentList.backerBoard ? componentList.backerBoard[0].id : 0; 

    this.cutAddOnsFiltered = this.cutAddOns; 
    this.finishingFiltered = this.finishing;
    this.boxingFiltered = this.boxing;
  }

  public setForm(spec: SpecDTO): void {
    this._spec = spec;

    this.headerForm.patchValue({
      styleNbr: spec.styleNbr || '',
      deliveryVehicleNbr: spec.deliveryVehicleNbr || '',
      customerNbr: spec.customerNbr || '',
      sequenceNbr: spec.sequenceNbr || ''
    });

    if(!this.userService.isVendor) {
      this.headerForm.patchValue({supplierId: spec.supplierId || ''});
    }

    this.setSpecDetails();
    this.swatchTypeChange();
  }

  public copySpec(spec: SpecDTO): void {
    this._spec = spec; 
    this.setSpecDetails();
    this.swatchTypeChange(); 
  }

  public checkSwatchQuantityErrors(): void {
    this.isSwatchError = this.swatchType.filter(s => s.quantity && s.quantity > MAX_SWATCH_QTY_VALUE).length > 0;
    this.isCutAddOnsError = this.cutAddOns.filter(s => s.quantity && s.quantity > MAX_SWATCH_QTY_VALUE).length > 0;
    this.isFinishingError = this.finishing.filter(s => s.quantity && s.quantity > MAX_SWATCH_QTY_VALUE).length > 0;
    this.isBoxingError = this.boxing.filter(s => s.quantity && s.quantity > MAX_SWATCH_QTY_VALUE).length > 0;
  }

  public swatchTypeChange(): void {
    const types = new Set(this.swatchType.filter(s => s.quantity && s.quantity > 0).map(s => s.type || 'both'));

    this.cutAddOnsFiltered = [];
    this.finishingFiltered = []; 
    this.boxingFiltered = [];

    this.cutAddOns.forEach(s => {
      if(this.filterSwatchListByType(types, s)) {
        this.cutAddOnsFiltered.push(s);
      } else {
        s.quantity = undefined;
      }
    });

    this.finishing.forEach(s => {
      if(this.filterSwatchListByType(types, s)) {
        this.finishingFiltered.push(s);
      } else {
        s.quantity = undefined;
      }
    });

    this.boxing.forEach(s => {
      if(this.filterSwatchListByType(types, s)) {
        this.boxingFiltered.push(s);
      } else {
        s.quantity = undefined;
      }
    });
  }

  private setSpecDetails(): void {
    const details = this._spec.specDetails;

    this.setSwatchTypeValues(details.filter(detail => detail.componentRefId === 'swatchType'));
    this.setCustAddOnValues(details.filter(detail => detail.componentRefId === 'cutAddOns'));
    this.setFinishingValues(details.filter(detail => detail.componentRefId === 'finishing'));
    this.setBoxingValues(details.filter(detail => detail.componentRefId === 'boxingPackaging'));

    this.selectedSwatches = this.concatSwatchArr.filter(value => value && value.quantity); 
  }

  private mapDataToSwatchList(list: ComponentList[] | undefined, compType: string): Swatch[] {
    if (!list) return [];

    return list.map(componentList => {
      return {
        id: componentList.id,
        name: componentList.value,
        description: componentList.description,
        uom: componentList.uom,
        type: componentList.productType || 'both', 
        compType: compType
      }
    })
  }

  private init() {
    this.headerForm = this.fb.group({
      styleNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      deliveryVehicleNbr: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      customerNbr: ['', [Validators.maxLength(7), Validators.pattern(CUST_NBR_REGEX)]],
      sequenceNbr: ['', Validators.max(99)]
    });

    if(!this.userService.isVendor) {
      this.headerForm.addControl('supplierId', new FormControl('', Validators.required));
    }
  }

  private setSwatchTypeValues(details: SpecDetailDTO[]): void {
    details.forEach(detail => {
      const idx = this.swatchType.findIndex(type => type.id === detail.componentListId);
      if(idx > -1) {
        this.swatchType[idx].quantity = detail.qty;
      }
    });
  }

  private setCustAddOnValues(details: SpecDetailDTO[]): void {
    details.forEach(detail => {
      const idx = this.cutAddOns.findIndex(type => type.id === detail.componentListId);
      if(idx > -1) {
        this.cutAddOns[idx].quantity = detail.qty;
      }
    });
  }

  private setFinishingValues(details: SpecDetailDTO[]): void {
    details.forEach(detail => {
      const idx = this.finishing.findIndex(type => type.id === detail.componentListId);
      if(idx > -1) {
        this.finishing[idx].quantity = detail.qty;
      }
    });
  }

  private setBoxingValues(details: SpecDetailDTO[]): void {
    details.forEach(detail => {
      const idx = this.boxing.findIndex(type => type.id === detail.componentListId);
      if(idx > -1) {
        this.boxing[idx].quantity = detail.qty;
      }
    });
  }

  private filterSwatchListByType(types: Set<string>, swatch: Swatch): boolean {    
    return !types || types.size <= 0 || !swatch.type || swatch.type === 'both' || types.has(swatch.type);
  }
}
