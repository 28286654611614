<div fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" width= "100%" class="mt-20">
        <mat-toolbar style="width: 100%;" color="primary">
            <span>User Profiles</span>
            <span class="example-spacer"></span>
        </mat-toolbar>
        <mat-card>
            <mat-form-field fxLayoutAlign="center" appearance="standard">
                <mat-label>Filter Users</mat-label>
                <input type="text" matInput placeholder="Filter Users" [(ngModel)]="filterValue" (keyup)="applyFilter()">
                <button matSuffix mat-icon-button aria-label="Clear" *ngIf="filterValue" (click)="filterValue=''; applyFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <div class="table-container-600">
                <table mat-table [dataSource]="dataSource" matSort width="100%">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="objectId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Object ID </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.objectId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="upn">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> UPN </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.upn}} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="preferredName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Preferred Name </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.preferredName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="supplierID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier ID </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.supplierID === 0 ? "" : usr.supplierID}} </td>
                    </ng-container>
                    <ng-container matColumnDef="supplierName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Supplier Name </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.supplierName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="vendorId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor ID </th>
                        <td mat-cell *matCellDef="let usr"> {{usr.vendorId}} </td>
                    </ng-container>
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                        <td mat-cell *matCellDef="let usr">
                            <mat-checkbox [checked]="usr.active == true"
                                [disabled]="true">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="updateButton">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let usr">
                            <button type="button" mat-icon-button matTooltip="Update" (click)="updateUserProfiles(usr)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                            <button type="button" mat-icon-button matTooltip="Delete User" (click)="deleteUser(usr)">
                                <mat-icon color="warn">delete_forever</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>

            <mat-paginator 
                [pageSizeOptions]="[5, 10, 20, 50]"
                [pageSize]="10"
                showFirstLastButtons="">
            </mat-paginator>
        </mat-card>
    </div>
</div>