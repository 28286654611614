import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { SpecHistoryDto } from '../models/spec-history.models';

@Component({
  selector: 'app-spec-history-table',
  templateUrl: './spec-history-table.component.html',
  styles:[]
})
export class SpecHistoryTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() 
  set data(value: SpecHistoryDto[]) {
    this.dataSource.data = value;
  }

  @Output() detailSelected = new EventEmitter<any>();

  dataSource: MatTableDataSource<SpecHistoryDto> = new MatTableDataSource();
  displayColumns = ['styleNbr', 'skuNbr', 'deliveryVehicleNbr', 'customerNbr', 'sequenceNbr', 'supplier', 'transactionTimestamp', 'changeUser', 'detailBtn'];

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getSpecHistoryDetail(historyId: number): void {
    this.detailSelected.emit(historyId);
  }
}
