import { Component, Input, OnInit } from '@angular/core';
import { SpecLibrarySpec } from '../../models/spec-library-pricing.models';

@Component({
  selector: 'app-spec-library-pricing-spec-table',
  templateUrl: './spec-library-pricing-spec-table.component.html',
  styleUrls: ['./spec-library-pricing-spec-table.component.scss']
})
export class SpecLibraryPricingSpecTable implements OnInit {
  @Input() data!: SpecLibrarySpec[]; 
  @Input() isAssembly: boolean = false;


  emptyColSpan = 1;
  vendorColSpan = 3; 

  displayedColumns: string[] = ['binderySpec']; 
  secondHeaders: string[] = ['empty']; 

  dataSource: SpecLibrarySpec[] = [];

  constructor() { }

  ngOnInit(): void {
    if(this.data && this.data.length > 0) {
      if(this.isAssembly) {
        this.displayedColumns[0] = 'assemblySpec';
        this.displayedColumns.push('specUom'); 
        this.emptyColSpan = 2;
        this.vendorColSpan = 2; 
      }

      this.data[0].vendors.forEach((data, i) => {
        if(!this.isAssembly) {
          this.displayedColumns.push('spec'+i);           
        }
        this.displayedColumns.push('qty'+i); 
        this.displayedColumns.push('price'+i); 
        this.secondHeaders.push(data.vendorName); 
      }); 

      this.dataSource = this.data;
    }
  }
}