import { Component, Input, OnInit } from '@angular/core';

import { PhotopackSpec } from 'src/app/spec-collection/core/models/photopack.model';

@Component({
  selector: 'app-photopack-review',
  templateUrl: './photopack-review.component.html',
  styles: []
})
export class PhotopackReviewComponent implements OnInit {
  @Input() specDetail!: PhotopackSpec | null;

  constructor() { }

  ngOnInit(): void {    
  }
}
