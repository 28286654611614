import { Accessory } from "src/app/core/models/spec-models";

export const MAX_SWATCH_QTY_VALUE = 9999;

export interface SwatchingSpec {
    boardWeight: string;
    coverStock: string;
    coverColors: string;
    coverFilm: string;
    linerStock: string;
    linerColors: string;
    linerFilm: string;
    finishedSize: string;
    finishingOptions: string[];
    accessories: Accessory[];
}

export interface SpecHeader {
    categoryId?: number;
    supplierId?: number;
    styleNbr: string;
    deliveryVehicleNbr: string;
    customerNbr?: string;
    sequenceNbr?: number;
}