<mat-toolbar style="width: 100%;" color="primary">
    <span>Update {{ data.name }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
<form class="example-form dialog-update" #compUpdateForm="ngForm" (ngSubmit)="updateComp(compUpdateForm)">
    <div mat-dialog-content>
        <input type="hidden" [ngModel]="data.id" name="id">  
            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
             <mat-form-field appearance="outline">
                 <mat-label>Change Component Name</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.name" name="name">
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Change Component Ref ID</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.referenceId" name="referenceId" >    
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Change Component Group ID</mat-label>
                 <mat-select [ngModel]="data.groupId" name="groupId" ngModel required>
                    <mat-option *ngFor="let e of groupIdList" [value]="e.groupIdValue">
                       {{ e.groupIdValue }} / {{ e.description }}
                    </mat-option>
                </mat-select>
             </mat-form-field>
             <mat-checkbox color="accent" [ngModel]="data.active" name="active">Active</mat-checkbox>
            </mat-card-content>
    </div>

    <div mat-dialog-actions align="end">
      <button mat-dialog-close type="submit" mat-raised-button color="accent" [disabled]="!compUpdateForm.valid">Update</button>
    </div>
</form>
