<table mat-table #table [dataSource]="componentList">

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element;">{{element.compType || ''}}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element;">{{element.name}}</td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>Quantity</th>
    <td mat-cell *matCellDef="let element;">
      <mat-form-field *ngIf="editable; else noEdit" appearance="outline" class="swatch-form-field" style="padding-bottom: 1px;" [style.fontSize.px]="13">
        <!-- <input matInput type="number" [max]="maxQty" maxlength="6" positiveNumber [allowDecimal]="false" [maxValue]="maxQty" autocomplete="off" [(ngModel)]="element.quantity" placeholder="0" (blur)="onQtyChange(element)"> -->
        <input matInput [maxlength]="maxQty" positiveNumber [allowDecimal]="false" autocomplete="off" [(ngModel)]="element.quantity" placeholder="0" (blur)="onQtyChange(element)">
      </mat-form-field>
      <ng-template #noEdit>{{(element.quantity != "" && element.quantity != undefined) ? element.quantity : 0}}</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="uom">
    <th mat-header-cell *matHeaderCellDef>Unit of Measure</th>
    <td mat-cell *matCellDef="let element;">{{element.uom}}</td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let element;">{{element.description}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
</table>