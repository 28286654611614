import { Component, Input, OnInit } from '@angular/core';

import { EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { Spec, SpecRequestDto } from 'src/app/core/models/spec-models';
import { PhotopackFormValue } from '../../core/models/photopack.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { PhotoPackService } from '../services/photo-pack.service';

@Component({
  selector: 'app-edit-photopack',
  templateUrl: './edit-photopack.component.html',
  providers: [PhotoPackService]
})
export class EditPhotopackComponent implements OnInit {
  @Input() specId: number = 0; 
  @Input() categoryId: number = 0; 
  @Input() isCopySpec: boolean = false; 

  componentLists: ComponentLists = {} as ComponentLists; 
  
  constructor(
    private specService: SpecBackendService,
    public photoService: PhotoPackService
  ) { }

  ngOnInit(): void {
    this.specService.getComponetListByCategoryId(this.categoryId)
      .pipe(
        tap(lists => this.componentLists = lists),
        concatMap(_ => this.specId > 0 ? this.specService.getSpecById(this.specId) : EMPTY)
      )
      .subscribe(data => {
        if(data) {
          if(this.isCopySpec) {
            this.photoService.copySpec(data);
          } else {
            this.photoService.setForm(data);
          }
        }
      });
  }

  submitForm(): void {
    if(this.isCopySpec) {
      this.copySpec();
    } else {
      this.updateSpec();
    }
  }

  close(): void {
    this.specService.closeEditDialog();
  }

  private copySpec(): void {
    const requests: SpecRequestDto[] = []; 
    const photopack = <PhotopackFormValue>this.photoService.detailForm.value;

    photopack.photopacks.forEach(pack => {
      let request = new SpecRequestDto(this.photoService.headerForm.value);
      request.id = 0; 

      if(request.categoryId === 0) {
        request.categoryId = this.categoryId;
      }

      request.setPhotopackDetails(this.photoService.detailForm.value);
      request.setPhotopack(pack);
      requests.push(request);
    });
    
    if(requests.length > 0) {
      this.specService.createSpec(requests[0]);
    }
  }

  private updateSpec(): void {
    // const request = new SpecRequestDto(this.photoService.getLoadedSpec() || <Spec>this.photoService.headerForm.value)
    const request = new SpecRequestDto(<Spec>this.photoService.headerForm.value)
    const photopack = <PhotopackFormValue>this.photoService.detailForm.value;

    if(request.id === 0 || request.categoryId === 0) {
      request.id = this.specId;
      request.categoryId = this.categoryId;
    }

    request.setPhotopackDetails(photopack);
    request.setPhotopack(photopack.photopacks[0]);
    
    this.specService.updateSpec(request);
  }
}
