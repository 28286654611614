export interface PhotopackSpec {
    coverStock: string;
    coverColors: string; 
    coverFilm: string; 
    pageStock: string; 
    pageColors: string;
    pageFilm: string;
    finishedSize: string;
    type: string;
    photopacks: PhotopackOption[];
}

export interface PhotopackFormValue {
    coverStock: number;
    coverColors: number; 
    coverFilm: number; 
    pageStock: number; 
    pageColors: number;
    pageFilm: number;
    finishedSize: number;
    type: number;
    photopacks: PhotopackOption[];
}

export interface PhotopackOption {
    finishing1: string | number; 
    finishing2: string | number; 
    pageCount: string | number;
}

export enum PhotopackTypes {
    WaterFall = 'waterfallPageCount',
    SaddlePage = 'saddlePageCount'
}