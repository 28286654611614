<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" class="center-text">
        <mat-card>

            <mat-form-field>
                <mat-label>Category Type</mat-label>
                <mat-select (selectionChange)="categoryChange($event)" [(ngModel)]="selectedCategory">
                    <mat-option [value]="0">None</mat-option>
                    <mat-option *ngFor="let option of categoryList$ | async" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card>
    </div>
</div>

<ng-template specCollectionHost></ng-template>