import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export interface ButtonRendererParams extends ICellRendererParams {
  buttons: ButtonConfig[]; 
}

export interface ButtonConfig {
  buttonText?: string; 
  clickEvent?: any;
  buttonColor?: string; 
  buttonIcon?: string;
  buttonTooltip?: string;
}

@Component({
  selector: 'app-button-renderer',
  template: `
    <!-- <button *ngIf="showTextButton" type="button" mat-raised-button [color]="btnColor" (click)="onClick()">{{ btnName }}</button> -->
    <!-- <button *ngIf="showAddIconBtn" type="button" mat-icon-button [color]="btnColor" (click)="onClick()">
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="showDeleteIconBtn" type="button" mat-icon-button [color]="btnColor" (click)="onClick()">
      <mat-icon>delete</mat-icon>
    </button> -->
    <!-- <button *ngIf="showIconBtn" type="button" mat-icon-button [color]="btnColor" (click)="onClick()">
      <mat-icon>{{ matIconName }}</mat-icon>
    </button> -->

    <ng-container *ngFor="let button of buttons; let idx = index">
      <button *ngIf="button.buttonText" type="button" mat-raised-button [color]="button.buttonColor" (click)="onClick(idx)">{{ button.buttonText }}</button>
      <button *ngIf="button.buttonIcon" type="button" mat-icon-button [color]="button.buttonColor" (click)="onClick(idx)">
        <mat-icon [matTooltip]="button.buttonTooltip || ''">{{ button.buttonIcon }}</mat-icon>
      </button>
    </ng-container>
    `,
  styles: [
  ]
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams; 
  buttons: ButtonConfig[] = [];

  // buttonText!: string; 
  // btnName!: string; 
  // btnColor!: string;
  // showDeleteIconBtn: boolean = false;
  // showAddIconBtn: boolean = false;
  // showIconBtn: boolean = false;
  // showTextButton: boolean = true;
  // matIconName: string = ''; 


  agInit(params: ButtonRendererParams): void {
    this.params = params;
    this.buttons = params.buttons;

    // this.btnName = params.btnName || 'Click Me!'; 
    // this.btnColor = params.color || 'primary';
    // this.showDeleteIconBtn = params.showDeleteIconBtn;
    // this.showAddIconBtn = params.showAddIconBtn
    // this.showIconBtn = params.matIconName;
    // this.matIconName = params.matIconName;

    // this.showTextButton = !this.showDeleteIconBtn && !this.showAddIconBtn && !this.showIconBtn;
  }

  refresh(params: any): boolean {
    return true;
  }

  onClick(idx: number): void {
    // if(this.params.clicked) {
    //   // if(this.showDeleteIconBtn) {
    //   //   this.params.clicked(this.params.rowIndex);
    //   //   return;
    //   // }

    //   this.params.clicked(this.params);
    // }

    if(this.buttons[idx].clickEvent) {
      this.buttons[idx].clickEvent(this.params);
    }
  }
}