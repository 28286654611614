import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminCategoryManageComponent } from '../admin-category-manage/admin-category-manage.component';
import { ComponentListTableDialogComponent } from '../component-list-table-dialog/component-list-table-dialog.component';
import { ManageComponentListDialogComponent } from '../manage-component-list-dialog/manage-component-list-dialog.component';

@Component({
  selector: 'app-admin-component-list-manage',
  templateUrl: './admin-component-list-manage.component.html',
  styleUrls: ['./admin-component-list-manage.component.scss'],
  providers: [AdminCategoryManageComponent]
})
export class AdminComponentListManageComponent implements AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<ComponentDTO>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'categoryId', 'categoryName', 'groupId', 'active'];
  displayedColumns = this.dataColumns;

  constructor(
    private adminService: AdministrationService,
    public dialog: MatDialog,
    public adminCatComp: AdminCategoryManageComponent
  ) { }

  ngAfterViewInit(): void {
    this.gettingComponents();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  gettingComponents() {
    this.adminService.getComponents().subscribe((response: any) => { 
      this.dataSource.data = response
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCompListtTableDialog() {
    this.dialog.open(ComponentListTableDialogComponent);
  }

  openCompListManageDialog(data: ComponentDTO) {
    this.dialog.open(ManageComponentListDialogComponent, {
      data: {
        componentId: data.id,
        componentName: data.name,
        active: data.active,
        canBid: true,
        compReferenceId: data.referenceId,
      }
    });
  }
}