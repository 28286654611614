import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Role, RolePermissionResponse } from '../models/role-permission-model';
import { ConfigModel } from 'src/app/core/models/config-model';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {

  public isPendingChanges: boolean = false;
  
  constructor(private httpClient: HttpClient) {}

  public getRolePermission(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${ConfigModel.API_URL}/v1/rolepermission`)
  }

  public getRolePermissionById(roleId: number): Observable<Role> {
    return this.httpClient.get<Role>(`${ConfigModel.API_URL}/v1/rolepermission/${roleId}`)
  }

  public updateRoleInfo(request: Role, roleId: number): Observable<RolePermissionResponse> {
    return this.httpClient.put<RolePermissionResponse>(`${ConfigModel.API_URL}/v1/rolepermission/update/${roleId}`, request)
  }

  public deleteRoleInfo(roleId: number): Observable<RolePermissionResponse> {
    return this.httpClient.delete<RolePermissionResponse>(`${ConfigModel.API_URL}/v1/rolepermission/delete/${roleId}`)
  }

  public createRole(request: Role): Observable<RolePermissionResponse> {
    return this.httpClient.post<RolePermissionResponse>(`${ConfigModel.API_URL}/v1/rolepermission/create`, request)
  }
}
