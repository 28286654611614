import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private readonly durationSeconds: number = 10;
  private readonly horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  private readonly verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) { }

  public success(msg: string): void {
    this.open(msg, 'snack-success');
  }

  public error(msg: string): void {
    this.open(msg, 'snack-error');
  }

  public warn(msg: string): void {
    this.open(msg, 'snack-warn');
  }

  public info(msg: string): void {
    this.open(msg, 'snack-info');
  }

  private open(msg: string, classType: string) {
    this._snackBar.open(msg, 'dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      // duration: this.durationSeconds * 1000,
      panelClass: ['csd-snack', classType]
    });
  }
  showError(msg: string): void {
    this._snackBar.open(msg, 'dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationSeconds * 1000,
      panelClass: ['error-snackbar']
    });
  }
}
