import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpecDTO } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { SpecHistoryService } from 'src/app/spec-history/services/spec-history.service';
import { SpecDetailHistoryDialogComponent } from 'src/app/spec-history/spec-detail-history-dialog/spec-detail-history-dialog.component';
import { ComponentListDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';

@Component({
  selector: 'app-active-check-complist-dialog',
  templateUrl: './active-check-complist-dialog.component.html',
  styleUrls: ['./active-check-complist-dialog.component.scss'],
  providers: [AdministrationService, SpecHistoryService]
})
export class ActiveCheckComplistDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<SpecDTO>(this.data.info);
  dataColumns: String[] = ['id', 'supplierName', 'styleNbr', 'skuNbr', 'deliveryVehicleNbr', 'customerNbr', 'sequenceNbr', 'newCompListId', 'viewSpec'];
  displayedColumns = this.dataColumns;

  compList: ComponentListDTO[] = [];
  testReQuestList: SpecDTO[] = [];

  constructor(public dialogRef: MatDialogRef<ActiveCheckComplistDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackService: SnackBarService,
              public dialog: MatDialog,
              private adminService: AdministrationService,
              private common: CommonService,
              private specHistoryService: SpecHistoryService,
  ) { }

  ngAfterViewInit(): void {
    this.snackService.warn('There are existing specs that use the component list value you are attempting to deactivate. Please change those spec\'s value in order to deactivate the current component list value.');
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getComponentListsById();
  }

  close(): void {
    this.dialogRef.close();
  }

  openSpecHistory(specId: number) {
    let historyId: number;
    this.specHistoryService.getRecentSpecHistory(specId).subscribe((response: any) => {
      historyId = response.id;
      this.common.openSpecDetailHistoryDialog(historyId);
    })
  }

  getComponentListsById() {
    this.adminService.getComponentListsById(this.data.componentId).subscribe(response => {
      this.compList = response.filter(row => (row.value !== this.data.componentListName) && (row.active != false))
    });
  }

  update() {
    let count: number = 0;
    this.testReQuestList = this.dataSource.data;
    this.testReQuestList.forEach(row => {
      if (row.newCompListId != undefined && row.newCompListId > 0) {
        count++;
      }
    })

    if (count == this.testReQuestList.length) {
      this.adminService.updateSpecCompListId(this.testReQuestList, this.data.compListId).subscribe(response => {
        this.snackService.success('Specs successfully updated.')
      },
      (e: any) => {
        this.snackService.error('An error occurred while updating specs. If problem persist, please contact support.');
        console.error(e);
      },
      () => {
        this.close();
      });
    }

    else {
      this.snackService.error(`You must change all the values of ${this.data.componentName} for every spec before deactivating the component list value.`)
    }
  }

  replaceCompList(newCompListId: number, specId: number) {
    this.dataSource.data.forEach(row => {
      if (specId == row.id) {
        row.newCompListId = newCompListId;
      }  
    })
  }

}


