import { Component, OnInit } from '@angular/core';

import { ColDef, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { ThemeService } from '../core/services/theme.service';
import { SpecLibraryPricingReportService } from './services/spec-library-pricing-report.service';
import { SnackBarService } from '../core/services/snack-bar.service';
import { ButtonConfig, ButtonRendererComponent } from '../core/renderers/button-renderer.component';

@Component({
  selector: 'app-spec-table',
  template: `
    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
      <div fxFlex="100">
        <div  style="max-height: 500px; overflow-x: auto;">
          <ag-grid-angular
            style="width: 100%;"
            domLayout="autoHeight"
            [singleClickEdit]="true"
            [stopEditingWhenCellsLoseFocus]="true"
            [defaultColDef]="defaultColDef"
            [ngClass]="themeService.agGridTheme"
            [rowData]="reportService.specs"
            [pinnedTopRowData]="reportService.topRowData"
            [columnDefs]="columnDefs"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class SpecTableComponent implements OnInit {
  constructor(
    public themeService: ThemeService, 
    public reportService: SpecLibraryPricingReportService, 
    private snackService: SnackBarService
  ) { }

  ngOnInit(): void { }

  
  defaultColDef: ColDef = {
    flex: 1, 
    editable: true, 
    valueFormatter: (params: ValueFormatterParams): any => 
      this.isEmptyPinnedCell(params) ? this.setPlaceholder(params) : undefined,     
  }

  columnDefs: ColDef[] = [
    { 
      field: 'skuNumber', 
      headerName: 'SKU Number', 
      cellEditor: 'agTextCellEditor', 
      cellEditorParams: { maxLength: 5 }
    }, 
    { 
      field: 'styleNumber', 
      headerName: 'Style Number *', 
      valueSetter: (params) => {
        let isTopRow = params.node?.rowPinned === 'top';

        if(!params.newValue && !isTopRow) {
          this.snackService.warn('Invalid style number entered. Style number cannot be empty.');
          return false;
        }

        params.data.styleNumber = params.newValue;
        return true;
      }, 
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 5 }
    }, 
    { 
      field: 'deliveryVehicle', 
      headerName: 'Delivery Vehicle *', 
      valueSetter: (params) => {
        let isTopRow = params.node?.rowPinned === 'top';

        if(!params.newValue && !isTopRow) {
          this.snackService.warn('Invalid delivery vehicle entered. Delivery vehicle cannot be empty.');
          return false;
        }

        params.data.deliveryVehicle = params.newValue;
        return true;
      }, 
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 3 }
    }, 
    { 
      field: 'customerNumber', 
      headerName: 'Customer Number', 
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 7 }
    }, 
    { 
      field: 'sequence', 
      headerName: 'Sequence', 
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 1, 
        max: 99, 
        precision: 0
      }, 
      valueSetter: (params) => {
        if(params.newValue === null) {
          params.data.sequence = undefined; 
        } else {
          params.data.sequence = params.newValue; 
        }

        return true;
      }
    }, 
    { 
      cellRendererSelector: params => {
        if(params.node.rowPinned === 'top') {
          return {
            component: ButtonRendererComponent, 
            params: { buttons: [
              { buttonColor: 'secondary', buttonIcon: 'restart_alt', clickEvent: this.resetAddRow.bind(this), buttonTooltip: 'Reset Spec' }, 
              { buttonColor: 'primary', buttonIcon: 'add', clickEvent: this.add.bind(this), buttonTooltip: 'Add Spec' }, 
            ] as ButtonConfig[]}
          }
        }

        return {
          component: ButtonRendererComponent, 
          params: { buttons: [{ buttonColor: 'warn', buttonIcon: 'delete', clickEvent: this.deleteSpec.bind(this), buttonTooltip: 'Remove Spec' }] as ButtonConfig[] }
        }
      }, 
      editable: false, 
      type: 'rightAligned',
    }
  ]; 

  public onGridReady(params: GridReadyEvent): void {
    this.reportService.specGridApi = params.api;
  }

  public test(): void {
    alert('Hello World'); 
  }

  public deleteSpec(data: any): void {
    this.reportService.deleteSpecByIndex(data.rowIndex); 
  }
  
  public add(data: any): void {
    this.reportService.addSpec();
  }

  public resetAddRow(): void {
    this.reportService.resetAddSpecRow();
  }

  private isEmptyPinnedCell(params: ValueFormatterParams): boolean {
    return (params.node?.rowPinned === 'top' && 
      (params.value === undefined || params.value === null || params.value === ''));
  }

  public setPlaceholder(params: ValueFormatterParams) {
    return params.colDef.headerName || 'Enter Value...';
  }
}
