<div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
    <div fxFlex="96" fxFlex.lt-md="96" fxFlex.xs="100" class="ml-1 mt-10">

        <mat-toolbar style="width: 100%;" color="primary">
            <span>Spec Library Table</span>
        </mat-toolbar>

        <mat-card>
            <div style="height: auto; max-height: 560px; overflow: auto;">

                <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">

                    <ng-container matColumnDef="categoryName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                        <td mat-cell *matCellDef="let element">{{ element.categoryName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="supplierName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Supplier</th>
                        <td mat-cell *matCellDef="let element">{{ element.supplierName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="styleNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Style Number</th>
                        <td mat-cell *matCellDef="let element">{{ element.styleNbr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="skuNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>SKU Number</th>
                        <td mat-cell *matCellDef="let element">{{ element.skuNbr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="deliveryVehicleNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Vehicle</th>
                        <td mat-cell *matCellDef="let element">{{ element.deliveryVehicleNbr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="customerNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Number</th>
                        <td mat-cell *matCellDef="let element">{{ element.customerNbr }}</td>
                    </ng-container>
                    <ng-container matColumnDef="sequenceNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence</th>
                        <td mat-cell *matCellDef="let element">{{ element.sequenceNbr || ''}}</td>
                    </ng-container>
                    <ng-container matColumnDef="createdByName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                        <td mat-cell *matCellDef="let element">{{ element.createdByName }}</td>
                    </ng-container>
                    <ng-container matColumnDef="createdTimestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
                        <td mat-cell *matCellDef="let element">{{ (element.createdTimestamp | date) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="changedByName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By</th>
                        <td mat-cell *matCellDef="let element">{{ element.changedByName || '-' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="changedByTimestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed Date</th>
                        <td mat-cell *matCellDef="let element">{{ (element.changedByTimestamp | date) || '-' }}</td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" style="text-align: end;">                            
                            <button type="button" mat-icon-button matTooltip="View/Edit spec details"
                                    *hasPermission="['EDIT_SPEC']"
                                    (click)="edit(element.specId, element.categoryId, element.categoryRefId)">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                            <button type="button" mat-icon-button matTooltip="View spec history"
                                    (click)="clickHistory(element.specId)">
                                <mat-icon>history</mat-icon>
                            </button>
                            <button type="button" mat-icon-button matTooltip="Copy spec"
                                    *hasPermission="['EDIT_SPEC']"
                                    (click)="clickCopy(element.specId, element.categoryId, element.categoryRefId)">
                                <mat-icon color="accent">content_copy</mat-icon>
                            </button>
                            
                            <!-- <button type="button" mat-icon-button [disabled]="true">
                                <mat-icon color="warn">delete_forever</mat-icon>
                            </button> -->
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" class="mat-table-header-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="10">No Results Found</td>
                    </tr>
                </table>

            </div>

            <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"
                            [pageSize]="10" 
                            showFirstLastButtons>
            </mat-paginator>

        </mat-card>
    </div>
</div>