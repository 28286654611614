<div class="homepageBackground mat-elevation-z4">
    <div fxLayoutAlign="center" >
        <h1 class="heading">Welcome to Shaw Automated Vendor Experience</h1>
    </div>
</div>


<div fxLayoutAlign="center" class="docs-component-category-list" style="padding-top: 0px;">
        <a class="docs-component-category-list-item" *hasPermission="['VIEW_SPEC_LIBRARY_PRICING','EDIT_SPEC_LIBRARY_PRICING']" routerLink="/spec-library-pricing" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">request_quote</mat-icon>  
                    Spec Library Pricing
                </mat-card-title>
                <mat-card-content>
                    <small>Select to navigate to the Spec Library Pricing screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_SPEC_LIBRARY_PRICING','EDIT_SPEC_LIBRARY_PRICING']; allowVendor: false" routerLink="/spec-library-pricing-report" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">payments</mat-icon>  
                    Spec Library Pricing Report
                </mat-card-title>
                <mat-card-content>
                    <small>Select to navigate to the Spec Library Pricing Report screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_SPEC', 'EDIT_SPEC']" routerLink="/spec-library" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">menu_book</mat-icon>  
                    Spec Library
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary">
                    <small>Select to navigate to the Spec Library screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_SPEC', 'EDIT_SPEC']" routerLink="/spec-collection" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">post_add</mat-icon>  
                    Spec Collection Tool
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Spec Collection Tool screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_VENDOR_PROFILE', 'EDIT_VENDOR_PROFILE']" routerLink="/vendor-profile" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">account_circle</mat-icon>  
                    Vendor Profile
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Vendor Profile screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_MANUAL_SPEC_PRICING','EDIT_MANUAL_SPEC_PRICING']" routerLink="/manual-spec-pricing" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">price_change</mat-icon>  
                    Manual Spec Pricing
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Manual Spec Pricing screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_VENDOR_BID', 'EDIT_VENDOR_BID']" routerLink="/vendor-bid" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">gavel</mat-icon>  
                    Vendor Bidding
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Vendor Bidding screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_SECURITY', 'EDIT_SECURITY']" routerLink="/role-permission" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">security</mat-icon>  
                    Role Permissions
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Role Permissions screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_USER_PROFILE','EDIT_USER_PROFILE']" routerLink="/user-profiles" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">account_box</mat-icon>  
                    User Profiles
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the User Profiles screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_ADMIN','EDIT_ADMIN']" routerLink="/administration" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">admin_panel_settings</mat-icon>  
                    Administration
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the Admin screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

        <a class="docs-component-category-list-item" *hasPermission="['VIEW_ADMIN','EDIT_ADMIN']" routerLink="/app-settings" [routerLinkActive]="['active']">
            <mat-card class="docs-component-category-list-card mat-card">
                <mat-card-title class="mat-card-title">
                    <mat-icon mat-list-icon color="accent">settings</mat-icon>  
                    Application Settings
                </mat-card-title>
                <mat-card-content class="docs-component-category-list-card-summary mat-card-content">
                    <small>Select to navigate to the App Settings screen.</small>
                </mat-card-content>
            </mat-card>
        </a>

<div>

