import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/internal/operators';

import { SpecDetailDTO, SpecDTO } from 'src/app/core/models/spec-models';
import { ALPHA_NUMERIC_REGEX, CUST_NBR_REGEX } from 'src/app/core/models/validation-models';
import { UserService } from 'src/app/core/services/user.service';
import { PhotopackFormValue, PhotopackOption, PhotopackSpec, PhotopackTypes } from '../../core/models/photopack.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';

@Injectable()
export class PhotoPackService implements OnDestroy {
  private readonly destory$: Subject<void> = new Subject(); 

  public headerForm!: FormGroup;
  public detailForm!: FormGroup;

  readonly reviewSpec$: Observable<PhotopackSpec>;
  readonly isDetailFormInvalid$: Observable<boolean>;

  get photopacks() { return <FormArray>this.detailForm.get('photopacks'); }
  get type() { return <FormControl>this.detailForm.get('type'); }

  private spec!: SpecDTO;
  
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private specService: SpecBackendService
  ) { 
    this.init();

    this.reviewSpec$ = this.detailForm.valueChanges.pipe(map(form => this.mapSpecReview(form)));

    const mergeForms$ = merge(this.detailForm.statusChanges, this.headerForm.statusChanges);

    this.isDetailFormInvalid$ = this.detailForm.statusChanges.pipe(
      map(() => this.detailForm.invalid),
      debounceTime(1000)
    );

    mergeForms$.pipe(
        takeUntil(this.destory$),
        map(() => this.detailForm.dirty || this.headerForm.dirty)
      )
      .subscribe(isPending => this.specService.isPendingChanges = isPending);
  }

  ngOnDestroy(): void {
    this.destory$.next(); 
    this.destory$.complete(); 
  }

  public setForm(spec: SpecDTO): void {
    this.spec = spec;

    this.headerForm.patchValue({
      styleNbr: spec.styleNbr || '',
      skuNbr: spec.skuNbr || '',
      deliveryVehicleNbr: spec.deliveryVehicleNbr || '',
      customerNbr: spec.customerNbr || '',
      sequenceNbr: spec.sequenceNbr || ''
    });

    if(!this.userService.isVendor) {
      this.headerForm.patchValue({supplierId: spec.supplierId || ''});
    }

    this.setSpecDetails(); 
  }

  public copySpec(spec: SpecDTO): void {
    this.spec = spec; 
    this.setSpecDetails();
  }

  public addPhotopackForm(photopack: SpecDetailDTO[]): void {
    this.photopacks.push(this.fb.group({
      finishing1: [this.getSpecValue(photopack, 'finishing1', 1), Validators.required],
      finishing2: [this.getSpecValue(photopack, 'finishing1', 2)],
      pageCount: [this.getPageCount(photopack), Validators.required]
    }));
  }

  // public getLoadedSpec(): SpecDTO | undefined {
  //   return this.spec;
  // }

  private setSpecDetails(): void {
    this.detailForm.patchValue({
      coverStock: this.getValue('outsideStock'),
      pageStock: this.getValue('insideStock'), 
      coverColors: this.getValue('outsideColorsCover'), 
      pageColors: this.getValue('insideColorsCover'), 
      coverFilm: this.getValue('outsideFilm'), 
      pageFilm: this.getValue('insideFilm'),
      finishedSize: this.getValue('sizeFactorGrid'), 
      type: this.getValue('photopackType'),
      photopacks: [{
        finishing1: this.getValue('finishing1', 1),
        finishing2: this.getValue('finishing1', 2),
        pageCount: this.getPageCount()
      }]     
    });
  }

  private init() {
    this.headerForm = this.fb.group({
      styleNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      skuNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      deliveryVehicleNbr: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      customerNbr: ['', [Validators.maxLength(7), Validators.pattern(CUST_NBR_REGEX)]],
      sequenceNbr: ['', Validators.max(99)]
    });

    if(!this.userService.isVendor) {
      this.headerForm.addControl('supplierId', new FormControl('', Validators.required));
    }

    this.detailForm = this.fb.group({
      coverStock: ['', Validators.required],
      pageStock: ['', Validators.required], 
      coverColors: ['', Validators.required], 
      pageColors: ['', Validators.required], 
      coverFilm: ['', Validators.required], 
      pageFilm: ['', Validators.required],
      finishedSize: ['', Validators.required], 
      type: ['', Validators.required],
      photopacks: this.fb.array([]),      
    });

    this.addPhotoPack();
  }
  

  addPhotoPack(): void {
    this.photopacks.push(this.getPhotopackFormGroup());
  }

  removePhotopack(index: number): void {
    this.photopacks.removeAt(index);
  }

  resetPhotopackPageCounts(): void {
    this.detailForm.get('pageCount')?.patchValue('');
    this.photopacks.controls.forEach(pack => pack.patchValue({pageCount: ''}));
  }

  private mapSpecReview(data: PhotopackFormValue): PhotopackSpec {
    return {
      coverStock: this.specService.getIdValue(data.coverStock),
      coverColors: this.specService.getIdValue(data.coverColors),
      coverFilm: this.specService.getIdValue(data.coverFilm),
      pageStock: this.specService.getIdValue(data.pageStock),
      pageColors: this.specService.getIdValue(data.pageColors),
      pageFilm: this.specService.getIdValue(data.pageFilm),
      finishedSize: this.specService.getIdValue(data.finishedSize), 
      type: this.specService.getIdValue(data.type),
      photopacks: this.getPhotopacksSpec(data.photopacks)
    }
  }

  private getPhotopackFormGroup(): FormGroup {
    return this.fb.group({
      finishing1: ['', Validators.required],
      finishing2: [''],
      pageCount: ['', Validators.required]
    });
  }

  private getPhotopacksSpec(packs: PhotopackOption[] | undefined): PhotopackOption[] {
    if(!packs || packs.length === 0) {
      return [];
    }

    return packs.map(pack => {
      return<PhotopackOption>{
        finishing1: this.specService.getIdValue(<number>pack.finishing1),
        finishing2: this.specService.getIdValue(<number>pack.finishing2),
        pageCount: this.specService.getIdValue(<number>pack.pageCount)
      }
    });
  }

  private getPageCount(details?: SpecDetailDTO[]): number | string {    

    let value = details ? this.getSpecValue(details, PhotopackTypes.WaterFall) : this.getValue(PhotopackTypes.WaterFall);

    if(!value) {
      value = details ? this.getSpecValue(details, PhotopackTypes.SaddlePage) : this.getValue(PhotopackTypes.SaddlePage);
    }

    return value;
  }

  private getValue(property: string, sequence: number = 0): number | string {
    if (!this.spec || !this.spec.specDetails) {
      return '';
    }

    if(sequence > 0) {
      return this.spec.specDetails.find(s => s.componentRefId === property && s.sequence === sequence)?.componentListId || ''; 
    }

    return this.spec.specDetails.find(s => s.componentRefId === property)?.componentListId || '';
  }

  private getSpecValue(details: SpecDetailDTO[], property: string, sequence: number = 0): number | string {
    if(!details || details.length === 0) {
      return ''; 
    }

    if(sequence > 0) {
      return details.find(detail => detail.componentRefId === property && detail.sequence === sequence)?.componentListId || ''; 
    }

    return details.find(detail => detail.componentRefId === property)?.componentListId || '';
  }
}