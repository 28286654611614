import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Swatch } from "src/app/core/models/spec-models";
import { MAX_SWATCH_QTY_VALUE } from "../models/swatching.model";

@Component({
  selector: 'app-swatching-table',
  templateUrl: './swatching-table.component.html',
  styles: [`
    .swatch-form-field .mat-form-field-wrapper { padding-bottom: 1px; width: 50%; }
    table { width: 100%; }
    th.mat-header-cell, td.mat-cell { 
      padding-left: 5px; 
      padding-right: 5px; 
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class SwatchingTableComponent implements OnInit {
  @Input() componentList!: Swatch[];
  @Input() editable: boolean = true;
  @Input() showType: boolean = false;

  @Output() valueChange = new EventEmitter<{id: number, qty: number}>();

  displayedColumns = ['name', 'quantity', 'uom', 'description'];
  maxQty: number = String(MAX_SWATCH_QTY_VALUE).length; 

  ngOnInit(): void {
    if(this.showType) {
      this.displayedColumns = ['type'].concat(this.displayedColumns); 
    }
  }

  onQtyChange(value: Swatch) {
    this.valueChange.emit({id: value.id, qty: value.quantity || 0});
  }
}
