<div fxLayout="row wrap">
    <mat-toolbar style="width: 100%;" color="primary">
        <span>{{ isCopySpec ? 'Copy ' : 'Edit '}}  Panel Backer Board</span>
        <span class="example-spacer"></span>
        <div fxFlex="10" fxLayoutAlign="end" >
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
</div>

<div *hasPermission="['EDIT_SPEC']" fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">
        <app-swatching-header [headerForm]="backerService.headerForm"></app-swatching-header>
    </div>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-dialog-content>
            <app-backer-board [componentLists]="componentLists" (submitForm)="submitForm()"></app-backer-board>
        </mat-dialog-content>
    </div>
</div>