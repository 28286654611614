import { ComponentGroupList } from "src/app/core/models/common-models";

export const NOT_AVAILABLE = 'N/A';

export interface RoutingToolData {
    type: string; 
    refId: string;
    value: number;
    group?: number;
    qty: number;
    lists?: ComponentGroupList[];
    isQty?: boolean; 
    isCheckbox?: boolean;    
    isRequired?: boolean;
    changeAction?: Function;
    // pricing?: Map<number, number | undefined>;
    pricing?: Map<number, {
        price: number | undefined; 
        isMissingBid: boolean; 
        isNoBid: boolean;
        priceStatus: string;
    }>;
}

export interface QuantityFactorGrid {
    quantity: number | null;
    refId: string;
    value: number;
    tier: string; 
    lists?: ComponentGroupList[];
    pricing?: Map<number, number | undefined>;
}

export interface VendorPricingHeader {
    vendorId: number;
    vendorName: string;
    pricing?: VendorPricingDetails[];
    unitPrice?: number;
    totalPrice?: number;
    diffTotalPrice?: number;
    diffUnitPrice?: number;
    unitPriceText?: string;
}

export interface VendorPricingDetails {
    componentListId: number;
    bidId: number; 
    price: number;
    qtyFactorGrid: number;
    qty: number;
    missingBid: boolean;
    noBid: boolean; 
}

export interface PriceDto {
    refId: string;
    groupNbr?: number;
    price?: number; 
    isMissingBid?: boolean;
    isNoBid?: boolean;
}

export interface SkuComparisonRequest {
    quantity: number | null;
    styleNumber: string; 
    deliveryVehicle: string; 
    customerNumber: string; 
    sequence: number | null;
}