<div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10" [formGroup]="group">
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Board Weight</mat-label>
        <mat-select formControlName="boardWeight">
            <mat-option *ngFor="let opt of componentLists?.boardWeight" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('boardWeight')?.invalid">
            <app-form-error [control]="group.get('boardWeight')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Stock</mat-label>
        <mat-select formControlName="coverStock">
            <mat-option *ngFor="let opt of componentLists?.coverStock" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverStock')?.invalid">
            <app-form-error [control]="group.get('coverStock')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Colors</mat-label>
        <mat-select formControlName="coverColors">
            <mat-option *ngFor="let opt of componentLists?.colorsCover" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverColors')?.invalid">
            <app-form-error [control]="group.get('coverColors')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Film</mat-label>
        <mat-select formControlName="coverFilm">
            <mat-option *ngFor="let opt of componentLists?.coverFilm" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverFilm')?.invalid">
            <app-form-error [control]="group.get('coverFilm')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Stock</mat-label>
        <mat-select formControlName="linerStock">
            <mat-option *ngFor="let opt of componentLists?.linerStock" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerStock')?.invalid">
            <app-form-error [control]="group.get('linerStock')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Colors</mat-label>
        <mat-select formControlName="linerColors">
            <mat-option *ngFor="let opt of componentLists?.colorsLiner" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerColors')?.invalid">
            <app-form-error [control]="group.get('linerColors')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="33" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Film</mat-label>
        <mat-select formControlName="linerFilm">
            <mat-option *ngFor="let opt of componentLists?.linerFilm" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerFilm')?.invalid">
            <app-form-error [control]="group.get('linerFilm')"></app-form-error>
        </mat-error>
    </mat-form-field>
</div>