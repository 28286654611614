import { StepperOrientation, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';

import { ComponentLists } from 'src/app/core/models/common-models';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { BackerBoardSpec } from './models/backer-board.model';
import { BackerBoardService } from './services/backer-board.service';

@Component({
  selector: 'app-backer-board',
  templateUrl: './backer-board.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ],
})
export class BackerBoardComponent implements OnInit {
  @Input() orientation: StepperOrientation = 'vertical'; 
  @Input() isStandalone: boolean = false;
  @Input() componentLists!: ComponentLists;
  @Output() submitForm = new EventEmitter();

  readonly maxAccessories: number = 10000;

  finishedSizeCtrl!: AbstractControl;
  boardWeightCtrl!: AbstractControl;
  accessoriesCtrl!: AbstractControl;

  specReview$: Observable<BackerBoardSpec>;
  
  constructor(public backerService: BackerBoardService, private snackService: SnackBarService) { 
    this.specReview$ = this.backerService.reviewSpec$;
  }

  ngOnInit(): void {
    this.finishedSizeCtrl = this.backerService.detailForm.controls['finishedSize'];
    this.boardWeightCtrl = this.backerService.detailForm.controls['boardWeight'];
    this.accessoriesCtrl = this.backerService.detailForm.controls['accessories'];
  }

  addAccessory(): void {
    this.backerService.addAccessory();
  }

  removeAccessory(idx: number): void {
    this.backerService.removeAccessory(idx);
  }

  submit(): void {
    if(this.backerService.headerForm.invalid || this.backerService.detailForm.invalid) {
      this.backerService.headerForm.markAllAsTouched();
      this.backerService.detailForm.markAllAsTouched();
      this.snackService.error('Cannot submit spec until invalid fields are corrected');
      return;
    }

    this.submitForm.emit();
  }

}
