import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject, from, EMPTY } from 'rxjs';
import { mergeMap, takeUntil, finalize, tap, filter, toArray } from 'rxjs/internal/operators';

import { SpecDTO } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SpecCollectionDirective } from 'src/app/spec-collection/core/directives/spec-collection.directive';
import { SpecHistoryService } from '../services/spec-history.service';

@Component({
  selector: 'app-spec-detail-history-dialog',
  templateUrl: './spec-detail-history-dialog.component.html',
  providers: [SpecHistoryService]
})
export class SpecDetailHistoryDialogComponent implements OnInit, OnDestroy {
  @ViewChild(SpecCollectionDirective, {static: true}) specCollectionHost!: SpecCollectionDirective;  

  public historyId!: number;
  public specDto!: SpecDTO;
  public childSpecs!: SpecDTO[]; 

  public isErrorState: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private historyService: SpecHistoryService,
    private common: CommonService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    if(this.data.historyId) {
      this.historyId = this.data.historyId;

      this.historyService.getSpecDetailHistory(this.historyId)
        .pipe(
          tap(spec => this.specDto = spec),          
          mergeMap(spec => from(spec.specDetails)),
          filter(details => details.childSpecId != undefined && details.childSpecId > 0 ),
          mergeMap(detail => detail.childSpecId && detail?.childSpecId > 0 ? this.historyService.getSpecDetailHistoryBySpecId(detail.childSpecId) : EMPTY), 
          takeUntil(this.destroy$),
          toArray(),
          finalize(() => this.loadDetails())
        )
        .subscribe(childDetails => this.childSpecs = childDetails);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadDetails(): void {
    try
    {
      const viewContainerRef = this.specCollectionHost.viewContainerRef;
      viewContainerRef.clear();
  
      const loader = this.common.getComponentByRefId(this.specDto.categoryRefId || '');
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loader.historyComponent);
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);
  
      componentRef.instance.specDto = this.specDto;
      if(this.childSpecs && this.childSpecs.length > 0) {
        componentRef.instance.childSpecs = this.childSpecs;
      }
    }
    catch (e) {
      console.error(e);
      this.isErrorState = true;
    }
  }  

}
