<mat-toolbar style="width: 100%;" color="primary">
    <span>Manage Component of {{ data.categoryName }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>

<div mat-dialog-content class="dialog-update">
    <form *hasPermission="['EDIT_ADMIN']" class="example-form" #createComponentForm="ngForm" (ngSubmit)="createComponent(createComponentForm)">
        <h2 mat-dialog-title>Create Component</h2>
        <input type="hidden" [ngModel]="data.categoryId" name="categoryId">  
            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
                <mat-form-field appearance="outline">
                    <mat-label>Create Component Name</mat-label>
                    <input matInput autocomplete="off" [ngModel]="data.name" name="name" required ngModel>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Create Component Ref ID</mat-label>
                    <input matInput autocomplete="off" [ngModel]="data.referenceId" name="referenceId" required ngModel>    
                </mat-form-field>
                
                <mat-form-field appearance="outline">
                    <mat-label>Create Component Group ID</mat-label>
                    <mat-select [ngModel]="data.groupId" name="groupId" required>
                        <mat-option *ngFor="let e of groupIdList" [value]="e.value">
                        {{ e.value }} / {{ e.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <div fxLayout="row wrap" fxLayoutAlign="center">
                <button type="submit" mat-raised-button color="accent" [disabled]="!createComponentForm.valid">Create</button>
                </div>
            </mat-card-content>
    </form>

    <div class="example-form">
        <br>
        <mat-divider></mat-divider>
        <br>
        <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
            <h2 mat-dialog-title>Update Component</h2>
        </ng-container>
        <ng-template #viewOnly>
            <h2 mat-dialog-title>View Component</h2>
        </ng-template>
        
        <table mat-table [dataSource]="dataSource" matSort>
    
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element;">
                    <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
                        <button type="button" class="btn-link" (click)="openUpdateCompDialog(element)" matTooltip="Manage {{ element.name }} ">
                            {{ element.name }}
                        </button>
                    </ng-container>
                    <ng-template #viewOnly>{{element.name}}</ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="referenceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.referenceId }}</td>
            </ng-container>

            <ng-container matColumnDef="categoryId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Category ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.parentId }}</td>
            </ng-container>

            <ng-container matColumnDef="groupId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Group ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.groupId }}</td>
            </ng-container>

            <ng-container matColumnDef="createdByName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell *matCellDef="let element;">{{ element.createdByName }}</td>
            </ng-container>

            <ng-container matColumnDef="createdTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.createdTimestamp | date) }}</td>
            </ng-container>

            <ng-container matColumnDef="changedByName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By</th>
                <td mat-cell *matCellDef="let element;">{{ element.changedByName || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="changedTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.changedTimestamp | date) || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                <td mat-cell *matCellDef="let element;">
                    <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>

        <mat-paginator #paginator
                            [pageSize]="15"
                            [pageSizeOptions]="[5, 15, 25, 50, 100]"
                            [showFirstLastButtons]="true">
        </mat-paginator>
    </div>
</div>
