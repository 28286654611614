<form [formGroup]="detailForm">
    <mat-stepper orientation="horizontal">

            <mat-step #step1 [hasError]="folderService.baseComponent.invalid && (folderService.baseComponent.touched || step1.interacted)">
                <ng-template matStepLabel>Base Components</ng-template>
                <ng-template matStepContent>
                    <app-base-component [componentLists]="folderLists" [group]="folderService.baseComponent"></app-base-component>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button type="button" mat-raised-button [color]="'primary'" matStepperNext>Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step2 [hasError]="folderService.isFlipUpOut && folderService.flipUpOutComponent.invalid && (folderService.flipUpOutComponent.touched || step2.interacted)">
                <ng-template matStepLabel>Flip Up/Out Components</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                        <mat-form-field fxFlex="24" [fxFlex.lt-md]="folderService.isFlipUpOut ? 48 : 100" appearance="outline">
                            <mat-label>Add Flip Up/Out</mat-label>
                            <mat-select [(ngModel)]="folderService.isFlipUpOut" [ngModelOptions]="{standalone: true}" 
                                        (selectionChange)="folderService.toggleFlipUpOut()">
                                <mat-option [value]='false'>No</mat-option>
                                <mat-option [value]='true'>Yes</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <ng-container *ngIf="folderService.isFlipUpOut" [formGroup]="folderService.flipUpOutCountQty">
                            <input type="hidden" formControlName="count">
                            <mat-form-field class="ml-2" fxFlex="24" fxFlex.lt-md="50" appearance="outline">
                                <mat-label>Flip Up/Out Count</mat-label>
                                <input type="text" matInput positiveNumber [allowDecimal]="false" maxlength="4" formControlName="qty" autocomplete="off">
                                <mat-error *ngIf="folderService.flipUpOutCountQty.get('qty')?.invalid">
                                    <app-form-error [control]="folderService.flipUpOutCountQty.get('qty')"></app-form-error>
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                    <app-flip-up-out *ngIf="folderService.flipUpOutComponent" [componentLists]="folderLists" [group]="folderService.flipUpOutComponent"></app-flip-up-out>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step3 [hasError]="detailForm.get('finishedSize')?.invalid && (detailForm.get('finishedSize')?.touched || step3.interacted)">
                <ng-template matStepLabel>Finished Size</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                        <mat-form-field fxFlex="24" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                            <mat-label>Size Factor</mat-label>
                            <mat-select formControlName="finishedSize">
                                <mat-option *ngFor="let opt of folderLists?.sizeFactorGrid" [value]="opt.id">{{ opt.value }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Finishing Options</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                        <div fxFlex="24" formArrayName="finishingOptions">
                            <p *ngFor="let opt of folderService.finishingOptions.controls; let i=index;" formGroupName="{{i}}">
                                <mat-checkbox color="primary" formControlName="selected">{{ opt.get('display')?.value }}</mat-checkbox>
                            </p>
                        </div>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step5 [hasError]="folderService.accessories.invalid && (folderService.accessories.touched || step5.interacted)">
                <ng-template matStepLabel>Accessory Options</ng-template>
                <ng-template matStepContent>

                    <app-accessories [maxAccessories]="maxAccessories" [group]="detailForm" [accList]="folderLists?.accessories" (addAccessory)="addAccessory()" (deleteAccessory)="removeAccessory($event)"></app-accessories>

                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step6 [hasError]="folderService.isPhotopack && !isPhotopackValid && (ppDetailForm.touched || step6.interacted)">
                <ng-template matStepLabel>Photopack</ng-template>
                <!-- <ng-template matStepContent> -->
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                        <mat-form-field fxFlex="24" fxFlex.lt-md="100" appearance="outline">
                            <mat-label>Add Photopack</mat-label>
                            <mat-select [(ngModel)]="folderService.isPhotopack" [ngModelOptions]="{standalone: true}">
                                <mat-option [value]='false'>No</mat-option>
                                <mat-option [value]='true'>Yes</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <app-photopack [hidden]="!folderService.isPhotopack"
                        [componentLists]="photopackLists"
                        [isStandalone]="false"
                        [orientation]="'vertical'"
                        [allowAddPhotopack]="true">
                    </app-photopack>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Review Spec</button>
                    </div>
                <!-- </ng-template> -->
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Review Spec</ng-template>
                <app-folder-review [specDetail]="specReview$ | async"></app-folder-review>
                <app-photopack-review [hidden]="!folderService.isPhotopack" [specDetail]="photopackService.reviewSpec$ | async"></app-photopack-review>
                <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <button type="button" mat-raised-button 
                            *hasPermission="['EDIT_SPEC']"
                            color="primary"
                            style="margin-left: 5px;" 
                            (click)="submit()">Submit</button>
                </div>
            </mat-step>

    </mat-stepper>
</form>