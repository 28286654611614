<div fxLayout="row wrap">
    <mat-toolbar style="width: 100%;" color="primary">
        <span>{{ (isCopySpec ? 'Copy ' : 'Edit ') + screenTitle }}</span>
        <span class="example-spacer"></span>
        <div fxFlex="10" fxLayoutAlign="end" >
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
</div>

<div *hasPermission="['EDIT_SPEC']" fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">
        <app-print-spec-header [headerForm]="multiService.headerForm" [categoryId]="categoryId"></app-print-spec-header>
    </div>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-dialog-content>
            <app-multi-spec [componentLists]="componentLists" (submitForm)="submitForm()"></app-multi-spec>
        </mat-dialog-content>
    </div>
</div>