import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';
import { merge } from 'rxjs/internal/observable/merge';
import { map, takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs/internal/Subject';

import { ComponentList } from 'src/app/core/models/common-models';
import { Accessory, FinishingOptions, SpecDetailDTO, SpecDTO } from 'src/app/core/models/spec-models';
import { ALPHA_NUMERIC_REGEX, CUST_NBR_REGEX } from 'src/app/core/models/validation-models';
import { UserService } from 'src/app/core/services/user.service';
import { FlipOutUpSpec, FolderFormValue, PrintFolderSpec } from '../../core/models/print-folder.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';

@Injectable()
export class FolderService implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  public headerForm!: FormGroup;
  public detailForm!: FormGroup;

  public countListId: number = 0; 

  public isFlipUpOut: boolean = false;
  public isPhotopack: boolean = false;

  readonly reviewSpec$: Observable<PrintFolderSpec>;

  private _spec!: SpecDTO;

  get accessories(): FormArray { return <FormArray>this.detailForm.get('accessories'); }
  get finishingOptions(): FormArray { return <FormArray>this.detailForm.get('finishingOptions'); }
  get baseComponent(): FormGroup { return this.detailForm.get('baseComponent') as FormGroup; }
  get flipUpOutComponent(): FormGroup { return this.detailForm.get('flipUpOutComponent') as FormGroup; }
  get flipUpOutCountQty(): FormGroup { return this.flipUpOutComponent.get('countQty') as FormGroup; }

  get spec(): SpecDTO | undefined { return this._spec; }

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private specService: SpecBackendService
  ) { 
    this.init();
    
    this.reviewSpec$ = this.detailForm.valueChanges.pipe(map(form => this.mapSpecReview(form)));

    merge(this.detailForm.statusChanges, this.headerForm.statusChanges)
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.detailForm.dirty || this.headerForm.dirty)
      )
      .subscribe(isPending => this.specService.isPendingChanges = isPending);
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete(); 
  }

  public setForm(spec: SpecDTO): void {
    this._spec = spec;

    this.headerForm.patchValue({
      styleNbr: spec.styleNbr || '',
      skuNbr: spec.skuNbr || '',
      deliveryVehicleNbr: spec.deliveryVehicleNbr || '',
      customerNbr: spec.customerNbr || '',
      sequenceNbr: spec.sequenceNbr || ''
    });

    if(!this.userService.isVendor) {
      this.headerForm.patchValue({supplierId: spec.supplierId || ''});
    }

    this.setSpecDetails(); 
  }

  public copySpec(spec: SpecDTO): void {
    this._spec = spec; 
    this.setSpecDetails();
  }

  public addAccessory(): void {
    this.accessories.push(this.getAccessoryFormGroup());
  }

  public removeAccessory(index: number): void {
    this.accessories.removeAt(index);
  }

  public setCountListId(list: ComponentList[] | undefined): void {
    if(!list || list.length <= 0) { return; }
    this.countListId = list[0].id; 
  }

  public setFinishingOptions(list: ComponentList[] | undefined): void {
    if(!list) { return; }

    list.forEach(option => {
      this.finishingOptions.push(this.fb.group({ selected: false, value: option.id, display: option.value }));
    });
  }

  public toggleFlipUpOut(): void {
    if(this.isFlipUpOut) {
      this.addFlipUpOutComponentRequired();
    } else {
      this.removeFlipUpOutComponentRequired();
    }
  }

  private setSpecDetails(): void {
    this.detailForm.patchValue({
      baseComponent: {
        boardWeight: this.getValue('boardWeight'),
        coverStock: this.getValue('coverStock'),
        coverColors: this.getValue('colorsCover'),
        coverFilm: this.getValue('coverFilm'),
        linerStock: this.getValue('linerStock'),
        linerColors: this.getValue('colorsLiner'),
        linerFilm: this.getValue('linerFilm')
      },
      finishedSize: this.getValue('sizeFactorGrid')
    });

    //This should mean that we have flipup component
    if(this.getValue('flipUpCoverStock')) {
      if(!this.isFlipUpOut) {
        this.isFlipUpOut = true;        
      }

      if(!this.flipUpOutComponent) {
        this.addFlipUpOutComponentRequired();
      }

      const countQty: SpecDetailDTO | undefined = this.getFlipUpOutCountSpec();

      this.detailForm.patchValue({
        flipUpOutComponent: {
          countQty: {
            count: countQty?.componentListId || this.countListId,
            qty: countQty?.qty || null
          },
          boardWeight: this.getValue('flipUpBoardWeight'),
          coverStock: this.getValue('flipUpCoverStock'),
          coverColors: this.getValue('flipUpColorsCover'),
          coverFilm: this.getValue('flipUpCoverFilm'),
          linerStock: this.getValue('flipUpLinerStock'),
          linerColors: this.getValue('flipUpColorsLiner'),
          linerFilm: this.getValue('flipUpLinerFilm'), 
          attachmentMethod: this.getValue('flipUpAttachmentMethod')
        }
      });
    }

    this.selectFinishingOptions();
    this.setAccessories();
  }

  private init(): void {
    this.headerForm = this.fb.group({
      styleNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      skuNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      deliveryVehicleNbr: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      customerNbr: ['', [Validators.maxLength(7), Validators.pattern(CUST_NBR_REGEX)]],
      sequenceNbr: ['', Validators.max(99)]
    });

    if(!this.userService.isVendor) {
      this.headerForm.addControl('supplierId', new FormControl('', Validators.required));
    }

    this.detailForm = this.fb.group({
      baseComponent: this.fb.group({
        boardWeight: ['', Validators.required],
        coverStock: ['', Validators.required],
        coverColors: ['', Validators.required],
        coverFilm: ['', Validators.required],
        linerStock: ['', Validators.required],
        linerColors: ['', Validators.required],
        linerFilm: ['', Validators.required]
      }),
      finishedSize: ['', Validators.required],
      finishingOptions: this.fb.array([]),
      accessories: this.fb.array([]),
    });
  }

  private mapSpecReview(data: FolderFormValue): PrintFolderSpec {
    const spec: PrintFolderSpec = {} as PrintFolderSpec;

    spec.boardWeight = this.specService.getIdValue(<number> data.baseComponent.boardWeight);
    spec.coverStock = this.specService.getIdValue(<number> data.baseComponent.coverStock);
    spec.coverColors = this.specService.getIdValue(<number> data.baseComponent.coverColors);
    spec.coverFilm = this.specService.getIdValue(<number> data.baseComponent.coverFilm);
    spec.linerStock = this.specService.getIdValue(<number> data.baseComponent.linerStock);
    spec.linerColors = this.specService.getIdValue(<number> data.baseComponent.linerColors);
    spec.linerFilm = this.specService.getIdValue(<number> data.baseComponent.linerFilm);
    spec.finishedSize = this.specService.getIdValue(<number> data.finishedSize);
    spec.accessories = this.getAccessories(data.accessories);
    spec.finishingOptions = this.getFinishingOptions(data.finishingOptions);

    if(data.flipUpOutComponent) {
      spec.flipOutUpSpec = {} as FlipOutUpSpec;
      spec.flipOutUpSpec.count = this.flipUpOutCountQty.get('qty')?.value || '-';
      spec.flipOutUpSpec.boardWeight = this.specService.getIdValue(<number> data.flipUpOutComponent.boardWeight);
      spec.flipOutUpSpec.coverStock = this.specService.getIdValue(<number> data.flipUpOutComponent.coverStock);
      spec.flipOutUpSpec.coverColors = this.specService.getIdValue(<number> data.flipUpOutComponent.coverColors);
      spec.flipOutUpSpec.coverFilm = this.specService.getIdValue(<number> data.flipUpOutComponent.coverFilm);
      spec.flipOutUpSpec.linerStock = this.specService.getIdValue(<number> data.flipUpOutComponent.linerStock);
      spec.flipOutUpSpec.linerColors = this.specService.getIdValue(<number> data.flipUpOutComponent.linerColors);
      spec.flipOutUpSpec.linerFilm = this.specService.getIdValue(<number> data.flipUpOutComponent.linerFilm);
      spec.flipOutUpSpec.attachmentMethod = this.specService.getIdValue(<number> data.flipUpOutComponent.attachmentMethod); 
    }

    return spec;
  }

  private addFlipUpOutComponentRequired(): void {
    this.detailForm.addControl('flipUpOutComponent', this.getFlipUpOutComponent());
  }

  private removeFlipUpOutComponentRequired(): void {
    this.detailForm.removeControl('flipUpOutComponent');
  }

  private getValue(property: string): number | string {
    if (!this.spec || !this.spec.specDetails) {
      return '';
    }

    return this.spec.specDetails.find(s => s.componentRefId === property)?.componentListId || '';
  }

  private getFlipUpOutCountSpec(): SpecDetailDTO | undefined {
    if(!this.spec || !this.spec.specDetails) {
      return undefined; 
    }

    return this.spec.specDetails.find(s => s.componentRefId === 'flipUpCount'); 
  }

  private selectFinishingOptions(): void {
    this.finishingOptions.controls.forEach(option => {
      if (this._spec.specDetails.some(s => s.componentListId === option.get('value')?.value)) {
        option.get('selected')?.patchValue(true);
      }
    });
  }

  private setAccessories(): void {
    if (!this.spec || !this.spec.specDetails) {
      return;
    }
    this.spec.specDetails
      .filter(s => s.componentRefId === 'accessories')
      .forEach(accessory => {
        this.accessories.push(this.fb.group({
          accessory: [accessory.componentListId, Validators.required],
          qty: [accessory.qty, Validators.required],
          comment1: [accessory.comments1],
          comment2: [accessory.comments2]
        }));
      });
  }

  private getAccessoryFormGroup(): FormGroup {
    return this.fb.group({
      accessory: ['', Validators.required],
      qty: [null, Validators.required],
      comment1: [''],
      comment2: ['']
    });
  }

  private getFlipUpOutComponent(): FormGroup {
    return this.fb.group({
      countQty: this.fb.group({
        count: [this.countListId], 
        qty: [null, Validators.required]
      }),
      boardWeight: ['', Validators.required],
      coverStock: ['', Validators.required],
      coverColors: ['', Validators.required],
      coverFilm: ['', Validators.required],
      linerStock: ['', Validators.required],
      linerColors: ['', Validators.required],
      linerFilm: ['', Validators.required], 
      attachmentMethod: ['', Validators.required]
    });
  }

  private getAccessories(accessories: Accessory[] | undefined): Accessory[] {
    if(!accessories || accessories.length === 0) {
      return [];
    }

    return accessories.map(acc => {
      return<Accessory>{
        accessory: this.specService.getIdValue(<number>acc.accessory),
        qty: acc.qty,
        comment1: acc.comment1,
        comment2: acc.comment2
      }
    });
  }

  private getFinishingOptions(options: FinishingOptions[] | undefined): string[] {
    if(!options || options.length === 0) {
      return [];
    }

    return options.filter(option => option.selected).map(option => option.display);
  }
}