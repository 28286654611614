import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-mass-bid-dialog',
  templateUrl: './mass-bid-dialog.component.html',
  styles: []
})
export class MassBidDialogComponent implements OnInit {
  amount!: number | undefined; 
  noBid: boolean = false;
  selection: string = 'increase';

  percentErrorMsg: string = 'Valid value: 0 - 100'; 
  percentError: boolean = false;

  constructor(public dialogRef: MatDialogRef<MassBidDialogComponent>) { }

  ngOnInit(): void { }

  change(event: MatRadioChange): void {
    this.noBid = event.value === 'noBid';

    if(this.noBid) {
      this.amount = undefined; 
    }

    this.keyup();
  }

  keyup(): void {
    if(this.selection === 'percent') {
      this.percentError = this.amount && this.amount > 100 ? true : false;
    } else {
      this.percentError = false;
    }
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  update(): void {
    this.dialogRef.close({
      amount: this.amount,
      noBid: this.noBid,
      operation: this.selection
    });
  }
}
