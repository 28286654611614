import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserService } from "../services/user.service";

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
    private permittedPermissions: string[] = []; 

    @Input()
    set hasPermission(value: string[]) {
        this.permittedPermissions = value;
        this.checkPermissions();
    }

    @Input() hasPermissionOperation: "OR" | "AND" = 'OR'; 
    @Input() hasPermissionElse?: TemplateRef<any>; 
    @Input() hasPermissionAllowVendor?: boolean = true;

    ngOnInit(): void {
        this.checkPermissions(); 
    }

    constructor(
        private element: ElementRef, 
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService
    ) { } 

    private checkPermissions() {
        let isAuthorized = false; 
        this.viewContainer.clear(); 

        if(this.hasPermissionOperation === 'OR') {
            isAuthorized = this.userService.hasAnyPermission(this.permittedPermissions); 
        } else if (this.hasPermissionOperation === 'AND') {
            isAuthorized = this.userService.hasAllPermissions(this.permittedPermissions); 
        }

        if(!this.hasPermissionAllowVendor && this.userService.isVendor) {
            isAuthorized = false;
        }

        if(isAuthorized) {
            this.viewContainer.createEmbeddedView(this.templateRef); 
        } else if(this.hasPermissionElse) {
            this.viewContainer.createEmbeddedView(this.hasPermissionElse); 
        }
    }
}