import { Component, Input, OnInit } from '@angular/core';
import { BackerBoardSpec } from 'src/app/spec-collection/backer-board/models/backer-board.model';

@Component({
  selector: 'app-backer-board-review',
  templateUrl: './backer-board-review.component.html',
  styles: [
  ]
})
export class BackerBoardReviewComponent implements OnInit {
  @Input() specDetail!: BackerBoardSpec | null;
  constructor() { }

  ngOnInit(): void { }

}
