import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { UserService } from "src/app/core/services/user.service";

@Directive({
    selector: '[authorized]'
})
export class AuthorizationDirective implements OnInit {
    @Input("authorized") permittedRoles: string[] = []; // Required permission passed in

    constructor(private el: ElementRef, private userService: UserService) { }

    ngOnInit() {
        this.el.nativeElement.style.display = "none";
        if (this.permittedRoles && this.userService.hasAnyRole(this.permittedRoles))
            this.el.nativeElement.style.display = "block";
    }
}