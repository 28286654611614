import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { StepperOrientation } from '@angular/material/stepper';

import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { ComponentLists, ComponentList } from 'src/app/core/models/common-models';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { PhotopackSpec } from '../core/models/photopack.model';
import { PhotoPackService } from './services/photo-pack.service';

@Component({
  selector: 'app-photopack',
  templateUrl: './photopack.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ],
})
export class PhotopackComponent implements OnInit, OnDestroy {
  /** Component List for Photopack category. Required */ 
  @Input() componentLists!: ComponentLists;
  /** Marks the component as standalone. It will show review step and have submit ability */
  @Input() isStandalone: boolean = true;  
  /** Adds the ability to add additional photopack under correct scenarios. Default is false */
  @Input() allowAddPhotopack: boolean = false;
  /** Orientation of mat stepper - horizontal or vertical only. Default is horizontal */
  @Input() orientation: StepperOrientation = 'horizontal';


  @Output() submit = new EventEmitter();
  
  private readonly destroy$: Subject<void> = new Subject<void>();

  specReview$: Observable<PhotopackSpec>;

  typePageCount: ComponentList[] | undefined = [];
  canAddPhotopack: boolean = false;
  isWaterfall: boolean = false;
  detailForm!: FormGroup;
  // isFormValid: boolean = false;

  constructor(
    public photoService: PhotoPackService,
    private snackService: SnackBarService
  ) { 
    this.specReview$ = this.photoService.reviewSpec$;
  }

  ngOnInit(): void {
    this.detailForm = this.photoService.detailForm;

    /** Listens for the photopacks array size to change */
    this.photoService.photopacks.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(changes => {
        if(this.isWaterfall && this.allowAddPhotopack) {
          this.canAddPhotopack = changes.length < 2;
        }
      });

    /** Handles event when photopack type is changed */
    this.photoService.type.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(changes => {        
        const type = this.componentLists.photopackType?.find(opt => opt.id === changes)?.value;

        this.isWaterfall = type?.toLowerCase() === 'waterfall';
        this.canAddPhotopack = this.allowAddPhotopack && this.isWaterfall;

        this.photoService.photopacks.controls.length = 1;
        this.photoService.resetPhotopackPageCounts();

        if(!type) {
          this.typePageCount = [];
        } else {
          this.typePageCount = this.isWaterfall ? this.componentLists.waterfallPageCount : this.componentLists.saddlePageCount;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(): void {
    if(this.photoService.detailForm.invalid || this.photoService.headerForm.invalid) {
      this.photoService.detailForm.markAllAsTouched();
      this.photoService.headerForm.markAllAsTouched(); 
      this.snackService.error('Cannot submit spec until invalid fields are corrected');
      return;
    }

    this.submit.emit();
  }

  addPhotopack(): void {
    if(this.canAddPhotopack) {
      this.photoService.addPhotoPack();
    }
  }

  removePhotopack(idx: number): void {
    this.photoService.removePhotopack(idx);
  }
}