import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentLists } from 'src/app/core/models/common-models';

@Component({
  selector: 'app-flip-up-out',
  templateUrl: './flip-up-out.component.html',
  styles: []
})
export class FlipUpOutComponent implements OnInit {
  @Input() componentLists!: ComponentLists;
  @Input() group!: FormGroup; 

  constructor() { }

  ngOnInit(): void {
  }

}
