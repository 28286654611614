<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-card *hasPermission="['EDIT_ADMIN']">
            <mat-card-header>
                <mat-card-title>Create Category</mat-card-title>
            </mat-card-header>

            <form #createForm="ngForm" (ngSubmit)="createCategory(createForm)">
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="center" class="center-text">
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Category Name</mat-label>
                        <input matInput autocomplete="off" name="name" required ngModel>
                        <mat-hint>Category Name is required.</mat-hint>
                    </mat-form-field>
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Category Ref ID</mat-label>
                        <input matInput autocomplete="off" name="referenceId" required ngModel>
                        <mat-hint>Category Reference ID is required.</mat-hint>
                    </mat-form-field>
                </mat-card-content>
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <button type="submit" class="md-button" mat-raised-button color="primary" [disabled]="!createForm.valid">Create</button>
                </div>
            </form>

        </mat-card>
    </div>
</div>
<div fxLayout="row wrap" fxLayoutAlign="center" class="mt-20">
    <div fxFlex="96" fxFlex.xs="100">

    <mat-toolbar style="width: 100%;" color="primary">
        <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
            <span>Update Category</span>
        </ng-container>
        <ng-template #viewOnly>
            <span>View Category</span>
        </ng-template>
        <span class="example-spacer"></span>
    </mat-toolbar>

    <mat-card>

        <mat-form-field fxLayoutAlign="center" appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Filter Category" #input>
        </mat-form-field>
        
        <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort multiTemplateDataRows>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element;">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="referenceId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.referenceId }}</td>
            </ng-container>

            <ng-container matColumnDef="seqNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence Nbr</th>
                <td mat-cell *matCellDef="let element;">{{ element.seqNbr }}</td>
            </ng-container>

            <ng-container matColumnDef="createUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell *matCellDef="let element;">{{ element.createUser }}</td>
            </ng-container>

            <ng-container matColumnDef="createdTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.createdTimestamp | date) }}</td>
            </ng-container>

            <ng-container matColumnDef="changeUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By</th>
                <td mat-cell *matCellDef="let element;">{{ element.changeUser || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="changedTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.changedTimestamp | date) || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="assembly">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Assembly</th>
                <td mat-cell *matCellDef="let element;">
                    <mat-checkbox [checked]="element.assembly == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="bindery">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Bindery</th>
                <td mat-cell *matCellDef="let element;">
                    <mat-checkbox [checked]="element.bindery == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                <td mat-cell *matCellDef="let element;">
                    <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="update">
                <th mat-header-cell *matHeaderCellDef>
                    <ng-container *hasPermission="['EDIT_ADMIN']">
                        Update
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *hasPermission="['EDIT_ADMIN']" mat-icon-button color="primary" (click)="openUpdateDialog(element)" matTooltip="Edit and Update Category">
                            <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>

        <mat-paginator #paginator
                           [pageSize]="25"
                           [pageSizeOptions]="[5, 10, 25, 50, 100]"
                           [showFirstLastButtons]="true">
        </mat-paginator>

    </mat-card>
</div>
</div>
