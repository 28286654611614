import { Injectable, OnDestroy } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { Subject, Observable, merge } from "rxjs";
import { map, takeUntil } from "rxjs/internal/operators";

import { ComponentList } from "src/app/core/models/common-models";
import { Accessory, FinishingOptions, SpecDTO } from "src/app/core/models/spec-models";
import { ALPHA_NUMERIC_REGEX, CUST_NBR_REGEX } from "src/app/core/models/validation-models";
import { UserService } from "src/app/core/services/user.service";
import { SpecBackendService } from "../../core/services/spec-backend.service";
import { MultiSpec, MultiSpecFormValue } from "../models/multi-spec.model";

@Injectable()
export class MultiSpecService implements OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  public headerForm!: FormGroup;
  public detailForm!: FormGroup;

  readonly reviewSpec$: Observable<MultiSpec>;

  private spec!: SpecDTO;

  get finishingOptions(): FormArray { return <FormArray>this.detailForm.get('finishingOptions'); }
  get accessories(): FormArray { return <FormArray>this.detailForm.get('accessories'); }
  get baseComponent(): FormGroup { return <FormGroup>this.detailForm.get('baseComponent'); }

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private specService: SpecBackendService
  ) {
    this.init();
    this.reviewSpec$ = this.detailForm.valueChanges.pipe(map(form => this.mapSpecReview(form)));

    merge(this.detailForm.statusChanges, this.headerForm.statusChanges)
      .pipe(
        takeUntil(this.destroy$),
        map(_ => (this.detailForm.dirty || this.headerForm.dirty))
      )
      .subscribe(isPending => this.specService.isPendingChanges = isPending);
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  public init() {
    this.headerForm = this.fb.group({
      styleNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      skuNbr: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      deliveryVehicleNbr: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(ALPHA_NUMERIC_REGEX)]],
      customerNbr: ['', [Validators.maxLength(7), Validators.pattern(CUST_NBR_REGEX)]],
      sequenceNbr: ['', Validators.max(99)]
    });

    if(!this.userService.isVendor) {
      this.headerForm.addControl('supplierId', new FormControl('', Validators.required));
    }

    this.detailForm = this.fb.group({
      baseComponent: this.fb.group({
        boardWeight: ['', Validators.required],
        coverStock: ['', Validators.required],
        coverColors: ['', Validators.required],
        coverFilm: ['', Validators.required],
        linerStock: ['', Validators.required],
        linerColors: ['', Validators.required],
        linerFilm: ['', Validators.required]
      }),
      finishedSize: ['', Validators.required],
      finishingOptions: this.fb.array([]),
      accessories: this.fb.array([])
    });
  }

  public setForm(spec: SpecDTO): void {
    this.spec = spec;

    this.headerForm.patchValue({
      styleNbr: spec.styleNbr || '',
      skuNbr: spec.skuNbr || '',
      deliveryVehicleNbr: spec.deliveryVehicleNbr || '',
      customerNbr: spec.customerNbr || '',
      sequenceNbr: spec.sequenceNbr || ''
    });

    if(!this.userService.isVendor) {
      this.headerForm.patchValue({supplierId: spec.supplierId || ''});
    }

    this.setSpecDetails(); 
  }

  public copySpec(spec: SpecDTO): void {
    this.spec = spec;
    this.setSpecDetails();
  }

  public addAccessory(): void {
    this.accessories.push(this.getAccessoryFormGroup());
  }

  public removeAccessory(index: number): void {
    this.accessories.removeAt(index);
  }

  public setFinisingOptions(list: ComponentList[] | undefined): void {
    if (!list) {
      return;
    }

    list.forEach(option => {
      this.finishingOptions.push(this.fb.group({
        selected: false,
        value: option.id,
        display: option.value
      }));
    });
  }

  private setSpecDetails(): void {
    this.detailForm.patchValue({
      baseComponent: {
        boardWeight: this.getValue('boardWeight'),
        coverStock: this.getValue('coverStock'),
        coverColors: this.getValue('colorsCover'),
        coverFilm: this.getValue('coverFilm'),
        linerStock: this.getValue('linerStock'),
        linerColors: this.getValue('colorsLiner'),
        linerFilm: this.getValue('linerFilm')
      },
      finishedSize: this.getValue('sizeFactorGrid')
    });

    this.selectFinishingOptions();
    this.setAccessories();
  }

  private getValue(property: string): number | string {
    if (!this.spec || !this.spec.specDetails) {
      return '';
    }

    return this.spec.specDetails.find(s => s.componentRefId === property)?.componentListId || '';
  }

  private selectFinishingOptions(): void {
    this.finishingOptions.controls.forEach(option => {
      if (this.spec.specDetails.some(s => s.componentListId === option.get('value')?.value)) {
        option.get('selected')?.patchValue(true);
      }
    });
  }

  private setAccessories(): void {
    if (!this.spec || !this.spec.specDetails) {
      return;
    }
    this.spec.specDetails
      .filter(s => s.componentRefId === 'accessories')
      .forEach(accessory => {
        this.accessories.push(this.fb.group({
          accessory: [accessory.componentListId, Validators.required],
          qty: [accessory.qty, [Validators.required, Validators.min(1)]],
          comment1: [accessory.comments1],
          comment2: [accessory.comments2]
        }));
      });
  }

  private getAccessoryFormGroup(): FormGroup {
    return this.fb.group({
      accessory: ['', Validators.required],
      qty: [null, [Validators.required, Validators.min(1)]],
      comment1: [''],
      comment2: ['']
    });
  }

  private mapSpecReview(data: MultiSpecFormValue): MultiSpec {
    return {
      boardWeight: this.specService.getIdValue(<number> data.baseComponent.boardWeight),
      coverStock: this.specService.getIdValue(<number> data.baseComponent.coverStock),
      coverColors: this.specService.getIdValue(<number> data.baseComponent.coverColors),
      coverFilm: this.specService.getIdValue(<number> data.baseComponent.coverFilm),
      linerStock: this.specService.getIdValue(<number> data.baseComponent.linerStock),
      linerColors: this.specService.getIdValue(<number> data.baseComponent.linerColors),
      linerFilm: this.specService.getIdValue(<number> data.baseComponent.linerFilm),
      finishedSize: this.specService.getIdValue(<number> data.finishedSize),
      accessories: this.getAccessories(data.accessories),
      finishingOptions: this.getFinishingOptions(data.finishingOptions)
    }
  }

  private getAccessories(accessories: Accessory[] | undefined): Accessory[] {
    if(!accessories || accessories.length === 0) {
      return [];
    }

    return accessories.map(acc => {
      return<Accessory>{
        accessory: this.specService.getIdValue(<number>acc.accessory),
        qty: acc.qty,
        comment1: acc.comment1,
        comment2: acc.comment2
      }
    });
  }

  private getFinishingOptions(options: FinishingOptions[] | undefined): string[] {
    if(!options || options.length === 0) {
      return [];
    }

    return options.filter(option => option.selected).map(option => option.display);
  }
}