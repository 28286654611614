import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-component-list-details',
  templateUrl: './component-list-details.component.html',
  styleUrls: ['./component-list-details.component.scss']
})
export class ComponentListDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ComponentListDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {
  }

}
