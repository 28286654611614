import { Component, Input } from '@angular/core';

import { MultiSpec } from '../models/multi-spec.model';

@Component({
  selector: 'app-multi-spec-review',
  templateUrl: './multi-spec-review.component.html',
  styles: [
  ]
})
export class MultiSpecReviewComponent {
  @Input() specDetail!: MultiSpec | null;

  constructor() { }
}
