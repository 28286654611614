<form [formGroup]="backerService.detailForm">
    <mat-stepper [orientation]="orientation">

            <mat-step #step1 [hasError]="boardWeightCtrl.invalid && (boardWeightCtrl.touched || step1.interacted)">
                <ng-template matStepLabel>Base Components</ng-template>
                <ng-template matStepContent>
                  <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                    <mat-form-field fxFlex="24" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Board Weight</mat-label>
                        <mat-select formControlName="boardWeight">
                            <mat-option *ngFor="let opt of componentLists?.boardWeight" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error>
                            <app-form-error [control]="boardWeightCtrl"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button type="button" mat-raised-button [color]="'primary'" matStepperNext>Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step2 [hasError]="finishedSizeCtrl.invalid && (finishedSizeCtrl.touched || step2.interacted)">
                <ng-template matStepLabel>Finished Size</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                        <mat-form-field fxFlex="24" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                            <mat-label>Size Factor</mat-label>
                            <mat-select formControlName="finishedSize">
                                <mat-option *ngFor="let opt of componentLists?.sizeFactorGrid" [value]="opt.id">{{ opt.value }}</mat-option>
                            </mat-select>
                            <mat-error>
                                <app-form-error [control]="finishedSizeCtrl"></app-form-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Finishing Options</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                        <div fxFlex="24" formArrayName="finishingOptions">
                            <p *ngFor="let opt of backerService.finishingOptions.controls; let i=index;" formGroupName="{{i}}">
                                <mat-checkbox color="primary" formControlName="selected">{{ opt.get('display')?.value }}</mat-checkbox>
                            </p>
                        </div>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step #step4 [hasError]="accessoriesCtrl.invalid && (accessoriesCtrl.touched || step4.interacted)">
                <ng-template matStepLabel>Accessory Options</ng-template>
                <ng-template matStepContent>

                    <app-accessories [maxAccessories]="maxAccessories" [group]="backerService.detailForm" [accList]="componentLists?.accessories" (addAccessory)="addAccessory()" (deleteAccessory)="removeAccessory($event)"></app-accessories>

                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button *ngIf="isStandalone" type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Review Spec</button>
                    </div>
                </ng-template>
            </mat-step>

            <mat-step *ngIf="isStandalone">
                <ng-template matStepLabel>Review Spec</ng-template>
                <app-backer-board-review [specDetail]="specReview$ | async"></app-backer-board-review>
                <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <button type="button" mat-raised-button 
                            *hasPermission="['EDIT_SPEC']"
                            color="primary"
                            style="margin-left: 5px;"
                            (click)="submit()">Submit</button>
                </div>
            </mat-step>

    </mat-stepper>
</form>