import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/internal/operators';

import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppSettingsService } from 'src/app/main/app-settings/app-settings.service';
import { ComponentListDetailsComponent } from './component-list-details/component-list-details.component';
import { Bid, BidComponent, VendorBid } from './models/vendor-bid-models';
import { VendorBidService } from './services/vendor-bid.service';

const DEBOUNCE_TIME: number = 500;

@Component({
	selector: 'vendor-bid-detail',
	templateUrl: './vendor-bid-detail.component.html',
	styleUrls: ['./vendor-bid-detail.component.scss'],
	providers: [VendorBidService, AppSettingsService]
})
export class VendorBidDetailComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() categoryId: number = 0;
	@Input() vendorId: number = 0;
	@Output('updateStatus') updateStatus = new EventEmitter<boolean>();

	@ViewChild(MatSort) sort!: MatSort;

	canEdit: boolean = false;
	isGlobalBidding: boolean = false;

	vendorBid: VendorBid = {} as VendorBid;
	originalVendorBid: VendorBid = {} as VendorBid;

	private readonly destroy$: Subject<void> = new Subject<void>();

	dataSource: MatTableDataSource<BidComponent> = new MatTableDataSource();
	displayColumns = ['changedTimestamp', 'name', 'value'];
	priceRangeDisplayColumns: String[] = [];
	rangedPrice: boolean = false;

	selectedColumn = 'name';
	sortOrder = 'asc';
	sortDirections = [{value: 'asc', display: 'Ascending'}, {value: 'desc', display: 'Descending'}];
	sortColumns = [{value: 'changedTimestamp', display: 'Date Last Updated'}, {value: 'name', display: 'Section'}, {value: 'value', display: 'Description'}];

	//filter junk
	nameFilter = new FormControl('');
	valueFilter = new FormControl('');
	dateFilter = new FormControl('');

	filterValues = { name: '', value: '', changedTimestamp: '' };

	constructor(
		private vendorBidSrv: VendorBidService, 
		private userService: UserService,
		private settingsService: AppSettingsService,
		private snackService: SnackBarService,
		public dialog: MatDialog,
	) { }

	ngOnInit(): void {
		let priceRanges = new Set<String>();

		this.vendorBid = {} as VendorBid;
		this.originalVendorBid = {} as VendorBid;
		this.dataSource.data = [];
		this.rangedPrice = false;

		if (this.categoryId > 0 && this.vendorId > 0) {
			this.vendorBidSrv.getVendorBidInfo(this.categoryId, this.vendorId)
				.subscribe(response => {
					this.vendorBid = response;					
					this.originalVendorBid = JSON.parse(JSON.stringify(response)); //keep original prices to compare with them on submit and send only changed values					
					this.dataSource.data = this.vendorBid.components || [];

					//Add price range columns dynamically and calculate last changed timestamp
					response.components?.forEach(comp => comp.bids?.forEach(bid => {
						if ( (!bid.minQty && !bid.maxQty))
						{
							if (priceRanges.has("Price") == false)
								priceRanges.add("Price");
						}
						else if (priceRanges.has(bid.minQty + "-" + bid.maxQty) == false) {
							priceRanges.add(bid.minQty + (bid.maxQty ? "-" + bid.maxQty : "+"));
						}

						if (!comp.changedTimestamp || (bid.changedTimestamp && bid.changedTimestamp > comp.changedTimestamp)) {
							comp.changedTimestamp = bid.changedTimestamp;
						}
					}));

					priceRanges.forEach(r => this.priceRangeDisplayColumns.push(r.toString()));
					priceRanges.forEach(r => this.displayColumns.push(r.toString()));
					this.rangedPrice = priceRanges.size > 1;
				});
		}

		this.settingsService.getAppSettings()
			.pipe(
				tap(settings => {
					this.isGlobalBidding = (settings.vendorBidding === 'on' || this.userService.hasPermission('EDIT_MASTER_BID')); 
					this.canEdit = this.userService.hasAnyPermission(['EDIT_VENDOR_BID','EDIT_MASTER_BID']); 
				})
			).subscribe(); 

		this.dataSource.filterPredicate = this.createFilter();

		this.nameFilter.valueChanges
			.pipe(
				debounceTime(DEBOUNCE_TIME),
				takeUntil(this.destroy$)
			)
			.subscribe(
				name => {
					this.filterValues.name = name;
					this.dataSource.filter = JSON.stringify(this.filterValues);
				}
			)
		this.dateFilter.valueChanges
			.pipe(
				debounceTime(DEBOUNCE_TIME),
				takeUntil(this.destroy$)
			)
			.subscribe(
				date => {
					this.filterValues.changedTimestamp = date;
					this.dataSource.filter = JSON.stringify(this.filterValues);
				}
			)
		this.valueFilter.valueChanges
			.pipe(
				debounceTime(DEBOUNCE_TIME),
				takeUntil(this.destroy$)
			)
			.subscribe(
				val => {
					this.filterValues.value = val;
					this.dataSource.filter = JSON.stringify(this.filterValues);
				}
			)
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		// this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	changeSortedColumn() {
		const sortState: Sort = { active: this.selectedColumn, direction: 'asc' };
		this.sort.active = sortState.active;
		if (this.sortOrder == "desc")
			sortState.direction = 'desc';
		this.sort.direction = sortState.direction;
		this.sort.sortChange.emit(sortState);
	}

	createFilter(): (data: any, filter: string) => boolean {
		let filterFunction = function (data: any, filter: string): boolean {
			let searchTerms = JSON.parse(filter);
			return (searchTerms.name == '' || data.name?.toLowerCase().indexOf(searchTerms.name.toLowerCase()) !== -1)
				&& (searchTerms.price == '' || data.price == searchTerms.price)
				&& (searchTerms.value == '' || data.value?.toLowerCase().indexOf(searchTerms.value.toLowerCase()) !== -1)
				&& (searchTerms.changedTimestamp == '' || data.changedTimestamp?.toLowerCase().indexOf(searchTerms.changedTimestamp.toLowerCase()) !== -1);
		}
		return filterFunction;
	}

	resetForm() {
		//@todo check if there are changes first and warn them		
		this.dataSource.data.forEach(comp => {
			let origComp = this.originalVendorBid.components?.find(origComp => origComp.componentListId === comp.componentListId); 
			comp.bids = origComp?.bids?.map(bid => Object.assign({}, bid)); 
		});
		this.rangedPrice = this.priceRangeDisplayColumns.length > 1;
		this.updateStatus.emit(false);
	}

	submitForm() {
		//check what changed and send only the changes
		let data: BidComponent[] = [];
		let updatedData: BidComponent[] = this.dataSource.data;
		let originalData: BidComponent[] = this.originalVendorBid.components || [];

		const zeroDollarBids = updatedData.map(comp => comp.bids).reduce((arr1, arr2) => arr1?.concat(arr2 || []))?.filter(bid => bid.price == 0 && bid.isChanged); 
		if(zeroDollarBids && zeroDollarBids.length > 0) {
			this.snackService.warn('Cannot submit $0 bids. Please fix $0 bids to submit'); 
			zeroDollarBids.forEach(bid => bid.error = true);
			return;
		}

		//we had nada, take them all
		if (originalData.length === 0) {
			data = updatedData;
		}
		else {      	
			//Grab only data that has changed	
			data = updatedData.map(comp => { return <BidComponent> {
				...comp, 
				bids: comp.bids?.filter(bid => bid.isChanged)
			}}).filter(comp => comp.bids && comp.bids.length > 0);
		}

		if(data.length === 0) {
			this.snackService.info('No bid information changed or entered. There\'s nothing to submit.');
			return;
		}

		this.vendorBidSrv.updateVendorBid(this.vendorId, data).subscribe(
			(data) => {
				if (data.errorMessage && data.errorMessage.length > 0) {
					this.snackService.error(data.errorMessage);
				}
				else {
					this.snackService.success('Bid was successfully updated');
					this.updateStatus.emit(false);
				}

				//service will return only updated lines, so we need to update the original data for the lines that were updated successfully
				if (data.components) {
					data.components.forEach(updComp => {
						let origComp = originalData.find(origComp => origComp.componentListId === updComp.componentListId);
						let displayComp = this.vendorBid.components?.find(comp => comp.componentListId === updComp.componentListId);

						updComp.bids?.forEach(updBid => {
							let origBid = origComp?.bids?.find(origBid => origBid.quantityFactorId === updBid.quantityFactorId)
							let displayBid = displayComp?.bids?.find(displayBid => displayBid.quantityFactorId === updBid.quantityFactorId);

							//No error, so update the price in our originalData and the component bid id and date last updated in our displayData
							if (!updBid.error || updBid.error.valueOf() == false) {
								if (origBid) {
									origBid.price = updBid.price;
									origBid.noBid = updBid.noBid; 
									origBid.missingBid = updBid.missingBid; 
									origBid.isChanged = false;
								}
								else {
									console.log("Error: Can't find original bid object for quantityFactorId: " + updBid.quantityFactorId);
								}

								if (displayComp && displayBid) {
									displayBid.componentBidId = updBid.componentBidId;
									displayBid.changedTimestamp = updBid.changedTimestamp;
									displayBid.error = false;
									displayBid.isChanged = false;
									displayBid.noBid = updBid.noBid;

									if (!displayComp.changedTimestamp || (updBid.changedTimestamp && updBid.changedTimestamp > displayComp.changedTimestamp)) {
										displayComp.changedTimestamp = updBid.changedTimestamp;
									}
								}
								else {
									console.log("Error: Can't find display component or bid object for componentListId: " + updComp.componentListId + ", quantityFactorId: " + updBid.quantityFactorId);
								}
							}
							//Error, so set the error flag in the data that displays
							else {
								if (displayBid) {
									displayBid.error = true;
								}
							}
						})
					});
				}
			},
			(e: any) => {
				this.snackService.error('An error occurred while updating the bid information. If problem persist, please contact support.')
				console.error(e);
			}
		)
	}

	exportCSV(): void {
		this.vendorBidSrv.downloadVendorBidInfoCSV(this.categoryId, this.vendorId); 
	}

	checkPrice(componentListId: number, bid: Bid): void {
		let originalData: BidComponent[] = this.originalVendorBid.components || [];
		let origComp = originalData.find(origComp => origComp.componentListId === componentListId);
		let origBid = origComp?.bids?.find(origBid => origBid.quantityFactorId === bid.quantityFactorId); 

		const bidPrice = bid.price ? +bid.price : null;

		bid.error = bidPrice === 0; 

		if((bidPrice || bidPrice === 0) && bid.noBid) {
			bid.noBid = false;
		}

		bid.missingBid = bidPrice === null && !bid.noBid;
		bid.isChanged = origBid && (origBid.noBid !== bid.noBid || origBid.price !== bidPrice); 

		if(bid.isChanged) {
			this.updateStatus.emit(true);
		}
	}

	setNoBid(componentListId: number, bid: Bid): void {
		bid.noBid = true; 
		bid.price = undefined; 
		this.checkPrice(componentListId, bid);
	}

	openComponentListExtraDetails(data: BidComponent) {
		this.dialog.open(ComponentListDetailsComponent, {
			data: {
				componentListId: data.componentListId,
				description: data.description,
				uom: data.uom,
			}
		});
	}
}
