import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ComponentListDTO } from '../../models/administration-models';
import { AdministrationService } from '../../services/administration.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-rearrange-sequence-nbr',
  templateUrl: './rearrange-sequence-nbr.component.html',
  styleUrls: ['./rearrange-sequence-nbr.component.scss'],
  providers: [AdministrationService]
})
export class RearrangeSequenceNbrComponent implements OnInit {
  @ViewChild('dataTable') table!: MatTable<ComponentListDTO>;

  // dataSource = new MatTableDataSource<ComponentListDTO>([]);
  dataSource = this.data.tableData;
  dataColumns: String[] = ['position', 'seqNbr', 'value'];
  displayedColumns = this.dataColumns;

  compListVal!: ComponentListDTO[];

  constructor(public dialogRef: MatDialogRef<RearrangeSequenceNbrComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adminService: AdministrationService,
              public dialog: MatDialog,
              private snackService: SnackBarService,
              private snackBar: MatSnackBar,
              public cdkScrollable: ScrollingModule
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  dropTable(event: CdkDragDrop<ComponentListDTO[]>): void {
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    this.dataSource.forEach((row: any, idx: any) => {
      row.seqNbr = idx + 1;
    })
    this.table.renderRows();
  }

  submitNewSeq() {
    this.adminService.updateListOfComponentList(this.dataSource).subscribe(response => {
      this.snackService.success(response.successMessage);
    },
    (e: HttpErrorResponse) => {
      this.snackService.error(e.error.localizedMessage);
    },
    () => {
      this.close()
    });
  }


}
