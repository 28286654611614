import { Directive, ElementRef, HostListener, Input, Optional, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[leftPad]'
})
export class LeftPadDirective {
    @Input() leftPad!: number;
    @Input() padChar: string = '0';

    inputElement: HTMLInputElement;

    private readonly allZeros = /^0+$/; 

    constructor(private el: ElementRef, @Optional() private ngControl: NgControl) { 
        this.inputElement = el.nativeElement;
    }

    @HostListener('blur', ['$event']) blur() {
        if(!this.leftPad || !this.inputElement.value || this.inputElement.value.match(this.allZeros)) {
            return;
        }

        if(this.ngControl) {
            this.handleFormValue();
        } else if(this.inputElement) {
            this.handleInputValue();
        }
    }

    private handleFormValue(): void {
        const inputValue = <string>this.ngControl.value;
        const strLen = inputValue?.trim().length || 0;
        
        if(strLen > 0 && strLen < this.leftPad) {
            this.ngControl.control?.setValue(inputValue.trim().padStart(this.leftPad, this.padChar));
        }
    }

    private handleInputValue(): void {        
        const inputValue = this.inputElement.value;
        const strLen = inputValue?.trim().length || 0;

        if(strLen > 0 && strLen < this.leftPad) {
            this.inputElement.value = inputValue.trim().padStart(this.leftPad, this.padChar);
        }
    }
}