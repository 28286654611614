import { Category, CategoryCreateRequest, CategoryUpdateRequest, Component, ComponentCreateRequest, ComponentDTO, ComponentListCreateRequest, ComponentListDTO, ComponentListResponse, ComponentListUpdateRequest, ComponentUpdateRequest, QfgCreateRequest, QfgUpdateRequest, QuantityFactorGrid, QuantityFactorGridDTO } from "../models/administration-models";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ConfigModel } from "src/app/core/models/config-model";
import { SpecDTO } from "src/app/core/models/spec-models";

@Injectable()
export class AdministrationService {

    constructor(
        private httpClient: HttpClient
    ){}

    public getCategoryList(supplierId?: number): Observable<Category[]> {
        let params = new HttpParams()
        if(supplierId && supplierId > 0) params = params.set('supplierId', supplierId);

        return this.httpClient.get<Category[]>(`${ConfigModel.API_URL}/v1/category/list`, {params: params});
    }

    public getCategoryById(id: number): Observable<Category> {
        return this.httpClient.get<Category>(`${ConfigModel.API_URL}/v1/category/simple/${id}`);
    }

    public createCategory(request: CategoryCreateRequest): Observable<Category> {
        let url = `${ConfigModel.API_URL}/v1/category`;

        return this.httpClient.post<Category>(url, request);
    }

    public createComponent(request: ComponentCreateRequest): Observable<Component> {
        let url = `${ConfigModel.API_URL}/v1/category/${request.categoryId}/component`

        return this.httpClient.post<Component>(url, request);
    }

    public createComponentList(request: ComponentListCreateRequest): Observable<ComponentListDTO> {
        let url = `${ConfigModel.API_URL}/v1/category/${request.componentId}/component-list`

        return this.httpClient.post<ComponentListDTO>(url, request);
    }

    public getComponents(): Observable<ComponentDTO> {
        return this.httpClient.get<ComponentDTO>(`${ConfigModel.API_URL}/v1/category/component`);
    }

    public getComponentLists(): Observable<ComponentListDTO> {
        return this.httpClient.get<ComponentListDTO>(`${ConfigModel.API_URL}/v1/category/component/component-list`);
    }

    public getComponentsById(catId: number): Observable<ComponentDTO> {
        return this.httpClient.get<ComponentDTO>(`${ConfigModel.API_URL}/v1/category/component/${catId}`);
    }

    public getComponentListsById(compId: number): Observable<ComponentListDTO[]> {
        return this.httpClient.get<ComponentListDTO[]>(`${ConfigModel.API_URL}/v1/category/component-list/${compId}`);
    }

    public getSpecsWithCompList(compListId: number): Observable<SpecDTO[]> {
        return this.httpClient.get<SpecDTO[]>(`${ConfigModel.API_URL}/v1/category/spec-info/${compListId}`);
    }

    public updateComponent(request: ComponentUpdateRequest): Observable<Component> {
        let url = `${ConfigModel.API_URL}/v1/category/component/${request.id}/update`

        return this.httpClient.patch<Component>(url, request);
    }

    public updateComponentList(request: ComponentListUpdateRequest): Observable<ComponentListDTO> {
        let url = `${ConfigModel.API_URL}/v1/category/component-list/${request.id}/update`

        return this.httpClient.patch<ComponentListDTO>(url, request);
    }

    public updateListOfComponentList(request: ComponentListDTO): Observable<ComponentListResponse> {
        let url = `${ConfigModel.API_URL}/v1/category/component-list/updateList`

        return this.httpClient.patch<ComponentListResponse>(url, request);
    }

    public updateCategory(request: CategoryUpdateRequest): Observable<Category> {
        let url = `${ConfigModel.API_URL}/v1/category/${request.id}/update`

        return this.httpClient.patch<Category>(url, request);
    }

    public getQuantityFactorGrid(): Observable<QuantityFactorGridDTO> {
        return this.httpClient.get<QuantityFactorGridDTO>(`${ConfigModel.API_URL}/v1/qfg`);
    }

    public getQfgByCatId(categoryId: number): Observable<QuantityFactorGridDTO> {
        return this.httpClient.get<QuantityFactorGridDTO>(`${ConfigModel.API_URL}/v1/qfg/${categoryId}`);
    }

    public createQuantityFactorGrid(request: QfgCreateRequest): Observable<QuantityFactorGrid> {
        let url = `${ConfigModel.API_URL}/v1/qfg/category/${request.categoryId}/create`

        return this.httpClient.post<QuantityFactorGrid>(url, request);
    }

    public updateQuantityFactorGrid(request: QfgUpdateRequest): Observable<QuantityFactorGrid> {
        let url = `${ConfigModel.API_URL}/v1/qfg/${request.id}/update`

        return this.httpClient.patch<QuantityFactorGrid>(url, request);
    }

    public updateSpecCompListId(request: SpecDTO[], compListId: number): Observable<SpecDTO[]> {
        let url = `${ConfigModel.API_URL}/v1/spec/comp-list/${compListId}`

        return this.httpClient.patch<SpecDTO[]>(url, request);
    }

    public updateAssemblyCompListId(compListId: number): Observable<void> {
        return this.httpClient.patch<void>(`${ConfigModel.API_URL}/v1/spec/swatch-comp-list/${compListId}`, {});
    }
}
