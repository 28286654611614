import { Component, Input, OnInit } from '@angular/core';
import { PrintFolderSpec } from 'src/app/spec-collection/core/models/print-folder.model';

@Component({
  selector: 'app-folder-review',
  templateUrl: './folder-review.component.html'
})
export class FolderReviewComponent implements OnInit {
  @Input() specDetail!: PrintFolderSpec | null;
  
  constructor() { }

  ngOnInit(): void {
  }

}
