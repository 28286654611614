import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/internal/operators';

import { ConfigModel } from 'src/app/core/models/config-model';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { AppConfig, AppStatus } from './app-settings.model';

@Injectable()
export class AppSettingsService {

  constructor(
    private httpClient: HttpClient, 
    private snackService: SnackBarService, 
    private errorHandler: ErrorHandlerService
  ) { }

  private readonly handleError = (err: any) => {
    this.errorHandler.handleError(err); 
    return throwError(err); 
  }

  public getAppSettings(): Observable<AppConfig> {
    return this.httpClient.get<AppConfig>(`${ConfigModel.API_URL}/v1/configuration`)
      .pipe(catchError(this.handleError));
  }

  public getAppStatus(): Observable<AppStatus> {
    return this.httpClient.get<AppStatus>(`${ConfigModel.API_URL}/v1/configuration/app-status`)
      .pipe(catchError(this.handleError));
  }

  public updateAppSettings(config: any): Observable<void> {
    return this.httpClient.put<void>(`${ConfigModel.API_URL}/v1/configuration`, config)
      .pipe(
        tap(() => this.snackService.success('Application settings were successfully updated.')), 
        catchError(this.handleError)
      ); 
  }

  public clearUserCache(): Observable<void> {
    return this.httpClient.delete<void>(`${ConfigModel.API_URL}/v1/users/clear-cache`)
      .pipe(
        tap(() => this.snackService.success('User cache was successfully cleared.')), 
        catchError(this.handleError)
      );
  }
}