import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lookup } from 'src/app/core/models/common-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ComponentDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';

@Component({
  selector: 'app-update-comp-dialog',
  templateUrl: './update-comp-dialog.component.html',
  styleUrls: ['./update-comp-dialog.component.scss'],
  providers: [AdministrationService]
})
export class UpdateCompDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdateCompDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ComponentDTO,
              private adminService: AdministrationService,
              private snackService: SnackBarService,
              public commonService: CommonService
  ) { }

  groupIdList!: Lookup[];

  ngOnInit(): void {
    this.getGroupIdList();
  }

  getGroupIdList() {
    this.commonService.getGroupIdList().subscribe((response: any) => {
      this.groupIdList = response;
      this.groupIdList.forEach(row => {
        row.groupIdValue = parseInt(row.value);
      })
    },
    (e) => {
      this.snackService.error('An error occurred while retrieving group id list. If problem persist, please contact support.');
      console.error(e);
    })
  }

  updateComp(form: NgForm) {
    this.adminService.updateComponent(form.value).subscribe((response: any) => {
      this.snackService.success('Component was successfully updated.');
    },
    (e: any) => {
      this.snackService.error('An error occurred while updating component. If problem persist, please contact support.');
      console.error(e);
    },
    () => {
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
