<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;" class="mb-20">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-card> 
            <mat-card-header>
                <mat-card-title>Administration</mat-card-title>
            </mat-card-header>
            <div class="center-text">
            <mat-form-field>
                <mat-label>Select Option to Manage</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="adminManageList">
                    <mat-option [value]="0">None</mat-option>
                    <mat-option *ngFor="let option of adminManageList" [value]="option.value">
                        {{ option.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
        </mat-card>
    </div>
</div>

<ng-container *ngIf="selectedValue===1">
     <app-admin-category-manage></app-admin-category-manage>
 </ng-container>

<ng-container *ngIf="selectedValue===2">
      <app-admin-component-manage></app-admin-component-manage>
</ng-container>

<ng-container *ngIf="selectedValue===3">
     <app-admin-component-list-manage></app-admin-component-list-manage>
</ng-container>

<ng-container *ngIf="selectedValue===4">
    <app-admin-qty-factor-grid></app-admin-qty-factor-grid>
</ng-container>