<mat-stepper [orientation]="orientation">
    <form [formGroup]="photoService.detailForm">

        <mat-step #step1 [hasError]="(detailForm.get('coverStock')?.invalid || detailForm.get('coverColors')?.invalid
                                      || detailForm.get('coverFilm')?.invalid || detailForm.get('pageStock')?.invalid 
                                      || detailForm.get('pageColors')?.invalid ||detailForm.get('pageFilm')?.invalid)
                                      && (detailForm.touched || step1.interacted)">
            <ng-template matStepLabel>Base Components</ng-template>
            <ng-template matStepContent>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Cover Stock</mat-label>
                        <mat-select formControlName="coverStock">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.outsideStock" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('coverStock')?.invalid">
                            <app-form-error [control]="detailForm.get('coverStock')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Cover Colors</mat-label>
                        <mat-select formControlName="coverColors">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.outsideColorsCover" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('coverColors')?.invalid">
                            <app-form-error [control]="detailForm.get('coverColors')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Cover Film</mat-label>
                        <mat-select formControlName="coverFilm">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.outsideFilm" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('coverFilm')?.invalid">
                            <app-form-error [control]="detailForm.get('coverFilm')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Pages Stock</mat-label>
                        <mat-select formControlName="pageStock">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.insideStock" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('pageStock')?.invalid">
                            <app-form-error [control]="detailForm.get('pageStock')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Pages Colors</mat-label>
                        <mat-select formControlName="pageColors">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.insideColorsCover" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('pageColors')?.invalid">
                            <app-form-error [control]="detailForm.get('pageColors')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="33" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Pages Film</mat-label>
                        <mat-select formControlName="pageFilm">
                            <mat-option [value]="''">Select</mat-option>
                            <mat-option *ngFor="let opt of componentLists?.insideFilm" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('pageFilm')?.invalid">
                            <app-form-error [control]="detailForm.get('pageFilm')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                </div>
    
                <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                    <button type="button" mat-raised-button [color]="'primary'" matStepperNext>Next</button>
                </div>
            </ng-template>
        </mat-step>
    
        <mat-step #step2 [hasError]="detailForm.get('finishedSize')?.invalid && (detailForm.get('finishedSize')?.touched || step2.interacted)">
            <ng-template matStepLabel>Finished Size</ng-template>
            <ng-template matStepContent>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">
                    <mat-form-field fxFlex="24" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Size Factor</mat-label>
                        <mat-select formControlName="finishedSize">
                            <mat-option *ngFor="let opt of componentLists?.sizeFactorGrid" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="detailForm.get('finishedSize')?.invalid">
                            <app-form-error [control]="detailForm.get('finishedSize')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                </div>
            </ng-template>
        </mat-step>
    
        <mat-step #step3 [hasError]="(detailForm.get('type')?.invalid || photoService.photopacks.invalid) 
                                     && (detailForm.get('type')?.touched || photoService.photopacks.touched || step3.interacted)">
            <ng-template matStepLabel>Photopack</ng-template>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                <mat-form-field fxFlex="33" fxFlexOffset.gt-sm="33" fxFlex.lt-md="100" appearance="outline">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                        <mat-option [value]="''">Select</mat-option>
                        <mat-option *ngFor="let opt of componentLists?.photopackType" [value]="opt.id">{{ opt.value }}</mat-option>
                    </mat-select>
                </mat-form-field>                    

                <div fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign.gt-sm="end center" fxLayoutAlign.lt-md="center" class="mb-10">
                    <button *ngIf="canAddPhotopack" mat-raised-button color="success" (click)="addPhotopack()">
                        <mat-icon>add</mat-icon> Add Photopack
                    </button>
                </div>
            </div>

            <div formArrayName="photopacks">
                <div *ngFor="let photopack of photoService.photopacks.controls; let i = index; let size = count;" 
                    formGroupName="{{i}}" fxLayout="row wrap" fxLayoutAlign="space-between">
                    <mat-form-field fxFlex="32" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Finishing 1</mat-label>
                        <mat-select formControlName="finishing1">
                            <mat-option *ngFor="let opt of componentLists?.finishing1" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="photopack.get('finishing1')?.invalid">
                            <app-form-error [control]="photopack.get('finishing1')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="32" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Finishing 2</mat-label>
                        <mat-select formControlName="finishing2">
                            <mat-option *ngFor="let opt of componentLists?.finishing1" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="32" fxFlex.md="30" fxFlex.lt-md="100" appearance="outline">
                        <mat-label>Page Count</mat-label>
                        <mat-select formControlName="pageCount">
                            <mat-option *ngFor="let opt of typePageCount" [value]="opt.id">{{ opt.value }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="photopack.get('pageCount')?.invalid">
                            <app-form-error [control]="photopack.get('pageCount')"></app-form-error>
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf="size > 1" fxFlex="2" fxFlex.lt-md="100" fxLayoutAlign="end center">
                        <button fxHide.lt-md mat-icon-button color="warn" (click)="removePhotopack(i)">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <button fxHide.gt-sm mat-raised-button color="warn" (click)="removePhotopack(i)">
                            Delete Photopack {{ i+1 }}
                        </button>
                    </div>
                </div>
            </div>
                
            <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">                    
                <button mat-raised-button matStepperPrevious>Back</button>
                <button type="button" *ngIf="isStandalone" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Review Photopack</button>
            </div>
        </mat-step>

        <mat-step *ngIf="isStandalone">
            <ng-template matStepLabel>Review</ng-template>
            <app-photopack-review [specDetail]="specReview$ | async"></app-photopack-review>
            <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                <button mat-raised-button matStepperPrevious>Back</button>
                <button type="button" mat-raised-button 
                        *hasPermission="['EDIT_SPEC']"
                        color="primary"
                        style="margin-left: 5px;"
                        (click)="submitForm()">Submit</button>
            </div>
        </mat-step>
    </form>

</mat-stepper>