import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class QuantityService {
  private _quantity!: number | null;
  private _quantitySub: Subject<number | null> = new Subject<number | null>();

  public readonly quantity$ = this._quantitySub.asObservable();

  public isQtyTierError: boolean = false;

  constructor() { }

  public get quantity(): number | null {
    return this._quantity; 
  }

  public setQuantity(quantity: number | null): void {
    this._quantity = quantity; 
    this._quantitySub.next(quantity);
  }
}