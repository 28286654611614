import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { SpecRequestDto } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { SwatchingService } from '../services/swatching.service';
import { BackerBoardService } from '../../backer-board/services/backer-board.service';

@Component({
  selector: 'app-create-swatch',
  templateUrl: './create-swatch.component.html',
  providers: [SwatchingService, BackerBoardService]
})
export class CreateSwatchComponent implements OnInit, OnDestroy {
  @Input() categoryId: number = 0;
  private destroy$: Subject<void> = new Subject<void>(); 
  private backerBoardCatId: number = 0; 

  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    public swatchService: SwatchingService, 
    public backerBoardService: BackerBoardService
  ) { }

  ngOnInit(): void {
    this.backerBoardCatId = this.common.getCategoryIdByRefId('backerboard');

    forkJoin([this.specService.getComponetListByCategoryId(this.categoryId), this.specService.getComponetListByCategoryId(this.backerBoardCatId)])
      .pipe(takeUntil(this.destroy$))
      .subscribe( ([swatchList, backerBoardList]) => {
        this.swatchService.loadSwatchTypes(swatchList); 
        this.swatchService.backerBoardLists = backerBoardList; 
        this.backerBoardService.setFinisingOptions(backerBoardList.finishing); 
      }); 
  }

  ngOnDestroy(): void {
      this.destroy$.next(); 
      this.destroy$.complete(); 
  }

  submitForm(): void {
    if(!this.swatchService.backerBoardCompId) {
      return; 
    }

    const backerBoardRequest: SpecRequestDto[] = []; 

    if(this.swatchService.isPanelBackerBoard) {
      let request = new SpecRequestDto(this.swatchService.headerForm.value); 
      if(request.categoryId === 0) {
        request.categoryId = this.backerBoardCatId; 
      }

      request.setBackerBoardDetails(this.backerBoardService.detailForm.value); 
      backerBoardRequest.push(request); 
    }

    const request = new SpecRequestDto(this.swatchService.headerForm.value);

    if(request.categoryId === 0) {
      request.categoryId = this.categoryId;
    }

    request.setSwatching(this.swatchService.concatSwatchArr.filter(dtl => dtl.quantity && dtl.quantity > 0));
    this.specService.createSpecWithChildrenRequest(this.swatchService.backerBoardCompId, backerBoardRequest, request);
  }
}
