import { Component, Input, OnInit } from '@angular/core';

import { RoutingToolService } from '../services/routing-tool.service';
import { QuantityService } from '../services/quantity.service';
import { Category } from 'src/app/admin/models/administration-models';

@Component({
  selector: 'app-spec-routing',
  templateUrl: './spec-routing.component.html',
  providers: [RoutingToolService],
  styles: []
})
export class SpecRoutingComponent implements OnInit {
  @Input() category!: Category;

  constructor(
    public routingToolService: RoutingToolService, 
    public qtyService: QuantityService
  ) { }

  ngOnInit() {
    this.routingToolService.setCategoryRouting(this.category);
  }

  valueChanges(value: any): void {
    this.routingToolService.valueChanges(value.selection, value.isSetPrice);    
  }
}