import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { FlexLayoutModule } from '@angular/flex-layout';

import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SideNavComponent } from './main/side-nav/side-nav.component';
import { DemoMaterialModule } from './core/material-module';
import { HomeComponent } from './main/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpecCollectionComponent } from './spec-collection/core/spec-collection.component';
import { BaseComponentComponent } from './spec-collection/core/spec-components/base-component/base-component.component';
import { AccessoriesComponent } from './spec-collection/core/spec-components/accessories/accessories.component';
import { SpecCollectionDirective } from './spec-collection/core/directives/spec-collection.directive';
import { AuthorizationDirective } from './core/directives/role.authorization.directive';
import { LoaderComponent } from './core/loader/loader.component';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor';
import { VendorProfileComponent } from './vendor/vendor-profile/vendor-profile.component';
import { SpecLibraryTableComponent } from './spec-library/spec-library-table/spec-library-table.component';
import { ErrorDialogComponent } from './core/dialog/error-dialog/error-dialog.component';
import { SwatchingHeaderComponent } from './spec-collection/core/spec-components/swatching-header/swatching-header.component';
import { CreateBackerBoardComponent } from './spec-collection/backer-board/create-backer-board/create-backer-board.component';
import { BackerBoardReviewComponent } from './spec-collection/backer-board/review/backer-board-review.component';
import { CommonService } from './core/services/common.service';
import { SwatchingTableComponent } from './spec-collection/swatching/table/swatching-table.component';
import { AdministrationComponent } from './admin/administration.component';
import { AdminCategoryManageComponent } from './admin/admin-category-manage/admin-category-manage.component';
import { AdminComponentManageComponent } from './admin/admin-component-manage/admin-component-manage.component';
import { AdminComponentListManageComponent } from './admin/admin-component-list-manage/admin-component-list-manage.component';
import { AdminUpdateDialogComponent } from './admin/admin-update-dialog/admin-update-dialog.component';
import { ComponentTableDialogComponent } from './admin/component-table-dialog/component-table-dialog.component';
import { ComponentListTableDialogComponent } from './admin/component-list-table-dialog/component-list-table-dialog.component';
import { ManageComponentDialogComponent } from './admin/manage-component-dialog/manage-component-dialog.component';
import { ManageComponentListDialogComponent } from './admin/manage-component-list-dialog/manage-component-list-dialog.component';
import { UpdateCompDialogComponent } from './admin/update-comp-dialog/update-comp-dialog.component';
import { UpdateCompListDialogComponent } from './admin/update-comp-list-dialog/update-comp-list-dialog.component';
import { CreateSwatchComponent } from './spec-collection/swatching/create-swatch/create-swatch.component';
import { EditSwatchComponent } from './spec-collection/swatching/edit-swatch/edit-swatch.component';
import { SwatchingComponent } from './spec-collection/swatching/swatching.component';
import { MultiSpecComponent } from './spec-collection/multi-spec/multi-spec.component';
import { MultiSpecReviewComponent } from './spec-collection/multi-spec/review/multi-spec-review.component';
import { BackerBoardComponent } from './spec-collection/backer-board/backer-board.component';
import { SpecLibraryContainerComponent } from './spec-library/spec-library-container/spec-library-container.component';
import { EditSpecDialogComponent } from './spec-collection/core/edit-spec-dialog/edit-spec-dialog.component';
import { PrintSpecHeaderComponent } from './spec-collection/core/spec-components/print-spec-header/print-spec-header.component';
import { EditMultiSpecComponent } from './spec-collection/multi-spec/edit-multi-spec/edit-multi-spec.component';
import { CreateMultiSpecComponent } from './spec-collection/multi-spec/create-multi-spec/create-multi-spec.component';
import { EditBackerBoardComponent } from './spec-collection/backer-board/edit-backer-board/edit-backer-board.component';
import { CreatePhotopackComponent } from './spec-collection/photopack/create-photopack/create-photopack.component';
import { EditPhotopackComponent } from './spec-collection/photopack/edit-photopack/edit-photopack.component';
import { FolderComponent } from './spec-collection/folder/folder.component';
import { CreateFolderComponent } from './spec-collection/folder/create-folder/create-folder.component';
import { FolderReviewComponent } from './spec-collection/folder/components/folder-review/folder-review.component';
import { EditFolderComponent } from './spec-collection/folder/edit-folder/edit-folder.component';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { SpecHistoryComponent } from './spec-history/spec-history.component';
import { SpecHistoryTableComponent } from './spec-history/spec-history-table/spec-history-table.component';
import { SpecHistoryDialogComponent } from './spec-history/spec-history-dialog/spec-history-dialog.component';
import { SpecDetailHistoryDialogComponent } from './spec-history/spec-detail-history-dialog/spec-detail-history-dialog.component';
import { BackerBoardHistoryComponent } from './spec-history/spec-detail-history-components/backer-board-history.component';
import { MultiSpecHistoryComponent } from './spec-history/spec-detail-history-components/multi-spec-history.component';
import { PhotopackHistoryComponent } from './spec-history/spec-detail-history-components/photopack-history.component';
import { FolderHistoryComponent } from './spec-history/spec-detail-history-components/folder-history.component';
import { FlipUpOutComponent } from './spec-collection/folder/components/flip-up-out/flip-up-out.component';
import { PhotopackComponent } from './spec-collection/photopack/photopack.component';
import { PhotopackReviewComponent } from './spec-collection/photopack/review/photopack-review.component';
import { AdminQtyFactorGridComponent } from './admin/admin-qty-factor-grid/admin-qty-factor-grid.component';
import { QfgTableDialogComponent } from './admin/qfg-table-dialog/qfg-table-dialog.component';
import { ManageQfgDialogComponent } from './admin/manage-qfg-dialog/manage-qfg-dialog.component';
import { UpdateQfgDialogComponent } from './admin/update-qfg-dialog/update-qfg-dialog.component';
import { VendorBidComponent } from './vendor/bid/vendor-bid.component';
import { VendorBidDetailComponent } from './vendor/bid/vendor-bid-detail.component';
import { ComponentLoaderDirective } from './core/directives/component-loader.directive';
import { UserProfilesComponent } from './user-profiles/user-profiles.component';
import { UpdateUserProfilesComponent } from './user-profiles/update-user-profiles/update-user-profiles/update-user-profiles.component';
import { ConfigModel } from './core/models/config-model';
import { PositiveNumberDirective } from './core/directives/positive-number.directive';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { SpecRoutingComponent } from './routing-tool/spec-routing/spec-routing.component';
import { PriceTableComponent } from './routing-tool/price-table/price-table.component';
import { ComponentTableComponent } from './routing-tool/component-table/component-table.component';
import { LeftPadDirective } from './core/directives/left-pad.directive';
import { FormErrorComponent } from './core/form-error/form-error.component';
import { SwatchRoutingComponent } from './routing-tool/swatch-routing/swatch-routing.component';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { PendingChangesDialogComponent } from './core/dialog/pending-changes-dialog/pending-changes-dialog.component';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler.interceptor';
import { ManualSpecPricingComponent } from './routing-tool/manual-spec-pricing.component';
import { UnauthorizedComponent } from './access-denied/unauthorized.component';
import { SwatchHistoryComponent } from './spec-history/spec-detail-history-components/swatch-history.component';
import { SpecLibraryPricingComponent } from './spec-library-pricing/spec-library-pricing.component';
import { AssemblyPricingComponent } from './spec-library-pricing/assembly-pricing/assembly-pricing.component';
import { AlphaNumericDirective } from './core/directives/alpha-numeric.directive';
import { SpecLibraryPricingTableComponent } from './spec-library-pricing/spec-library-pricing-table/spec-library-pricing-table.component';
import { BinderyPricingComponent } from './spec-library-pricing/bindery-pricing/bindery-pricing.component';
import { SpecLibraryPricingSpecTable } from './spec-library-pricing/spec-library-pricing-spec/spec-library-pricing-spec-table/spec-library-pricing-spec-table.component';
import { AssemblyDialogComponent } from './spec-library-pricing/spec-library-pricing-spec/dialogs/assembly-dialog/assembly-dialog.component';
import { BinderyDialogComponent } from './spec-library-pricing/spec-library-pricing-spec/dialogs/bindery-dialog/bindery-dialog.component';
import { BinderyPricingSpecComponent } from './spec-library-pricing/bindery-pricing/bindery-pricing-spec/bindery-pricing-spec.component';
import { ActiveCheckComplistDialogComponent } from './admin/active-check-complist-dialog/active-check-complist-dialog.component';
import { VendorCreateDialogComponent } from './vendor/vendor-profile/vendor-create-dialog/vendor-create-dialog.component';
import { VendorEditDialogComponent } from './vendor/vendor-profile/vendor-edit-dialog/vendor-edit-dialog.component';
import { HasPermissionDirective } from './core/directives/has-permission.directive';
import { CreateRoleComponent } from './role-permission/create-role/create-role.component';
import { ConfirmDialogComponent } from './role-permission/confirm-dialog/confirm-dialog.component';
import { EditRoleComponent } from './role-permission/edit-role/edit-role.component';
import { SwatchComplistDialog } from './admin/active-check-complist-dialog/swatch-complist-dialog.component';
import { AppSettingsComponent } from './main/app-settings/app-settings.component';
import { RearrangeSequenceNbrComponent } from './admin/manage-component-list-dialog/rearrange-sequence-nbr/rearrange-sequence-nbr.component';
import { HeaderInterceptor } from './core/interceptors/header-interceptor';
import { InvalidUserComponent } from './access-denied/invalid-user.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { UserGuard } from './core/guards/user.guard';
import { ComponentListDetailsComponent } from './vendor/bid/component-list-details/component-list-details.component';
import { ConfirmDeleteUserComponent } from './user-profiles/confirm-delete-user/confirm-delete-user.component';
import 'ag-grid-enterprise'; 
import { AgGridModule } from 'ag-grid-angular';
import { BidAgGridComponent } from './vendor/bid/bid-ag-grid/bid-ag-grid.component';
import { MassBidDialogComponent } from './vendor/bid/mass-bid-dialog/mass-bid-dialog.component';
import { SpecLibraryPricingReportComponent } from './spec-library-pricing-report/spec-library-pricing-report.component';
import { ButtonRendererComponent } from './core/renderers/button-renderer.component';
import { SpecTableComponent } from './spec-library-pricing-report/spec-table.component';
import { QuantityTableComponent } from './spec-library-pricing-report/quantity-table.component';
import { ExcludeVendorsComponent } from './spec-library-pricing-report/dialogs/exclude-vendors.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use.
      clientId: ConfigModel.CLIENT_ID, // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: ConfigModel.AUTHORITY, // PPE testing environment.
      redirectUri: ConfigModel.REDIRECT_URL,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  // const protectedResourceMap = new Map<string, Array<string> | null>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  // protectedResourceMap: new Map<string, Array<string> | null>([
  //   ['https://graph.microsoft.com/', ['User.Read']]
  // ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['https://graph.microsoft.com/', ['User.Read']],
      [ConfigModel.API_URL + "/public", null],
      [ ConfigModel.API_URL + "/*", [ConfigModel.API_SCOPE]
    ]
    ])
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    HomeComponent,
    SpecCollectionComponent,
    AccessoriesComponent,
    BaseComponentComponent,
    SpecCollectionDirective,
    AuthorizationDirective,
    HasPermissionDirective,
    ComponentLoaderDirective,
    PositiveNumberDirective,
    AlphaNumericDirective,
    LeftPadDirective,
    LoaderComponent,
    VendorProfileComponent,
    SwatchingTableComponent,
    AdministrationComponent,
    AdminCategoryManageComponent,
    AdminComponentManageComponent,
    AdminComponentListManageComponent,
    AdminUpdateDialogComponent,
    ComponentTableDialogComponent,
    ComponentListTableDialogComponent,
    ManageComponentDialogComponent,
    ManageComponentListDialogComponent,
    UpdateCompDialogComponent,
    UpdateCompListDialogComponent,
    SpecLibraryContainerComponent,
    SpecLibraryTableComponent,
    MultiSpecComponent,
    EditSpecDialogComponent,
    PrintSpecHeaderComponent,
    EditMultiSpecComponent,
    ErrorDialogComponent,
    MultiSpecReviewComponent,
    CreateMultiSpecComponent,
    BackerBoardComponent,
    SwatchingHeaderComponent,
    CreateBackerBoardComponent,
    BackerBoardReviewComponent,
    EditBackerBoardComponent,
    CreatePhotopackComponent,
    EditPhotopackComponent,
    SwatchingComponent,
    CreateSwatchComponent,
    EditSwatchComponent,
    FolderComponent,
    CreateFolderComponent,
    FolderReviewComponent,
    EditFolderComponent,
    SpecHistoryComponent,
    SpecHistoryTableComponent,
    SpecHistoryDialogComponent,
    SpecDetailHistoryDialogComponent,
    BackerBoardHistoryComponent,
    MultiSpecHistoryComponent,
    PhotopackHistoryComponent,
    FolderHistoryComponent,
    FlipUpOutComponent,
    PhotopackComponent,
    PhotopackReviewComponent,
    AdminQtyFactorGridComponent,
    QfgTableDialogComponent,
    ManageQfgDialogComponent,
    UpdateQfgDialogComponent,
    RolePermissionComponent,
    VendorBidComponent,
    VendorBidDetailComponent,
    AccessDeniedComponent,
    UserProfilesComponent,
    UpdateUserProfilesComponent,
    SpecRoutingComponent,
    PriceTableComponent,
    ComponentTableComponent,
    FormErrorComponent,
    FormErrorComponent,
    SwatchRoutingComponent,
    PendingChangesDialogComponent,
    SpecLibraryPricingComponent, 
    ManualSpecPricingComponent,
    UnauthorizedComponent,
    SwatchHistoryComponent,
    AssemblyPricingComponent,
    SpecLibraryPricingTableComponent,
    BinderyPricingComponent,
    SpecLibraryPricingSpecTable,
    AssemblyDialogComponent,
    BinderyDialogComponent,
    BinderyPricingSpecComponent,
    ActiveCheckComplistDialogComponent,
    SwatchHistoryComponent, 
    ActiveCheckComplistDialogComponent, 
    VendorCreateDialogComponent, 
    VendorEditDialogComponent, 
    CreateRoleComponent, 
    ConfirmDialogComponent, 
    EditRoleComponent,
    SwatchComplistDialog,
    AppSettingsComponent,
    RearrangeSequenceNbrComponent,
    InvalidUserComponent,
    ComponentListDetailsComponent,
    ConfirmDeleteUserComponent,
    BidAgGridComponent,
    MassBidDialogComponent,
    SpecLibraryPricingReportComponent,
    ButtonRendererComponent,
    SpecTableComponent,
    QuantityTableComponent,
    ExcludeVendorsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MsalModule,
    FlexLayoutModule, 
    AgGridModule,
  ],
  exports:[
    SideNavComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor, 
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },    
    {
      provide: CommonService, 
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: CommonService
    },
    PendingChangesGuard,
    PermissionsGuard,
    UserGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }