<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Base Components</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Board Weight:&nbsp;</span>
        {{ specDetail?.boardWeight }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Stock:&nbsp;</span>
        {{ specDetail?.coverStock }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Colors:&nbsp;</span>
        {{ specDetail?.coverColors }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Film:&nbsp;</span>
        {{ specDetail?.coverFilm }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Liner Stock:&nbsp;</span>
        {{ specDetail?.linerStock }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Liner colors:&nbsp;</span>
        {{ specDetail?.linerColors }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Liner Film:&nbsp;</span>
        {{ specDetail?.linerFilm }}
    </p>
</div>

<ng-container *ngIf="specDetail?.flipOutUpSpec">
    <mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
        <h2 style="margin: 5px 0 5px;">Flip Up/Out Components</h2>
    </mat-toolbar>

    <div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Quantity:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.count }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Board Weight:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.boardWeight }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Cover Stock:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.coverStock }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Cover Colors:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.coverColors }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Cover Film:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.coverFilm }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Liner Stock:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.linerStock }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Liner Colors:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.linerColors }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Liner Film:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.linerFilm }}
        </p>
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Attachment Method:&nbsp;</span>
            {{ specDetail?.flipOutUpSpec?.attachmentMethod }}
        </p>
    </div>
</ng-container>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Finished Size</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Size Factor:&nbsp;</span>
        {{ specDetail?.finishedSize }}
    </p>    
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Finishing Options</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <ng-container *ngIf="specDetail && specDetail.finishingOptions.length > 0; else noFinishingOptions">
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text" *ngFor="let option of specDetail.finishingOptions; let i = index">
            {{ (i+1) + ': ' + option }}
        </p>    
    </ng-container>
    <ng-template #noFinishingOptions>
        <p>No finishing options were selected</p>
    </ng-template>
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Accessory Options</h2>
</mat-toolbar>

<ng-container *ngIf="specDetail && specDetail.accessories.length > 0; else noAccessories;">
    <div fxLayout="row wrap" fxLayoutAlign="start" 
         class="accessory-row py-1"
         *ngFor="let acc of specDetail.accessories; let i = index;"
         [class.odd-row]="i % 2 !== 0">

        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Accessory:&nbsp;</span> {{ acc.accessory }}
        </p>    
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Quantity:&nbsp;</span> {{ acc.qty }}
        </p>    
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Comment1:&nbsp;</span> {{ acc.comment1 }}
        </p>    
        <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
            <span class="label-font">Comment2:&nbsp;</span> {{ acc.comment2 }}
        </p>    

    </div>
</ng-container>

<ng-template #noAccessories>
    <div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">No Accessories were added</div>
</ng-template>