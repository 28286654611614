<mat-toolbar style="width: 100%;" color="primary">
    <span>Update {{ data.value }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
<form class="example-form dialog-update" #compListUpdateForm="ngForm" (ngSubmit)="checkedBeforeUpdate(compListUpdateForm)">
    <div mat-dialog-content>
        <input type="hidden" [ngModel]="data.id" name="id">  
        <input type="hidden" [ngModel]="data.componentId" name="componentId">
        <input type="hidden" [ngModel]="data.compReferenceId" name="compReferenceId">
        <input type="hidden" [ngModel]="data.seqNbr" name="seqNbr">
            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
             <mat-form-field appearance="outline">
                 <mat-label>Change Component List Value</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.value" name="value">
             </mat-form-field>
             <!-- <mat-form-field appearance="outline">
                 <mat-label>Change Component List Sequence Number</mat-label>
                 <input matInput type="number" min="1" max={{this.data.listLength}} autocomplete="off" [ngModel]="data.seqNbr" name="seqNbr" ngModel required>    
             </mat-form-field> -->
             <mat-form-field appearance="outline">
                 <mat-label>Change Component List Description</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.description" name="description">    
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Change Component List Unit of Measurement</mat-label>
                 <mat-select [ngModel]="data.uom" name="uom">
                    <mat-option *ngFor="let e of swatchUomList" [value]="e.value">
                       {{ e.value }}
                    </mat-option>
                </mat-select>
                 <!-- <input matInput autocomplete="off" [ngModel]="data.uom" name="uom">     -->
             </mat-form-field>
             <mat-form-field appearance="outline">
                <mat-label>Change Component List Product Type</mat-label>
                <mat-select name="productType" [ngModel]="data.productType">
                    <mat-option [value]="''">None</mat-option>
                    <mat-option [value]="'both'">Both</mat-option>
                    <mat-option [value]="'softSurface'">Soft Surface</mat-option>
                    <mat-option [value]="'hardSurface'">Hard Surface</mat-option>
                </mat-select>
             </mat-form-field>
             <mat-form-field appearance="outline" *ngIf="this.data.compReferenceId == 'quantityFactorGrid'">
                 <mat-label>Change Minimum Quantity</mat-label>
                 <input matInput type="number" min="0" autocomplete="off" [ngModel]="data.minQty" name="minQty">    
             </mat-form-field>
             <mat-form-field appearance="outline" *ngIf="this.data.compReferenceId == 'quantityFactorGrid'">
                 <mat-label>Change Maximum Quantity</mat-label>
                 <input matInput type="number" min="0" autocomplete="off" [ngModel]="data.maxQty" name="maxQty">    
             </mat-form-field>
             <div fxLayout="row wrap" fxLayoutAlign="center" class="my-2">
                <mat-checkbox color="accent" [ngModel]="data.canBid" name="canBid">Can Bid</mat-checkbox>
                <mat-checkbox class="pl-3" color="accent" [ngModel]="data.negativeBid" name="negativeBid">Negative Bid</mat-checkbox>
                <mat-checkbox class="pl-3" color="accent" [ngModel]="data.active" name="active">Active</mat-checkbox>
             </div>
            </mat-card-content>
    </div>

    <div mat-dialog-actions align="end">
      <button type="submit" mat-raised-button color="accent" [disabled]="!compListUpdateForm.valid">Update</button>
    </div>
</form>
