import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { Subject } from 'rxjs';

import { ThemeService } from 'src/app/core/services/theme.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfigModel } from 'src/app/core/models/config-model';

export const sideNavTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 500, 
	hideDelay: 100,
	touchendHideDelay: 500,
};

/** @title Responsive sidenav */
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  providers: [
		{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: sideNavTooltipDefaults}
	],
  animations: [
    trigger('sidenavAnimationIsExpanded', [
      state('true', style({
        width: '250px'
      })),
      state('false', style({
        width: '64px'
      })),
      transition('false <=> true', animate('100ms ease'))
    ])
  ]
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() isLoaded: boolean = false;
  @Input() isAuthorized: boolean = false; 

  private readonly destroy$: Subject<void> = new Subject<void>();
  
  userName: string | undefined  = '';
  vendorName: string | undefined = '';
  mobileQuery: MediaQueryList;
  envName: string = ConfigModel.ENV_NAME;
  appVersion = ConfigModel.APP_VERSION; 

  isExpanded = false;
  animating = false;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher, 
    private userService: UserService, 
    private themeService: ThemeService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.userName = this.userService.getUserName();
    
    if(this.isAuthorized) {
      const user = this.userService.getUser(); 
  
      if(user && user.vendorName) {
        this.vendorName = `(${user.vendorName})`;
      } 
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  public logout(): void {
    this.userService.logout();
  }

  changeTheme(theme: string) {
    this.themeService.update(theme);
  }

  start() {
    this.animating = true;
    this.tick();
  }

  done() {
    this.animating = false;
  }

  tick() {
    if (this.animating) requestAnimationFrame(() => this.tick());
  }
}