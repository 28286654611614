<mat-stepper orientation="horizontal" (selectionChange)="stepChanged($event)">

    <mat-step [hasError]="swatchService.isSwatchError">
        <ng-template matStepLabel>Swatch Type</ng-template>
          <div class="table-container-500">
            <app-swatching-table [componentList]="swatchService.swatchType" (valueChange)="swatchTypeQtyChange($event)"></app-swatching-table>
          </div>
          <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
            <button type="button" mat-raised-button [color]="'primary'" matStepperNext>Next</button>
          </div>        
    </mat-step>

    <mat-step [hasError]="swatchService.isCutAddOnsError">
        <ng-template matStepLabel>Cut Add-Ons</ng-template>
          <div class="table-container-500">
            <app-swatching-table [componentList]="swatchService.cutAddOnsFiltered" (valueChange)="qtyChange($event)"></app-swatching-table>
          </div>
          <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
            <button type="button" mat-raised-button matStepperPrevious>Back</button>
            <button type="button" mat-raised-button [color]="'primary'" matStepperNext style="margin-left: 5px;">Next</button>
          </div>      
    </mat-step>

    <mat-step [hasError]="swatchService.isFinishingError">
        <ng-template matStepLabel>Finishing / Extras</ng-template>
          <div class="table-container-500">
            <app-swatching-table [componentList]="swatchService.finishingFiltered" (valueChange)="qtyChange($event)"></app-swatching-table>
          </div>
          <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
            <button type="button" mat-raised-button matStepperPrevious>Back</button>
            <button type="button" mat-raised-button [color]="'primary'" matStepperNext style="margin-left: 5px;">Next</button>
          </div>
    </mat-step>

    <mat-step [hasError]="swatchService.isBoxingError">
        <ng-template matStepLabel>Boxing / Packaging</ng-template>
          <div class="table-container-500">
            <app-swatching-table [componentList]="swatchService.boxingFiltered" (valueChange)="qtyChange($event)"></app-swatching-table>
          </div>
          <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
            <button type="button" mat-raised-button matStepperPrevious>Back</button>
            <button type="button" mat-raised-button [color]="'primary'" matStepperNext style="margin-left: 5px;">Next</button>
          </div>
    </mat-step>

    <mat-step #pbStep [hasError]="swatchService.isPanelBackerBoard && !isBackerBoardValid && (pbbDetailForm.touched || pbStep.interacted)">
      <ng-template matStepLabel>Panel Backer Board</ng-template>
      <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
        <mat-form-field fxFlex="24" fxFlex.lt-md="100" appearance="outline">
          <mat-label>Add Panel Backer Board</mat-label>
          <mat-select [(ngModel)]="swatchService.isPanelBackerBoard" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-backer-board [hidden]="!swatchService.isPanelBackerBoard" [componentLists]="swatchService.backerBoardLists"></app-backer-board>
      <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
        <button mat-raised-button matStepperPrevious>Back</button>
        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Review Spec</button>
    </div>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Review Spec</ng-template>
          <div class="table-container-500">
            <app-swatching-table [componentList]="swatchService.selectedSwatches" [editable]="false" [showType]="true"></app-swatching-table>

            <div [hidden]="!swatchService.isPanelBackerBoard">
              <div fxLayout="row wrap" class="mt-10">
                <div fxFlex="100" fxLayoutAlign="center center">
                  <h2>Panel Backer Board</h2>
                </div>
              </div>
              <app-backer-board-review [specDetail]="backerService.reviewSpec$ | async"></app-backer-board-review>
            </div>
          </div>

          <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
              <button type="button" mat-raised-button matStepperPrevious>Back</button>
              <button type="button" mat-raised-button 
                      *hasPermission="['EDIT_SPEC']"
                      color="primary"
                      style="margin-left: 5px;"
                      (click)="submit()">Submit</button>
          </div>
    </mat-step>

</mat-stepper>