import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { isEmpty } from 'lodash';
import { EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators';

import { Lookup, SpecActions } from 'src/app/core/models/common-models';
import { SpecDTO } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ActiveCheckComplistDialogComponent } from '../active-check-complist-dialog/active-check-complist-dialog.component';
import { SwatchComplistDialog } from '../active-check-complist-dialog/swatch-complist-dialog.component';
import { AdministrationService } from '../services/administration.service';

@Component({
  selector: 'app-update-comp-list-dialog',
  templateUrl: './update-comp-list-dialog.component.html',
  styleUrls: ['./update-comp-list-dialog.component.scss'],
  providers: [AdministrationService]
})
export class UpdateCompListDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdateCompListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adminService: AdministrationService,
              private snackService: SnackBarService,
              public commonService: CommonService,
              public dialog: MatDialog,
  ) { }

  swatchUomList!: Lookup[];

  ngOnInit(): void {
    this.getSwatchUomList();
  }

  checkedBeforeUpdate(form: NgForm) {
    if (form.value.active == true) {
      this.updateCompList(form);
      return;
    }

    this.adminService.getSpecsWithCompList(this.data.id).subscribe(response => {
      if(!isEmpty(response)) {
        if(response.some(spec => spec.categoryRefId === 'swatching')) {
          this.openAssemblyCompListDialog(this.data.id);  
          return;
        }

        this.openActiveCheckCompListDialog(response); 
        return;
      }

      this.updateCompList(form); 
    },
    (e) => {
      this.snackService.error('An error occurred while retrieving Spec. If problem persist, please contact support.');
      console.error(e);
    });
  }

  getSwatchUomList() {
    this.commonService.getSwatchUomList().subscribe((response: any) => {
      this.swatchUomList = response;
    },
    (e) => {
      this.snackService.error('An error occurred while retrieving group id list. If problem persist, please contact support.');
      console.error(e);
    })
  }

  updateCompList(form: NgForm) {
    this.adminService.updateComponentList(form.value).subscribe((response: any) => {
      this.snackService.success('Component list was successfully updated.');
    },
    (e: any) => {
      this.snackService.error('An error occurred while updating component list. If problem persist, please contact support.');
      console.error(e);
    },
    () => {
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  openActiveCheckCompListDialog(specInfo: SpecDTO[]) {    
    this.dialog.open(ActiveCheckComplistDialogComponent, {
      data: {
        compListId: this.data.id,
        componentId: this.data.componentId,
        componentName: this.data.componentName,
        componentListName: this.data.value,
        info: specInfo
      }
    });
  }

  openAssemblyCompListDialog(compListId: number) {
    const dialogRef = this.dialog.open(SwatchComplistDialog, {disableClose: true, hasBackdrop: true});

    if(dialogRef) {
      dialogRef.afterClosed()
        .pipe(switchMap(action => action === SpecActions.Updated ? this.adminService.updateAssemblyCompListId(compListId) : EMPTY))
        .subscribe(() => this.snackService.success('Component list value was successfully removed from spec(s)')); 
    }
  }
}