import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { SpecLibraryDto } from '../models/spec-library.models';

@Component({
  selector: 'app-spec-library-table',
  templateUrl: './spec-library-table.component.html'
})
export class SpecLibraryTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  @Input()
  set data(value: SpecLibraryDto[]) {
    this.dataSource.data = value;
  }

  @Output() editSpec = new EventEmitter<any>();
  @Output() historySelected = new EventEmitter<any>();
  @Output() copySpecSelected = new EventEmitter<any>();

  dataSource: MatTableDataSource<SpecLibraryDto> = new MatTableDataSource();
  displayColumns = ['categoryName', 'supplierName','styleNbr','skuNbr','deliveryVehicleNbr', 'customerNbr', 'sequenceNbr', 'createdByName', 'createdTimestamp', 'changedByName', 'changedByTimestamp','edit'];

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  edit(specId: number, categoryId: number, categoryRefId: string): void {
    this.editSpec.emit({specId: specId, categoryId: categoryId, categoryRefId: categoryRefId});
  }

  clickHistory(specId: number): void {
    this.historySelected.emit(specId);
  }

  clickCopy(specId: number, categoryId: number, categoryRefId: string): void {
    this.copySpecSelected.emit({specId: specId, categoryId: categoryId, categoryRefId: categoryRefId});
  }
}
