import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/internal/operators';

import { User } from "../models/common-models";
import { ConfigModel } from '../models/config-model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user!: User;
  private _userLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly isUserLoaded$ = this._userLoaded.asObservable(); 

  constructor(
    private msalService: MsalService,  
    private router: Router,
    private httpClient: HttpClient
  ) { }

  get isVendor(): boolean {
    const user = this.getUser(); 

    if(!this.isValidUser) {
      return true;
    }

    return this.isVendorRole || user.supplierId > 0;
  }

  get isValidUser(): boolean {
    const user = this.getUser(); 
    
    if(this.isVendorRole && (!user || user.supplierId <= 0)) {
      this.router.navigate(['invalid-user']); 
      return false;
    }

    return true;
  }

  private get isVendorRole(): boolean {
    const userRoles = this.getUserRoles();     
    return userRoles.includes('vendor_admin') || userRoles.includes('vendor_user'); 
  }

  public get fetchUser$(): Observable<User> {
    this._userLoaded.next(false);
    return this.httpClient.get<User>(`${ConfigModel.API_URL}/v1/users`)
      .pipe(
        tap(user => {
          this.user = user; 
          this._userLoaded.next(true);
        })
      );
  }

  public getUserRoles(): string[] {   
    let claims: any =  this.getAccount()?.idTokenClaims;
    let roles: Array<string> = claims?.roles;

    if(!roles || roles.length <= 0) {
      this.router.navigate(['invalid-user']); 
      throw 'Invalid user -> User roles could not be determined.'; 
    }

    return roles?.map(role => role.toLowerCase());
  }

  public getUserName(): string | undefined {
    // return this.getAccount()?.name;
    return this.user.name || ''; 
  }

  public hasRole(role: string): boolean {
      return this.hasAnyRole([role])
  }

  public hasAnyRole(roles: string[] | undefined): boolean {
    let userRoles = this.getUserRoles(); 
    roles = roles?.map(r => r.toLowerCase()) || []; 

    if (userRoles && roles)
    {
      /* Vendor role is not allowed. User has non vendor role, but is setup as vendor in SAVE profile, 
        then treat them as a vendor */
      if((!roles.includes('vendor_admin') && !roles.includes('vendor_user')) && this.isVendor) { //Look at this.isVendor
        return false;
      }

      for (let role of roles) { 
        if (userRoles.includes(role.toLowerCase()))
          return true;
      }
    }
    return false;
  }

  public hasPermission(permission: string): boolean {
    return this.hasAnyPermission([permission]);
  }

  public hasAnyPermission(permissions: string[] | undefined): boolean {
    let userPermissions = this.getUser().permissions?.map(p => p.toUpperCase()); 
    permissions = permissions?.map(p => p.toUpperCase()); 

    if(!userPermissions || !permissions) {
      return false; 
    }

    return userPermissions.some(uP => permissions?.includes(uP)); 
  }

  public hasAllPermissions(permissions: string[] | undefined): boolean {
    let userPermissions = this.getUser().permissions?.map(p => p.toUpperCase()); 
    permissions = permissions?.map(p => p.toUpperCase()); 

    if(!userPermissions || !permissions) {
      return false; 
    }

    return permissions.every(p => userPermissions?.includes(p)); 
  }

  public getUser(): User {
    if(!this.user) {
      this.fetchUser$.subscribe();
    }

    return this.user || {} as User; 
  }

  public logout(): void {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: `${ConfigModel.REDIRECT_URL}`
    });
  }

  public isCacheInvalid(expiry: number): boolean {
    if(expiry > this.user.expiry) { 
      this.user.expiry = expiry; 
      return true; 
    }

    return false; 
  }

  private getAccount(): AccountInfo {
    return this.msalService.instance.getAllAccounts()[0];
  }
}