<form [formGroup]="myForm">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxLayoutAlign="start" style="margin-top: 5px;">
            <h2 mat-dialog-title>Edit {{data.name}}</h2>
        </div>
        <div fxLayoutAlign="end start">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-around">
        <input type="hidden" formControlName="id">
            <mat-form-field fxFlex="45" fxFlex.md="56" fxFlex.lt-md="100" appearance="outline">
                <mat-label>UPN</mat-label>
                <input matInput placeholder="upn" formControlName="upn">
            </mat-form-field>
            <mat-form-field fxFlex="45" fxFlex.md="56" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="name" formControlName="name">
            </mat-form-field>
        </div>
        <div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-around">
            <mat-form-field fxFlex="45" fxFlex.md="56" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Preferred Name</mat-label>
                <input matInput placeholder="preferredName" formControlName="preferredName">
            </mat-form-field>
            <mat-form-field fxFlex="45" fxFlex.md="56" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="email" formControlName="email">
            </mat-form-field>
        </div>
        <div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="center">
            <mat-form-field fxFlex="45" fxFlex.md="56" fxFlex.lt-md="100" appearance="outline">
                <mat-select placeholder="{{data.supplierName}}" formControlName="supplierId">
                    <mat-option [value]="0">-- None --</mat-option>
                    <mat-option *ngFor="let vendor of vendorList$ | async" [value]="vendor.id">
                        {{ vendor.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center">
            <mat-checkbox color="primary" placeholder="active" formControlName="active">
                <mat-label>Active</mat-label>
            </mat-checkbox>
    </div>

    <div fxLayoutAlign="end">
        <button mat-raised-button color="primary" [disabled]="myForm.invalid" (click)="submit()">Submit</button> 
    </div>
</form>