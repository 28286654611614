import { Component, OnInit } from '@angular/core';

import { tap } from 'rxjs/internal/operators';
import { ConfigModel } from 'src/app/core/models/config-model';
import { UserService } from 'src/app/core/services/user.service';

import { AppConfig } from './app-settings.model';
import { AppSettingsService } from './app-settings.service';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styles: [
  ], 
  providers: [AppSettingsService]
})
export class AppSettingsComponent implements OnInit {
  appConfig!: AppConfig; 
  isEditAdmin: boolean = false; 
  config: {isVendorBidding: boolean} = {isVendorBidding: false};
  apiUrl: string = ConfigModel.API_URL;
  
  constructor(
    private settingsService: AppSettingsService, 
    private userService: UserService
  ) { }

  readonly appStatus$ = this.settingsService.getAppStatus(); 

  ngOnInit(): void { 
    this.settingsService.getAppSettings()
      .pipe(tap(config => {
        this.appConfig = config; 
        this.config.isVendorBidding = this.appConfig.vendorBidding === 'on'; 
      }))
      .subscribe(); 

      this.isEditAdmin = this.userService.hasPermission('EDIT_ADMIN'); 
  }

  toggleVendorBidding(): void {
    this.settingsService.updateAppSettings({'vendorBidding': (this.config.isVendorBidding ? 'on' : 'off')}).subscribe(); 
  }

  clearUserCache(): void {
    this.settingsService.clearUserCache().subscribe(); 
  }
}
