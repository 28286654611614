<mat-stepper orientation="horizontal">

    <mat-step>
        <ng-template matStepLabel>{{ parentSpec.categoryName }}</ng-template>
        <ng-template matStepContent>
            <app-spec-library-pricing-spec-table *ngIf="parentSpec && parentSpec.specComponents && parentSpec.specComponents.length > 0 " [data]="parentSpec.specComponents"></app-spec-library-pricing-spec-table>
            <div fxLayout="row wrap" fxLayoutAlign="end" class="mt-10">
                <button *ngIf="childSpecs && childSpecs.length > 0" mat-raised-button type="button" color="primary" matStepperNext style="margin-left: 5px;">Next</button>
            </div>
        </ng-template>
    </mat-step>

    <ng-container *ngIf="childSpecs && childSpecs.length > 0">
        <mat-step *ngFor="let childSpec of childSpecs; let idx = index;">
            <ng-template matStepLabel>{{ childSpec.categoryName }}</ng-template>
            <ng-template matStepContent>
                <app-spec-library-pricing-spec-table *ngIf="childSpec.specComponents && childSpec.specComponents.length > 0" [data]="childSpec.specComponents"></app-spec-library-pricing-spec-table>
                <div fxLayout="row wrap" fxLayoutAlign="end" class="mt-10">
                    <button type="button" mat-raised-button matStepperPrevious>Back</button>
                    <button *ngIf="(idx+1) < childSpecs.length" type="button" mat-rasied-button color="primary" matStepperNext>Next</button>  
                </div>
            </ng-template>
        </mat-step>
    </ng-container>

</mat-stepper>