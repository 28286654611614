import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/internal/operators";

import { EditSpecDialogComponent } from "src/app/spec-collection/core/edit-spec-dialog/edit-spec-dialog.component";
import { SpecDetailHistoryDialogComponent } from "src/app/spec-history/spec-detail-history-dialog/spec-detail-history-dialog.component";
import { SpecHistoryDialogComponent } from "src/app/spec-history/spec-history-dialog/spec-history-dialog.component";
import { ErrorDialogComponent } from "../dialog/error-dialog/error-dialog.component";
import { CategoryComponents } from "../models/category-map.models";
import { CategoryList, CategoryListStorage , ListStorage, ItemStorage, Lookup } from "../models/common-models";
import { ConfigModel } from "../models/config-model";

const CATLIST_KEY: string = 'csds-category-list';
const MINUTES_TO_LIVE: number = 10;

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private readonly categoryLists$ = this.httpClient.get<CategoryList[]>(`${ConfigModel.API_URL}/v1/category/lists`);

    constructor(
        private httpClient: HttpClient, 
        private dialog: MatDialog
    ) {
        this.loadCategoryList();
    }

    /**
     * Gets an array of categories in a CategoryList object.
     * @returns Observable<CategoryList[]> - a list of category list objects
     */
    public getCategoryList(isActive: boolean = false): Observable<CategoryList[]> {        
        const storage = this.getCatoryListFromStorage();

        if(storage) {
            let now = new Date();

            if(storage.expiry > now.getTime()) {
                return of(storage.categoryLists.filter(cat => !isActive || cat.active));
            }

            localStorage.removeItem(CATLIST_KEY);
        }

        return this.categoryLists$
            .pipe(
                tap(lists => this.storeCategoryList(lists)),
                map(lists => lists.filter(cat => !isActive || cat.active))
            );
    }

    public getCategoryIdByRefId(refId: string): number {
        return this.getCategoryByRefId(refId)?.value || 0;
    }

    public getCategoryByRefId(refId: string): CategoryList | undefined {
        const storage = this.getCatoryListFromStorage(); 
        return storage?.categoryLists.find(cat => cat.refId === refId);
    }
    
    public getComponentByRefId(refId: string): any {
        return CategoryComponents.CATEGORY_MAP.get(refId);
    }

    public getListByName$(listName: string): Observable<string[] | void> {
        return this.httpClient.get<string[] | void>(`/util-service/lists/${listName}`);
    }

    public getGroupIdList(): Observable<Lookup> {
        return this.httpClient.get<Lookup>(`${ConfigModel.API_URL}/v1/lookup/group-id`)
    }

    public getSwatchUomList(): Observable<Lookup> {
        return this.httpClient.get<Lookup>(`${ConfigModel.API_URL}/v1/lookup/swatch-uom`)
    }

    public openEditSpecDialog(specId: number, categoryId: number, categoryRefId: string, isCopy: boolean = false): MatDialogRef<any, any> | undefined {
        let dialogRef: MatDialogRef<any, any> | undefined;
        try 
        {
            dialogRef = this.dialog.open(EditSpecDialogComponent, {
                maxWidth: '90vw',
                minWidth: '80vw',
                disableClose: true,
                autoFocus: false,
                data: {
                    specId: specId,
                    categoryId: categoryId, 
                    categoryRefId: categoryRefId,
                    isCopySpec: isCopy
                }
            });
        }
        catch(e) 
        {
            console.log('Error occurred');

            if(dialogRef) {
                dialogRef.close('error');
            }

            this.dialog.open(ErrorDialogComponent, {
                data: {message: 'An error occurred while loading spec. If error persist, please contact support.'}
            });
        }

        return dialogRef;
    }

    public openSpecHistory(specId: number): MatDialogRef<any, any> | undefined {
        let dialogRef: MatDialogRef<any, any> | undefined;

        try
        {
            dialogRef = this.dialog.open(SpecHistoryDialogComponent, {
                maxWidth: '90vw',
                minWidth: '70vw',
                maxHeight: '90vh',
                autoFocus: false, 
                data: { specId: specId }
            });
        }
        catch(e)
        {
            console.log('Error occurred: ', e);

            if(dialogRef) {
                dialogRef.close('error')
            }
        }

        return dialogRef;
    }

    public openSpecDetailHistoryDialog(historyId: number): MatDialogRef<any, any> | undefined {
        let dialogRef: MatDialogRef<any, any> | undefined;

        try
        {
            dialogRef = this.dialog.open(SpecDetailHistoryDialogComponent, {
                maxWidth: '90vw',
                minWidth: '70vw',
                maxHeight: '90vh',
                autoFocus: false, 
                data: { historyId: historyId }
            });
        }
        catch(e)
        {
            console.log('Error occurred: ', e);

            if(dialogRef) {
                dialogRef.close('error')
            }

            this.dialog.open(ErrorDialogComponent, {
                data: {message: `An error occurred while loading spec detail history for ID ${historyId}. If problem persist, please contact support.`}
            })
        }

        return dialogRef;
    }

    /**
     * Loads the category list and stores them in localStorage. This is 
     * primarily used on app startup. This shouldn't really be called elsewhere.
     */
    private loadCategoryList(): void {
        this.categoryLists$.subscribe(lists => this.storeCategoryList(lists));
    }

    private getCatoryListFromStorage(): CategoryListStorage | null {
        try {
            const storage = localStorage.getItem(CATLIST_KEY);

            if(storage) {
                let catListStorage = <CategoryListStorage>JSON.parse(storage);
                return catListStorage;
            }

            return null;
        } catch(e) {
            return null;
        }
    }

    /**
     * This stores the CategoryList[] in localStorage using the @see `CATLIST_KEY` as the id
     * with a time to live in minutes set by @see `MINUTES_TO_LIVE`. 
     * 
     * @param lists - CategoryList[]
     */
    private storeCategoryList(lists: CategoryList[]): void {
        const currentDate = new Date();

        const storage: CategoryListStorage = {expiry: (currentDate.getTime() + (MINUTES_TO_LIVE * 60000)), categoryLists: lists};
        localStorage.setItem(CATLIST_KEY, JSON.stringify(storage));
    }


    /**
     * This stores any List[] in localStorage using the key string as the id
     * with a time to live in minutes set by @see `MINUTES_TO_LIVE`. 
     * 
     * @param list - Any List
     * @param key - local storage key to store the list under
     * @param minutesToLive - for how many minutes from now the list needs to stay in storage
     */
     public storeList(aList: any[], key: string, minutesToLive: number): void {
        const currentDate = new Date();

        const storage: ListStorage = {expiry: (currentDate.getTime() + (minutesToLive * 60000)), list: aList};
        localStorage.setItem(key, JSON.stringify(storage));
    }

    /**
     *  Returns a cached list from local storage using the key.
     * If the list expired, it will be reomoved from local cache
     */
    public getListFromStorage(key: string): ListStorage | null 
    {
        try 
        {
            const storage = localStorage.getItem(key);

            if(storage) 
            {
                let listStorage = <ListStorage>JSON.parse(storage);
                let now = new Date();

                if(listStorage.expiry >= now.getTime()) {
                    return listStorage;
                }
                else 
                {
                    localStorage.removeItem(key);
                    return null;
                }
            }
            return null;
        } 
        catch(e) 
        {
            return null;
        }
    }


    /**
     * Store any object in localStorage using key and minutes to live
     * @param data 
     * @param key 
     * @param expiryInMinutes 
     */
    public store(data: any, key: string, expiryInMinutes: number): void {
        const currentDate = new Date();
        const minutesToLive: number = 60;
        const storage: ItemStorage = {expiry: (currentDate.getTime() + (expiryInMinutes * 60000)), item: data };
        const storageItem =  JSON.stringify(storage); 
        localStorage.setItem(key, storageItem);
      }

  /**
     *  Returns a cached object from local storage using the key.
     * If the list expired, it will be reomoved from local cache
     */
   public getFromStorage(key: string): any | null 
   {
       try 
       {
           const storage = localStorage.getItem(key);

           if(storage) 
           {
               let itemStorage = <ItemStorage>JSON.parse(storage);
               let now = new Date();

               if(itemStorage.expiry >= now.getTime()) {
                   return itemStorage.item;
               }
               else 
               {
                   localStorage.removeItem(key);
                   return null;
               }
           }
           return null;
       } 
       catch(e) 
       {
           return null;
       }
   }
    
    public saveFileAs(file: Blob, fileName?: string): void {
        let url = window.URL.createObjectURL(file); 
        let href = document.createElement('a'); 
        href.href = url; 
        if(fileName) {
            href.download = fileName; 
        }
        href.click(); 
        href.remove(); 
    }
}