import { ComponentGroup } from "src/app/core/models/common-models";
import { NOT_AVAILABLE, PriceDto, QuantityFactorGrid, RoutingToolData, VendorPricingHeader } from "./price-routing.models";

export const VendorPriceBuilder = {
    calculate(refId: string, vendorPricing: VendorPricingHeader[], routingData: RoutingToolData[], qtyFactorGrid: QuantityFactorGrid): void {
        switch (refId)
        {
            case 'backerboard':
            case 'card':            
            case 'deckboard':
            case 'csf': 
            case 'photopack':
                calculateMultiSpecPricing(vendorPricing, routingData, qtyFactorGrid);
                break;
            case 'folder':
                calculateMultiSpecPricing(vendorPricing, routingData, qtyFactorGrid, true);
                break;
        }
    },
    buildQuantityFactor(group: ComponentGroup | undefined): QuantityFactorGrid {
        if(!group) { 
            return {refId: 'qtyFactorGrid', quantity: 1, tier: NOT_AVAILABLE, value: 0, lists: [], pricing: new Map()};
        }
        
        let tier = group.listValues.find(value => value.minQty === 1);
        return { refId: 'qtyFactorGrid', quantity: 1, tier: tier?.value || NOT_AVAILABLE, value: tier?.id || 0, lists: group.listValues, pricing: new Map() };
    }
}

function calculateMultiSpecPricing(vendorPricing: VendorPricingHeader[], routingData: RoutingToolData[], qtyFactorGrid: QuantityFactorGrid, isFolder: boolean = false): void {
    let sizeFactor = routingData.find(data => data.group === 2);
    let isAllRequired = routingData.filter(data => data.isRequired && data.value <= 0).length === 0;
    
    if(!sizeFactor || sizeFactor.value === 0 || !isAllRequired || !qtyFactorGrid || !qtyFactorGrid.quantity || !qtyFactorGrid.value) {
        vendorPricing.forEach(vendor => {
            vendor.unitPrice = undefined; 
            vendor.totalPrice = undefined; 
            vendor.diffTotalPrice = undefined; 
            vendor.diffUnitPrice = undefined;
            vendor.unitPriceText = ''; 
        });
        return;
    }

    let map = buildVendorPriceMap(routingData);
    
    vendorPricing.forEach(vendor => {
        let priceList = map.get(vendor.vendorId);
        
        let isMissingBid = (priceList?.filter(price => price.isMissingBid).length || 0) > 0; 
        let isNoBid = (priceList?.filter(price => price.isNoBid).length || 0) > 0; 

        let factor = priceList?.find(price => price.groupNbr === 2)?.price;
        let qtyFactor = qtyFactorGrid.pricing?.get(vendor.vendorId);

        vendor.diffTotalPrice = undefined;
        vendor.diffUnitPrice = undefined;

        if(factor && qtyFactor && !isMissingBid && !isNoBid) {
            let group1Sum = priceList?.filter(price => price.groupNbr === 1).map(price => price.price || 0).reduce((sum, current) => sum + current) || 0;

            if(isFolder) {
                let group6Sum = priceList?.filter(price => price.groupNbr === 6).map(price => price.price || 0).reduce((sum, current) => sum + current) || 0; 
                let count = routingData.find(data => data.group === 7)?.qty || 0; 
                group1Sum = group1Sum + (group6Sum * count); 
            }

            let group3Sum = priceList?.filter(price => price.groupNbr === 3).map(price => price.price || 0).reduce((sum, current) => sum + current) || 0;

            vendor.unitPrice = ((group1Sum * factor) + group3Sum) * qtyFactor;
            vendor.totalPrice = vendor.unitPrice * (qtyFactorGrid.quantity || 0);
        } else {
            vendor.totalPrice = undefined;
            vendor.unitPrice = undefined;
            vendor.unitPriceText = isNoBid ? 'No Bid' : isMissingBid ? 'Missing Bid' : ''; 
        }
    });

    calculateMinPricing(vendorPricing);
}

function calculateMinPricing(vendorPricing: VendorPricingHeader[]): void {
    let minUnitPrice = Math.min(...vendorPricing.map(vendor => vendor.unitPrice || 0).filter(price => price > 0));
    let minTotalPrice = Math.min(...vendorPricing.map(vendor => vendor.totalPrice || 0).filter(price => price > 0));

    vendorPricing.forEach(vendor => {
        vendor.diffUnitPrice = vendor.unitPrice && vendor.unitPrice != minUnitPrice ? (vendor.unitPrice - minUnitPrice) : undefined;
        vendor.diffTotalPrice = vendor.totalPrice && vendor.totalPrice != minTotalPrice ? (vendor.totalPrice - minTotalPrice) : undefined;
    });
}

function buildVendorPriceMap(routingData: RoutingToolData[]): Map<number, PriceDto[]> {
    let map: Map<number, PriceDto[]> = new Map(); 

    routingData.forEach(comp => {
        comp.pricing?.forEach( (value, key) => {
            if(!map.has(key)) {
                map.set(key, []);
            }

            map.get(key)?.push({refId: comp.refId, price: value.price, groupNbr: comp.group, isMissingBid: value.isMissingBid, isNoBid: value.isNoBid});
        });
    });

    return map;
}