import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { switchMap, tap } from 'rxjs/internal/operators';

import { UserService } from 'src/app/core/services/user.service';
import { PricingResponse, SpecLibrarySpecResponse } from '../models/spec-library-pricing.models';
import { SpecLibraryPricingService } from '../services/spec-library-pricing.service';

@Component({
  selector: 'app-bindery-pricing',
  templateUrl: './bindery-pricing.component.html',
  styles: [], 
  providers: [SpecLibraryPricingService]
})
export class BinderyPricingComponent implements OnInit {
  @ViewChild('requestForm') requestForm!: NgForm; 

  pricing: PricingResponse[] = [];
  spec: SpecLibrarySpecResponse = {} as SpecLibrarySpecResponse; 

  isVendor: boolean = true; 
  btnPreText: string = 'Compare'; 

  constructor(
    private pricingService: SpecLibraryPricingService, 
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isVendor = this.userService.isVendor; 
    if(this.isVendor) {
      this.btnPreText = 'View';
    }
  }

  submitSpec(): void {
    this.pricing = []; 
    this.spec = {} as SpecLibrarySpecResponse; 

    if(this.isVendor) {
      this.pricingService.getBinderyPricing(this.requestForm.value).pipe(
        tap(pricing => this.pricing = pricing), 
        switchMap(_ => this.pricingService.getBinderySpec(this.requestForm.value))
      )
      .subscribe(spec => this.spec = spec); 

      return;
    }

    this.pricingService.getBinderyPricing(this.requestForm.value).subscribe(data => {
      this.pricing = data;
    }); 
  }

  openDialog(): void {
    this.pricingService.openBinderySpecComparison(this.requestForm.value);
  }

  clear(): void {
    this.pricing = [];
    this.requestForm.reset(
      {quantity: '', styleNumber: '', skuNumber: '', deliveryVehicle: '', customerNumber: '', sequence: ''}
    ); 
  }
}
