import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SpecCategory, SpecLibrarySpecResponse } from 'src/app/spec-library-pricing/models/spec-library-pricing.models';

@Component({
  selector: 'app-assembly-dialog',
  templateUrl: './assembly-dialog.component.html',
  styles: [
  ]
})
export class AssemblyDialogComponent implements OnInit {
  parentSpec!: SpecCategory; 
  childSpecs!: SpecCategory[]; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
  ) { }

  ngOnInit(): void {
    const response = <SpecLibrarySpecResponse> this.data.response; 
    this.parentSpec = response.parentSpec; 
    this.childSpecs = response.childSpecs; 
  }

}
