<div fxLayout="row wrap" class="mt-10">

    <div style="height: auto; max-height: 540px; overflow: auto; width: 100%">
        <table mat-table [dataSource]="dataSource" style="width: 100%;" class="mat-elevation-z8">

            <ng-container matColumnDef="styleNbr">
                <th mat-header-cell *matHeaderCellDef>Style Number</th>
                <td mat-cell *matCellDef="let element">{{ element.styleNbr }}</td>
            </ng-container>
            <ng-container matColumnDef="skuNbr">
                <th mat-header-cell *matHeaderCellDef>SKU Number</th>
                <td mat-cell *matCellDef="let element">{{ element.skuNbr }}</td>
            </ng-container>
            <ng-container matColumnDef="deliveryVehicleNbr">
                <th mat-header-cell *matHeaderCellDef>Delivery Vehicle</th>
                <td mat-cell *matCellDef="let element">{{ element.deliveryVehicleNbr }}</td>
            </ng-container>
            <ng-container matColumnDef="customerNbr">
                <th mat-header-cell *matHeaderCellDef>Customer Number</th>
                <td mat-cell *matCellDef="let element">{{ element.customerNbr }}</td>
            </ng-container>
            <ng-container matColumnDef="sequenceNbr">
                <th mat-header-cell *matHeaderCellDef>Sequence</th>
                <td mat-cell *matCellDef="let element">{{ element.sequenceNbr }}</td>
            </ng-container>
            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef>Supplier</th>
                <td mat-cell *matCellDef="let element">{{ element.supplier }}</td>
            </ng-container>
            <ng-container matColumnDef="transactionTimestamp">
                <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                <td mat-cell *matCellDef="let element">{{ (element.transactionTimestamp | date: 'medium') }}</td>
            </ng-container>
            <ng-container matColumnDef="changeUser">
                <th mat-header-cell *matHeaderCellDef>Changed By</th>
                <td mat-cell *matCellDef="let element">{{ element.changeUser || '-' }}</td>
            </ng-container>
            <ng-container matColumnDef="detailBtn">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" style="text-align: end;">
                    <button mat-icon-button color="primary" (click)="getSpecHistoryDetail(element.id)"  matTooltip="View History">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true" class="mat-table-header-primary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No History Found</td>
            </tr>

        </table>

    </div>

</div>
    
<div fxLayout="row wrap" fxLayoutAlign="end">
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]"
                    [pageSize]="10" 
                    showFirstLastButtons>
    </mat-paginator>
</div>