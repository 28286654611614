import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AdministrationService } from '../services/administration.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Category } from '../models/administration-models';
import { QfgTableDialogComponent } from '../qfg-table-dialog/qfg-table-dialog.component';
import { ManageQfgDialogComponent } from '../manage-qfg-dialog/manage-qfg-dialog.component';


@Component({
  selector: 'app-admin-qty-factor-grid',
  templateUrl: './admin-qty-factor-grid.component.html',
  styleUrls: ['./admin-qty-factor-grid.component.scss']
})
export class AdminQtyFactorGridComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<Category>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'seqNbr', 'active'];
  displayedColumns = this.dataColumns;

  findSwatch: Category[] = [];
  addSwatch: Category[] = [];

  constructor(
    private adminService: AdministrationService,
    public dialog: MatDialog,
  ) { }

  ngAfterViewInit(): void {
    this.gettingCategoryList();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  gettingCategoryList() {
    this.adminService.getCategoryList().subscribe((response: any) => { 
      this.findSwatch = response;
      this.findSwatch.forEach(row => {
        if (row.referenceId == 'swatching') this.addSwatch.push(row);
      })
      this.dataSource.data = this.addSwatch;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openQFGTableDialog() {
    this.dialog.open(QfgTableDialogComponent);
  }

  openQFGManageDialog(data: Category) {
    this.dialog.open(ManageQfgDialogComponent, {
      data: {
        categoryId: data.id,
        categoryName: data.name,
      }
    });
  }

}