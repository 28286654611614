import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { VendorProfileResponse } from '../vendor-profile.response';
import { ConfigModel } from 'src/app/core/models/config-model';
import { Supplier } from '../models/vendor-profile-models';
import { VendorDto } from 'src/app/core/models/vendor-models';


@Injectable()
export class VendorProfileService {

  private basePath = `${ConfigModel.API_URL}/v1/vendors`

  constructor(private httpClient: HttpClient) { }

  public getVendor(vendorID: String): Observable<VendorProfileResponse> {
    return this.httpClient.get<VendorProfileResponse>(`${this.basePath}/${vendorID}`);
  }
  
  public getVendorByItsId(id: number): Observable<Supplier>
  {
    return this.httpClient.get<Supplier>(`${this.basePath}/vendor-info/${id}`);
  }

  public getVendorFromTableBySuppId(supplierId: string): Observable<Supplier>
  {
    return this.httpClient.get<Supplier>(`${this.basePath}/table-data/${supplierId}`);
  }

  public createVendor(supplierId: string, request: Supplier): Observable<VendorProfileResponse> {
    return this.httpClient.post<VendorProfileResponse>(`${this.basePath}/new-vendor/${supplierId}`, request);
  }

  public updateVendor(supplierId: string, request: Supplier): Observable<VendorProfileResponse> {
    return this.httpClient.patch<VendorProfileResponse>(`${this.basePath}/update-vendor/${supplierId}`, request);
  }

}