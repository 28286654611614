import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { RolePermissionService } from '../services/role-permission.service';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title>WARNING</h1>
    <div mat-dialog-content>
      <p>WARNING: You are about to delete role: {{ data.displayName }}. Are you sure you want to delete? </p>
    </div>
    <div mat-dialog-actions align="end">
      <button type="button" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
      <button type="button" mat-raised-button color="warn" style="margin-left: 5px;" (click)="delete()">Delete</button>
    </div>
  `,
  styles: []
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackBarService, 
    private rolePermissionService: RolePermissionService,
    ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(); 
  }

  delete(): void {
    this.rolePermissionService.deleteRoleInfo(this.data.roleId).subscribe(response => {
      this.snackService.success(response.successMessage);
    },
    (e: HttpErrorResponse) => {
      this.snackService.error(e.error.localizedMessage);
    },
    () => {
      this.dialogRef.close();
    });
  }
}
