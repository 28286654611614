import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SpecLibrarySpecResponse } from 'src/app/spec-library-pricing/models/spec-library-pricing.models';

@Component({
  selector: 'app-bindery-dialog',
  template: `
    <app-bindery-pricing-spec [specResponse]="response"></app-bindery-pricing-spec>
  `,
  styles: [
  ]
})
export class BinderyDialogComponent implements OnInit {
  response!: SpecLibrarySpecResponse; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
  ) { }

  ngOnInit(): void {
    this.response =  <SpecLibrarySpecResponse> this.data.response; 
  }
}
