import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable, EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/internal/operators';

import { SpecActions, User } from 'src/app/core/models/common-models';
import { BackendService } from 'src/app/core/services/back-end.service';
import { CommonService } from 'src/app/core/services/common.service';
import { UserService } from 'src/app/core/services/user.service';
import { VendorProfileService } from 'src/app/vendor/vendor-profile/services/vendor-profile.service';
import { SpecLibraryDto, SpecLibraryRequest } from '../models/spec-library.models';
import { SpecLibraryService } from '../services/spec-library.service';

@Component({
  selector: 'app-spec-library-container',
  templateUrl: './spec-library-container.component.html',
  providers: [SpecLibraryService, VendorProfileService]
})
export class SpecLibraryContainerComponent implements OnInit {
  request!: SpecLibraryRequest;
  isVendor: boolean = true;
  isNoResults: boolean = false;

  ignoreCustNbr: boolean = true;
  ignoreSeqNbr: boolean = true;
  
  constructor(
    private backendService: BackendService,
    private specLibararyService: SpecLibraryService,
    private vendorService: VendorProfileService,
    private userService: UserService,
    private common: CommonService,
    private title: Title
  ) { }
  
  readonly vendorList$ = this.backendService.getVendorDtoList(false);
  specs: SpecLibraryDto[] = [];
  
  ngOnInit(): void { 
    this.title.setTitle('Spec Library | SAVE');
    this.initializeRequest();
    this.isVendor = this.userService.isVendor; 
    this.search().subscribe(specs => this.specs = specs);
  }

  submitSearch(): void {
    this.clearSearch();
    this.search().subscribe(specs => this.specs = specs);
  }

  reset(): void {
    this.initializeRequest();
    this.clearSearch();
    this.search().subscribe(specs => this.specs = specs);
  }

  editSpec(data: any): void {
    const dialogRef = this.common.openEditSpecDialog(data.specId, data.categoryId, data.categoryRefId);

    if(dialogRef) {
      dialogRef.afterClosed()
        .pipe(switchMap(response => response === SpecActions.Updated ? this.search() : EMPTY))
        .subscribe(specs => this.specs = specs);      
    }
  }

  copySpec(data: any): void {
    const dialogRef = this.common.openEditSpecDialog(data.specId, data.categoryId, data.categoryRefId, true);

    if(dialogRef) {
      dialogRef.afterClosed()
        .pipe(switchMap(response => response === SpecActions.Updated || response === SpecActions.Created ? this.search() : EMPTY))
        .subscribe(specs => this.specs = specs);      
    }
  }

  historySelected(specId: number): void {
    const dialogRef = this.common.openSpecHistory(specId);
  }

  toggleIgnoreCustNbr(): void {
    this.ignoreCustNbr = !this.ignoreCustNbr; 
    this.request.customerNbr = !this.ignoreCustNbr ? '' : undefined;
  }

  toggleIgnoreSeqNbr(): void {
    this.ignoreSeqNbr = !this.ignoreSeqNbr; 
    this.request.sequenceNbr = !this.ignoreSeqNbr ? '' : undefined; 
  }

  private initializeRequest(): void {
    this.request = <SpecLibraryRequest>{};
    this.ignoreCustNbr = true; 
    this.ignoreSeqNbr = true; 
  }

  private clearSearch(): void {
    this.isNoResults = false;
    this.specs = [];
  }

  private search(): Observable<SpecLibraryDto[]> {
    return this.specLibararyService.getSpecs(this.request).pipe(tap(specs => this.isNoResults = (!specs || specs.length === 0)));
  }
}
