import { Component, ComponentFactoryResolver, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

import { Subject, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/internal/operators';

import { SpecCollectionDirective } from './directives/spec-collection.directive';
import { CategoryList } from '../../core/models/common-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SpecBackendService } from './services/spec-backend.service';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { PendingChangesDialogComponent } from 'src/app/core/dialog/pending-changes-dialog/pending-changes-dialog.component';
import { UserService } from 'src/app/core/services/user.service';
import { AdministrationService } from 'src/app/admin/services/administration.service';
import { Category } from 'src/app/admin/models/administration-models';

@Component({
  selector: 'app-spec-collection',
  templateUrl: './spec-collection.component.html',
  providers: [SpecBackendService, AdministrationService], 
})
export class SpecCollectionComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild(SpecCollectionDirective, {static: true}) specCollectionHost!: SpecCollectionDirective;  
  private categoryList: Category[] = []; 

  readonly destroy$: Subject<void> = new Subject<void>();
  // readonly categoryList$ = this.common.getCategoryList()
  //   .pipe(
  //     map(lists => lists.filter(comp => comp.refId !== 'backerboard')), 
  //     tap(lists => this.categoryList = lists), 
  //   );
  readonly userSupplierId$ = this.userService.getUser().supplierId;
  readonly categoryList$ = this.adminService.getCategoryList(this.userSupplierId$)
    .pipe(
      map(lists => lists.filter(comp => comp.referenceId !== 'backerboard')), 
      tap(lists => this.categoryList = lists), 
    );

  previousCategory: number = 0; 
  selectedCategory: number = 0; 
  
  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialog: MatDialog,
    private titleService: Title,
    private userService: UserService,
    private adminService: AdministrationService
  ) { }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    if(this.specService.isPendingChanges) {
      return false;
    }

    return true;
  }

  
  ngOnInit(): void {
    this.titleService.setTitle('Spec Collection | SAVE');

    this.specService.specCreated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedCategory = 0;
        this.setContainerRef(this.selectedCategory);
      }); 
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  categoryChange(change: MatSelectChange): void {    
    try
    {
      if(this.specService.isPendingChanges) {
        const dialogRef = this.openPendingChangesDialog();

        dialogRef.afterClosed().subscribe(data => {
          if(!data) {
            this.selectedCategory = this.previousCategory; 
          } else {
            this.previousCategory = this.selectedCategory; 
            this.setContainerRef(change.value);
          }
        });
      } else {
        this.previousCategory = this.selectedCategory; 
        this.setContainerRef(change.value);
      }
    }
    catch (e) 
    {
      console.log('Error loading component: ' + change.source.triggerValue + ' -> ' + e);
      this.selectedCategory = 0;
    } 
  }

  private openPendingChangesDialog(): MatDialogRef<PendingChangesDialogComponent> {
    return this.dialog.open(PendingChangesDialogComponent, {hasBackdrop: true, disableClose: true}); 
  }

  private setContainerRef(categoryId: number): void {
    const category = this.categoryList.find(cat => cat.id === categoryId);
    const viewContainerRef = this.specCollectionHost.viewContainerRef;
    viewContainerRef.clear();
    this.specService.isPendingChanges = false;

    if(categoryId > 0 && category) {
      const loader = this.common.getComponentByRefId(category?.referenceId || '');

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loader.createComponent);
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);

      componentRef.instance.categoryId = categoryId;
      if(loader.data) {
        componentRef.instance.screenTitle = loader.data.screenTitle;
      }
    }
  }
}
