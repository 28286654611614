import { Component, Input, OnInit } from '@angular/core';

import { ComponentLists } from 'src/app/core/models/common-models';
import { SpecRequestDto } from 'src/app/core/models/spec-models';
import { PhotopackFormValue } from '../../core/models/photopack.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { PhotoPackService } from '../services/photo-pack.service';

@Component({
  selector: 'app-create-photopack',
  templateUrl: './create-photopack.component.html',
  providers: [PhotoPackService]
})
export class CreatePhotopackComponent implements OnInit {
  @Input() categoryId: number = 0; 

  componentLists: ComponentLists = {} as ComponentLists;

  constructor(
    private specService: SpecBackendService,
    public photoService: PhotoPackService
  ) { }

  ngOnInit(): void {
    this.specService.getComponetListByCategoryId(this.categoryId)
      .subscribe(lists => this.componentLists = lists);
  }

  submitForm(): void {
    const requests: SpecRequestDto[] = [];
    const photopack = <PhotopackFormValue>this.photoService.detailForm.value;

    photopack.photopacks.forEach(pack => {
      let request = new SpecRequestDto(this.photoService.headerForm.value);
      if(request.categoryId === 0) {
        request.categoryId = this.categoryId; 
      }

      request.setPhotopackDetails(this.photoService.detailForm.value);
      request.setPhotopack(pack);
      requests.push(request);
    });
    
    if(requests.length > 0) {
      //For now, we're only allowing one photopack to be created for stand alone.
      this.specService.createSpec(requests[0]);
    }
  }

}
