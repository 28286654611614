import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/internal/Observable";
import { Subject } from "rxjs/internal/Subject";
import { tap } from "rxjs/internal/operators";

import { ConfigModel } from "src/app/core/models/config-model";
import { ComponentLists, SpecActions } from "src/app/core/models/common-models";
import { SpecDTO, SpecRequestDto, SpecRequestWithChildDto } from "src/app/core/models/spec-models";
import { SnackBarService } from "src/app/core/services/snack-bar.service";

@Injectable()
export class SpecBackendService {
  private _componentMap: Map<number, string> = new Map<number, string>();
  private _specCreated: Subject<SpecActions> = new Subject();
  private _specUpdated: Subject<SpecActions> = new Subject();

  readonly specCreated$ = this._specCreated.asObservable();
  readonly specUpdated$ = this._specUpdated.asObservable();

  public isPendingChanges: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private snackService: SnackBarService
  ) { }

  public createSpec(request: SpecRequestDto): void {
    this.createSpec$(request)   
    .subscribe(
      () => {
        this.snackService.success('Spec was successfully created.');
        this._specCreated.next(SpecActions.Created);
      }
    );
  }

  public createSpecWithChildrenRequest(childListId: number, childrenSpec: SpecRequestDto[], parent: SpecRequestDto): void {
    if(!childrenSpec || childrenSpec.length === 0) {
      this.createSpec(parent);
      return;
    }
    
    if(!childListId)  {
      this.snackService.error('System Error: Could not determine ID for the photopacks. Please contact support for assistance.'); 
      return;
    }

    const request = <SpecRequestWithChildDto>{ parent: parent, childListId: childListId, children: childrenSpec };

    this.createSpecWithChildren(request)
      .subscribe(
        () => {
          this.snackService.success('Spec was successfully created.');
          this._specCreated.next(SpecActions.Created);
        }
      );
  }

  public updateSpec(request: SpecRequestDto): void {
    this.updateSpec$(request).subscribe(
      () => {
        this.snackService.success('Spec was successfully updated');
        this._specUpdated.next(SpecActions.Updated);
      }
    );
  }

  public updateSpecWithChildrenRequest(childListId: number, childrenSpec: SpecRequestDto[], parent: SpecRequestDto): void {
    if(!childrenSpec || childrenSpec.length == 0) {
      this.updateSpec(parent);
      return;
    }

    if(!childListId) {
      return;
    }

    const request = <SpecRequestWithChildDto>{ parent: parent, childListId: childListId, children: childrenSpec };

    this.updateSpecWithChildren(request)
      .subscribe(
        () => {
          this.snackService.success('Spec was successfully updated');
          this._specUpdated.next(SpecActions.Updated);
        }
      );
  }

  public getComponetListByCategoryId(categoryId: number): Observable<ComponentLists> {
    return this.httpClient.get<ComponentLists>(`${ConfigModel.API_URL}/v1/category/lists/${categoryId}`)
      .pipe(tap(list => {
        let keys = Object.keys(list) as Array<keyof ComponentLists>;
        keys.forEach(key => list[key]?.forEach(o => this._componentMap.set(o.id, o.value)));
      }));
  }

  public getSpecById(id: number): Observable<SpecDTO> {        
      return this.httpClient.get<SpecDTO>(`${ConfigModel.API_URL}/v1/spec/${id}`);
  }

  public getIdValue(id: number): string {
    return this._componentMap.get(id) || ''; 
  }

  public closeEditDialog(): void {
    this._specUpdated.next(SpecActions.Cancelled);
  }

  private createSpec$(request: SpecRequestDto): Observable<void> {
    return this.httpClient.post<void>(`${ConfigModel.API_URL}/v1/spec`, request);
  }

  private updateSpec$(request: SpecRequestDto): Observable<SpecDTO> {
    return this.httpClient.put<SpecDTO>(`${ConfigModel.API_URL}/v1/spec/${request.id}`, request);
  }

  private createBatchSpecs(requests: SpecRequestDto[]): Observable<number[]> {
    return this.httpClient.post<number[]>(`${ConfigModel.API_URL}/v1/spec/batch`, requests);
  }

  private createSpecWithChildren(request: SpecRequestWithChildDto): Observable<void> {
    return this.httpClient.post<void>(`${ConfigModel.API_URL}/v1/spec/children`, request);
  }

  private updateSpecWithChildren(request: SpecRequestWithChildDto): Observable<void> {
    return this.httpClient.put<void>(`${ConfigModel.API_URL}/v1/spec/children`, request);
  }
}