import { Component, Input } from '@angular/core';
import { SpecDTO, Swatch } from 'src/app/core/models/spec-models';
import { BackerBoardSpec } from 'src/app/spec-collection/backer-board/models/backer-board.model';

@Component({
  selector: 'app-swatch-history',
  template: `
    <ng-container *ngIf="spec">
      <app-swatching-table [componentList]="spec" [editable]="false" [showType]="true"></app-swatching-table>
      <div *ngIf="backerboard">
        <div fxLayout="row wrap" class="mt-10">
          <div fxFlex="100" fxLayoutAlign="center center">
            <h2>Panel Backer Board</h2>
          </div>
        </div>
        <app-backer-board-review [specDetail]="backerboard"></app-backer-board-review>
      </div>
    </ng-container>
  `,
  styles: [
  ]
})
export class SwatchHistoryComponent {
  @Input()
  set specDto(value: SpecDTO) {
    this.setSpecs(value); 
  }

  @Input() 
  set childSpecs(value: SpecDTO[]) {
    this.backerboardDto = value[0]; 
    this.buildBackerBoardSpec(); 
  }

  private backerboardDto!: SpecDTO; 

  spec: Swatch[] = []; 
  backerboard!: BackerBoardSpec; 

  constructor() { }

  private setSpecs(dto: SpecDTO): void {
    this.spec = dto.specDetails
      .filter(detail => detail.qty && detail.qty > 0)
      .map(detail => {
        return <Swatch>{
          id: detail.componentListId, 
          name: detail.value || '', 
          description: detail.description || '', 
          uom: detail.uom || '', 
          quantity: detail.qty, 
          compType: detail.componentName || ''
        }; 
      }); 
  }

  private buildBackerBoardSpec(): void {
    this.backerboard = {
      boardWeight: this.findBackerboardSpecValue('boardWeight'), 
      finishedSize: this.findBackerboardSpecValue('sizeFactorGrid'), 
      finishingOptions: [], 
      accessories: []
    }; 

    this.setBackerboardFinishingOptions(); 
  }

  private setBackerboardFinishingOptions(): void {
    this.backerboardDto.specDetails
      .filter(detail => detail.componentRefId === 'finishing')
      .forEach(detail => this.backerboard.finishingOptions.push(detail.value || ''));
  }

  private setBackerboardAccessories(): void {
    this.backerboardDto.specDetails
      .filter(detail => detail.componentRefId === 'accessories')
      .forEach(detail => {
        this.backerboard.accessories.push({
          accessory: detail.value || '',
          qty: detail.qty,
          comment1: detail.comments1,
          comment2: detail.comments2
        });
      });
  }

  private findBackerboardSpecValue(property: string): string {
    return this.backerboardDto.specDetails.find(detail => detail.componentRefId === property)?.value || '';
  }
}
