<mat-toolbar style="width: 100%; position: sticky;" color="primary">
    <span>Rearrange Component List Sequence Numbers</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div class="table-container-600" cdkScrollable>
        <table mat-table
        #dataTable
        fxFlex
        [dataSource]="dataSource"
        cdkDropList
        (cdkDropListDropped)="dropTable($event)">

         <ng-container matColumnDef="position">
             <th mat-header-cell *matHeaderCellDef></th>
             <td mat-cell *matCellDef="let element">
               <mat-icon cdkDragHandle>reorder</mat-icon>
             </td>
         </ng-container>

         <ng-container matColumnDef="seqNbr">
             <th mat-header-cell *matHeaderCellDef>Sequence Nbr</th>
             <td mat-cell *matCellDef="let element;">{{ element.seqNbr }}</td>
         </ng-container>

         <ng-container matColumnDef="value">
             <th mat-header-cell *matHeaderCellDef>Value</th>
             <td mat-cell *matCellDef="let element;">{{ element.value }}</td>
         </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"  class="mat-table-header-primary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag></tr>

        <div *cdkDragPlaceholder></div>
        </table>
    
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="submitNewSeq()">Submit</button>
</div>
