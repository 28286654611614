import { Component, Input, OnInit } from '@angular/core';
import { SpecCategory, SpecLibrarySpecResponse } from '../../models/spec-library-pricing.models';

@Component({
  selector: 'app-bindery-pricing-spec',
  templateUrl: './bindery-pricing-spec.component.html',
  styles: [
  ]
})
export class BinderyPricingSpecComponent implements OnInit {
  @Input() specResponse!: SpecLibrarySpecResponse; 

  parentSpec!: SpecCategory; 
  childSpecs!: SpecCategory[];

  constructor() { }

  ngOnInit(): void {
    this.parentSpec = this.specResponse.parentSpec; 
    this.childSpecs = this.specResponse.childSpecs; 
  }

}
