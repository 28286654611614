import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { controlErrorMessages } from '../models/validation-models';

@Component({
  selector: 'app-form-error',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-container>{{ errorMsg }}</ng-container>`
})
export class FormErrorComponent implements OnChanges {
  @Input() control!: AbstractControl | null; 

  errorMsg: string = ''; 

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.errorMsg = controlErrorMessages(this.control);
  }
}