import { Component, Input } from '@angular/core';

import { SpecDetailDTO, SpecDTO } from 'src/app/core/models/spec-models';
import { PhotopackSpec, PhotopackTypes } from 'src/app/spec-collection/core/models/photopack.model';
import { PrintFolderSpec } from 'src/app/spec-collection/core/models/print-folder.model';

@Component({
  selector: 'app-folder-history',
  template: `
    <ng-container *ngIf="spec">
      <app-folder-review [specDetail]="spec"></app-folder-review>
      <app-photopack-review *ngIf="photopack" [specDetail]="photopack"></app-photopack-review>
    </ng-container>
  `
})
export class FolderHistoryComponent {
  spec!: PrintFolderSpec;
  photopack!: PhotopackSpec;

  private dto!: SpecDTO; 
  private photopacks!: SpecDTO[]; 

  @Input()
  set specDto(value: SpecDTO) {
    this.dto = value;
    this.buildSpec();
  }

  @Input()
  set childSpecs(value: SpecDTO[]) {
    this.photopacks = value;
    this.buildPhotopacks();
  }

  constructor() { }

  private buildSpec(): void {
    this.spec = {
      boardWeight: this.findSpecValue('boardWeight'),
      coverStock: this.findSpecValue('coverStock'),
      coverColors: this.findSpecValue('colorsCover'),
      coverFilm: this.findSpecValue('coverFilm'),
      linerStock: this.findSpecValue('linerStock'),
      linerColors: this.findSpecValue('colorsLiner'),
      linerFilm: this.findSpecValue('linerFilm'),
      finishedSize: this.findSpecValue('sizeFactorGrid'),
      finishingOptions: [],
      accessories: []
    }

    this.setFinishingOptions();
    this.setAccessories();

    if(this.findSpecValue('flipUpCoverStock')) {
      this.spec.flipOutUpSpec = {
        count: this.findSpecQty('flipUpCount'),
        boardWeight: this.findSpecValue('flipUpBoardWeight'),
        coverStock: this.findSpecValue('flipUpCoverStock'),
        coverColors: this.findSpecValue('flipUpColorsCover'),
        coverFilm: this.findSpecValue('flipUpCoverFilm'),
        linerStock: this.findSpecValue('flipUpLinerStock'),
        linerColors: this.findSpecValue('flipUpColorsLiner'),
        linerFilm: this.findSpecValue('flipUpLinerFilm'), 
        attachmentMethod: this.findSpecValue('flipUpAttachmentMethod')
      }
    }
  }

  private buildPhotopacks(): void {
    let photopack: SpecDTO = this.photopacks[0];
    this.photopack = {
      coverStock: this.findPhotopackValue(photopack, 'outsideStock'),
      pageStock: this.findPhotopackValue(photopack, 'insideStock'),      
      coverColors: this.findPhotopackValue(photopack, 'outsideColorsCover'),
      pageColors: this.findPhotopackValue(photopack, 'insideColorsCover'),
      coverFilm: this.findPhotopackValue(photopack, 'outsideFilm'),
      pageFilm: this.findPhotopackValue(photopack, 'insideFilm'),
      finishedSize: this.findPhotopackValue(photopack, 'sizeFactorGrid'),
      type: this.findPhotopackValue(photopack, 'photopackType'),
      photopacks: [{
        finishing1: this.findPhotopackValue(photopack,'finishing1', 1),
        finishing2: this.findPhotopackValue(photopack,'finishing1', 2),
        pageCount: this.findPageCount(photopack)
      }]
    };

    if(this.photopacks.length > 1) {
      for(let i=1; i<this.photopacks.length; i++) {
        photopack = this.photopacks[i];
        this.photopack.photopacks.push({
          finishing1: this.findPhotopackValue(photopack,'finishing1', 1),
          finishing2: this.findPhotopackValue(photopack,'finishing1', 2),
          pageCount: this.findPageCount(photopack)
        });
      }
    }
  }

  private findPhotopackValue(dto: SpecDTO, property: string, sequence: number = 0): string {
    return this.findSpecDetailDto(dto, property, sequence)?.value || '';
  }

  private findSpecValue(property: string): string {
    return this.findSpecDetailDto(this.dto, property)?.value || ''; 
  }

  private findSpecQty(property: string): string {
    return <string>(this.findSpecDetailDto(this.dto, property)?.qty || '-'); 
  }

  private findSpecDetailDto(dto: SpecDTO, property: string, sequence: number = 0): SpecDetailDTO | undefined {
    if(sequence > 0) {
      return dto.specDetails.find(detail => detail.componentRefId === property && detail.sequence === sequence); 
    }

    return dto.specDetails.find(detail => detail.componentRefId === property);
  } 

  private setFinishingOptions(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'finishing')
      .forEach(detail => this.spec.finishingOptions.push(detail.value || ''));
  }

  private setAccessories(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'accessories')
      .forEach(detail => {
        this.spec.accessories.push({
          accessory: detail.value || '',
          qty: detail.qty,
          comment1: detail.comments1,
          comment2: detail.comments2
        });
      });
  }

  private findPageCount(dto: SpecDTO): string {
    let value = this.findPhotopackValue(dto, PhotopackTypes.WaterFall);

    if(!value) {
      value = this.findPhotopackValue(dto, PhotopackTypes.SaddlePage);
    }

    return value;
  }
}
