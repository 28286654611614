import { Component, Input, OnInit } from '@angular/core';

import { SpecDTO } from 'src/app/core/models/spec-models';
import { BackerBoardSpec } from 'src/app/spec-collection/backer-board/models/backer-board.model';

@Component({
  selector: 'app-backer-board-history',
  template: `
    <ng-container *ngIf="spec">
      <app-backer-board-review [specDetail]="spec"></app-backer-board-review>
    </ng-container>
  `
})
export class BackerBoardHistoryComponent implements OnInit {
  spec!: BackerBoardSpec;

  private dto!: SpecDTO;

  @Input() 
  set specDto(value: SpecDTO) {
    this.dto = value;    
    this.buildSpec();
  }
  
  constructor() { }

  ngOnInit(): void {
  }

  private buildSpec(): void {
    this.spec = {
      boardWeight: this.findSpecValue('boardWeight'),
      finishedSize: this.findSpecValue('sizeFactorGrid'),
      finishingOptions: [],
      accessories: []
    };
    
    this.setFinishingOptions();
    this.setAccessories();
  }

  private setFinishingOptions(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'finishing')
      .forEach(detail => this.spec.finishingOptions.push(detail.value || ''));
  }

  private setAccessories(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'accessories')
      .forEach(detail => {
        this.spec.accessories.push({
          accessory: detail.value || '',
          qty: detail.qty,
          comment1: detail.comments1,
          comment2: detail.comments2
        });
      });
  }

  private findSpecValue(property: string): string {
    return this.dto.specDetails.find(detail => detail.componentRefId === property)?.value || '';
  }
}
