import { Component, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import { Role } from '../models/role-permission-model';
import { RolePermissionService } from '../services/role-permission.service';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EditRoleComponent } from '../edit-role/edit-role.component';
import { UserService } from 'src/app/core/services/user.service';


@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.css'],
  providers: [RolePermissionService],
})
export class RolePermissionComponent implements AfterViewInit, ComponentCanDeactivate {
  @ViewChild(MatSort) sort!: MatSort;


  dataSource = new MatTableDataSource<Role>();
  displayedViewColumns = ['displayName', 'createUser', 'createdTimestamp', 'changeUser', 'changedTimestamp', 
    'viewVendorProfile', 'viewVendorBid', 'viewSpecLibraryPricing', 'viewManualSpecPricing', 'viewSpec', 'viewSecurity', 'viewUserProfile', 'viewAdmin'];
  displayedEditColumns = ['displayName', 'createUser', 'createdTimestamp', 'changeUser', 'changedTimestamp',
  'editVendorProfile', 'editVendorBid', 'editSpecLibraryPricing', 'editManualSpecPricing', 'editSpec', 'editSecurity', 'editUserProfile', 'editAdmin','editMasterBid'];

  currentRole!: Role;

  constructor(
    private rolePermissionService: RolePermissionService,
    private userService: UserService,
    public dialog: MatDialog,
    private title: Title
  ) { 
    this.title.setTitle('Role Permissions | SAVE'); 
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    if(this.rolePermissionService.isPendingChanges) {
      return false;
    }

    return true;
  }

  ngAfterViewInit(): void {
    if(this.userService.hasAnyPermission(['EDIT_SECURITY'])) {
      this.displayedViewColumns.push('editButtons'); 
      this.displayedEditColumns.push('editButtons'); 
    }

    this.getRolePermissionDto();
    this.dataSource.sort = this.sort;
  }

  getRolePermissionDto() {
    this.rolePermissionService.getRolePermission().subscribe(response => {
        this.dataSource.data = response;
    });
  }

  getRoleInfoById(roleId: number) {
    this.rolePermissionService.getRolePermissionById(roleId).subscribe((response: Role) => {
      this.currentRole = response;
    })
  }

  openDeleteRoleDialog(roleId: number, displayName: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        roleId: roleId,
        displayName: displayName,
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getRolePermissionDto();
    });
  }

  editRoleInfoDialog(role: Role) {
    const dialogRef = this.dialog.open(EditRoleComponent, {
      maxWidth: '95vw',
      minWidth: '80vw',
      data: {
        role: role,
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getRolePermissionDto();
    });
  }
}
