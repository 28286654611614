<mat-toolbar style="width: 100%;" color="primary">
    <span>Update ID {{ data.id }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
<form class="example-form dialog-update" #qfgUpdateForm="ngForm" (ngSubmit)="updateQfg(qfgUpdateForm)">
    <div mat-dialog-content>
        <input type="hidden" [ngModel]="data.id" name="id">  
            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
             <mat-form-field appearance="outline">
                 <mat-label>Change Minimum Quantity</mat-label>
                 <input matInput type="number" min="0" autocomplete="off" [ngModel]="data.minQty" name="minQty">    
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Change Maximum Quantity</mat-label>
                 <input matInput type="number" min="0" autocomplete="off" [ngModel]="data.maxQty" name="maxQty">    
             </mat-form-field>
             <mat-checkbox color="accent" [ngModel]="data.active" name="active">Active</mat-checkbox>
            </mat-card-content>
    </div>

    <div mat-dialog-actions align="end">
      <button mat-dialog-close type="submit" mat-raised-button color="accent" [disabled]="!qfgUpdateForm.valid">Update</button>
    </div>
</form>