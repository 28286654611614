import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs/internal/Observable';
import { ConfigModel } from 'src/app/core/models/config-model';

import { PricingRequest, PricingResponse, SpecLibrarySpecResponse } from '../models/spec-library-pricing.models';
import { AssemblyDialogComponent } from '../spec-library-pricing-spec/dialogs/assembly-dialog/assembly-dialog.component';
import { BinderyDialogComponent } from '../spec-library-pricing-spec/dialogs/bindery-dialog/bindery-dialog.component';

@Injectable()
export class SpecLibraryPricingService {

  constructor(
    private http: HttpClient, 
    private dialog: MatDialog
  ) { }

  public getAssemblyPricing(request: PricingRequest): Observable<PricingResponse[]> {
    return this.http.post<PricingResponse[]>(`${ConfigModel.API_URL}/v1/spec-library/pricing/assembly`, request); 
  }

  public getAssemblySpec(request: PricingRequest): Observable<SpecLibrarySpecResponse> {
    return this.http.post<SpecLibrarySpecResponse>(`${ConfigModel.API_URL}/v1/spec-library/pricing/assembly/spec`, request); 
  }

  public getBinderyPricing(request: PricingRequest): Observable<PricingResponse[]> {
    return this.http.post<PricingResponse[]>(`${ConfigModel.API_URL}/v1/spec-library/pricing/bindery`, request); 
  }

  public getBinderySpec(request: PricingRequest): Observable<SpecLibrarySpecResponse> {
    return this.http.post<SpecLibrarySpecResponse>(`${ConfigModel.API_URL}/v1/spec-library/pricing/bindery/spec`, request); 
  }

  public openBinderySpecComparison(request: PricingRequest): void {
    this.getBinderySpec(request).subscribe(data => {
      this.dialog.open(BinderyDialogComponent, {
        maxWidth: '95vw', 
        minWidth: '60vw', 
        maxHeight: '90vh', 
        autoFocus: false, 
        data: { response: data }
      }); 
    }); 
  }

  public openAssemblySpecComparison(request: PricingRequest): void {
    this.getAssemblySpec(request).subscribe(data => {
      this.dialog.open(AssemblyDialogComponent, {
        maxWidth: '95vw',
        minWidth: '60vw', 
        maxHeight: '90vh', 
        autoFocus: false, 
        data: { response: data }
      }); 
    })
  }
}
