import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs/internal/Observable";

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService, 
    private router: Router
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.handleValidUserCheck(); 
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.handleValidUserCheck(); 
  }

  private handleValidUserCheck(): boolean {
    try {
      if(this.userService.isValidUser) {
          return true;
      }      
    } catch(e) {
      this.router.navigate(['invalid-user']); 
      return false;
    }

    return false;
  }
}