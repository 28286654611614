<div fxLayout="row wrap" fxLayoutAlign.gt-md="start" fxLayoutAlign.lt-lg="space-between" fxLayoutGap.gt-md="5px" class="mt-10" [formGroup]="group">    
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Board Weight</mat-label>
        <mat-select formControlName="boardWeight">
            <mat-option *ngFor="let opt of componentLists?.flipUpBoardWeight" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('boardWeight')?.invalid">
            <app-form-error [control]="group.get('boardWeight')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Stock</mat-label>
        <mat-select formControlName="coverStock">
            <mat-option *ngFor="let opt of componentLists?.flipUpCoverStock" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverStock')?.invalid">
            <app-form-error [control]="group.get('coverStock')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Colors</mat-label>
        <mat-select formControlName="coverColors">
            <mat-option *ngFor="let opt of componentLists?.flipUpColorsCover" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverColors')?.invalid">
            <app-form-error [control]="group.get('coverColors')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Cover Film</mat-label>
        <mat-select formControlName="coverFilm">
            <mat-option *ngFor="let opt of componentLists?.flipUpCoverFilm" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('coverFilm')?.invalid">
            <app-form-error [control]="group.get('coverFilm')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Stock</mat-label>
        <mat-select formControlName="linerStock">
            <mat-option *ngFor="let opt of componentLists?.flipUpLinerStock" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerStock')?.invalid">
            <app-form-error [control]="group.get('linerStock')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Colors</mat-label>
        <mat-select formControlName="linerColors">
            <mat-option *ngFor="let opt of componentLists?.flipUpColorsLiner" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerColors')?.invalid">
            <app-form-error [control]="group.get('linerColors')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Liner Film</mat-label>
        <mat-select formControlName="linerFilm">
            <mat-option *ngFor="let opt of componentLists?.flipUpLinerFilm" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('linerFilm')?.invalid">
            <app-form-error [control]="group.get('linerFilm')"></app-form-error>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="calc(32% - 5px)" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline">
        <mat-label>Attachment Method</mat-label>
        <mat-select formControlName="attachmentMethod">
            <mat-option *ngFor="let opt of componentLists?.flipUpAttachmentMethod" [value]="opt.id">{{ opt.value }}</mat-option>
        </mat-select>
        <mat-error *ngIf="group.get('attachmentMethod')?.invalid">
            <app-form-error [control]="group.get('attachmentMethod')"></app-form-error>
        </mat-error>
    </mat-form-field>
</div>