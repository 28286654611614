import { Component, Input, OnInit } from '@angular/core';

import { SpecDTO } from 'src/app/core/models/spec-models';
import { PhotopackSpec, PhotopackTypes } from 'src/app/spec-collection/core/models/photopack.model';

@Component({
  selector: 'app-photopack-history',
  template: `
    <ng-container *ngIf="spec">
      <app-photopack-review [specDetail]="spec"></app-photopack-review>
    </ng-container>
  `
})
export class PhotopackHistoryComponent implements OnInit {
  spec!: PhotopackSpec;

  private dto!: SpecDTO;

  @Input() 
  set specDto(value: SpecDTO) {
    this.dto = value;    
    this.buildSpec();
  }
  
  constructor() { }

  ngOnInit(): void {
  }

  private buildSpec(): void {
    this.spec = {
      coverStock: this.findSpecValue('outsideStock'),
      pageStock: this.findSpecValue('insideStock'),      
      coverColors: this.findSpecValue('outsideColorsCover'),
      pageColors: this.findSpecValue('insideColorsCover'),
      coverFilm: this.findSpecValue('outsideFilm'),
      pageFilm: this.findSpecValue('insideFilm'),
      finishedSize: this.findSpecValue('sizeFactorGrid'),
      type: this.findSpecValue('photopackType'),
      photopacks: [{
        finishing1: this.findSpecValue('finishing1', 1),
        finishing2: this.findSpecValue('finishing1', 2),
        pageCount: this.findPageCount()  
      }]
    };
  }

  private findSpecValue(property: string, sequence: number = 0): string {
    if(sequence > 0) {
      return this.dto.specDetails.find(detail => detail.componentRefId === property && detail.sequence === sequence)?.value || ''; 
    }

    return this.dto.specDetails.find(detail => detail.componentRefId === property)?.value || '';
  }

  private findPageCount(): string {

    let value = this.findSpecValue(PhotopackTypes.WaterFall);

    if(!value) {
      value = this.findSpecValue(PhotopackTypes.SaddlePage);
    }

    return value;
  }

}
