import { AbstractControl } from "@angular/forms";

export const ALPHA_NUMERIC_REGEX = '^[a-zA-Z0-9]*$';
export const CUST_NBR_REGEX = '^(?!0+$)[0-9]{1,10}$'; 

export function controlErrorMessages(control: AbstractControl | null): string {
    if(!control || control.valid || !control.errors) return ''; 

    if(control.hasError('pattern')) {
        const pattern = control.errors.pattern.requiredPattern; 
        switch(pattern) {
            case CUST_NBR_REGEX: return 'Customer number field cannot be zero or all zeros'; 
            case ALPHA_NUMERIC_REGEX: return 'Special characters are not allowed in this field'; 
            default: return 'There are invalid characters in this field'; 
        }
    }

    if(control.hasError('maxlength')) {
        return `The max lengh for this field is ${control.getError('maxlength').requiredLength} characters`;
    }

    if(control.hasError('min')) {
        return `The minimum value for this field is ${control.getError('min').min}`;
    }

    if(control.hasError('max')) {
        return `The max value for this field is ${control.getError('max').max}`; 
    }

    if(control.hasError('required')) {
        return 'This field is required';
    }

    return 'Field contains validation errors'; 
}