import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

const DEFAULT_THEME = 'lightBlueTheme';
const AG_GRID_LIGHT_THEME = 'ag-theme-alpine';
const AG_GRID_DARK_THEME = 'ag-theme-alpine-dark';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private renderer: Renderer2;
    private colorTheme!: string;
    public agGridTheme!: string;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.initTheme();
    }

    update(theme: string) {
        if(this.colorTheme) {
            if(this.colorTheme === theme) {
                return;
            }
            this.renderer.addClass(document.body, theme);
            this.renderer.removeClass(document.body, this.colorTheme);
        }
        this.setColorTheme(theme);
    }

    private initTheme() {
        this.getColorTheme();
        this.renderer.addClass(document.body, this.colorTheme);

        this.agGridTheme = this.colorTheme.startsWith('dark') ? AG_GRID_DARK_THEME : AG_GRID_LIGHT_THEME;
    }

    private setColorTheme(theme: string) {
        this.colorTheme = theme;
        this.agGridTheme = this.colorTheme.startsWith('dark') ? AG_GRID_DARK_THEME : AG_GRID_LIGHT_THEME;
        localStorage.setItem('user-theme', theme);
    }

    private getColorTheme() {
        if (localStorage.getItem('user-theme')) {
            this.colorTheme = localStorage.getItem('user-theme') || '{}';
        }
        else {
            this.colorTheme = DEFAULT_THEME;
        }
    }
}