<div class="example-container" [ngClass]="theme" [class.example-is-mobile]="mobileQuery.matches">

    <!-- Mat Tool Bar -->
    <mat-toolbar color="primary" class="csd-height csd-toolbar mat-elevation-z4">
        <mat-toolbar-row class="csd-height">

            <button *ngIf="isAuthorized" mat-icon-button (click)="isExpanded = !isExpanded">
                <mat-icon>menu</mat-icon>
            </button>

            <a *ngIf="isAuthorized" routerLink="/" class="image-container">
                <img src="assets/images/save_logo_135x31.png" alt="shawsavelogo" scale="0"> 
            </a>

            <a *ngIf="!isAuthorized" class="image-container" style="pointer-events: none; cursor: not-allowed;">
                <img src="assets/images/save_logo_135x31.png" alt="shawsavelogo" scale="0"> 
            </a>

            <div style="font-family: Verdana, Geneva, Tahoma, sans-serif">
                - {{ envName }}
            </div>

            <div style="-webkit-box-flex: 1; flex-grow: 1;"></div>

            <!-- Application Version Number -->
            <div class="mr-1" style="font-size: 12px;">v{{ appVersion }}</div>            

            <!-- Theme Switcher -->
            <button mat-button [matMenuTriggerFor]="theme">
                <mat-icon matTooltip="Change Theme">palette</mat-icon>
            </button>

            <mat-menu #theme="matMenu" xPosition="before">
                <button mat-menu-item [matMenuTriggerFor]="lightTheme">Light Theme</button>
                <button mat-menu-item [matMenuTriggerFor]="darkTheme">Dark Theme</button>
            </mat-menu>

            <mat-menu #lightTheme="matMenu">
                <button mat-menu-item (click)="changeTheme('lightBlueTheme')">Blue</button>
                <button mat-menu-item (click)="changeTheme('lightTealTheme')">Teal</button>
                <button mat-menu-item (click)="changeTheme('lightBlackTheme')">Black</button>
                <button mat-menu-item (click)="changeTheme('lightIndigoTheme')">Indigo</button>
            </mat-menu>

            <mat-menu #darkTheme="matMenu">
                <button mat-menu-item (click)="changeTheme('darkBlueTheme')">Blue</button>
                <button mat-menu-item (click)="changeTheme('darkTealTheme')">Teal</button>
                <button mat-menu-item (click)="changeTheme('darkBlackTheme')">Black</button>
                <button mat-menu-item (click)="changeTheme('darkIndigoTheme')">Indigo</button>
            </mat-menu>

            <button mat-button [matMenuTriggerFor]="userOptions">
                <mat-icon>person</mat-icon> {{ (userName || 'Profile') + ' ' }} {{ vendorName }}
            </button>

        </mat-toolbar-row>
    </mat-toolbar>
    <!-- End Mat Toolbar -->


    <!-- Drop down for user options -->
    <mat-menu #userOptions="matMenu">
        <button type="button" mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>Logout
        </button>
    </mat-menu>
    <!-- End -->

    <!-- Sidenav -->
    <mat-sidenav-container class="example-sidenav-container" autosize hasBackdrop="false">
        <!-- Sidenav Menu -->
        <mat-sidenav #snav class="mat-elevation-z2" [mode]="mobileQuery.matches ? 'over' : 'side'" opened="true" *ngIf="isAuthorized">
            <mat-nav-list [@sidenavAnimationIsExpanded]="isExpanded" 
                          (@sidenavAnimationIsExpanded.start)="start()" 
                          (@sidenavAnimationIsExpanded.done)="done()">

                <mat-list-item matTooltip="Spec Library Pricing" *hasPermission="['VIEW_SPEC_LIBRARY_PRICING','EDIT_SPEC_LIBRARY_PRICING']" [matTooltipPosition]="'right'" routerLink="/spec-library-pricing" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">request_quote</mat-icon>  
                    <h4 mat-line>Spec Library Pricing</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Spec Library Pricing Report" *hasPermission="['VIEW_SPEC_LIBRARY_PRICING','EDIT_SPEC_LIBRARY_PRICING']; allowVendor: false" [matTooltipPosition]="'right'" routerLink="/spec-library-pricing-report" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">payments</mat-icon>  
                    <h4 mat-line>Spec Library Pricing Report</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Spec Library" *hasPermission="['VIEW_SPEC', 'EDIT_SPEC']" [matTooltipPosition]="'right'" routerLink="/spec-library" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">menu_book</mat-icon>
                    <h4 mat-line *ngIf="isExpanded">Spec Library</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Spec Collection" *hasPermission="['VIEW_SPEC', 'EDIT_SPEC']" [matTooltipPosition]="'right'" routerLink="/spec-collection" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">post_add</mat-icon>
                    <h4 mat-line *ngIf="isExpanded">Spec Collection Tool</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Vendor Profile" *hasPermission="['VIEW_VENDOR_PROFILE', 'EDIT_VENDOR_PROFILE']" [matTooltipPosition]="'right'" routerLink="/vendor-profile" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">account_circle</mat-icon>
                    <h4 mat-line *ngIf="isExpanded">Vendor Profile</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Manual Spec Pricing" *hasPermission="['VIEW_MANUAL_SPEC_PRICING','EDIT_MANUAL_SPEC_PRICING']" [matTooltipPosition]="'right'" routerLink="/manual-spec-pricing" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">price_change</mat-icon>  
                    <h4 mat-line>Manual Spec Pricing</h4>
                </mat-list-item>
		        <mat-list-item matTooltip="Vendor Bidding" *hasPermission="['VIEW_VENDOR_BID', 'EDIT_VENDOR_BID']" [matTooltipPosition]="'right'" routerLink="/vendor-bid" [routerLinkActive]="['active']">
	       		  	<mat-icon mat-list-icon color="accent">gavel</mat-icon>  
          			<h4 mat-line *ngIf="isExpanded">Vendor Bidding</h4>
        		</mat-list-item>
                <mat-list-item matTooltip="Permissions" *hasPermission="['VIEW_SECURITY', 'EDIT_SECURITY']" [matTooltipPosition]="'right'" routerLink="/role-permission" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">security</mat-icon>  
                    <h4 mat-line *ngIf="isExpanded">Role Permissions</h4>
                </mat-list-item>  
                <mat-list-item matTooltip="User Profiles" *hasPermission="['VIEW_USER_PROFILE','EDIT_USER_PROFILE']" [matTooltipPosition]="'right'" routerLink="/user-profiles" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">account_box</mat-icon>  
                    <h4 mat-line>User Profiles</h4>
                </mat-list-item>                              
                <mat-list-item matTooltip="Administration" *hasPermission="['VIEW_ADMIN','EDIT_ADMIN']" [matTooltipPosition]="'right'" routerLink="/administration" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">admin_panel_settings</mat-icon>
                    <h4 mat-line *ngIf="isExpanded">Admin</h4>
                </mat-list-item>
                <mat-list-item matTooltip="Application Settings" *hasPermission="['VIEW_ADMIN','EDIT_ADMIN']" [matTooltipPosition]="'right'" routerLink="/app-settings" [routerLinkActive]="['active']">
                    <mat-icon mat-list-icon color="accent">settings</mat-icon>
                    <h4 mat-line *ngIf="isExpanded">Application Settings</h4>
                </mat-list-item>

            </mat-nav-list>
        </mat-sidenav>
        <!-- End Sidenav Menu -->

        <!-- Sidenav content -->
        <mat-sidenav-content>
            <ng-content ></ng-content>
        </mat-sidenav-content>
        <!-- End Sidenav content -->

    </mat-sidenav-container>
    <!-- End Sidenav -->
</div>