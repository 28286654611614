import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs/internal/Observable";

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService, 
    private router: Router
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const allowedPermissions = childRoute.data.allowedPermissions; 
    const allowVendors = childRoute.data.allowVendors;
    return this.handlePermissionCheck(allowedPermissions, allowVendors); 
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const allowedPermissions = route.data.allowedPermissions; 
    const allowVendors = route.data.allowVendors;
    return this.handlePermissionCheck(allowedPermissions, allowVendors);
  }

  private handlePermissionCheck(allowedPermissions: string[], allowVendors: boolean = true): boolean {
    let canActive: boolean = false;
    let isVendor: boolean = false;

    try {
      canActive = this.userService.hasAnyPermission(allowedPermissions);
      isVendor = this.userService.isVendor;
    } catch(e) {
      this.router.navigate(['']); 
      return false;
    }

    if(!canActive && allowedPermissions) {
      this.router.navigate(['accessdenied']); 
    }

    if(!allowVendors && isVendor) {
      this.router.navigate(['accessdenied']);
    }

    return canActive; 
  }
}