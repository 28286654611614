import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';

import { ComponentLists } from 'src/app/core/models/common-models';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { MultiSpec } from './models/multi-spec.model';
import { MultiSpecService } from './services/multi-spec.service';

@Component({
  selector: 'app-multi-spec',
  templateUrl: './multi-spec.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ],
})
export class MultiSpecComponent implements OnInit {  
  @Input() componentLists!: ComponentLists;
  @Output() submitForm = new EventEmitter(); 

  readonly maxAccessories: number = 10000;

  specReview$: Observable<MultiSpec>;
  detailForm!: FormGroup;
  baseComponent!: FormGroup; 
  finishingOptions!: FormArray;

  constructor(public multiService: MultiSpecService, private snackService: SnackBarService) { 
    this.specReview$ = this.multiService.reviewSpec$;    
  }

  ngOnInit(): void { 
    this.detailForm = this.multiService.detailForm;
    this.baseComponent = this.multiService.baseComponent;
    this.finishingOptions = this.multiService.finishingOptions;
  }

  addAccessory(): void {
    this.multiService.addAccessory();    
  }

  removeAccessory(idx: number) {
    this.multiService.removeAccessory(idx);
  }

  submit(): void {
    if(this.detailForm.invalid || this.multiService.headerForm.invalid) {
      this.detailForm.markAllAsTouched();
      this.multiService.headerForm.markAllAsTouched();
      this.snackService.error('Cannot submit spec until invalid fields are corrected');
      return;
    }
    this.submitForm.emit();
  }
}
