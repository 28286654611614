<div fxLayout="row wrap" fxLayoutAlign="center" *ngIf="!isVendor">
    <div fxFlex="96" fxFlex.xs="100" class="center-text">
        <mat-card>
            <div fxLayout="row wrap" fxLayoutAlign="center">
                <mat-form-field>
                    <mat-label>Vendor</mat-label>
                    <mat-select (selectionChange)="getVendor()" [(ngModel)]="vendorId">
                        <mat-option [value]="''">**Select a Vendor**</mat-option>
                        <mat-option *ngFor="let vendor of vendorList$ | async" [value]="vendor.supplierId">
                            {{ vendor.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="pl-3 mt-10">
                    <button *hasPermission="['EDIT_VENDOR_PROFILE']" mat-raised-button color="accent" (click)="openVendorCreateDialog()">Create</button>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<div *ngIf="profile && supplierDetails" class="mt-20" fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" class="center-text">

        <mat-toolbar style="width: 100%;" color="primary">
            <span>Vendor Profile</span>
            <span class="example-spacer"></span>
            <button *hasPermission="['EDIT_VENDOR_PROFILE']" mat-raised-button color="accent" (click)="openVendorEditDialog()">Edit {{ profile.name }}</button>
        </mat-toolbar>

        <mat-card>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20 mb-20">
                <mat-checkbox [disabled]="true" [(ngModel)]="supplierDetails.assembly" name="assembly">Assembly</mat-checkbox>
                <mat-checkbox class='pl-2' [disabled]="true" [(ngModel)]="supplierDetails.bindery" name="bindery">Bindery</mat-checkbox>
                <mat-checkbox class='pl-2' [disabled]="true" [(ngModel)]="supplierDetails.active" name="active">Active</mat-checkbox>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center">
                <p><strong>Name:</strong></p>
                <div class="ml-1"></div>
                <p>{{ profile.name }}</p>
                <div class="pr-4"></div>

                <p><strong>Address:</strong></p>
                <ng-container *ngIf="profile.address1; else noAddress;">
                    <div class="ml-1 pr-4">
                        <p>{{ profile.address1 }}</p>
                        <p>{{ profile.city + ", " + " " + profile.state + " " + profile.zip + " " + profile.country }} </p>
                    </div>
                </ng-container>
                <ng-template #noAddress>
                    <div class="ml-1 pr-4">
                        <p>N/A</p>
                    </div>
                </ng-template>
                
                <p><strong>Phone #:</strong></p>
                <div class="ml-1"></div>
                <p>{{ profile.phoneNumber || 'N/A'}}</p>
                <div class="pr-4"></div>

                <p><strong>Email:</strong></p> 
                <div class="ml-1"></div>
                <p class="pr-2" *ngFor="let email of profile.emails">{{ email || 'N/A'}}</p>
            </div>

        </mat-card>
    </div>
</div>