export interface ComponentList {
    id: number;
    componentId: number;
    description: string;
    seqNbr: number;
    uom: string;
    value: string;
    createdBy: number;
    productType?: string;
}

export interface ComponentGroup
{
    name: string; 
    refId: string; 
    groupId: number;
    listValues: ComponentGroupList[];
}

export interface ComponentGroupList {
    id: number;
    type?: string;
    seqNbr: number;
    value: string;
    description: string;
    uom: string;
    minQty: number | undefined;
    maxQty: number | undefined;
}

export interface CategoryList {
    value: number;
    display: string;
    refId: string;
    seqNbr?: number;
    active?: boolean;
}

export interface VendorList {
    id: number;
    supplierId: string;
    name: string;
    assembly: boolean;
    bindery: boolean;
    active: boolean;
}

export interface ListStorage {
    expiry: number;
    list: any[];
}

export interface ItemStorage {
    expiry: number;
    item: any;
}

export interface CategoryListStorage {
    expiry: number;
    categoryLists: CategoryList[];
}

export interface ComponentLists {
    boardWeight?: ComponentList[];
    coverStock?: ComponentList[];
    colorsCover?: ComponentList[];
    coverFilm?: ComponentList[];
    linerStock?: ComponentList[];
    colorsLiner?: ComponentList[];
    linerFilm?: ComponentList[];
    sizeFactorGrid?: ComponentList[];
    finishing?: ComponentList[];
    finishing1?: ComponentList[];
    finishing2?: ComponentList[];
    accessories?: ComponentList[];
    quantityFactorGrid?: ComponentList[];
    insideColorsCover?: ComponentList[];
    insideFilm?: ComponentList[];
    insideStock?: ComponentList[];
    outsideColorsCover?: ComponentList[];
    outsideFilm?: ComponentList[];
    outsideStock?: ComponentList[];
    photopackType?: ComponentList[];
    photoPack?: ComponentList[];
    saddlePageCount?: ComponentList[];
    waterfallPageCount?: ComponentList[];
    flipUpBoardWeight?: ComponentList[]; 
    flipUpCount?: ComponentList[]; 
    flipUpCoverStock?: ComponentList[];
    flipUpColorsCover?: ComponentList[];
    flipUpCoverFilm?: ComponentList[];
    flipUpLinerStock?: ComponentList[];
    flipUpColorsLiner?: ComponentList[];
    flipUpLinerFilm?: ComponentList[];
    flipUpAttachmentMethod?: ComponentList[];
    cutAddOns?: ComponentList[];
    swatchType?: ComponentList[];
    boxingPackaging?: ComponentList[], 
    backerBoard?: ComponentList[],
    minPrice?: ComponentList[],
}

export enum SpecActions {
    Created,
    Updated,
    Deleted,
    Cancelled
}

export interface User {
    id: number,
    name: string,
    objectId: string,
    email?: string,
    preferredName?: string,
    supplierId: number,
    upn?: string,
    vendorId: string, //from peopleSoft
    vendorName: string,
    roles?: string[],
    permissions?: string[],
    expiry: number
}

export interface ComponentGroupResponse {
    boardWeight?: ComponentGroup;
    coverStock?: ComponentGroup;
    colorsCover?: ComponentGroup;
    coverFilm?: ComponentGroup;
    linerStock?: ComponentGroup;
    colorsLiner?: ComponentGroup;
    linerFilm?: ComponentGroup;    
    sizeFactorGrid?: ComponentGroup;
    finishing?: ComponentGroup;
    finishing1?: ComponentGroup;
    finishing2?: ComponentGroup;
    accessories?: ComponentGroup;
    quantityFactorGrid?: ComponentGroup;
    insideColorsCover?: ComponentGroup;
    insideFilm?: ComponentGroup;
    insideStock?: ComponentGroup;
    outsideColorsCover?: ComponentGroup;
    outsideFilm?: ComponentGroup;
    outsideStock?: ComponentGroup;
    photopackType?: ComponentGroup;
    photoPack?: ComponentGroup;
    saddlePageCount?: ComponentGroup;
    waterfallPageCount?: ComponentGroup;
    flipUpCount?: ComponentGroup;
    flipUpCoverStock?: ComponentGroup;
    flipUpColorsCover?: ComponentGroup;
    flipUpCoverFilm?: ComponentGroup;
    flipUpLinerStock?: ComponentGroup;
    flipUpColorsLiner?: ComponentGroup;
    flipUpLinerFilm?: ComponentGroup;
    cutAddOns?: ComponentGroup;
    swatchType?: ComponentGroup;
    boxingPackaging?: ComponentGroup; 
    minPrice?: ComponentGroup;
}

export interface Lookup {
    id: number;
    type: string;
    value: string;
    description: string;
    sequenceNbr: number;
    active: boolean;
    groupIdValue: number;
}

export interface Table {
    id: number;
    changedBy: number;
    changeUser: string;
    changedTimestamp: Date;
    createdBy: number;
    createUser: string;
    createdTimestamp: Date;
  }
