import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Observable } from 'rxjs/internal/Observable';

import { UserProfilesService } from '../../services/user-profiles.service';
import { SpecActions } from 'src/app/core/models/common-models';
import { UserProfiles } from '../../models/user-profiles.models';
import { BackendService } from 'src/app/core/services/back-end.service';
import { VendorDto } from 'src/app/core/models/vendor-models';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-update-user-profiles',
  templateUrl: './update-user-profiles.component.html',
  styles: [],
})
export class UpdateUserProfilesComponent implements OnInit {

  myForm!: FormGroup;

  vendorList$: Observable<VendorDto[]> = this.backendService.getVendorDtoList(true);

  constructor(public dialogRef: MatDialogRef<UpdateUserProfilesComponent>,
    private userProfilesService: UserProfilesService,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackService: SnackBarService) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      id: this.data.id,
      upn: [this.data.upn, Validators.required],
      name: [this.data.name, Validators.required],
      preferredName: [this.data.preferredName, Validators.required],
      email: [this.data.email, Validators.required],
      supplierId: this.data.supplierId,
      supplierName: this.data.supplierName,
      active: [this.data.active, Validators.required]
    });
  }

  submit() {
    this.userProfilesService.updateUserProfile(this.myForm.value).subscribe((res: UserProfiles) => {
      this.dialogRef.close(SpecActions.Updated);
      this.snackService.success('User was successfully updated.')
    });
  }
}
