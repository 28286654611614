import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Lookup } from 'src/app/core/models/common-models';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ComponentDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';
import { UpdateCompDialogComponent } from '../update-comp-dialog/update-comp-dialog.component';

@Component({
  selector: 'app-manage-component-dialog',
  templateUrl: './manage-component-dialog.component.html',
  styleUrls: ['./manage-component-dialog.component.scss'],
  providers: [AdministrationService]
})
export class ManageComponentDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<ComponentDTO>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'categoryId', 'groupId', 'createdByName', 'createdTimestamp', 'changedByName', 'changedTimestamp', 'active'];
  displayedColumns = this.dataColumns;

  groupIdList!: Lookup[];

  constructor(public dialogRef: MatDialogRef<ManageComponentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ComponentDTO,
              private adminService: AdministrationService,
              public dialog: MatDialog,
              private snackService: SnackBarService,
              public commonService: CommonService
    ) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getGroupIdList();
    this.getComponentsById();
  }

  getGroupIdList() {
    this.commonService.getGroupIdList().subscribe((response: any) => {
      this.groupIdList = response;
    },
    (e) => {
      this.snackService.error('An error occurred while retrieving group id list. If problem persist, please contact support.');
      console.error(e);
    })
  }

  getComponentsById() {
    this.adminService.getComponentsById(this.data.categoryId).subscribe((response: any) => {
      this.dataSource.data = response;
    });

  }

  createComponent(form: NgForm) {
    this.adminService.createComponent(form.value).subscribe((response: any) => {
      this.snackService.success('Component was successfully created');
      this.getComponentsById();
    },
    (e: any) => {
      this.snackService.error('An error occurred while creating component. If problem persist, please contact support.');
      console.error(e);
    });
  }

  openUpdateCompDialog(data: ComponentDTO) {
    const dialogRef = this.dialog.open(UpdateCompDialogComponent, {
      data: {
        id: data.id,
        name: data.name,
        active: data.active,
        groupId: data.groupId,
        referenceId: data.referenceId,
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getComponentsById();
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
