import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { tap } from 'rxjs/internal/operators';

import { CommonService } from '../core/services/common.service';
import { UserService } from '../core/services/user.service';
import { SpecCollectionDirective } from '../spec-collection/core/directives/spec-collection.directive';
import { VendorProfileService } from '../vendor/vendor-profile/services/vendor-profile.service';

@Component({
  selector: 'app-spec-library-pricing',
  template: `
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <div fxFlex="96" fxFlex.xs="100">
        <mat-card>
          <div fxFlex="100" fxLayoutAlign="center">
              <mat-form-field>
                  <mat-label>Category Type</mat-label>
                  <mat-select (selectionChange)="categoryChange($event)">
                      <mat-option [value]="0">None</mat-option>                       
                      <ng-container>
                          <mat-option *ngIf="userSupplierId$ == 0 || isAssemblyVendor" [value]="'swatchsku'">Assembly WO Pricing</mat-option>
                          <mat-option *ngIf="userSupplierId$ == 0 || isBinderyVendor" [value]="'printsku'">Bindery WO Pricing</mat-option>
                      </ng-container>
                  </mat-select>
              </mat-form-field>
          </div>
        </mat-card> 
      </div>
    </div>

    <ng-template specCollectionHost></ng-template>
  `,
  styles: [
  ],
  providers: [VendorProfileService]
})
export class SpecLibraryPricingComponent implements OnInit {
  @ViewChild(SpecCollectionDirective, {static: true}) host!: SpecCollectionDirective;

  readonly userSupplierId$ = this.userService.getUser().supplierId;

  isAssemblyVendor: boolean = false;
  isBinderyVendor: boolean = false; 

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private common: CommonService,
    private title: Title,
    private userService: UserService,
    private vendorProfileService: VendorProfileService
  ) { 
    this.title.setTitle('Spec Library Pricing | SAVE');
  }

  ngOnInit(): void {
    if(this.userSupplierId$ > 0) {
      this.vendorProfileService.getVendorByItsId(this.userSupplierId$).pipe(
        tap(supplier => {
          this.isAssemblyVendor = supplier.assembly; 
          this.isBinderyVendor = supplier.bindery; 
        })
      ).subscribe(); 
    }
  }

  categoryChange(change: MatSelectChange): void {
    try {

      const loader = this.common.getComponentByRefId(change.value);
      const viewContainerRef = this.host.viewContainerRef; 
      viewContainerRef.clear(); 

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loader.routingComponent);
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);
      
    } catch(e) {
      console.error('Error loading component: ' + change.source.triggerValue + ' -> ' + e);
    }
  }

}
