import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';

import { VendorSkuPricingHeader } from '../models/swatch-routing.models';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styles: []
})
export class PriceTableComponent {  
  @ViewChild(MatSort) sort!: MatSort;

  @Input() set vendorPricing(vendors: VendorSkuPricingHeader[]) {
    this.vendors = vendors.slice().map(vendor => {
      vendor.unitPrice = vendor.unitPrice || undefined;
      vendor.totalPrice = vendor.totalPrice || undefined;
      vendor.isNoBid = !vendor.unitPrice && vendor.pricing && vendor.pricing.length > 0; 
      return vendor;
    });

    if(this.sort) {
      this.sort.sort({id: '', start: 'asc', disableClear: false});
    } else {
      const data = this.vendors.slice(); 
      this.vendors = data.sort((a,b) => this.compare(a.unitPrice, b.unitPrice, 'asc'));
    }
  }
  
  constructor() { }
  
  vendors: VendorSkuPricingHeader[] = [];
  displayColumns: string[] = ['vendor', 'unitPrice', 'diffUnitPrice', 'totalPrice','diffTotalPrice'];

  handleSort(sort: Sort): void {
    const data = this.vendors.slice(); 

    //Sort has been reset, so sort by vendor ascending
    if(!sort.active || sort.direction === '') {
      this.vendors = data.sort((a,b) => this.compare(a.unitPrice, b.unitPrice, 'asc'));
      return;
    }

    this.vendors = data.sort((a,b) => {
      switch(sort.active) {
        case 'vendorName': return this.compare(a.vendorName, b.vendorName, sort.direction); 
        case 'unitPrice': return this.compare(a.unitPrice, b.unitPrice, sort.direction); 
        case 'totalPrice': return this.compare(a.totalPrice, b.totalPrice, sort.direction); 
        default: return 0;
      }
    });
  }

  private compare(a: number | string | undefined, b: number | string | undefined, direction: string): number {
    const isAsc = direction === 'asc';
    const multiplier: number = isAsc ? 1 : -1;

    //If the field is null or equals 0 then put it at the bottom
    if(a === b) return 0
    if(!a || a === 0) return 1;
    if(!b || b === 0) return -1;
    
    //Fields are not null and don't equal 0, so compare them
    if(a < b) return (-1 * multiplier); 
    if(a > b) return (1 * multiplier);

    return 0;
  }
}
