<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">

        <mat-card *hasPermission="['EDIT_SPEC']" class="mb-15">
            <mat-card-header>
                <mat-card-title>Panel Backer Board</mat-card-title>
            </mat-card-header>

            <app-swatching-header [headerForm]="backerService.headerForm" [categoryId]="categoryId"></app-swatching-header>

        </mat-card>

        <mat-toolbar style="width: 100%;" color="primary" class="mb-10">
            <ng-container *hasPermission="['EDIT_SPEC']; else viewOnly">
                <span>Create Panel Backer Board</span>
            </ng-container>
            <ng-template #viewOnly>
                <span>View Panel Backer Board</span>
            </ng-template>
        </mat-toolbar>

        <mat-card>
            <app-backer-board [componentLists]="componentLists" (submitForm)="submitForm()"></app-backer-board>
        </mat-card>

    </div>
</div>