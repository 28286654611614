
<div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
    <div fxFlex="47" style="align-items: flex-start;text-align: left;" >
      <mat-label>Sort By:&nbsp;</mat-label>
      <mat-form-field style="margin-top:10px;width:120px">
        <mat-select  [(value)]="selectedColumn" (selectionChange)="changeSortedColumn()">
          <mat-option *ngFor="let column of sortColumns" [value]="column.value">
            {{ column.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <mat-form-field style="margin-top:10px;width:100px">
        <mat-select  [(value)]="sortOrder" (selectionChange)="changeSortedColumn()">
          <mat-option *ngFor="let d of sortDirections" [value]="d.value">
            {{ d.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="47" fxLayoutAlign="end center">
      <button type="button" mat-raised-button color="primary" (click)="exportCSV()">
        Export CSV
        <mat-icon>file_download</mat-icon>
      </button>
    </div>

    <div fxFlex="96" fxflex.xs="100" class="table-container-600">
        <table mat-table [dataSource]="dataSource" matSort class="mat-table bid-table" style="width:100%" cdkScrollable>
        <ng-container matColumnDef="changedTimestamp" [sticky]="rangedPrice">
          <th mat-header-cell *matHeaderCellDef>
            Date Last Updated<br/>
              <mat-form-field>
                  <mat-label class="filterText">Search</mat-label>
                  <input matInput class="filterText" [formControl]="dateFilter">
                  <button mat-icon-button matSuffix *ngIf="dateFilter.value" (click)="dateFilter.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
              </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.changedTimestamp | date: 'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="name" [sticky]="rangedPrice">
          <th mat-header-cell *matHeaderCellDef >
              Section<br/>
              <mat-form-field>
                <mat-label class="filterText">Search</mat-label>
                  <input matInput class="filterText" [formControl]="nameFilter">
                  <button mat-icon-button matSuffix *ngIf="nameFilter.value" (click)="nameFilter.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
              </mat-form-field>
          </th>
          <td mat-cell class="bid-table-cell" *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="value" [sticky]="rangedPrice">
          <th mat-header-cell *matHeaderCellDef >
              Description<br>
              <mat-form-field>
                <mat-label class="filterText">Search</mat-label>
                  <input matInput class="filterText" [formControl]="valueFilter">
                  <button mat-icon-button matSuffix *ngIf="valueFilter.value" (click)="valueFilter.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
              </mat-form-field>
          </th>
          <td mat-cell class="bid-table-cell" *matCellDef="let element">
            <div *ngIf="categoryId == 7; else elseBlock">
              <button type="button" class="btn-link" style="text-align: left;" (click)="openComponentListExtraDetails(element)">
                {{ element.value }}
              </button>
              <!-- <a href="#">{{ element.value }}</a> -->
            </div>
            <ng-template #elseBlock>
              {{ element.value }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container *ngFor="let disCol of priceRangeDisplayColumns; let colIndex = index;" matColumnDef="{{disCol}}">
					<th mat-header-cell *matHeaderCellDef >{{disCol}}</th>
          
          <td mat-cell *matCellDef="let element">
            <mat-form-field [floatLabel]="'never'" appearance="outline" class="light-padding" 
                            [style.width.px]="'100'" [class.mat-form-field-invalid]="element.bids[colIndex].error"
                            [class.highlight]="element.bids[colIndex].isChanged">

              <input matInput #input type="text" positiveNumber [allowNegative]="element.negativeBid" 
                    [class.missing-bid-placeholder]="element.bids[colIndex].missingBid"
                    [(ngModel)]="element.bids[colIndex].price" placeholder="{{ element.bids[colIndex].noBid ? 'No Bid' : 'Missing'}}" 
                    (blur)="checkPrice(element.componentListId, element.bids[colIndex])"
                    [matAutocomplete]="auto"
                    [matAutocompleteDisabled]="element.bids[colIndex].noBid"
                    [disabled]="!canEdit || (!isGlobalBidding && (!element.bids[colIndex].missingBid && !element.bids[colIndex].isChanged))">

              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setNoBid(element.componentListId, element.bids[colIndex])">
                <mat-option>No Bid</mat-option>
              </mat-autocomplete>                
            </mat-form-field>              
          </td>
			  </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"  class="mat-table-header-primary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

      </table>
    </div>

<div *ngIf="canEdit" fxLayout="row wrap" class="mt-10" fxLayoutAlign="center">
  <button mat-raised-button color="primary"
    style="margin-left: 5px;"
    (click)="submitForm()">Save</button>

  <button mat-raised-button color="primary"
    style="margin-left: 10px;"
    (click)="resetForm()">Reset</button>
</div>