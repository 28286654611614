import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { SwatchingService } from './services/swatching.service';
import { MAX_SWATCH_QTY_VALUE } from './models/swatching.model';
import { BackerBoardService } from '../backer-board/services/backer-board.service';

@Component({
  selector: 'app-swatching',
  templateUrl: './swatching.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    }
  ]
})
export class SwatchingComponent implements OnInit, OnDestroy {  
  @Output() submitForm = new EventEmitter();

  private readonly destroy$: Subject<void> = new Subject<void>(); 
  isBackerBoardValid: boolean = false;
  pbbDetailForm!: FormGroup;

  constructor(
    public swatchService: SwatchingService, 
    public backerService: BackerBoardService,
    private snackService: SnackBarService
  ) { }

  ngOnInit(): void { 
    this.pbbDetailForm = this.backerService.detailForm; 

    this.backerService.isDetailFormInvalid$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isInvalid => {        
        if(this.swatchService.isPanelBackerBoard) {
          this.isBackerBoardValid = !isInvalid; 
        } else {
          this.isBackerBoardValid = true;
        }
      }); 
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete(); 
  }

  stepChanged(_: StepperSelectionEvent): void {
    this.swatchService.checkSwatchQuantityErrors();
  }
  
  swatchTypeQtyChange(data: {id: number, qty: number}): void {
    this.swatchService.swatchTypeChange();
    this.swatchService.checkPendingData();
  }

  qtyChange(data: {id: number, qty: number}): void {
    this.swatchService.checkPendingData();
  }

  submit(): void {
    const qtyTooLargeCount = this.swatchService.concatSwatchArr.filter(swatch => swatch.quantity && swatch.quantity > MAX_SWATCH_QTY_VALUE).length;

    if(this.swatchService.headerForm.invalid || qtyTooLargeCount || (this.swatchService.isPanelBackerBoard && this.backerService.detailForm.invalid)) {
      this.swatchService.checkSwatchQuantityErrors();
      this.swatchService.headerForm.markAllAsTouched();
      if(this.swatchService.isPanelBackerBoard) {
        this.backerService.detailForm.markAllAsTouched(); 
      }
      this.snackService.error('Cannot submit spec until invalid fields are corrected');
      return;
    }

    let count = this.swatchService.concatSwatchArr.filter(swatch => swatch.quantity && swatch.quantity > 0).length;
    if(count <= 0) {
      this.snackService.error('Cannot submit swatch spec with no components selected.');
      return;
    }

    // count = this.swatchService.swatchType.filter(swatch => swatch.quantity && swatch.quantity > 0).length; 
    // if(count <= 0) {
    //   this.swatchService.isSwatchError = true; 
    //   this.snackService.error('Cannot subit swatch spec without a Swatch Type selected');
    //   return;
    // }

    this.submitForm.emit();    
  }
}