import { Component, OnInit } from '@angular/core';

import { ColDef, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { ThemeService } from '../core/services/theme.service';
import { SpecLibraryPricingReportService } from './services/spec-library-pricing-report.service';
import { ButtonRendererComponent } from '../core/renderers/button-renderer.component';
import { SnackBarService } from '../core/services/snack-bar.service';

@Component({
  selector: 'app-quantity-table',
  template: `
    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
      <div fxFlex="100">
        <div  style="max-height: 500px; overflow-x: auto;">
          <ag-grid-angular
            style="width: 100%;"
            domLayout="autoHeight"
            [singleClickEdit]="true"
            [stopEditingWhenCellsLoseFocus]="true"
            [ngClass]="themeService.agGridTheme"
            [rowData]="reportService.quantities"
            [pinnedTopRowData]="reportService.topRowQuantity"
            [columnDefs]="columnDefs"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class QuantityTableComponent implements OnInit {
  constructor(
    public themeService: ThemeService,
    public reportService: SpecLibraryPricingReportService, 
    private snackService: SnackBarService
  ) { }

  ngOnInit(): void { }

  columnDefs: ColDef[] = [
    { 
      field: 'quantity', 
      headerName: 'Quantity *', 
      flex: 1, 
      editable: true, 
      valueFormatter: (params: ValueFormatterParams): any => 
        params.node?.rowPinned === 'top' && !params.value ? 'Quantity' : undefined, 
      valueSetter: (params) => {
        if(params.node?.rowPinned === 'top' && !params.newValue) {
          params.data.quantity = undefined; 
          return true;
        }

        if(!params.newValue || params.newValue < 1) {
          this.snackService.warn('Invalid quantity entered. Quantity must be greater than 0.');
          return false;
        }

        if(isNaN(params.newValue)) {
          this.snackService.warn('Invalid quantity entered. Quantity must be a number.');
          return false;
        }

        params.data.quantity = params.newValue;
        return true;
      }
    },
    {
      cellRendererSelector: params => {
        if(params.node.rowPinned === 'top') {
          return {
            component: ButtonRendererComponent,
            params: { 
              buttons: [
                { buttonColor: 'primary', buttonIcon: 'add', buttonTooltip: 'Add Quantity', clickEvent: this.add.bind(this) }
              ]
            }
          }
        }

        return {
          component: ButtonRendererComponent, 
          params: { 
            buttons: [
              { buttonColor: 'warn', buttonIcon: 'delete', buttonTooltip: 'Delete Quantity', clickEvent: this.deleteSpec.bind(this) }
            ]
          }
        }
      }, 
      type: 'rightAligned'      
    }
  ];  

  public onGridReady(params: GridReadyEvent): void {
    this.reportService.quantityGridApi = params.api;
  }

  deleteSpec(data: any): void {
    this.reportService.deleteQuantityByIndex(data.rowIndex);
  }

  add(): void { 
    this.reportService.addQuantity();
  }
}
