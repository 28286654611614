<div fxLayout="row wrap">    
    <mat-toolbar style="width: 100%;" color="primary">
        <span>{{ categoryName }} History</span>
        <span class="example-spacer"></span>
        <div fxFlex="4" fxLayoutAlign="end" >
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
</div>

<mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="center">
        <div fxFlex="96" fxFlex.xs="100">
            <app-spec-history-table [data]="specHistory" (detailSelected)="openDetailDialog($event)"></app-spec-history-table>
        </div>
    </div>
</mat-dialog-content>
