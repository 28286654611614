<div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-card class="mb-1">
            <div fxFlex="60">
                <button type="button" mat-raised-button (click)="update()">Update</button>
                <button type="button" mat-raised-button color="primary" (click)="resetBids()" style="margin-left: 10px;">Reset</button>
                <button type="button" mat-raised-button color="secondary" (click)="massBid()" [disabled]="!isMassBid" style="margin-left: 10px;">Mass Bid</button>
            </div>
            <div fxFlex="40" fxLayoutAlign="end center">
                <button type="button" mat-raised-button color="primary" (click)="exportCSV()">
                    Export CSV
                    <mat-icon>file_download</mat-icon>
                </button>
            </div>
        </mat-card>

        <ag-grid-angular
            style="width: 100%; height: 500px;"
            [ngClass]="themeService.agGridTheme"
            [getRowId]="getRowId"
            [rowData]="rowData"
            [suppressRowClickSelection]="true"
            [rowSelection]="'multiple'"
            [isRowSelectable]="isRowSelectable"
            (selectionChanged)="selectionChange($event)"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
