import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { VendorList } from '../../core/models/common-models';
import { UserService } from 'src/app/core/services/user.service';
import { VendorBidService } from './services/vendor-bid.service';
import { ComponentLoaderDirective } from 'src/app/core/directives/component-loader.directive';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { BackendService } from 'src/app/core/services/back-end.service';
import { AdministrationService } from 'src/app/admin/services/administration.service';
import { Category } from 'src/app/admin/models/administration-models';
import { VendorProfileService } from '../vendor-profile/services/vendor-profile.service';
import { BidAgGridComponent } from './bid-ag-grid/bid-ag-grid.component';

@Component({
  selector: 'app-vendor-bid',
  templateUrl: './vendor-bid.component.html',
  providers: [VendorProfileService, VendorBidService, AdministrationService]
})
export class VendorBidComponent implements OnInit, OnDestroy {
  @ViewChild(ComponentLoaderDirective, {static: true}) bidCollectionHost!: ComponentLoaderDirective;
  readonly destroy$: Subject<void> = new Subject<void>();

  selectedCategory: number = 0;
  selectedVendor: number = 0;
  categoryList: Category[] = [];
  vendorList: VendorList[] = [];
  filteredVendorList: VendorList[] = [];
  hasChanges: boolean = false;

  isVendor: boolean = false;

  /** @usageNotes Sets the BidCollectionDirective host for attaching components dynamically */
  set host(bidCollectionHost: ComponentLoaderDirective) {
    this.bidCollectionHost = bidCollectionHost;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private titleService: Title,
    private userSrv: UserService,
    private snackService: SnackBarService,
    private backendService: BackendService,
    private adminService: AdministrationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Vendor Bid | SAVE');

    this.isVendor = this.userSrv.isVendor; 

    forkJoin([this.adminService.getCategoryList(this.userSrv.getUser().supplierId), this.backendService.getVendorDtoList(true)])
      .pipe(takeUntil(this.destroy$))
      .subscribe( ([categoryList, vendorList]) => {
        this.categoryList = categoryList;
        this.vendorList = vendorList;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  headerChange(): void {
    try
    {
      const viewContainerRef = this.bidCollectionHost.viewContainerRef;
      viewContainerRef.clear();

      if(this.isVendor) { 
        this.selectedVendor = this.userSrv.getUser()?.supplierId;
      }

      const category = this.categoryList.find(cat => cat.id === this.selectedCategory);
      this.filterVendorList(category);
      if(!this.filteredVendorList.some(ven => ven.id === this.selectedVendor)) this.selectedVendor = 0;

      if (this.selectedVendor>0 && this.selectedCategory>0)
      {
        let detailComponent: any = {};
        detailComponent = BidAgGridComponent;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(detailComponent);
        const componentRef = viewContainerRef.createComponent<any>(componentFactory);

        componentRef.instance.categoryId = this.selectedCategory;
        componentRef.instance.vendorId = this.selectedVendor;
        // componentRef.instance.screenTitle = category?.name + " Bidding";
        
        // componentRef.instance.updateStatus.subscribe( 
        //   (event:any)=> {
        //     this.hasChanges = event;
        //   })
      }
    }
    catch (e: any)
    {
      this.snackService.error(e);
      console.log('Error loading component. ' + ' -> ' + e);
      this.selectedCategory = 0;
    }
  }

  private filterVendorList(cat: Category | undefined) {
    if(!cat) {
      this.filteredVendorList = [];
      return;
    }
    this.filteredVendorList = this.vendorList.filter(ven => cat.assembly ? ven.assembly : ven.bindery);
  }
}