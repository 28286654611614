<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100">
  
        <mat-card class="mt-20">

            <form #requestForm="ngForm">
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <div fxFlex="98" fxFlex.xs="100" fxLayoutAlign="center">
                        <mat-form-field>
                            <mat-label>Quantity</mat-label>
                            <input type="number" positiveNumber [allowDecimal]="false" matInput autocomplete="off" min="0" name="quantity" ngModel required>
                        </mat-form-field>
                    </div>
                </div>
    
                <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>SKU Number</mat-label>
                        <input matInput maxlength="5" alphaNumeric autocomplete="off" name="skuNumber" ngModel required [disabled]="!requestForm.value.quantity">
                    </mat-form-field>
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Style Number</mat-label>
                        <input matInput maxlength="5" alphaNumeric autocomplete="off" name="styleNumber" ngModel required [disabled]="!requestForm.value.quantity">
                    </mat-form-field>
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Delivery Vehicle Number</mat-label>
                        <input matInput maxlength="3" alphaNumeric autocomplete="off" name="deliveryVehicle" ngModel required [disabled]="!requestForm.value.quantity">
                    </mat-form-field>
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Customer Number</mat-label>
                        <input matInput maxlength="7" autocomplete="off" name="customerNumber" [leftPad]="7" positiveNumber [allowDecimal]="false" ngModel [disabled]="!requestForm.value.quantity">
                    </mat-form-field>
                    <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Sequence Number</mat-label>
                        <input type="text" matInput positiveNumber [allowDecimal]="false" max="99" min="0" maxlength="2" autocomplete="off" name="sequence" ngModel [disabled]="!requestForm.value.quantity">
                    </mat-form-field>
                </div>
    
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <button mat-raised-button color="primary" type="button" style="margin-right: 10px;" (click)="submitSpec()" [disabled]="requestForm.invalid">{{ btnPreText }} Pricing</button>
                    <button mat-raised-button color="accent" type="button" (click)="openDialog()" [disabled]="!pricing || pricing.length === 0" style="margin-right: 10px;">{{ btnPreText }} Spec</button>
                    <button mat-raised-button color="warn" type="button" (click)="clear()">Clear</button>
                </div>
            </form>

            <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10" *ngIf="pricing && pricing.length > 0">
                <div fxFlex="98" fxFlex.lt-lg="100">
                    <app-spec-library-pricing-table [pricingData]="pricing"></app-spec-library-pricing-table>
                </div>               
            </div>
            
            <!-- <ng-container *ngIf="pricing && pricing.length > 0">
                <ng-container *ngIf="!isVendor; else vendorBlock;">
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                        <div fxFlex="98" fxFlex.lt-lg="100">
                            <app-spec-library-pricing-table [pricingData]="pricing"></app-spec-library-pricing-table>
                        </div>               
                    </div>
                </ng-container>                
                <ng-template #vendorBlock>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                        <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                            <mat-label>Unit Price</mat-label>
                            <input matInput disabled [value]="pricing[0].unitPrice">
                        </mat-form-field>
                        <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                            <mat-label>Total Price</mat-label>
                            <input matInput disabled [value]="pricing[0].totalPrice">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-10">
                        <app-bindery-pricing-spec *ngIf="spec && spec.parentSpec" [specResponse]="spec"></app-bindery-pricing-spec>
                    </div>
                </ng-template>
            </ng-container> -->

        </mat-card>

    </div>
</div>