import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable, of } from "rxjs";

import { PendingChangesDialogComponent } from "../dialog/pending-changes-dialog/pending-changes-dialog.component";

export interface ComponentCanDeactivate {
    canDeactivate(): boolean | Observable<boolean>;
  }

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private dialog: MatDialog) {}

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {        
        if(!component || component.canDeactivate()) {
            return of(true);
        }

        return this.dialog.open(PendingChangesDialogComponent, {
            hasBackdrop: true,
            disableClose: true
        }).afterClosed(); 
    }
}