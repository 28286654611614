import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { Supplier } from '../models/vendor-profile-models';
import { VendorProfileService } from '../services/vendor-profile.service';

@Component({
  selector: 'app-vendor-edit-dialog',
  templateUrl: './vendor-edit-dialog.component.html',
  styleUrls: ['./vendor-edit-dialog.component.scss'],
  providers: [VendorProfileService]
})
export class VendorEditDialogComponent implements OnInit {

  assembly!: boolean;
  bindery!: boolean;
  active!: boolean;

  constructor(public dialogRef: MatDialogRef<VendorEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackBarService,
    public commonService: CommonService,
    private vendorService: VendorProfileService,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  update() {
    let request: Supplier = {
      name: this.data.vendorName,
      supplierId: this.data.supplierId, 
      assembly: this.data.assembly, 
      bindery: this.data.bindery,
      active: this.data.active
    }

    this.vendorService.updateVendor(this.data.supplierId, request).subscribe(response => {
      this.snackService.success(response.successMessage);
    },
    (e: HttpErrorResponse) => {
      this.snackService.error(e.error.localizedMessage);
      console.log(e);
    },
    () => {
      this.close();
    });    
  }

}
