import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/internal/Observable";

import { ComponentGroupResponse, ComponentLists } from "../models/common-models";
import { SpecDTO } from "../models/spec-models";
import { VendorDto } from "../models/vendor-models";
import { ConfigModel } from "src/app/core/models/config-model";
import { VendorPricingHeader } from "src/app/routing-tool/models/price-routing.models";
import { QtyTier } from "src/app/routing-tool/models/swatch-routing.models";

@Injectable({
    providedIn: 'root'
})
export class BackendService {

    constructor(private httpClient: HttpClient) {}

    public getVendorDtoList(isOnlyActive: boolean, categoryId?: number): Observable<VendorDto[]> {
        let params = new HttpParams()
        if(categoryId && categoryId > 0) params = params.set('categoryId', categoryId);
        params = params.set('onlyActive', isOnlyActive);
       
        return this.httpClient.get<VendorDto[]>(`${ConfigModel.API_URL}/v1/vendors/list`, {params: params});      
    }

    public getVendorPricingHeaderByCategoryId(categoryId: number): Observable<VendorPricingHeader[]> {
        return this.httpClient.get<VendorPricingHeader[]>(`${ConfigModel.API_URL}/v1/routing-tool/vendor-pricing/${categoryId}`);
    }

    public getVendorQtyPricingByCategoryId(categoryId: number, qty: number): Observable<QtyTier> {
        return this.httpClient.get<QtyTier>(`${ConfigModel.API_URL}/v1/routing-tool/vendor-pricing/${categoryId}/qty-factor/${qty}`);
    }

    public getSpecById(id: number): Observable<SpecDTO> {        
        return this.httpClient.get<SpecDTO>(`${ConfigModel.API_URL}/v1/spec/${id}`);
    }

    public getComponentListByCategoryId(categoryId: number): Observable<ComponentLists> {
        return this.httpClient.get<ComponentLists>(`${ConfigModel.API_URL}/v1/category/lists/${categoryId}`);
    }

    public getComponentGroupListByCategoryId(categoryId: number): Observable<ComponentGroupResponse> {
        return this.httpClient.get<ComponentGroupResponse>(`${ConfigModel.API_URL}/v1/category/component-list/group/${categoryId}`);
    }
}