<mat-toolbar style="width: 100%;" color="primary">
    <span>Change Component List of Specs</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div mat-dialog-content class="dialog-update">
    <form class="example-form">
        <table mat-table [dataSource]="dataSource" matSort>
        
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Spec ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
            </ng-container>
    
            <ng-container matColumnDef="supplierName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Name</th>
                <td mat-cell *matCellDef="let element;">{{ element.supplierName }}</td>
            </ng-container>
    
            <ng-container matColumnDef="styleNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Style Nbr</th>
                <td mat-cell *matCellDef="let element;">{{ element.styleNbr }}</td>
            </ng-container>
    
            <ng-container matColumnDef="skuNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SKU</th>
                <td mat-cell *matCellDef="let element;">{{ element.skuNbr || '-' }}</td>
            </ng-container>
    
            <ng-container matColumnDef="deliveryVehicleNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Vehicle</th>
                <td mat-cell *matCellDef="let element;">{{ element.deliveryVehicleNbr || '-' }}</td>
            </ng-container>
    
            <ng-container matColumnDef="customerNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Nbr</th>
                <td mat-cell *matCellDef="let element;">{{ element.customerNbr || '-' }}</td>
            </ng-container>
    
            <ng-container matColumnDef="sequenceNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence Nbr</th>
                <td mat-cell *matCellDef="let element;">{{ element.sequenceNbr || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="newCompListId">
                <th mat-header-cell *matHeaderCellDef>Change {{ data.componentName }}</th>
                <td mat-cell *matCellDef="let element;">
                     <mat-form-field appearance="standard">
                        <mat-label>Change value of {{ data.componentName }}</mat-label>
                        <mat-select name="newCompListId">
                            <mat-option *ngFor="let e of compList" [value]="e.id" (click)="replaceCompList(e.id, element.id)">
                               {{ e.value }}
                            </mat-option>
                        </mat-select>
                     </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="viewSpec">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>View Spec</th>
                <td mat-cell *matCellDef="let element;">
                    <button mat-icon-button color="accent" matTooltip="View Spec History" (click)="openSpecHistory(element.id)">
                        <mat-icon>history</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    
        </table>

        <mat-paginator #paginator
                           [pageSize]="300">
        </mat-paginator>

        <div mat-dialog-actions align="center">
            <button type="submit" mat-raised-button color="accent" (click)="update()">Update</button>
        </div>
    </form>
</div>