import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { SpecHistoryDto } from '../models/spec-history.models';
import { SpecHistoryService } from '../services/spec-history.service';

@Component({
  selector: 'app-spec-history-dialog',
  templateUrl: './spec-history-dialog.component.html',
  providers: [SpecHistoryService]
})
export class SpecHistoryDialogComponent implements OnInit {
  public specHistory: SpecHistoryDto[] = [];
  public specId!: number;
  public categoryName!: string;

  constructor(
    private common: CommonService,
    private historyService: SpecHistoryService,
    private dialogRef: MatDialogRef<SpecHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit(): void {
    
    if(this.data.specId) {
      this.specId = this.data.specId;

      this.historyService.getSpecHistoryBySpecId(this.data.specId)
        .subscribe(response => {
          this.specHistory = response;
          if(this.specHistory.length > 0) {
            this.categoryName = this.specHistory[0].category;
          }
        });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  openDetailDialog(historyId: number): void {
    this.common.openSpecDetailHistoryDialog(historyId);
  }

}
