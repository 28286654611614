<div fxLayout="row wrap" fxLayoutAlign="center" color="primary">    
    <h2 mat-dialog-title>{{ specDto?.categoryType || 'Spec' }} Detail History</h2>
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">History Details</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="center" class="my-3">
    <div fxFlex="24" fxFlex.xs="48" fxLayoutAlign="center">
        <strong>Created By:&nbsp;</strong> {{ specDto?.createUser }}
    </div>
    <div fxFlex="24" fxFlex.xs="48" fxLayoutAlign="center">
        <strong>Created:&nbsp;</strong> {{ specDto?.createdTimestamp | date: 'medium' }}
    </div>
    <div fxFlex="24" fxFlex.xs="48" fxLayoutAlign="center">
        <strong>Changed By:&nbsp;</strong> {{ specDto?.changeUser || '-' }}
    </div>
    <div fxFlex="24" fxFlex.xs="48" fxLayoutAlign="center">
        <strong>Changed:&nbsp;</strong> {{ (specDto?.changedTimestamp | date: 'medium') || '-' }}
    </div>
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Spec Header Info</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="center" class="my-3">
    <div fxFlex="19" fxFlex.xs="32" fxLayoutAlign="center">
        <strong>Style Number:&nbsp;</strong> {{ specDto?.styleNbr || '-'}}
    </div>
    <div fxFlex="19" fxFlex.xs="32" fxLayoutAlign="center">
        <strong>SKU Number:&nbsp;</strong> {{ specDto?.skuNbr || '-'}}
    </div>
    <div fxFlex="19" fxFlex.xs="32" fxLayoutAlign="center">
    <strong>Delivery Vehicle:&nbsp;</strong> {{ specDto?.deliveryVehicleNbr || '-' }}
    </div>
    <div fxFlex="19" fxFlex.xs="32" fxLayoutAlign="center">
        <strong>Customer Number:&nbsp;</strong> {{ specDto?.customerNbr || '-' }}
    </div>
    <div fxFlex="19" fxFlex.xs="32" fxLayoutAlign="center">
        <strong>Sequence Number:&nbsp;</strong> {{ specDto?.sequenceNbr || '-' }}
    </div>
</div>

<ng-template specCollectionHost></ng-template>

<div *ngIf="isErrorState" fxLayout="row wrap">
    <div fxFlex="100" fxLayoutAlign="center center">
        <h3 style="color: red;">An error occurred while loading spec detail history. If error persist, please contact support.</h3>    
    </div>
</div>
