import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/internal/operators';

import { SpecLibraryPricingReportService } from './services/spec-library-pricing-report.service';
import { ExcludeVendorsComponent } from './dialogs/exclude-vendors.component';
import { VendorDto } from '../core/models/vendor-models';

@Component({
  selector: 'app-spec-library-pricing-report',
  templateUrl: './spec-library-pricing-report.component.html',
  styles: [
  ], 
  providers: [SpecLibraryPricingReportService]
})
export class SpecLibraryPricingReportComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();

  canSubmit: boolean = false;
  excludedVendors: VendorDto[] = [];
  
  constructor(
    private title: Title,
    private reportSerivce: SpecLibraryPricingReportService,
    private dialog: MatDialog , 
    private viewContainerRef: ViewContainerRef
  ) { 
    this.title.setTitle('Spec Library Pricing Report | SAVE');
  }

  ngOnInit(): void { 
    this.reportSerivce.canSubmit$.pipe(
      takeUntil(this.destroy$), 
      tap(canSubmit => this.canSubmit = canSubmit)
    ).subscribe(); 

    this.reportSerivce.excludeVendors$.pipe(
      takeUntil(this.destroy$), 
      tap(excludeVendors => this.excludedVendors = excludeVendors)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  reset(): void {
    this.reportSerivce.resetData();
  }

  generateReport(): void {
    this.reportSerivce.generateReport();
  }

  removeExcludedVendor(index: number): void { 
    this.reportSerivce.removeExcludedVendor(index);
  }

  openExcludeVendorsDialog(): void {
    this.dialog.open(ExcludeVendorsComponent, {
      position: { top: '5%' },
      viewContainerRef: this.viewContainerRef
    });
  }
}
