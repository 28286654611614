<div fxLayout="row wrap" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" class="center-text">
        <mat-card>
            <form (submit)="submitSearch()">
                <mat-form-field *ngIf="!isVendor">
                    <mat-label>Vendor</mat-label>
                    <mat-select name="supplierId" [(ngModel)]="request.supplierId">
                        <mat-option [value]="''">All</mat-option>
                        <mat-option *ngFor="let vendor of vendorList$ | async" [value]="vendor.id">{{ vendor.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
    
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>SKU Number</mat-label>
                        <input matInput maxlength="5" type="text" name="skuNbr" [(ngModel)]="request.skuNbr" [maxlength]="5">
                    </mat-form-field>
                    <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Style Number</mat-label>
                        <input matInput maxlength="5" type="text" name="styleNbr" [(ngModel)]="request.styleNbr" [maxlength]="5">
                    </mat-form-field>
                    <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Delivery Vehicle Number</mat-label>
                        <input matInput maxlength="3" type="text" name="deliveryVehicleNbr" [(ngModel)]="request.deliveryVehicleNbr" [maxlength]="3">
                    </mat-form-field>
                    <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Customer Number</mat-label>
                        <input matInput maxlength="7" type="text" name="customerNbr" [(ngModel)]="request.customerNbr" [maxlength]="7" [leftPad]="7" positiveNumber [allowDecimal]="false" [disabled]="ignoreCustNbr">
                        <button type="button" matSuffix mat-icon-button (click)="toggleIgnoreCustNbr()" tabindex="-1">
                            <mat-icon>{{ignoreCustNbr ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-hint *ngIf="ignoreCustNbr">Search for all customer numbers</mat-hint>
                        <mat-hint *ngIf="!ignoreCustNbr && !request.customerNbr">Search for blank customer number</mat-hint>
                    </mat-form-field>
                    <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                        <mat-label>Sequence Number</mat-label>
                        <input matInput positiveNumber [allowDecimal]="false" max="99" min="0" type="text" maxlength="2" name="sequenceNbr" [(ngModel)]="request.sequenceNbr" [disabled]="ignoreSeqNbr">
                        <button type="button" matSuffix mat-icon-button (click)="toggleIgnoreSeqNbr()" tabindex="-1">
                            <mat-icon>{{ignoreSeqNbr ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-hint *ngIf="ignoreSeqNbr">Search for all sequence numbers</mat-hint>
                        <mat-hint *ngIf="!ignoreSeqNbr && !request.sequenceNbr">Search for blank sequence number</mat-hint>
                    </mat-form-field>
                </div>
    
                <div fxLayout="row wrap" fxLayoutAlign="center">
    
                    <button type="submit" mat-raised-button [color]="'primary'">Search</button>
                    <button type="button" mat-raised-button style="margin-left: 5px;" (click)="reset()">Reset</button>
    
                </div>

                <div fxLayout="row wrap">
                    <div fxFlex="100" fxLayoutAlign="center" class="mt-10" *ngIf="isNoResults">
                        <h3 style="color: red;">No Results Found</h3>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
</div>

<app-spec-library-table [hidden]="!specs || specs.length === 0" 
    [data]="specs" 
    (editSpec)="editSpec($event)" 
    (historySelected)="historySelected($event)"
    (copySpecSelected)="copySpec($event)"></app-spec-library-table>