<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">

        <mat-card *hasPermission="['EDIT_SPEC']" class="mb-15">
            <mat-card-header>
                <mat-card-title>Photopack</mat-card-title>
            </mat-card-header>

            <app-print-spec-header [headerForm]="photoService.headerForm" [categoryId]="categoryId"></app-print-spec-header>

        </mat-card>

        <mat-toolbar style="width: 100%;" color="primary">
            <ng-container *hasPermission="['EDIT_SPEC']; else viewOnly">
                <span>Create Photopack</span>
            </ng-container>
            <ng-template #viewOnly>
                <span>View Photopack</span>
            </ng-template>
        </mat-toolbar>

        <mat-card>
            <app-photopack [componentLists]="componentLists" [isStandalone]="true" (submit)="submitForm()"></app-photopack>
        </mat-card>
    </div>
</div>