import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { CommonService } from 'src/app/core/services/common.service';
import { SpecCollectionDirective } from '../directives/spec-collection.directive';
import { SpecBackendService } from '../services/spec-backend.service';

@Component({
  selector: 'app-edit-dialog-component',
  templateUrl: './edit-spec-dialog.component.html',
  providers: [SpecBackendService]
})
export class EditSpecDialogComponent implements OnInit, OnDestroy {
  @ViewChild(SpecCollectionDirective, {static: true}) specCollectionHost!: SpecCollectionDirective;
  readonly destroy$: Subject<void> = new Subject<void>();

  isErrorState: boolean = false;

  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<EditSpecDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {    
    this.loadSpecComponent();

    this.specService.specUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(action => this.dialogRef.close(action));

    this.specService.specCreated$
      .pipe(takeUntil(this.destroy$)) 
      .subscribe(action => this.dialogRef.close(action));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadSpecComponent(): void {
    try 
    {
      const viewContainerRef = this.specCollectionHost.viewContainerRef;
      viewContainerRef.clear();
  
      if(this.data && this.data.categoryRefId) {
        const loader = this.common.getComponentByRefId(this.data.categoryRefId);
  
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loader.editComponent);
        const componentRef = viewContainerRef.createComponent<any>(componentFactory);
  
        componentRef.instance.specId = this.data.specId;
        componentRef.instance.categoryId = this.data.categoryId;
        componentRef.instance.isCopySpec = this.data.isCopySpec;
  
        if(loader.data) {
          componentRef.instance.screenTitle = loader.data.screenTitle;
        }
      }      
    }
    catch (e) {
      console.log(e);
      this.dialogRef.disableClose = false;
      this.isErrorState = true;
    }
  }
}
