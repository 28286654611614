import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, forkJoin, EMPTY, from } from 'rxjs';
import { concatMap, filter, mergeMap, takeUntil, tap } from 'rxjs/internal/operators';

import { Spec, SpecRequestDto } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { BackerBoardService } from '../../backer-board/services/backer-board.service';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { SwatchingService } from '../services/swatching.service';

@Component({
  selector: 'app-edit-swatch',
  templateUrl: './edit-swatch.component.html',
  providers: [SwatchingService, BackerBoardService]
})
export class EditSwatchComponent implements OnInit, OnDestroy {
  @Input() specId: number = 0;
  @Input() categoryId: number = 0;
  @Input() isCopySpec: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();   
  backerBoardCatId: number = 0; 

  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    public swatchService: SwatchingService, 
    public backerService: BackerBoardService
  ) { }

  ngOnInit(): void {
    this.backerBoardCatId = this.common.getCategoryIdByRefId('backerboard'); 

    forkJoin([this.specService.getComponetListByCategoryId(this.categoryId), this.specService.getComponetListByCategoryId(this.backerBoardCatId)])
      .pipe(
        tap( ([swatchList, backerboardList]) => {
          this.swatchService.loadSwatchTypes(swatchList); 
          this.swatchService.backerBoardLists = backerboardList; 
          this.backerService.setFinisingOptions(backerboardList.finishing); 
        }), 
        concatMap(_ => this.specId > 0 ? this.specService.getSpecById(this.specId) : EMPTY), 
        tap(data => {
          if(this.isCopySpec) {
            this.swatchService.copySpec(data); 
          } else {
            this.swatchService.setForm(data); 
          }
        }), 
        mergeMap( (data, i) => from(data.specDetails)), 
        filter(detail => detail.componentRefId === 'backerBoard'), 
        mergeMap(detail => detail.childSpecId && detail.childSpecId > 0 ? this.specService.getSpecById(detail.childSpecId) : EMPTY), 
        takeUntil(this.destroy$)
      )
      .subscribe(backerboard => {
        if(backerboard) {
          this.swatchService.isPanelBackerBoard = true; 

          if(this.isCopySpec) {
            this.backerService.copySpec(backerboard); 
          } else {
            this.backerService.setForm(backerboard);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete(); 
  }

  public submitForm(): void {
    if(!this.swatchService.backerBoardCompId) {
      return; 
    }

    const backerBoardRequest: SpecRequestDto[] = [];

    if(this.swatchService.isPanelBackerBoard) {
      let request = new SpecRequestDto(this.swatchService.headerForm.value); 
      if(request.categoryId === 0) {
        request.categoryId =  this.backerBoardCatId; 
      }

      request.setBackerBoardDetails(this.backerService.detailForm.value); 
      backerBoardRequest.push(request); 
    }

    const request = new SpecRequestDto(<Spec>this.swatchService.headerForm.value);

    if(request.id === 0 && !this.isCopySpec) { request.id = this.specId; }
    if(request.categoryId === 0) { request.categoryId = this.categoryId; }

    request.setSwatching(this.swatchService.concatSwatchArr.filter(dtl => dtl.quantity && dtl.quantity > 0));

    if(this.isCopySpec) {
      this.specService.createSpecWithChildrenRequest(this.swatchService.backerBoardCompId, backerBoardRequest, request);
    } else {
      this.specService.updateSpecWithChildrenRequest(this.swatchService.backerBoardCompId, backerBoardRequest, request);
    }
  }

  public close(): void {
    this.specService.closeEditDialog();
  }
}