import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/internal/operators";

import { LoaderService } from "../services/loader.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private currentRequest = 0; 

    constructor(private loader: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.currentRequest++;
        this.loader.showLoader();

        return next.handle(req).pipe(
            catchError((e) => {
                this.currentRequest = 0; 
                this.loader.hideLoader();
                return throwError(e);
            }),
            finalize(() => {
                this.currentRequest--; 

                if(this.currentRequest <= 0) {
                    if(this.currentRequest < 0) {
                        this.currentRequest = 0; 
                    }

                    this.loader.hideLoader();
                }
            })
        );
    }
}