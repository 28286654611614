import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';

import { ConfigModel } from 'src/app/core/models/config-model';
import { UserProfiles } from '../models/user-profiles.models';

@Injectable({
  providedIn: 'root'
})
export class UserProfilesService {

  constructor(private httpClient: HttpClient) { }

  public getUserProfiles(): Observable<UserProfiles[]> {
    return this.httpClient.get<UserProfiles[]>(`${ConfigModel.API_URL}/v1/user-profiles`); 
  }

  public updateUserProfile(request: UserProfiles): Observable<UserProfiles> {
    return this.httpClient.put<UserProfiles>(`${ConfigModel.API_URL}/v1/user-profiles/update/${request.id}`, request); 
  }

  public deleteUserProfile(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${ConfigModel.API_URL}/v1/user-profiles/${id}`); 
  }
   
}
