<div fxLayout="row wrap" fxLayoutAlign="center">
  <div fxFlex="96" fxFlex.xs="100">

    <mat-card class="mt-20">

      <div fxLayout="row wrap" fxLayoutAlign="space-around" class="mb-1">
          <div class="table-container-600 mat-elevation-z2">
            <app-routing-component-table 
              *ngIf="routingToolService.routingData && routingToolService.routingData.length > 0" 
              [vendors]="routingToolService.vendors" 
              [data]="routingToolService.routingData" 
              [isDisabled]="!routingToolService.isQty || qtyService.isQtyTierError"
              (valueChange)="valueChanges($event)"></app-routing-component-table>
        </div>
      </div>

    </mat-card>

  </div>
</div>