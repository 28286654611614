import { ComponentGroup, ComponentGroupList, ComponentGroupResponse } from "src/app/core/models/common-models";
import { RoutingToolData } from "./price-routing.models";

export const RoutingObjectBuilder = {
    build(refId: string, lists: ComponentGroupResponse): RoutingToolData[] {
        switch (refId)
        {
            case 'card':
            case 'deckboard':
            case 'csf': 
                return builMultiSpecData(lists);
            case 'backerboard':
                return buildBackerBoardData(lists);
            case 'photopack': 
                return buildPhotopackData(lists);
            case 'folder': 
                return buildFolderData(lists);
            default: 
                return [];
        }
    }
}

function builMultiSpecData(group: ComponentGroupResponse): RoutingToolData[] {
    let temp: RoutingToolData[] = [
        {
            type: 'Board Weight', 
            refId: 'boardWeight', 
            value: 0, 
            group: group.boardWeight?.groupId, 
            qty: 1, 
            lists: group.boardWeight?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Stock', 
            refId: 'coverStock', 
            value: 0, 
            group: group.coverStock?.groupId, 
            qty: 1, 
            lists: group.coverStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Number of Colors Front', 
            refId: 'colorsCover', 
            value: 0, 
            group: group.colorsCover?.groupId, 
            qty: 1, 
            lists: group.colorsCover?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Film', 
            refId: 'coverFilm', 
            value: 0, 
            group: group.coverFilm?.groupId, 
            qty: 1, 
            lists: group.coverFilm?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Liner Stock', 
            refId: 'linerStock', 
            value: 0, 
            group: group.linerStock?.groupId, 
            qty: 1, 
            lists: group.linerStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Number of Colors Back', 
            refId: 'colorsLiner', 
            value: 0, 
            group: group.colorsLiner?.groupId, 
            qty: 1, 
            lists: group.colorsLiner?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Liner Film', 
            refId: 'linerFilm', 
            value: 0, 
            group: group.linerFilm?.groupId, 
            qty: 1, 
            lists: group.linerFilm?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Size Factor Grid', 
            refId: 'sizeFactorGrid', 
            value: 0, 
            group: group.sizeFactorGrid?.groupId, 
            qty: 1, 
            lists: group.sizeFactorGrid?.listValues, 
            isRequired: true, 
            pricing: new Map()
        }
    ];

    temp = temp.concat(buildFinishings(group.finishing));
    temp = temp.concat(buildAccessories(group.accessories, 10));

    return temp;
}

function buildBackerBoardData(group: ComponentGroupResponse): RoutingToolData[] {
    let temp: RoutingToolData[] = [
        {
            type: 'Board Weight', 
            refId: 'boardWeight', 
            value: 0, 
            group: group.boardWeight?.groupId,
            qty: 1, 
            lists: group.boardWeight?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Size Factor Grid', 
            refId: 'sizeFactorGrid', 
            value: 0, 
            group: group.sizeFactorGrid?.groupId,
            qty: 1, 
            lists: group.sizeFactorGrid?.listValues, 
            isRequired: true, 
            pricing: new Map()
        }
    ];

    temp = temp.concat(buildFinishings(group.finishing));
    temp = temp.concat(buildAccessories(group.accessories, 10));

    return temp;
}

function buildPhotopackData(group: ComponentGroupResponse): RoutingToolData[] {
    let temp: RoutingToolData[] = [
        {
            type: 'Cover Stock', 
            refId: 'coverStock', 
            value: 0, 
            group: group.outsideStock?.groupId,
            qty: 1, 
            lists: group.outsideStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Colors', 
            refId: 'colorsCover', 
            value: 0, 
            group: group.outsideColorsCover?.groupId,
            qty: 1, 
            lists: group.outsideColorsCover?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Film', 
            refId: 'coverFilm', 
            value: 0, 
            group: group.outsideFilm?.groupId,
            qty: 1, 
            lists: group.outsideFilm?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Page Stock', 
            refId: 'pageStock', 
            value: 0, 
            group: group.insideStock?.groupId,
            qty: 1, 
            lists: group.insideStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Page Colors', 
            refId: 'pageColors', 
            value: 0, 
            group: group.insideColorsCover?.groupId,
            qty: 1, 
            lists: group.insideColorsCover?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Page Film', 
            refId: 'pageFilm', 
            value: 0, 
            group: group.insideFilm?.groupId,
            qty: 1, 
            lists: group.insideFilm?.listValues, 
            isRequired: true,
            pricing: new Map()
        },
        {
            type: 'Size Factor Grid', 
            refId: 'sizeFactorGrid', 
            value: 0, 
            group: group.sizeFactorGrid?.groupId,
            qty: 1, 
            lists: group.sizeFactorGrid?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Type', 
            refId: 'photoPackType', 
            value: 0, 
            group: group.photopackType?.groupId,
            qty: 1, 
            lists: group.photopackType?.listValues, 
            isRequired: true, 
            changeAction: () => {
                const pageCount = temp.find(t => t.refId === 'pageCount');
                const type = temp.find(t => t.refId === 'photoPackType');

                if(pageCount) {
                    pageCount.lists = []; 
                    pageCount.value = 0;
                }

                if(type && pageCount) {
                    const typeValue = type.lists?.find(item => item.id === type.value);
                    if(typeValue?.value.toLowerCase() === 'waterfall') {
                        pageCount.lists = group.waterfallPageCount?.listValues;
                    } else {
                        pageCount.lists = group.saddlePageCount?.listValues;
                    }

                    pageCount.pricing = new Map();
                }
            },
            pricing: new Map()
        },
        {
            type: 'Page Count',
            refId: 'pageCount',
            value: 0,
            group: group.waterfallPageCount?.groupId,
            qty: 1, 
            lists: [],
            isRequired: true,
            pricing: new Map()
        },
        {
            type: 'Finishing 1', 
            refId: 'finishing1', 
            value: 0, 
            group: group.finishing1?.groupId,
            qty: 1, 
            lists: group.finishing1?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Finishing 2', 
            refId: 'finishing1', 
            value: 0, 
            group: group.finishing1?.groupId,
            qty: 1, 
            lists: group.finishing1?.listValues, 
            pricing: new Map()
        },
    ];

    return temp;
}

function buildFolderData(group: ComponentGroupResponse): RoutingToolData[] {
    let temp: RoutingToolData[] = [
        {
            type: 'Board Weight', 
            refId: 'boardWeight', 
            value: 0, 
            group: group.boardWeight?.groupId, 
            qty: 1, 
            lists: group.boardWeight?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Stock', 
            refId: 'coverStock', 
            value: 0, 
            group: group.coverStock?.groupId, 
            qty: 1, 
            lists: group.coverStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Number of Colors Front', 
            refId: 'colorsCover', 
            value: 0, 
            group: group.colorsCover?.groupId, 
            qty: 1, 
            lists: group.colorsCover?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Cover Film', 
            refId: 'coverFilm', 
            value: 0, 
            group: group.coverFilm?.groupId, 
            qty: 1, 
            lists: group.coverFilm?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Liner Stock', 
            refId: 'linerStock', 
            value: 0, 
            group: group.linerStock?.groupId, 
            qty: 1, 
            lists: group.linerStock?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Number of Colors Back', 
            refId: 'colorsLiner', 
            value: 0, 
            group: group.colorsLiner?.groupId, 
            qty: 1, 
            lists: group.colorsLiner?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Liner Film', 
            refId: 'linerFilm', 
            value: 0, 
            group: group.linerFilm?.groupId, 
            qty: 1, 
            lists: group.linerFilm?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Count', 
            refId: 'flipUpCount', 
            value: 0, 
            group: group.flipUpCount?.groupId, 
            qty: 1, 
            isQty: true,
            lists: [], 
            pricing: new Map()
        },
        {
            type: 'Flip-up Cover Stock', 
            refId: 'flipUpCoverStock', 
            value: 0, 
            group: group.flipUpCoverStock?.groupId, 
            qty: 1, 
            lists: group.flipUpCoverStock?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Cover Colors', 
            refId: 'flipUpColorsCover', 
            value: 0, 
            group: group.flipUpColorsCover?.groupId, 
            qty: 1, 
            lists: group.flipUpColorsCover?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Cover Film', 
            refId: 'flipUpCoverFilm', 
            value: 0, 
            group: group.flipUpCoverFilm?.groupId, 
            qty: 1, 
            lists: group.flipUpCoverFilm?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Liner Stock', 
            refId: 'flipUpLinerStock', 
            value: 0, 
            group: group.flipUpLinerStock?.groupId, 
            qty: 1, 
            lists: group.flipUpLinerStock?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Liner Colors', 
            refId: 'flipUpColorsLiner', 
            value: 0, 
            group: group.flipUpColorsLiner?.groupId, 
            qty: 1, 
            lists: group.flipUpColorsLiner?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Flip-up Liner Film', 
            refId: 'flipUpLinerFilm', 
            value: 0, 
            group: group.flipUpLinerFilm?.groupId, 
            qty: 1, 
            lists: group.flipUpLinerFilm?.listValues, 
            pricing: new Map()
        },
        {
            type: 'Size Factor Grid', 
            refId: 'sizeFactorGrid', 
            value: 0, 
            group: group.sizeFactorGrid?.groupId, 
            qty: 1, 
            lists: group.sizeFactorGrid?.listValues, 
            isRequired: true, 
            pricing: new Map()
        },      
    ];

    temp = temp.concat(buildFinishings(group.finishing));
    temp = temp.concat(buildAccessories(group.accessories, 10));    

    return temp;
}

function buildFinishings(group: ComponentGroup | undefined): RoutingToolData[] {
    if(!group || !group.listValues) return [];

    return group.listValues.map( (finishing, idx) => {
        return {
            type: finishing.value, 
            refId: `finishing${idx}`, 
            value: finishing.id, 
            group: group.groupId, 
            qty: 1, 
            lists: [], 
            isQty: false, 
            isCheckbox: true, 
            pricing: new Map()
        };
    });
}

function buildAccessories(group: ComponentGroup | undefined, size: number): RoutingToolData[] {
    if( !group || (!group.listValues || group.listValues.length === 0) ) return [];

    let temp: RoutingToolData[] = [];    
    for(let i=0; i<size; i++) {
        /* Add None as a selection in drop down so we can clear accessories if needed. */
        let listValues = [<ComponentGroupList>{id: 0, description: '', minQty: 0, maxQty: 0, seqNbr: 0, uom: '', value: 'None'}, ...group.listValues]; 
        temp.push({
            type: 'Accessories', 
            refId: `accessory${i}`, 
            value: 0, 
            group: group.groupId, 
            qty: 1, 
            lists: listValues, 
            isQty: true, 
            pricing: new Map()
        });
    }

    return temp;
}