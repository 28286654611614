<div fxLayout="row wrap" fxLayoutAlign="center" style="margin-top: 10px;">
    <div fxFlex="96" fxFlex.xs="100">

        <mat-card *hasPermission="['EDIT_SPEC']" class="mb-15">
            <mat-card-header>
                <mat-card-title>Print Folder</mat-card-title>
            </mat-card-header>

            <app-print-spec-header [headerForm]="folderService.headerForm" [categoryId]="categoryId"></app-print-spec-header>
        </mat-card>

        <mat-toolbar style="width: 100%;" color="primary" class="mb-10">
            <ng-container *hasPermission="['EDIT_SPEC']; else viewOnly">
                <span>Create Folder</span>
            </ng-container>
            <ng-template #viewOnly>
                <span>View Folder</span>
            </ng-template>
        </mat-toolbar>

        <mat-card>
            <app-folder [folderLists]="folderLists" [photopackLists]="photopackLists" (submitForm)="submitForm()"></app-folder>
        </mat-card>

    </div>
</div>