<mat-toolbar style="width: 100%;" color="primary">
    <span>Manage Component of {{ data.categoryName }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
    <div mat-dialog-content class="dialog-update">
        <form *hasPermission="['EDIT_ADMIN']" class="example-form" #createQfgForm="ngForm" (ngSubmit)="createQfg(createQfgForm)">
            <h2 mat-dialog-title>Create Quantity Factor Grid</h2>
            <input type="hidden" [ngModel]="data.categoryId" name="categoryId">  

            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
                <mat-form-field appearance="outline">
                    <mat-label>Enter Minimum Quantity</mat-label>
                    <input matInput type="number" autocomplete="off" [ngModel]="data.minQty" min="0" name="minQty" required ngModel>    
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Enter Maximum Quantity</mat-label>
                    <input matInput type="number" autocomplete="off" [ngModel]="data.maxQty" min="0" name="maxQty">    
                </mat-form-field>
            
                <div fxLayout="row wrap" fxLayoutAlign="center">
                    <button type="submit" mat-raised-button color="accent" [disabled]="!createQfgForm.valid">Create</button>
                </div>
            </mat-card-content>
        </form>

        <div class="example-form">
            <br>
            <mat-divider></mat-divider>
            <br>

            <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
                <h2 mat-dialog-title>Update Quantity Factor Grid</h2>
            </ng-container>
            <ng-template #viewOnly>
                <h2 mat-dialog-title>View Quantity Factor Grid</h2>
            </ng-template>
            
            <div *ngIf="this.paginator.length <= 0; else elseBlock">
                <h2 style="text-align: center;">No Available Data.</h2>
            </div>

            <ng-template #elseBlock>
                <table mat-table [dataSource]="dataSource" matSort>
        
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element;">
                            <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
                                <button type="button" class="btn-link" (click)="openUpdateQfgDialog(element)" matTooltip="Manage {{ element.id }} ">
                                    {{ element.id }}
                                </button>
                            </ng-container>
                            <ng-template #viewOnly>{{ element.id }}</ng-template>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="categoryId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.categoryId }}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="minQty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Min Quantity</th>
                        <td mat-cell *matCellDef="let element;">{{ element.minQty || '-' }}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="maxQty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Max Quantity</th>
                        <td mat-cell *matCellDef="let element;">{{ element.maxQty || '-' }}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="createdByName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                        <td mat-cell *matCellDef="let element;">{{ element.createdByName }}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="createdTimestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By Timestamp</th>
                        <td mat-cell *matCellDef="let element;">{{ (element.createdTimestamp | date) }}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="changedByName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By</th>
                        <td mat-cell *matCellDef="let element;">{{ element.changedByName || '-' }}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="changedTimestamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By Timestamp</th>
                        <td mat-cell *matCellDef="let element;">{{ (element.changedTimestamp | date) || '-' }}</td>
                    </ng-container>
    
                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element;">
                            <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
                </table>
            </ng-template>

            <mat-paginator #paginator
                    [pageSize]="15"
                    [pageSizeOptions]="[5, 15, 25, 50, 100]"
                    [showFirstLastButtons]="true">
            </mat-paginator>
        </div>

    </div>
