import { Component, Input, OnInit } from '@angular/core';

import { SpecDTO } from 'src/app/core/models/spec-models';
import { MultiSpec } from 'src/app/spec-collection/multi-spec/models/multi-spec.model';

@Component({
  selector: 'app-multi-spec-history',
  template: `
    <ng-container *ngIf="spec">
      <app-multi-spec-review [specDetail]="spec"></app-multi-spec-review>
    </ng-container>
  `
})
export class MultiSpecHistoryComponent implements OnInit {
  spec!: MultiSpec;

  private dto!: SpecDTO;

  @Input() 
  set specDto(value: SpecDTO) {
    this.dto = value;    
    this.buildSpec();
  }

  constructor() { }

  ngOnInit(): void {
  }

  private buildSpec(): void {
    this.spec = {
      boardWeight: this.findSpecValue('boardWeight'),
      coverStock: this.findSpecValue('coverStock'),
      coverColors: this.findSpecValue('colorsCover'),
      coverFilm: this.findSpecValue('coverFilm'),
      linerStock: this.findSpecValue('linerStock'),
      linerColors: this.findSpecValue('colorsLiner'),
      linerFilm: this.findSpecValue('linerFilm'),
      finishedSize: this.findSpecValue('sizeFactorGrid'),
      finishingOptions: [],
      accessories: []
    };

    this.setFinishingOptions();
    this.setAccessories();
  }

  private findSpecValue(property: string): string {
    return this.dto.specDetails.find(detail => detail.componentRefId === property)?.value || '';
  }

  private setFinishingOptions(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'finishing')
      .forEach(detail => this.spec.finishingOptions.push(detail.value || ''));
  }

  private setAccessories(): void {
    this.dto.specDetails
      .filter(detail => detail.componentRefId === 'accessories')
      .forEach(detail => {
        this.spec.accessories.push({
          accessory: detail.value || '',
          qty: detail.qty,
          comment1: detail.comments1,
          comment2: detail.comments2
        });
      });
  }
}
