import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';

import { SpecLibraryDto, SpecLibraryRequest } from '../models/spec-library.models';
import { ConfigModel } from 'src/app/core/models/config-model';

@Injectable()
export class SpecLibraryService {

  constructor(private httpClient: HttpClient) { }

  public getSpecs(request: SpecLibraryRequest): Observable<SpecLibraryDto[]> {
    let httpParams: HttpParams = new HttpParams();
    
    if(request.styleNbr) httpParams = httpParams.set('styleNbr', request.styleNbr);
    if(request.skuNbr) httpParams = httpParams.set('skuNbr', request.skuNbr);
    if(request.deliveryVehicleNbr) httpParams = httpParams.set('deliveryVehicleNbr', request.deliveryVehicleNbr);
    if(request.customerNbr !== undefined) httpParams = httpParams.set('customerNbr', request.customerNbr);
    if(request.sequenceNbr !== undefined) httpParams = httpParams.set('sequenceNbr', request.sequenceNbr || 0);
    if(request.supplierId) httpParams = httpParams.set('supplierId', request.supplierId);

    return this.httpClient.get<SpecLibraryDto[]>(`${ConfigModel.API_URL}/v1/spec/library`, {params: httpParams});
  }
}
