<div [formGroup]="group">
    <div formArrayName="accessories">

        <div *ngFor="let accessory of accessoriesArray.controls; let i = index;"
             formGroupName="{{i}}" fxLayout="row wrap" fxLayoutAlign="space-between" class="mt-10">

            <mat-form-field fxFlex="18" fxFlex.md="68" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Accessory {{ i+1 }}</mat-label>
                <mat-select formControlName="accessory">
                    <mat-option *ngFor="let acc of accList" [value]="acc.id">{{ acc.value }}</mat-option>
                </mat-select>
                <mat-error *ngIf="accessory.get('accessory')?.invalid">
                    <app-form-error [control]="accessory.get('accessory')"></app-form-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="8" fxFlex.md="28" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Quantity</mat-label>
                <input type="number" positiveNumber [allowDecimal]="false" matInput min="1" formControlName="qty" autocomplete="off">
                <mat-error *ngIf="accessory.get('qty')?.invalid">
                    <app-form-error [control]="accessory.get('qty')"></app-form-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="34" fxFlex.md="100" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Comment 1</mat-label>
                <input matInput formControlName="comment1" #comment1 autocomplete="off" maxlength="250">
                <mat-hint align="end">{{ comment1.value?.length || 0 }}/250</mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="34" fxFlex.md="100" fxFlex.lt-md="100" appearance="outline">
                <mat-label>Comment 2</mat-label>
                <input matInput formControlName="comment2" #comment2 autocomplete="off" maxlength="250">
                <mat-hint align="end">{{ comment2.value?.length || 0 }}/250</mat-hint>
            </mat-form-field>
            <div fxFlex="4" fxFlex.md="100" fxFlex.lt-md="100" fxLayoutAlign="end center" class="row-padding-bottom">
                <button fxHide.lt-lg mat-icon-button color="warn" (click)="deleteAccessory.emit(i); $event.stopPropagation();">
                    <mat-icon>close</mat-icon>
                </button>
                <button fxHide.gt-md mat-raised-button color="warn" (click)="deleteAccessory.emit(i); $event.stopPropagation();">
                    Remove Accessory {{ i+1 }}
                </button>
            </div>

        </div>

        <div class="row wrap" fxLayoutAlign="space-between">
            <button *ngIf="accessoriesArray.controls.length < maxAccessories" mat-raised-button color="accent" (click)="addAccessory.emit()">
                <mat-icon>add</mat-icon> Add Accessory
            </button>
        </div>

    </div>
</div>
