<div *hasPermission="['EDIT_SECURITY']" fxLayoutAlign="center">
    <div fxFlex="96" fxFlex.xs="100" width= "100%">
        <br>
        <mat-toolbar style="width: 100%;" color="primary">
            <span>Create Role</span>
            <span class="example-spacer"></span>
        </mat-toolbar>

        <mat-card>
            <app-create-role (getRolePermissionDto)="getRolePermissionDto()"></app-create-role>
        </mat-card>
    </div>
</div>
    

<div fxLayout="row wrap" fxLayoutAlign="center" width= "100%" class="mt-20">
    <div fxFlex="96" fxFlex.xs="100">
        <mat-toolbar style="width: 100%;" color="primary">
            <span>Role Permissions</span>
            <span class="example-spacer"></span>
        </mat-toolbar>
        <mat-card>
            <mat-tab-group color="accent" dynamicHeight mat-align-tabs="center">
                <mat-tab label="View Permissions">
                    <div class="mt-10 center-text">
                        <small>Roles that have access to <span style="color: red"><strong>VIEW</strong></span> listed screens below</small>
                    </div>
                    <div class="mt-10">
                        <table mat-table [dataSource]="dataSource" fxFlex="100" fxFlex.xs="100">
                            <ng-container matColumnDef="displayName">
                                <th mat-header-cell *matHeaderCellDef>Display Role Name</th>
                                <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createUser">
                                <th mat-header-cell *matHeaderCellDef>Created By</th>
                                <td mat-cell *matCellDef="let element">{{ element.createUser }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createdTimestamp">
                                <th mat-header-cell *matHeaderCellDef>Created Timestamp</th>
                                <td mat-cell *matCellDef="let element">{{ element.createdTimestamp | date:'medium' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="changeUser">
                                <th mat-header-cell *matHeaderCellDef>Changed By</th>
                                <td mat-cell *matCellDef="let element">{{ element.changeUser }}</td>
                            </ng-container>
                            <ng-container matColumnDef="changedTimestamp">
                                <th mat-header-cell *matHeaderCellDef> Changed Timestamp </th>
                                <td mat-cell *matCellDef="let element">{{ element.changedTimestamp | date:'medium' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="viewVendorProfile">
                                <th mat-header-cell *matHeaderCellDef>Vendor Profile</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewVendorProfile == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewVendorBid">
                                <th mat-header-cell *matHeaderCellDef>Vendor Bid</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewVendorBid == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewSpecLibraryPricing">
                                <th mat-header-cell *matHeaderCellDef>Spec Library Pricing</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewSpecLibraryPricing == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewManualSpecPricing">
                                <th mat-header-cell *matHeaderCellDef>Manual Spec Pricing</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewManualSpecPricing == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>                           
                            <ng-container matColumnDef="viewSpec">
                                <th mat-header-cell *matHeaderCellDef>Spec Tools</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewSpec == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewSecurity">
                                <th mat-header-cell *matHeaderCellDef>Security</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewSecurity == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewUserProfile">
                                <th mat-header-cell *matHeaderCellDef>User Profile</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewUserProfile == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="viewAdmin">
                                <th mat-header-cell *matHeaderCellDef>Admin</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.viewAdmin == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>

                            
                            <ng-container matColumnDef="editButtons">
                                <th mat-header-cell *matHeaderCellDef style="min-width: 105px;">Edit Role</th>
                                <td mat-cell *matCellDef="let element">
                                    <button type="button" mat-icon-button matTooltip="Edit" (click)="editRoleInfoDialog(element)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                    <button type="button" mat-icon-button matTooltip="Delete" (click)="openDeleteRoleDialog(element.id, element.displayName)">
                                        <mat-icon color="warn">delete_forever</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedViewColumns; sticky: true" class="mat-table-header-primary"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedViewColumns"></tr>
                        </table>
                    </div>
                </mat-tab>
                <mat-tab label="Edit Permissions">
                    <div class="mt-10 center-text">
                        <small>Roles that have access to <span style="color: red"><strong>EDIT</strong></span> listed screens below</small>
                    </div>
                    <div class="mt-10">
                        <table mat-table [dataSource]="dataSource" fxFlex="100" fxFlex.xs="100">
                            <ng-container matColumnDef="displayName">
                                <th mat-header-cell *matHeaderCellDef>Display Role Name</th>
                                <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createUser">
                                <th mat-header-cell *matHeaderCellDef>Created By</th>
                                <td mat-cell *matCellDef="let element">{{ element.createUser }}</td>
                            </ng-container>
                            <ng-container matColumnDef="createdTimestamp">
                                <th mat-header-cell *matHeaderCellDef>Created Timestamp</th>
                                <td mat-cell *matCellDef="let element">{{ element.createdTimestamp | date:'medium' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="changeUser">
                                <th mat-header-cell *matHeaderCellDef>Changed By</th>
                                <td mat-cell *matCellDef="let element">{{ element.changeUser }}</td>
                            </ng-container>
                            <ng-container matColumnDef="changedTimestamp">
                                <th mat-header-cell *matHeaderCellDef> Changed Timestamp </th>
                                <td mat-cell *matCellDef="let element">{{ element.changedTimestamp | date:'medium' }}</td>
                            </ng-container>
                            <ng-container matColumnDef="editVendorProfile">
                                <th mat-header-cell *matHeaderCellDef>Vendor Profile</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editVendorProfile == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editVendorBid">
                                <th mat-header-cell *matHeaderCellDef>Vendor Bid</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editVendorBid == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editSpecLibraryPricing">
                                <th mat-header-cell *matHeaderCellDef>Spec Library Pricing</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editSpecLibraryPricing == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editManualSpecPricing">
                                <th mat-header-cell *matHeaderCellDef>Manual Spec Pricing</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editManualSpecPricing == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editSpec">
                                <th mat-header-cell *matHeaderCellDef>Spec Tools</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editSpec == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editSecurity">
                                <th mat-header-cell *matHeaderCellDef>Security</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editSecurity == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editUserProfile">
                                <th mat-header-cell *matHeaderCellDef>User Profile</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editUserProfile == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editAdmin">
                                <th mat-header-cell *matHeaderCellDef>Admin</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editAdmin == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editMasterBid">
                                <th mat-header-cell *matHeaderCellDef>Master Bid</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [checked]="element.editMasterBid == true" [disabled]="true"></mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="editButtons">
                                <th mat-header-cell *matHeaderCellDef style="min-width: 105px;">Edit Role</th>
                                <td mat-cell *matCellDef="let element">
                                    <button type="button" mat-icon-button matTooltip="Edit" (click)="editRoleInfoDialog(element)">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                    <button type="button" mat-icon-button matTooltip="Delete" (click)="openDeleteRoleDialog(element.id, element.displayName)">
                                        <mat-icon color="warn">delete_forever</mat-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedEditColumns; sticky: true" class="mat-table-header-primary"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedEditColumns"></tr>
                        </table>
                    </div>
                </mat-tab>
            </mat-tab-group>

            
        </mat-card>
    </div>
</div>