<mat-toolbar style="width: 100%;" color="primary">
    <span>Component List Table</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>

    <div mat-dialog-content class="example-form"> 
            <mat-card-content fxLayout="column" fxLayoutAlign="center" fxFlex="100" fxFlex.xs="100">
             
                <mat-form-field fxLayoutAlign="center" appearance="standard">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filter Category" #input>
                </mat-form-field>
                
                <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
                        <td mat-cell *matCellDef="let element;">{{ element.value }}</td>
                    </ng-container>

                    <ng-container matColumnDef="componentId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Component ID</th>
                        <td mat-cell *matCellDef="let element;">{{ element.componentId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="componentName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Component ID Name</th>
                        <td mat-cell *matCellDef="let element;">{{ element.componentName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                        <td mat-cell *matCellDef="let element;">{{ element.description || '-' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="uom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit of Measurement</th>
                        <td mat-cell *matCellDef="let element;">{{ element.uom || '-' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="minQty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Min Quantity</th>
                        <td mat-cell *matCellDef="let element;">{{ element.minQty || '-' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="maxQty">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Max Quantity</th>
                        <td mat-cell *matCellDef="let element;">{{ element.maxQty || '-' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="seqNbr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence Nbr</th>
                        <td mat-cell *matCellDef="let element;">{{ element.seqNbr }}</td>
                    </ng-container>

                    <ng-container matColumnDef="canBid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Can Bid</th>
                        <td mat-cell *matCellDef="let element;">
                            <mat-checkbox [checked]="element.canBid == true" [disabled]="true"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                        <td mat-cell *matCellDef="let element;">
                            <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                        </td>
                    </ng-container>
        

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        
                </table>
        
                <mat-paginator #paginator
                                   [pageSize]="20"
                                   [pageSizeOptions]="[10, 20, 30, 50, 100, 250, 350]"
                                   [showFirstLastButtons]="true">
                </mat-paginator>

            </mat-card-content>
    </div>
