<table mat-table [dataSource]="data" style="max-width: 100%;">
    <ng-container matColumnDef="type" sticky>
        <th mat-header-cell *matHeaderCellDef>Specification Type</th>
        <td mat-cell *matCellDef="let element;" [class.required-field]="element.isRequired">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="spec" sticky>
        <th mat-header-cell *matHeaderCellDef>Specification</th>
        <td mat-cell *matCellDef="let element;">
            <div *ngIf="element.isCheckbox; else specSelect;" class="pr-4 center-text">
                <mat-checkbox color="primary" (change)="valueChanges(element, $event.checked)" [disabled]="isDisabled"></mat-checkbox>
            </div>
            <ng-template #specSelect>                
                <mat-form-field *ngIf="element.lists && element.lists.length > 0" style="width: 130px;" [floatLabel]="'never'">
                    <mat-label>{{element.type}}</mat-label>
                    <mat-select [(ngModel)]="element.value" (selectionChange)="valueChanges(element)" [required]="element.isRequired" [disabled]="isDisabled">
                        <mat-option *ngFor="let option of element.lists" [value]="option.id">{{ option.value }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="qty" sticky>
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element;">
            <ng-container *ngIf="element.isQty; else noQty">
                <mat-form-field style="width: 60px;">
                    <input type="number" matInput min="1" positiveNumber [(ngModel)]="element.qty" autocomplete="off" (blur)="valueChanges(element)" [disabled]="isDisabled">
                </mat-form-field>
            </ng-container>
            <ng-template #noQty>N/A</ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="unitPrice" sticky>
        <td mat-footer-cell *matFooterCellDef>Unit Price</td>
    </ng-container>

    <ng-container matColumnDef="totalPrice" sticky>
        <td mat-footer-cell *matFooterCellDef>Total Price</td>
    </ng-container>

    <ng-container matColumnDef="emptyFooter" sticky>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container *ngFor="let vendor of vendors; let i = index;">
        <ng-container matColumnDef="{{ i.toString() }}">
            <th mat-header-cell *matHeaderCellDef [class.min-price-cell]="vendor.unitPrice && !vendor.diffUnitPrice">{{ vendorSize <= 1 ? 'Component Pricing' : vendor.vendorName }}</th>
            <td mat-cell *matCellDef="let element;" [style.color]="element.pricing?.get(vendor.vendorId)?.isMissingBid || element.pricing?.get(vendor.vendorId)?.isNoBid ? 'red' : ''">
                {{ (element.pricing?.get(vendor.vendorId)?.price | currency) || element.pricing?.get(vendor.vendorId)?.priceStatus }}
            </td>
        </ng-container>

        <ng-container matColumnDef="{{ ('ft1-' + i.toString()) }}">
            <td mat-footer-cell *matFooterCellDef [class.min-price-cell]="vendor.unitPrice && !vendor.diffUnitPrice">{{ (vendor.unitPrice | currency) || vendor.unitPriceText}}</td>
        </ng-container>

        <ng-container matColumnDef="{{ ('ft2-' + i.toString()) }}">
            <td mat-footer-cell *matFooterCellDef [class.min-price-cell]="vendor.totalPrice && !vendor.diffTotalPrice">{{ (vendor.totalPrice | currency) || '' }}</td>
        </ng-container>
    </ng-container> 

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true;" class="mat-table-header-primary"></tr>

    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

    <tr mat-footer-row *matFooterRowDef="footer1Columns; sticky: true;" class="mat-table-header-primary"></tr>
    <tr mat-footer-row *matFooterRowDef="footer2Columns; sticky: true;" class="mat-table-header-primary"></tr>
</table>