import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  template: `
    <mat-dialog-content> 
        <p>{{ msg }}</p>
    </mat-dialog-content>
  `
})
export class ErrorDialogComponent implements OnInit {
  msg: string = 'An error occurred. Please try request again. If problem persist, please contact support.'; 
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data.message) {
      this.msg = this.data.message;
    }
  }

}
