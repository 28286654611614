import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, forkJoin, EMPTY, from } from 'rxjs';
import { concatMap, filter, mergeMap, takeUntil, tap, toArray } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { Spec, SpecRequestDto } from 'src/app/core/models/spec-models';
import { FolderFormValue } from '../../core/models/print-folder.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { PhotoPackService } from '../../photopack/services/photo-pack.service';
import { FolderService } from '../services/folder.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PhotopackFormValue } from '../../core/models/photopack.model';

@Component({
  selector: 'app-edit-folder',
  templateUrl: './edit-folder.component.html',
  providers: [FolderService, PhotoPackService]
})
export class EditFolderComponent implements OnInit, OnDestroy {
  @Input() specId: number = 0;
  @Input() categoryId: number = 0;
  @Input() isCopySpec: boolean = false;

  folderLists: ComponentLists = {} as ComponentLists;
  photopackLists: ComponentLists = {} as ComponentLists;
  photopackCatId: number = 0;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    private photoService: PhotoPackService, 
    public folderService: FolderService
  ) { }

  ngOnInit(): void {
    this.photopackCatId = this.common.getCategoryIdByRefId('photopack');

    forkJoin([this.specService.getComponetListByCategoryId(this.categoryId), this.specService.getComponetListByCategoryId(this.photopackCatId)])            
      .pipe(        
        tap( ([folderList, photopackList]) => {
          this.folderLists = folderList;
          this.folderService.setCountListId(this.folderLists.flipUpCount);
          this.folderService.setFinishingOptions(this.folderLists.finishing);
          this.photopackLists = photopackList;
        }),
        concatMap(_ => this.specId > 0 ? this.specService.getSpecById(this.specId) : EMPTY), //Get folder spec
        tap(data => {
          if(this.isCopySpec) {
            this.folderService.copySpec(data);
          } else {
            this.folderService.setForm(data);
          }
        }), //set folder form        
        mergeMap( (data, i) => from(data.specDetails)), //collect spec details from spec
        filter(detail => detail.componentRefId === 'photoPack'), //filter everything except photopacks
        mergeMap(detail => detail.childSpecId && detail?.childSpecId > 0 ? this.specService.getSpecById(detail.childSpecId) : EMPTY), //fetch each photopack
        takeUntil(this.destroy$),
        toArray() //convert photopacks to an array
      )
      .subscribe(photopacks => { //handle photopacks
        if(photopacks && photopacks.length > 0) {
          this.folderService.isPhotopack = true;

          if(this.isCopySpec) {
            this.photoService.copySpec(photopacks[0]);
          } else {
            this.photoService.setForm(photopacks[0]);
          }

          for(let i=1; i<photopacks.length; i++) {
            this.photoService.addPhotopackForm(photopacks[i].specDetails);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(): void {
    if(!this.folderLists.photoPack) {
      return;
    }

    const photopackRequests: SpecRequestDto[] = this.getPhotopackRequests();

    // const request = this.getFolderRequest();
    const request = new SpecRequestDto(<Spec>this.folderService.headerForm.value);
    const photopackId = this.folderLists.photoPack[0].id;

    if(request.categoryId === 0) { request.categoryId = this.categoryId; }
    if(request.id === 0 && !this.isCopySpec) { request.id = this.specId; }

    request.setFolderDetails(<FolderFormValue>this.folderService.detailForm.value);

    if(this.isCopySpec) {
      request.id = 0;
      this.specService.createSpecWithChildrenRequest(photopackId, photopackRequests, request);  
    } else {
      this.specService.updateSpecWithChildrenRequest(photopackId, photopackRequests, request);
    }
  }

  close(): void {
    this.specService.closeEditDialog();
  }

  private getPhotopackRequests(): SpecRequestDto[] {
    const photopackRequests: SpecRequestDto[] = [];

    if(this.folderService.isPhotopack) {
      const photopack = <PhotopackFormValue>this.photoService.detailForm.value;

      photopack.photopacks.forEach(pack => {
        let request = new SpecRequestDto(this.folderService.headerForm.value);
        if(request.categoryId === 0) {
          request.categoryId = this.photopackCatId;
        }

        request.setPhotopackDetails(this.photoService.detailForm.value);
        request.setPhotopack(pack);
        photopackRequests.push(request);
      });
    }

    return photopackRequests; 
  }

}
