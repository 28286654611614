import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <mat-card>
      <mat-card-header>
          <mat-card-title>
              Please wait...
          </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
  ]
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
