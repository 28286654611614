import { Injectable, OnDestroy } from '@angular/core';

import { Subject, Observable, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { QuantityFactorGrid, RoutingToolData, VendorPricingHeader } from '../models/price-routing.models';
import { RoutingObjectBuilder } from '../models/routing-object-builder.model';
import { VendorPriceBuilder } from '../models/vendor-pricing-builder.model';
import { QuantityService } from './quantity.service';
import { BackendService } from 'src/app/core/services/back-end.service';
import { Category } from 'src/app/admin/models/administration-models';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';


@Injectable()
export class RoutingToolService implements OnDestroy {
  private _vendorSubject: Subject<VendorPricingHeader[]> = new Subject();
  private readonly destroy$: Subject<void> = new Subject();

  private category: Category = {} as Category;  
  public vendors: VendorPricingHeader[] = [];
  public routingData: RoutingToolData[] = [];
  public qtyFactor: QuantityFactorGrid = {} as QuantityFactorGrid;

  public isQty: boolean = false; 

  readonly vendors$: Observable<VendorPricingHeader[]> = this._vendorSubject.asObservable();

  constructor(
    private backend: BackendService,
    private snackService: SnackBarService,
    private quantityService: QuantityService
  ) { 
    this.quantityService.quantity$
      .pipe(takeUntil(this.destroy$))
      .subscribe(quantity => this.quantityChange(quantity));
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete();
  }

  public setCategoryRouting(category: Category | undefined): void {
    this.routingData = [];

    if(!category) {
      this.category = {} as Category; 
      return;
    }

    this.category = category;
    const componentList$ = this.backend.getComponentGroupListByCategoryId(this.category.id);
    const vendorPricingList$ = this.backend.getVendorPricingHeaderByCategoryId(this.category.id);

    forkJoin([componentList$, vendorPricingList$])
      .subscribe(([componentLists, vendorPricing]) => {        
        this.routingData = RoutingObjectBuilder.build(category.referenceId, componentLists);
        this.qtyFactor = VendorPriceBuilder.buildQuantityFactor(componentLists.quantityFactorGrid);
        this.vendors = vendorPricing;
        this.routingData.forEach(data => data.pricing = new Map(this.vendors.map(vendor => [vendor.vendorId, {price: undefined, isMissingBid: false, isNoBid: false, priceStatus: '-'}])));
        this.qtyFactor.pricing = new Map(this.vendors.map(vendor => [vendor.vendorId, undefined]));
        this._vendorSubject.next(this.vendors);
      });
  }

  public valueChanges(data: RoutingToolData, isSetPrice: boolean = true): void {
    this.vendors.forEach(vendor => {
      data.pricing?.set(vendor.vendorId, {price: undefined, isMissingBid: false, isNoBid: false, priceStatus: '-'});
      if(isSetPrice) {
          vendor.pricing?.filter(price => price.componentListId === data.value)
            .forEach(price => data.pricing?.set(vendor.vendorId, {
              price: (data.isQty && price.price ? (price.price * data.qty) : price.price), 
              isMissingBid: price.missingBid,
              isNoBid: price.noBid,
              priceStatus: price.noBid ? 'No Bid' : price.missingBid ? 'Missing Bid' : '-'
            }));
      }
    });

    if(data.changeAction) {
      data.changeAction.apply(undefined);
    }

    this.calculateVendorPricing();
  }

  public quantityChange(qty: number | null): void {
    this.qtyFactor.quantity = qty; 
    this.isQty = qty && qty > 0 ? true : false;

    this.setPriceTier();
    this.calculateVendorPricing();
  }

  private setPriceTier(): void {
    let tier = this.qtyFactor.lists?.find(tier => {
      if(!this.qtyFactor.quantity) {
        return false
      }

      if(tier.minQty && !tier.maxQty && (this.qtyFactor.quantity >= tier.minQty)) {
        return true;
      }

      if(tier.minQty && tier.maxQty &&  (this.qtyFactor.quantity >= tier.minQty && this.qtyFactor.quantity <= tier.maxQty)) {
        return true;
      }

      return false;
    });
    
    // this.quantityService.setTierByMinMax(tier?.minQty || null, tier?.maxQty || null);

    this.qtyFactor.value = tier?.id || 0;

    if(this.isQty && (!tier || tier.id === 0) ) {
      this.snackService.error('No quantity factor can be found for quantity ' + this.qtyFactor.quantity + '. Pricing cannot be computed without a quantity factor.'); 
      this.quantityService.isQtyTierError = true;
    } else {
      this.quantityService.isQtyTierError = false;
    }

    this.vendors.forEach(vendor => {
      this.qtyFactor?.pricing?.set(vendor.vendorId, undefined);
      if(this.qtyFactor.quantity && this.qtyFactor.quantity > 0) {
        vendor.pricing?.filter(price => price.componentListId === tier?.id)
          .forEach(price => this.qtyFactor.pricing?.set(vendor.vendorId, price.price));
      }
    });
  }

  private calculateVendorPricing(): void {
    VendorPriceBuilder.calculate(this.category.referenceId, this.vendors, this.routingData, this.qtyFactor);
    this._vendorSubject.next(this.vendors);
  }
}