import { Component, Input, OnInit } from '@angular/core';

import { EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { Spec, SpecRequestDto } from 'src/app/core/models/spec-models';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { MultiSpecFormValue } from '../models/multi-spec.model';
import { MultiSpecService } from '../services/multi-spec.service';

@Component({
  selector: 'app-edit-multi-spec',
  templateUrl: './edit-multi-spec.component.html',
  providers: [MultiSpecService]
})
export class EditMultiSpecComponent implements OnInit {
  @Input() specId: number = 0;
  @Input() categoryId: number = 0; 
  @Input() screenTitle: string = 'Spec';
  @Input() isCopySpec: boolean = false;

  componentLists: ComponentLists = {} as ComponentLists;

  constructor(
    private specService: SpecBackendService,
    public multiService: MultiSpecService
  ) { }

  ngOnInit(): void {
    this.specService.getComponetListByCategoryId(this.categoryId)
      .pipe(
        tap(lists => {
          this.componentLists = lists;
          this.multiService.setFinisingOptions(this.componentLists.finishing);
        }),
        concatMap(_ => this.specId > 0 ? this.specService.getSpecById(this.specId) : EMPTY)
      )
      .subscribe(data => {
        if(data) {
          if(this.isCopySpec) {
            this.multiService.copySpec(data);
          } else {
            this.multiService.setForm(data);
          }
        }
      });
  }

  submitForm(): void {
    const request = new SpecRequestDto(<Spec>this.multiService.headerForm.value);

    if(request.id === 0 && !this.isCopySpec) { request.id = this.specId; }
    if(request.categoryId === 0) { request.categoryId = this.categoryId; }

    request.setMultiSpecDetails(<MultiSpecFormValue>this.multiService.detailForm.value);

    if(this.isCopySpec) {
      // this.copySpec();
      this.specService.createSpec(request); 
    } else {
      // this.updateSpec();
      this.specService.updateSpec(request);
    }
  }

  close(): void {
    this.specService.closeEditDialog();
  }

  // private copySpec(): void {
  //   const request = new SpecRequestDto(<Spec>this.multiService.headerForm.value);

  //   if(request.id === 0 || request.categoryId === 0) {
  //     request.categoryId = this.categoryId; 
  //   }

  //   request.setMultiSpecDetails(<MultiSpecFormValue>this.multiService.detailForm.value);
  //   this.specService.createSpec(request);
  // }

  // private updateSpec(): void {
  //   const request = new SpecRequestDto(<Spec>this.multiService.headerForm.value);

  //   if(request.id === 0 || request.categoryId === 0) {
  //     request.id = this.specId; 
  //     request.categoryId = this.categoryId; 
  //   }

  //   request.setMultiSpecDetails(<MultiSpecFormValue>this.multiService.detailForm.value);

  //   this.specService.updateSpec(request);
  // }
}
