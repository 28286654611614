import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/internal/operators';

import { GridApi } from 'ag-grid-community';
import { isEqual } from 'lodash';
import { ConfigModel } from 'src/app/core/models/config-model';
import { CommonService } from 'src/app/core/services/common.service';
import { BackendService } from 'src/app/core/services/back-end.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ReportQuantity, ReportRequest, ReportSpec } from '../models/spec-library-pricing-report.models';
import { VendorDto } from 'src/app/core/models/vendor-models';

@Injectable()
export class SpecLibraryPricingReportService {
  private _specGridApi!: GridApi<any>;
  private _quantityGridApi!: GridApi<any>;
  private _canSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _excludeVendors: BehaviorSubject<VendorDto[]> = new BehaviorSubject<VendorDto[]>([]);

  public readonly canSubmit$ = this._canSubmit.asObservable();
  public readonly excludeVendors$ = this._excludeVendors.asObservable();

  constructor(
    private snackService: SnackBarService,
    private http: HttpClient,
    private common: CommonService, 
    private backend: BackendService
  ) { 
    this.backend.getVendorDtoList(true).subscribe(vendors => {
      this.vendors = vendors;
      this.vendors.forEach(v => v.selected = false);
    });
  }

  vendors: VendorDto[] = [];
  specs: ReportSpec[] = []; 

  topRowData: ReportSpec[] = [
    {
      skuNumber: '',
      styleNumber: '',
      deliveryVehicle: '',
      customerNumber: '', 
      sequence: undefined
    }
  ];

  quantities: ReportQuantity[] = [
    {quantity: 5},
    {quantity: 24},
    {quantity: 25},
    {quantity: 50},
    {quantity: 75},
    {quantity: 100},
    {quantity: 250},
    {quantity: 500},
    {quantity: 1000},
  ];

  topRowQuantity: ReportQuantity[] = [
    {quantity: undefined}
  ];
  
  set specGridApi(grid: GridApi<any>) {
    this._specGridApi = grid;
  }

  set quantityGridApi(grid: GridApi<any>) {
    this._quantityGridApi = grid;
  }

  public generateReport(): void {
    let request: ReportRequest = {
      requests: this.specs,
      quantities: this.quantities.map(q => q.quantity!),
      excludedVendors: this.vendors.filter(v => v.selected).map(v => v.id)
    }; 

    this.http.post(`${ConfigModel.API_URL}/v1/spec-library/pricing/pricing-report`, request, {observe: 'response', responseType: 'text'})
      .pipe(
        tap((response: HttpResponse<any>) => {
          if(response && response.body) {
            let blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'}); 
            this.common.saveFileAs(blob, `spec_library_pricing_report_${new Date().getTime()}.csv`); 
            this.snackService.success('Report generated successfully.');
          }  
        }), 
        catchError((err) => {
          this.snackService.error('Error generating report.');
          throw err;
        })
      ).subscribe();
  }

  public resetData(): void {
    this.specs = [];
    this.topRowData[0] = { skuNumber: '', styleNumber: '', deliveryVehicle: '', customerNumber: '', sequence: undefined };
    this.topRowQuantity[0] = { quantity: undefined };
    this.vendors.forEach(v => v.selected = false);

    this.refreshSpecGrid(true);
    this.refreshQuantityGrid(true);
    this.excludeVendorChange();
  }

  public deleteSpecByIndex(idx: number): void {
    if(idx >=0 && idx < this.specs.length) {
      this.specs.splice(idx, 1); 
      this.refreshSpecGrid(); 
    }
  }

  public resetAddSpecRow(): void {
    this.topRowData[0] = { skuNumber: '', styleNumber: '', deliveryVehicle: '', customerNumber: '', sequence: undefined };
    this._specGridApi.setPinnedTopRowData(this.topRowData);
  }

  public addSpec(): void {
    if(!this.topRowData || !this.topRowData[0] || !this.topRowData[0].styleNumber || !this.topRowData[0].deliveryVehicle) {
      this.snackService.warn('Style Number and Delivery Vehicle are required fields.');
      return; 
    }

    let alreadyExist = this.specs.some(spec => {
      let addRow = this.topRowData[0]; 
      return isEqual(addRow, spec);
    }); 

    if(alreadyExist) {
      this.snackService.warn('Cannot add Spec. Spec with this configuration already exists.'); 
      return;
    }

    this.specs.push(this.topRowData[0]);
    this.topRowData[0] = { skuNumber: '', styleNumber: '', deliveryVehicle: '', customerNumber: '', sequence: undefined };

    this.refreshSpecGrid(true);
  }

  public deleteQuantityByIndex(idx: number): void {
    if(idx >=0 && idx < this.quantities.length) {
      this.quantities.splice(idx, 1); 
      this.refreshQuantityGrid();
    }
  }

  public addQuantity(): void {
    if(!this.topRowQuantity || !this.topRowQuantity[0] || !this.topRowQuantity[0].quantity) {
      this.snackService.warn('Quantity is a required field.');
      return; 
    }

    if(this.quantities.some(q => q.quantity === this.topRowQuantity[0].quantity)) {
      this.snackService.warn(`Quantity of ${ this.topRowQuantity[0].quantity } already exists.`);
      return;
    }

    this.quantities.push(this.topRowQuantity[0]);
    this.quantities.sort((a, b) => a.quantity! - b.quantity!);

    this.topRowQuantity[0] = { quantity: undefined };

    this.refreshQuantityGrid(true);
  }; 

  public excludeVendorChange(): void {
    this._excludeVendors.next(this.vendors.filter(v => v.selected));
  }

  public removeExcludedVendor(id: number): void {
    this.vendors.filter(v => v.id === id).forEach(v => v.selected = false);
    this.excludeVendorChange();
  }

  private refreshSpecGrid(isRefreshTopRow: boolean = false): void {
    if(isRefreshTopRow) {
      this._specGridApi.setPinnedTopRowData(this.topRowData);
    }
    
    this._specGridApi.setRowData(this.specs);
    this.updateCanSubmit();
  }

  private refreshQuantityGrid(isRefreshTopRow: boolean = false): void {
    if(isRefreshTopRow) {
      this._quantityGridApi.setPinnedTopRowData(this.topRowQuantity);
    }
    
    this._quantityGridApi.setRowData(this.quantities);
    this.updateCanSubmit();
  }

  private updateCanSubmit(): void {
    this._canSubmit.next(this.specs.length > 0 && this.quantities.length > 0);
  }
}
