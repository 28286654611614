import { AfterViewInit, Inject, ViewChild, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComponentDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';

@Component({
  selector: 'app-component-table-dialog',
  templateUrl: './component-table-dialog.component.html',
  styleUrls: ['./component-table-dialog.component.scss'],
  providers: [AdministrationService]
})
export class ComponentTableDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<ComponentDTO>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'categoryId', 'categoryName', 'groupId', 'active'];
  displayedColumns = this.dataColumns;

  constructor(private adminService: AdministrationService,
              public dialogRef: MatDialogRef<ComponentTableDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ComponentDTO
  ) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.adminService.getComponents().subscribe((response: any) => { 
      this.dataSource.data = response
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
