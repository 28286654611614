<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Base Components</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Stock:&nbsp;</span>
        {{ specDetail?.coverStock }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Colors:&nbsp;</span>
        {{ specDetail?.coverColors }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Cover Film:&nbsp;</span>
        {{ specDetail?.coverFilm }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Pages Stock:&nbsp;</span>
        {{ specDetail?.pageStock }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Pages Colors:&nbsp;</span>
        {{ specDetail?.pageColors }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Pages Film:&nbsp;</span>
        {{ specDetail?.pageFilm }}
    </p>
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Finished Size</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Size Factor:&nbsp;</span>
        {{ specDetail?.finishedSize }}
    </p>
</div>

<mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
    <h2 style="margin: 5px 0 5px;">Photopack(s)</h2>
</mat-toolbar>

<div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Photopack Type:&nbsp;</span>
        {{ specDetail?.type }}
    </p>
</div>

<div *ngFor="let pack of specDetail?.photopacks; let i = index" fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
<!-- <div fxLayout="row wrap" fxLayoutAlign="start"> -->
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Finishing 1:&nbsp;</span>{{ pack.finishing1 }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Finishing 2:&nbsp;</span>{{ pack.finishing2 }}
    </p>
    <p fxFlex="24" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100" class="detail-text">
        <span class="label-font">Page Count:&nbsp;</span>{{ pack.pageCount }}
    </p>
</div>