import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/internal/Observable";

import { VendorBid, BidComponent } from "../models/vendor-bid-models";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ConfigModel } from "src/app/core/models/config-model";
import { CommonService } from "src/app/core/services/common.service";

/*
    This service contains the common methods that are used by all vendor related components.
*/
@Injectable()
export class VendorBidService {
  
  constructor(
    private httpClient: HttpClient,
    private common: CommonService, 
    private snackService: SnackBarService
  ) { }

  private vendorBid$ : VendorBid = {} ; 

  public getVendorBidInfo(categoryId: number, vendorId: number): Observable<VendorBid>  {
    return this.httpClient.get<VendorBid>(`${ConfigModel.API_URL}/v1/bid/vendors/${vendorId}/categories/${categoryId}`)
  }

  public downloadVendorBidInfoCSV(categoryId: number, vendorId: number): void {
    this.httpClient.get(`${ConfigModel.API_URL}/v1/bid/vendors/${vendorId}/categories/${categoryId}/csv`, {observe: 'response', responseType: 'text'})
      .subscribe((response: HttpResponse<string>) => {
        if(response && response.body) {
          let blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'}); 
          this.common.saveFileAs(blob, `vendor_bid_${new Date().getTime()}.csv`); 
        } else { }
      }); 
  }

  public updateVendorBid(vendorId: number, components: BidComponent[]): Observable<VendorBid> {
    return this.httpClient.put<VendorBid>(`${ConfigModel.API_URL}/v1/bid/vendors/${vendorId}`, components);
  }
}