import { Component, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Title } from '@angular/platform-browser';

import { tap } from 'rxjs/internal/operators';

import { Category } from '../admin/models/administration-models';
import { AdministrationService } from '../admin/services/administration.service';
import { CommonService } from '../core/services/common.service';
import { UserService } from '../core/services/user.service';
import { SpecCollectionDirective } from '../spec-collection/core/directives/spec-collection.directive';
import { QuantityService } from './services/quantity.service';

@Component({
  selector: 'app-manual-spec-price',
  template: `
    <div fxLayout="row wrap" fxLayoutAlign="center">
      <div fxFlex="96" fxFlex.xs="100">
          <mat-card>

              <div [fxFlex]="categorySelected ? 50 : 100" [fxLayoutAlign]="categorySelected ? 'end' : 'center'" [class.mr-1]="categorySelected">
                  <mat-form-field>
                      <mat-label>Category Type</mat-label>
                      <mat-select (selectionChange)="categoryChange($event)">
                          <mat-option [value]="0">None</mat-option>
                          <mat-option *ngFor="let option of categoryList$ | async" [value]="option.id">
                              {{ option.name + ' Pricing' }}
                          </mat-option>                      
                      </mat-select>
                  </mat-form-field>
              </div>

              <div fxFlex="50" fxLayoutAlign="start" class="ml-1" *ngIf="categorySelected">
                  <mat-form-field [class.mat-form-field-invalid]="quantityService.isQtyTierError">
                      <mat-label>Quantity</mat-label>
                      <input type="number" matInput positiveNumber [allowDecimal]="false" min="0" [(ngModel)]="quantity" autocomplete="off" (blur)="quantityChange()">
                      <mat-hint *ngIf="quantityService.isQtyTierError" class="mat-error" style="color: red;">Cannot determine quantity factor</mat-hint>
                  </mat-form-field>
              </div>

          </mat-card>
      </div>
    </div>

    <ng-template specCollectionHost></ng-template>
  `,
  providers: [QuantityService, AdministrationService]
})
export class ManualSpecPricingComponent {
  @ViewChild(SpecCollectionDirective, {static: true}) host!: SpecCollectionDirective;
  categoryList: Category[] = [];

  public quantity!: number | null;
  public categorySelected: boolean = false;

  readonly userSupplierId$ = this.userService.getUser().supplierId;
  readonly categoryList$ = this.adminService.getCategoryList(this.userSupplierId$).pipe(tap(lists => this.categoryList = lists));

  readonly isVendorUser: boolean = this.userService.isVendor;

  constructor(
    private common: CommonService,
    public quantityService: QuantityService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UserService,
    private title: Title,
    private adminService: AdministrationService
  ) { 
    this.title.setTitle('Manual Spec Pricing | SAVE'); 
  }

  quantityChange(): void {
    this.quantityService.setQuantity(this.quantity);
  }

  categoryChange(change: MatSelectChange): void {   
    try {
      this.categorySelected = false;
      this.quantity = null;
      this.quantityService.setQuantity(null);
      this.loadCategoryRef(change.value);
    } catch(e) {
      console.log('Error loading component: ' + change.source.triggerValue + ' -> ' + e);
    }
  }

  private loadCategoryRef(categoryId: number): void {
    const category = this.categoryList.find(cat => cat.id === categoryId);
    const viewContainerRef = this.host.viewContainerRef;
    viewContainerRef.clear(); 

    if(categoryId > 0 && category) {
      const loader = this.common.getComponentByRefId(category?.referenceId || '');

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loader.routingComponent);
      const componentRef = viewContainerRef.createComponent<any>(componentFactory);
      if(category) {
        componentRef.instance.category = category;
        if(loader.data) {
          componentRef.instance.screenTitle = loader.data.screenTitle;
        }

        this.categorySelected = true;
      }
    }
  }
}