import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoutingToolData, VendorPricingHeader } from '../models/price-routing.models';

@Component({
  selector: 'app-routing-component-table',
  templateUrl: './component-table.component.html',
  styleUrls: ['./component-table.component.scss']
})
export class ComponentTableComponent implements OnInit {
  @Input() data!: RoutingToolData[];
  @Input() vendors!: VendorPricingHeader[];
  @Input() isDisabled: boolean = true;
  @Output() valueChange = new EventEmitter();

  displayColumns: string[] = ['type', 'spec', 'qty'];
  footer1Columns: string[] = ['unitPrice', 'emptyFooter', 'emptyFooter'];
  footer2Columns: string[] = ['totalPrice', 'emptyFooter', 'emptyFooter'];
  vendorSize: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.displayColumns = [...this.displayColumns, ...this.vendors.map((v,idx) => idx.toString())];
    this.footer1Columns = [...this.footer1Columns, ...this.vendors.map((v,idx) => 'ft1-' + idx.toString())];
    this.footer2Columns = [...this.footer2Columns, ...this.vendors.map((v,idx) => 'ft2-' + idx.toString())];
    this.vendorSize = this.vendors.length; 
  }
  
  valueChanges(selection: RoutingToolData, isSetPrice: boolean = true): void {
    this.valueChange.emit({selection, isSetPrice});
  }
}
