import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { HomeComponent } from './main/home/home.component';
import { SpecCollectionComponent } from './spec-collection/core/spec-collection.component';
import { SpecLibraryContainerComponent } from './spec-library/spec-library-container/spec-library-container.component';
import { VendorProfileComponent } from './vendor/vendor-profile/vendor-profile.component';
import { AdministrationComponent } from './admin/administration.component';
import { VendorBidComponent } from './vendor/bid/vendor-bid.component';
import { RolePermissionComponent } from './role-permission/role-permission/role-permission.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { UserProfilesComponent } from './user-profiles/user-profiles.component';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { ManualSpecPricingComponent } from './routing-tool/manual-spec-pricing.component';
import { UnauthorizedComponent } from './access-denied/unauthorized.component';
import { SpecLibraryPricingComponent } from './spec-library-pricing/spec-library-pricing.component';
import { SpecLibraryPricingReportComponent } from './spec-library-pricing-report/spec-library-pricing-report.component';
import { PermissionsGuard } from './core/guards/permissions.guard';
import { AppSettingsComponent } from './main/app-settings/app-settings.component';
import { InvalidUserComponent } from './access-denied/invalid-user.component';
import { UserGuard } from './core/guards/user.guard';

const routes: Routes = [
  {
    path: '', 
    component: HomeComponent, 
    canActivate: [MsalGuard, UserGuard]
  }, 
  {
    path: 'spec-collection',
    component: SpecCollectionComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    canDeactivate: [PendingChangesGuard],
    data : {
      allowedPermissions: ['VIEW_SPEC', 'EDIT_SPEC']
    }
  },
  {
    path: 'spec-library',
    component: SpecLibraryContainerComponent,
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_SPEC','EDIT_SPEC']
    }
  },
  {
    path: 'vendor-profile',
    component: VendorProfileComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_VENDOR_PROFILE', 'EDIT_VENDOR_PROFILE']
    }
  },
  {
    path: 'vendor-bid',
    component: VendorBidComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_VENDOR_BID', 'EDIT_VENDOR_BID']
    }
  },
  {
    path: 'administration',
    component: AdministrationComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_ADMIN','EDIT_ADMIN']
    }
  },
  {
    path: 'role-permission',
    component: RolePermissionComponent,
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    canDeactivate: [PendingChangesGuard],
    data : {
      allowedPermissions: ['VIEW_SECURITY','EDIT_SECURITY']
    }
  },
  {
    path: 'user-profiles',
    component: UserProfilesComponent,
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_USER_PROFILE','EDIT_USER_PROFILE']
    }
  },
  {
    path: 'manual-spec-pricing',
    component: ManualSpecPricingComponent,
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data : {
      allowedPermissions: ['VIEW_MANUAL_SPEC_PRICING','EDIT_MANUAL_SPEC_PRICING']
    }
  },
  {
    path: 'spec-library-pricing',
    component: SpecLibraryPricingComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data: {
      allowedPermissions: ['VIEW_SPEC_LIBRARY_PRICING', 'EDIT_SPEC_LIBRARY_PRICING']
    }
  },
  {
    path: 'spec-library-pricing-report',
    component: SpecLibraryPricingReportComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard],
    data: {
      allowedPermissions: ['VIEW_SPEC_LIBRARY_PRICING', 'EDIT_SPEC_LIBRARY_PRICING'], 
      allowVendors: false
    }
  },
  {
    path: 'app-settings',
    component: AppSettingsComponent, 
    canActivate: [MsalGuard, UserGuard, PermissionsGuard], 
    data: {
      allowedPermissions: ['VIEW_ADMIN','EDIT_ADMIN']
    }
  },
  {
    path: 'accessdenied',
    component: AccessDeniedComponent,
    data: {}
  }, 
  {
    path: 'unauthorized', 
    component: UnauthorizedComponent, 
    data: {}
  },
  {
    path: 'invalid-user', 
    component: InvalidUserComponent, 
    data: {}
  },
  {
    path: '**',
    redirectTo: ''
  }      
];


// const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    initialNavigation: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }