<div fxLayout="row wrap" fxLayoutAlign="center">
  <div fxFlex="96" fxFlex.xs="100">
    <mat-card>

      <div fxLayout="row wrap">
          <div matTooltip="At least one spec and quantity are required to generate a report" [matTooltipDisabled]="canSubmit">
            <button mat-raised-button color="primary" (click)="generateReport()" [disabled]="!canSubmit">
              Generate Report
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
          <button mat-raised-button color="accent" style="margin-left: 10px;" (click)="openExcludeVendorsDialog()">
            Exclude Vendor
            <mat-icon>visibility_off</mat-icon>
          </button>
          <button mat-raised-button color="warn" style="margin-left: auto;" (click)="reset()">
            Reset
            <mat-icon>restart_alt</mat-icon>
          </button>
      </div>

      <div fxLayout="row wrap" class="mt-10" *ngIf="excludedVendors && excludedVendors.length > 0">
        <div fxFlex="100">
          Excluded Vendors: 
          <mat-chip-list>
            <mat-chip *ngFor="let vendor of excludedVendors">
              {{vendor.name}}
              <mat-icon matChipRemove (click)="removeExcludedVendor(vendor.id)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      
      <div fxLayout="row wrap" fxLayoutAlign="space-evenly">
        <div fxFlex="30" fxFlex.lt-lg="100">
          <app-quantity-table></app-quantity-table>
        </div>
        <div fxFlex="70" fxFlex.lt-lg="100">
          <app-spec-table></app-spec-table>
        </div>
      </div>

    </mat-card>
  </div>
</div>