<mat-toolbar style="width: 100%;" color="primary">
    <span>Manage Component List of {{ data.componentName }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
<div mat-dialog-content>
    <form *hasPermission="['EDIT_ADMIN']" class="example-form" #createComponentListForm="ngForm" (ngSubmit)="createComponentList(createComponentListForm)">
        <h2 mat-dialog-title>Create Component List</h2>

        <input type="hidden" [ngModel]="data.active" name="active">   
        <input type="hidden" [ngModel]="data.componentId" name="componentId">   

        <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text" fxFlex="100" fxFlex.xs="100">
            <mat-form-field appearance="outline">
                <mat-label>Create Component List Value</mat-label>
                <input matInput autocomplete="off" [ngModel]="data.value" name="value" required ngModel>
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>Create Component List Description</mat-label>
            <input matInput autocomplete="off" [ngModel]="data.description" name="description">  
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>Create Component List Unit of Measurement</mat-label>
            <mat-select [ngModel]="data.uom" name="uom">
                <mat-option *ngFor="let e of swatchUomList" [value]="e.value">
                    {{ e.value }}
                </mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
            <mat-label>Create Component List Product Type</mat-label>
            <mat-select name="productType" ngModel>
                <mat-option [value]="''">None</mat-option>
                <mat-option [value]="'both'">Both</mat-option>
                <mat-option [value]="'softSurface'">Soft Surface</mat-option>
                <mat-option [value]="'hardSurface'">Hard Surface</mat-option>
            </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="data.compReferenceId == 'quantityFactorGrid'">
                <mat-label>Enter Minimum Quantity</mat-label>
                <input matInput type="number" autocomplete="off" [ngModel]="data.minQty" min="0" name="minQty" required ngModel>    
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="data.compReferenceId == 'quantityFactorGrid'">
                <mat-label>Enter Maximum Quantity</mat-label>
                <input matInput type="number" autocomplete="off" [ngModel]="data.maxQty" min="0" name="maxQty">    
            </mat-form-field>
            <div fxLayout="row wrap" fxLayoutAlign="center" class="my-3">
                <mat-checkbox color="accent" [ngModel]="data.canBid" name="canBid">Can Bid</mat-checkbox>
                <mat-checkbox class="ml-2" color="accent" [ngModel]="data.negativeBid" name="negativeBid">Negative Bid</mat-checkbox>
             </div>
            <div fxLayout="row wrap" fxLayoutAlign="center">
                <button type="submit" mat-raised-button color="accent" [disabled]="!createComponentListForm.valid">Create</button>
            </div>
        </mat-card-content>
    </form>

    <div class="example-form">
        <br>
        <mat-divider></mat-divider>
        <br>
        <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
            <mat-toolbar style="width: 100%; background-color: #ffffff00;">
                <span>Update Component List</span>
                <span class="example-spacer"></span>
                <button class="table-button" mat-raised-button color="accent" matTooltip="Click to rearrange sequence numbers" (click)="openRearrangeSeqDialog()">
                    Rearrange Sequence
                </button>
            </mat-toolbar>
        </ng-container>
        <ng-template #viewOnly>
            <h2 mat-dialog-title>View Component List</h2>
        </ng-template>
                
        <table mat-table [dataSource]="dataSource" matSort>
    
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.id }}</td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
                <td mat-cell *matCellDef="let element;">
                    <ng-container *hasPermission="['EDIT_ADMIN']; else viewOnly">
                        <button type="button" class="btn-link" (click)="openUpdateCompListDialog(element)" matTooltip="Manage Value: {{ element.value }} ">
                            {{ element.value }}
                        </button>
                    </ng-container>
                    <ng-template #viewOnly>{{ element.value }}</ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="componentId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Component ID</th>
                <td mat-cell *matCellDef="let element;">{{ element.componentId }}</td>
            </ng-container>

            <ng-container matColumnDef="seqNbr">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sequence Nbr</th>
                <td mat-cell *matCellDef="let element;">{{ element.seqNbr }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element;">{{ element.description || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="uom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit of Measurement</th>
                <td mat-cell *matCellDef="let element;">{{ element.uom || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="minQty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Min Quantity</th>
                <td mat-cell *matCellDef="let element;">{{ element.minQty || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="maxQty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Max Quantity</th>
                <td mat-cell *matCellDef="let element;">{{ element.maxQty || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="createdByName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                <td mat-cell *matCellDef="let element;">{{ element.createdByName }}</td>
            </ng-container>

            <ng-container matColumnDef="createdTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.createdTimestamp | date) }}</td>
            </ng-container>

            <ng-container matColumnDef="changedByName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By</th>
                <td mat-cell *matCellDef="let element;">{{ element.changedByName || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="changedTimestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changed By Timestamp</th>
                <td mat-cell *matCellDef="let element;">{{ (element.changedTimestamp | date) || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="productType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Type</th>
                <td mat-cell *matCellDef="let element;">{{ element.productType || '-' }}</td>
            </ng-container>

            <ng-container matColumnDef="canBid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Can Bid</th>
                <td mat-cell *matCellDef="let element;" class="center-text">
                    <mat-checkbox [checked]="element.canBid == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="negativeBid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Negative Bid</th>
                <td mat-cell *matCellDef="let element;" class="center-text">
                    <mat-checkbox [checked]="element.negativeBid == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                <td mat-cell *matCellDef="let element;" class="center-text">
                    <mat-checkbox [checked]="element.active == true" [disabled]="true"></mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-table-header-primary"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>

        <mat-paginator #paginator
                            [pageSize]="15"
                            [pageSizeOptions]="[5, 15, 25, 50, 100]"
                            [showFirstLastButtons]="true">
        </mat-paginator>

    </div>
</div>