import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/internal/operators";

import { UserService } from "../services/user.service";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) { }

    /*
      This is a horrible hacked together nightmare. If we determine that the user profile on the server 
      has changed, then we will force a refresh of the user permissions in memory. 
    */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            concatMap((event: HttpEvent<any>) => {
                if(event instanceof HttpResponse) {
                    if(event.headers) {
                        let expiry = event.headers.get('X-User-Expiry'); 
                        if(expiry && this.userService.isCacheInvalid(+expiry)) {
                            return this.userService.fetchUser$.pipe(map(_ => event)); //Pipe to the user service, but map it to return the original event
                        }
                    } 
                }                    

                return of(event);
            })
        )
    }
}