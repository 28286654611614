    <mat-stepper #stepper orientation="horizontal">

        <mat-step [stepControl]="nameCreationForm" [hasError]="nameCreationForm.invalid">
            <form [formGroup]="nameCreationForm">
                <ng-template matStepLabel>Name Creation</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="center">
                        <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                            <mat-label>Azure Role Name</mat-label>
                            <input matInput autocomplete="off" formControlName="roleName" required (blur)="stringNameFormatting()">
                            <mat-hint>Azure role name is required.</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="24" fxFlex.md="48" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
                            <mat-label>Display Name</mat-label>
                            <input matInput autocomplete="off" formControlName="displayName" required>
                            <mat-hint>Display name is required.</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button type="button" mat-raised-button [color]="'primary'" matStepperNext>Next</button>
                    </div>
                </ng-template>
            </form>
        </mat-step>
    
        <mat-step [stepControl]="viewPermissionsForm">
            <form [formGroup]="viewPermissionsForm">
                <ng-template matStepLabel>View Permissions</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
                        <mat-checkbox class='pr-3' color="accent" name="viewVendorProfile" formControlName="viewVendorProfile">Vendor Profile</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="viewVendorBid" formControlName="viewVendorBid">Vendor Bid</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="viewSpecLibraryPricing" formControlName="viewSpecLibraryPricing">Spec Library Pricing</mat-checkbox>
                        <mat-checkbox class='pl-3' color="accent" name="viewManualSpecPricing" formControlName="viewManualSpecPricing">Manual Spec Pricing</mat-checkbox>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="viewSpec" formControlName="viewSpec">Spec Tools</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="viewSecurity" formControlName="viewSecurity">Security</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="viewUserProfile" formControlName="viewUserProfile">User Profile</mat-checkbox>
                        <mat-checkbox class='pl-3' color="accent" name="viewAdmin" formControlName="viewAdmin">Admin</mat-checkbox>
                    </div>
                    <small class="mt-20" fxLayoutAlign="center" *ngIf="isEdit">If you are disabling a view permission, please disable it from the edit permission as well.</small>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </form>
        </mat-step>
    
        <mat-step [stepControl]="editPermissionsForm">
            <form [formGroup]="editPermissionsForm">
                <ng-template matStepLabel>Edit Permissions</ng-template>
                <ng-template matStepContent>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
                        <mat-checkbox class='pr-3' color="accent" name="editVendorProfile" formControlName="editVendorProfile">Vendor Profile</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="editVendorBid" formControlName="editVendorBid">Vendor Bid</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="editSpecLibraryPricing" formControlName="editSpecLibraryPricing">Spec Library Pricing</mat-checkbox>
                        <mat-checkbox class='pl-3' color="accent" name="editManualSpecPricing" formControlName="editManualSpecPricing">Manual Spec Pricing</mat-checkbox>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="editSpec" formControlName="editSpec">Spec Tools</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="editSecurity" formControlName="editSecurity">Security</mat-checkbox>
                        <mat-checkbox class='pl-3 pr-3' color="accent" name="editUserProfile" formControlName="editUserProfile">User Profile</mat-checkbox>
                        <mat-checkbox class='pl-3' color="accent" name="editAdmin" formControlName="editAdmin">Admin</mat-checkbox>
                        <mat-checkbox class='pl-3' color="accent" name="editMasterBid" formControlName="editMasterBid">Master Bid</mat-checkbox>
                    </div>
                    <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                        <button mat-raised-button matStepperPrevious>Back</button>
                        <button type="button" mat-raised-button color="primary" matStepperNext style="margin-left: 5px;">Next</button>
                    </div>
                </ng-template>
            </form>
        </mat-step>
    
        <mat-step>
            <ng-template matStepLabel>Review Role</ng-template>
                <mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
                    <h2 style="margin: 5px 0 5px;">Name Creation</h2>
                </mat-toolbar>
                <div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
                    <p fxFlex="15" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100"  class="detail-text">
                        <span class="label-font">Azure Role Name:&nbsp;</span>
                        {{ nameCreationForm.value.roleName }}
                    </p>
                    <p fxFlex="15" fxFlex.md="32" fxFlex.sm="48" fxFlex.lt-sm="100"  class="detail-text">
                        <span class="label-font">Display Name:&nbsp;</span>
                        {{ nameCreationForm.value.displayName }}
                    </p>
                </div>

                <mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
                    <h2 style="margin: 5px 0 5px;">View Permissions</h2>
                </mat-toolbar>
                <div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewVendorProfile == true">Vendor Profile</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewVendorBid == true">Vendor Bid</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewSpecLibraryPricing == true">Spec Library Pricing</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewManualSpecPricing == true">Manual Spec Pricing</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewSpec == true">Spec Tools</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewSecurity == true">Security</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewUserProfile == true">User Profile</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="viewPermissionsForm.value.viewAdmin == true">Admin</mat-checkbox>
                </div>

                <mat-toolbar fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; height: 40px;" color="primary">
                    <h2 style="margin: 5px 0 5px;">Edit Permissions</h2>
                </mat-toolbar>
                <div fxLayout="row wrap" fxLayoutAlign="start" class="my-3">
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editVendorProfile == true">Vendor Profile</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editVendorBid == true">Vendor Bid</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editSpecLibraryPricing == true">Spec Library Pricing</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editManualSpecPricing == true">Manual Spec Pricing</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editSpec == true">Spec Tools</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editSecurity == true">Security</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editUserProfile == true">User Profile</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editAdmin == true">Admin</mat-checkbox>
                    <mat-checkbox fxFlex="12" fxFlex.lg="15" fxFlex.md="32" fxFlex.sm="48" [disabled]="true" [checked]="editPermissionsForm.value.editMasterBid == true">Master Bid</mat-checkbox>
                </div>



            <div class="mt-20" fxLayout="row wrap" fxLayoutAlign="end">
                <button mat-raised-button matStepperPrevious>Back</button>
                <button type="button" mat-raised-button color="primary"
                        style="margin-left: 5px;"
                        (click)="submitLogic()" (click)="stepper.reset()">Submit</button>
            </div>
        </mat-step>
    
    </mat-stepper>
