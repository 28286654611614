import { environment } from '../../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class ConfigModel {
    public static APP_VERSION: string = environment.appVersion; 
    public static API_URL: string = environment.API_URL;
    public static REDIRECT_URL: string = environment.REDIRECT_URL;
    public static API_SCOPE: string = environment.API_SCOPE;
    public static CLIENT_ID: string = environment.CLIENT_ID;
    public static AUTHORITY: string = environment.AUTHORITY;
    public static ENV_NAME: string = environment.ENV_NAME;
}
