export default class DirectiveUtils {
    static NavigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Esc',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste',
    ]; 

    static isValidKeyBoardEvent(e: KeyboardEvent): boolean {
        return this.NavigationKeys.indexOf(e.key) > -1 || 
            (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
            (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
            (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
            (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
            (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
            (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
            (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
            (e.key === 'x' && e.metaKey === true); // Cmd+X (Mac)
    }  

    static isAlphaNumericKey(e: KeyboardEvent): boolean {
        return /^[a-z0-9]/i.test(e.key); 
    }

    static isAlphaNumericString(str: string): boolean {
        return /^[A-Za-z0-9]+$/.test(str); 
    }
}