export const environment = {
  production: false,
  appVersion: require('../../package.json').version,
  API_URL: 'https://sz-ue2-save-api-d-app-01.azurewebsites.net',
  API_SCOPE: 'api://169b008d-9842-42db-8733-a45cb761562b/samples-save',
  REDIRECT_URL: 'https://samples-save-sys.shawinc.com',
  CLIENT_ID: '169b008d-9842-42db-8733-a45cb761562b',
  AUTHORITY: 'https://login.microsoftonline.com/9407f998-df4c-49e1-bb48-b181e9e3f8dc',
  ENV_NAME: 'Test'
};
