import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';

import { PricingResponse } from '../models/spec-library-pricing.models';
import { SpecLibraryPricingService } from '../services/spec-library-pricing.service';

@Component({
  selector: 'app-assembly-pricing',
  templateUrl: './assembly-pricing.component.html',
  styles: [],
  providers: [SpecLibraryPricingService]
})
export class AssemblyPricingComponent implements OnInit {
  @ViewChild('requestForm') requestForm!: NgForm; 

  pricing: PricingResponse[] = []; 
  btnPreText: string = 'Compare'; 
  isVendor: boolean = true;

  constructor(
    private pricingService: SpecLibraryPricingService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isVendor = this.userService.isVendor; 
    if(this.isVendor) {
      this.btnPreText = 'View';
    }
  }

  submitSpec(): void {
    this.pricing = []; 
    
    this.pricingService.getAssemblyPricing(this.requestForm.value).subscribe(data => {
      this.pricing = data;
    });
  }

  openDialog(): void {
    this.pricingService.openAssemblySpecComparison(this.requestForm.value); 
  }

  clear(): void {
    this.pricing = [];
    this.requestForm.reset({quantity: '', styleNumber: '', deliveryVehicle: '', customerNumber: '', sequence: ''}); 
  }
}
