import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AdministrationService } from '../services/administration.service';
import { AdminUpdateDialogComponent } from '../admin-update-dialog/admin-update-dialog.component';
import { FormGroup, NgForm } from '@angular/forms';
import { Category } from '../models/administration-models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-admin-category-manage',
  templateUrl: './admin-category-manage.component.html',
  styleUrls: ['./admin-category-manage.component.scss'],
})

export class AdminCategoryManageComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('table') table!: MatTable<Category>;


  dataSource = new MatTableDataSource<Category>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'seqNbr', 'createUser', 'createdTimestamp', 'changeUser', 'changedTimestamp', 'assembly', 'bindery', 'active', 'update'];
  displayedColumns = this.dataColumns;

  totalRows!: number;
  
  constructor(
    private adminService: AdministrationService,
    public dialog: MatDialog,
    private snackService: SnackBarService
  ) { }

  ngAfterViewInit(): void {
    this.gettingCategoryList();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  gettingCategoryList() {
    this.adminService.getCategoryList().subscribe((response: any) => { 
      this.dataSource.data = response;
      this.totalRows = this.dataSource.data.length;
    });
    
  }

  createCategory(form: NgForm) {
    this.adminService.createCategory(form.value).subscribe((response: any) => {
      this.snackService.success('Category was successfully created');
      this.gettingCategoryList();
    },
    (e: any) => {
      this.snackService.error('An error occurred while creating category. If problem persist, please contact support.');
      console.error(e);
    }); 
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openUpdateDialog(data: Category) {
    const dialogRef = this.dialog.open(AdminUpdateDialogComponent, {
      data: {
        id: data.id,
        name: data.name,
        referenceId: data.referenceId,
        seqNbr: data.seqNbr,
        assembly: data.assembly,
        bindery: data.bindery,
        active: data.active,
        listLength: this.totalRows
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.gettingCategoryList();
    });    
  }
}