<div class="table-container-600 mat-elevation-z2">
    <table mat-table style="width: 100%;" [dataSource]="dataSource">
    
        <!-- Position Column -->
        <ng-container matColumnDef="binderySpec" sticky>
            <th mat-header-cell *matHeaderCellDef class="w-200">Specification Type</th>
            <td mat-cell *matCellDef="let element"> {{element.componentName}} </td>
        </ng-container>

        <ng-container matColumnDef="assemblySpec" sticky>
            <th mat-header-cell *matHeaderCellDef class="w-300">Specification Type</th>
            <td mat-cell *matCellDef="let element"> {{element.componentName}} </td>
        </ng-container>
    
        <ng-container matColumnDef="specUom" sticky>
            <th mat-header-cell *matHeaderCellDef class="w-100">UOM</th>
            <td mat-cell *matCellDef="let element"> {{element.uom}} </td>
        </ng-container>
    
        <ng-container *ngFor="let col of displayedColumns; let idx = index">
    
            <ng-container matColumnDef="{{ 'spec' + idx }}">
                <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'">Spec</th>
                <td mat-cell *matCellDef="let element" [style.text-align]="'center'">{{ element.vendors[idx].componentValue }}</td>
            </ng-container>
    
            <ng-container matColumnDef="{{ 'qty' + idx }}">
                <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'">Qty</th>
                <td mat-cell *matCellDef="let element" [style.text-align]="'center'">
                    {{ element.vendors[idx].quantity || (element.vendors[idx].componentValue ? 'N/A' : '-') }}
                </td>
            </ng-container>
    
            <ng-container matColumnDef="{{ 'price' + idx }}">
                <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [style.border-right]="'2px solid rgba(96, 96, 96, 0.5)'">Price</th>
                <td mat-cell *matCellDef="let element" [style.text-align]="'center'" 
                    [style.border-right]="'2px solid rgba(96, 96, 96, 0.5)'" 
                    [style.color]="element.vendors[idx].noBid || element.vendors[idx].missingPrice ? 'red' : ''">
                        {{ (element.vendors[idx].price | currency) || (element.vendors[idx].noBid ? 'No Bid' : element.vendors[idx].missingPrice ? 'Missing Bid' : '') }}
                </td>
            </ng-container>
    
        </ng-container>
    
        <ng-container *ngFor="let col of secondHeaders; let idx = index;">
            <ng-container *ngIf="idx !== 0" matColumnDef="{{ col }}">
                <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [style.border-right]="'2px solid rgba(96, 96, 96, 0.5)'" [attr.colspan]="vendorColSpan">{{ col }}</th>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="empty" sticky>
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="emptyColSpan"></th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="secondHeaders; sticky: true;" class="mat-table-header-primary" ></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="mat-table-header-primary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"></tr>
    </table>
</div>