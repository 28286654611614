import { Component, OnInit } from '@angular/core';
import { AdministrationService } from './services/administration.service';
import { AdminManageList } from './models/administration-models';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
  providers: [AdministrationService]
})
export class AdministrationComponent implements OnInit {

  selectedValue!: number;

  adminManageList: AdminManageList[] = [
    {value: 1, display: 'Category'},
    {value: 2, display: 'Component'},
    {value: 3, display: 'Component List'},
    {value: 4, display: 'Swatching Quantity Factor Grid'}
  ];

  constructor(private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Admin Management | SAVE');
  }


}
