import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { LoaderComponent } from '../loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) { 
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });
  }

  showLoader() {
    if(!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(new ComponentPortal(LoaderComponent));
    }
  }

  hideLoader() {
    this.overlayRef.detach();
  }
}
