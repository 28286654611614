import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { QuantityFactorGridDTO } from '../models/administration-models';
import { AdministrationService } from '../services/administration.service';
import { UpdateQfgDialogComponent } from '../update-qfg-dialog/update-qfg-dialog.component';

@Component({
  selector: 'app-manage-qfg-dialog',
  templateUrl: './manage-qfg-dialog.component.html',
  styleUrls: ['./manage-qfg-dialog.component.scss'],
  providers: [AdministrationService]
})
export class ManageQfgDialogComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<QuantityFactorGridDTO>([]);
  dataColumns: String[] = ['id', 'categoryId', 'minQty', 'maxQty', 'createdByName', 'createdTimestamp', 'changedByName', 'changedTimestamp', 'active'];
  displayedColumns = this.dataColumns;

  constructor(public dialogRef: MatDialogRef<ManageQfgDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: QuantityFactorGridDTO,
              private adminService: AdministrationService,
              public dialog: MatDialog,
              private snackService: SnackBarService
  ) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getQfgByCatId();
  }

  getQfgByCatId() {
    this.adminService.getQfgByCatId(this.data.categoryId).subscribe((response: any) => {
      this.dataSource.data = response;
    });
  }

  createQfg(form: NgForm) {
    this.adminService.createQuantityFactorGrid(form.value).subscribe((response: any) => {
      this.snackService.success('Quantity Factor Grid was successfully created.');
      this.getQfgByCatId();
    },
    (e: any) => {
      this.snackService.error('An error occurred while createing quantity factor grid. If problem persists, please contact support.')
      console.error(e);
    });
  }

  openUpdateQfgDialog(data: QuantityFactorGridDTO) {
    const dialogRef = this.dialog.open(UpdateQfgDialogComponent, {
      data: {
        id: data.id,
        minQty: data.minQty,
        maxQty: data.maxQty,
        active: data.active,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getQfgByCatId();
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}