<div style="max-height: 600px; overflow: auto;" class="mat-elevation-z2">
    <mat-table [dataSource]="pricing" matSort (matSortChange)="handleSort($event)">
        <ng-container matColumnDef="vendor">
            <mat-header-cell *matHeaderCellDef mat-sort-header="vendorName">Vendor Name</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.vendorName }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
            <mat-header-cell *matHeaderCellDef mat-sort-header="unitPrice">Unit Price</mat-header-cell>
            <mat-cell *matCellDef="let element;" [style.color]="(element.noBid || element.missingBid) ? 'red' : ''">
                {{ (element.unitPrice | currency) || (element.noBid ? 'No Bid' : element.missingBid ? 'Missing Bid' : 'No Match') }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="diffUnitPrice">
            <mat-header-cell *matHeaderCellDef>Unit Price Difference From Lowest</mat-header-cell>
            <mat-cell *matCellDef="let element;" [style.color]="element.diffUnitPrice ? 'red' : ''">{{ (element.diffUnitPrice | currency) || '-' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalPrice">
            <mat-header-cell *matHeaderCellDef mat-sort-header="totalPrice">Order Price</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ (element.totalPrice | currency) || '-' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="diffTotalPrice">
            <mat-header-cell *matHeaderCellDef>Order Price Difference From Lowest</mat-header-cell>
            <mat-cell *matCellDef="let element;" [style.color]="element.diffTotalPrice ? 'red' : ''">{{ (element.diffTotalPrice | currency) || '-' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="basedOn">
            <mat-header-cell *matHeaderCellDef>Price Based On Vendor Spec</mat-header-cell>
            <mat-cell *matCellDef="let element;">{{ element.specBasedOnVendorName || '-' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns; sticky: true;" class="mat-table-header-primary"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
</div>