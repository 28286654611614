import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

import { Observable, EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/internal/operators';

import { VendorDto } from 'src/app/core/models/vendor-models';
import { UserService } from 'src/app/core/services/user.service';
import { BackendService } from 'src/app/core/services/back-end.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { Supplier } from './models/vendor-profile-models';
import { VendorProfileService } from './services/vendor-profile.service';
import { VendorCreateDialogComponent } from './vendor-create-dialog/vendor-create-dialog.component';
import { VendorEditDialogComponent } from './vendor-edit-dialog/vendor-edit-dialog.component';
import { VendorProfileResponse } from './vendor-profile.response';

@Component({
  selector: 'app-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styles: [
  ],
  providers: [VendorProfileService]
})
export class VendorProfileComponent implements OnInit {

  vendorList$: Observable<VendorDto[]> = this.backendService.getVendorDtoList(false);
  profile!: VendorProfileResponse; 
  supplierDetails!: Supplier;
  vendorId: string = ''; 
  isVendor: boolean = false;

  constructor( 
    private vendorService: VendorProfileService,
    private backendService: BackendService,
    private userService: UserService,
    private title: Title,
    public dialog: MatDialog,
    public snackService: SnackBarService,
  ) { }

  ngOnInit() {
    this.title.setTitle('Vendor Profile | SAVE');

    if(this.userService.isVendor) {
      this.vendorId = this.userService.getUser()?.vendorId; 
      this.isVendor = true; 
      this.getVendor(); 
    }
  }

  public getVendor() {
    if (!this.vendorId) {
      this.profile = {} as VendorProfileResponse;
      return;
    } 

    this.vendorService.getVendor(this.vendorId)
      .pipe(
        tap(profile => this.profile = profile),
        concatMap(profile => profile.supplierId ? this.vendorService.getVendorFromTableBySuppId(this.profile.supplierId) : EMPTY)
      )
      .subscribe(
        (supplier) => this.supplierDetails = supplier, 
        (e: HttpErrorResponse) => {
          this.snackService.error(e.error.localizedMessage); 
          console.error(e);
        });
	}

  openVendorCreateDialog() {
    const dialogRef = this.dialog.open(VendorCreateDialogComponent);
    dialogRef.afterClosed().subscribe(() => {
      location.reload();
    });
  }

  openVendorEditDialog() {
    const dialogRef = this.dialog.open(VendorEditDialogComponent, {
      data: {
        vendorName: this.profile.name,
        supplierId: this.profile.supplierId,
        assembly: this.supplierDetails.assembly,
        bindery: this.supplierDetails.bindery,
        active: this.supplierDetails.active
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getVendor(); 
    });
  }
}