<mat-toolbar style="width: 100%;" color="primary">
    <span>Edit {{ data.vendorName }}</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div mat-dialog-content class="example-form dialog-update">
    <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text mt-15">
        <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text mt-20">
            <mat-checkbox class='pr-3' [(ngModel)]="data.assembly" color="accent" name="assembly">Assembly</mat-checkbox>
            <mat-checkbox class='pl-3'[(ngModel)]="data.bindery" color="accent" name="bindery">Bindery</mat-checkbox>
            <mat-checkbox class='pl-3'[(ngModel)]="data.active" color="accent" name="active">Active</mat-checkbox>
        </div>
        <div fxLayoutAlign="center" class="center-text mt-20">
            <button mat-raised-button color="accent" (click)="update()">Update</button>
        </div> 
    </mat-card-content>

</div>