import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { Supplier } from '../models/vendor-profile-models';
import { VendorProfileService } from '../services/vendor-profile.service';

@Component({
  selector: 'app-vendor-create-dialog',
  templateUrl: './vendor-create-dialog.component.html',
  styleUrls: ['./vendor-create-dialog.component.scss'],
  providers: [VendorProfileService]
})
export class VendorCreateDialogComponent implements OnInit {

  supplierId: string = '';
  triggerVendorOptions: boolean = false;
  assembly: boolean = false;
  bindery: boolean = false;
  supplierObject!: Supplier;

  constructor(public dialogRef: MatDialogRef<VendorCreateDialogComponent>,
    private snackService: SnackBarService,
    public commonService: CommonService,
    private vendorService: VendorProfileService,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  checkVendorExists(): void {
    this.triggerVendorOptions = false;
    this.vendorService.getVendor(this.supplierId).subscribe((response: any) => {
      if(response !== null) {
        this.snackService.success('Vendor: ' + response.name + ' was found with supplier ID of: ' + response.supplierId);
        this.triggerVendorOptions = true;
      }
      this.supplierObject = response;
    },
    (e: HttpErrorResponse) => {
      this.snackService.error(e.error.localizedMessage);
      console.log(e);
    });
  }

  addVendor() {
    this.supplierObject.assembly = this.assembly;
    this.supplierObject.bindery = this.bindery;

    let request: Supplier = {
      name: this.supplierObject.name, 
      supplierId: this.supplierObject.supplierId, 
      assembly: this.supplierObject.assembly, 
      bindery: this.supplierObject.bindery
    }

    this.vendorService.createVendor(this.supplierObject.supplierId, request).subscribe(response => {
      this.snackService.success(response.successMessage);
    },
    (e: HttpErrorResponse) => {
      this.snackService.error(e.error.localizedMessage);
      console.log(e);
    },
    () => {
      this.close();
    });    
  }

}
