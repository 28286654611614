import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ComponentLists } from 'src/app/core/models/common-models';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styles: [
  ]
})
export class BaseComponentComponent implements OnInit {
  @Input() componentLists!: ComponentLists;
  @Input() group!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}