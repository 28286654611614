import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BackendService } from 'src/app/core/services/back-end.service';

@Component({
  selector: 'app-print-spec-header',
  templateUrl: './print-spec-header.component.html'
})
export class PrintSpecHeaderComponent implements OnInit {
  @Input() headerForm!: FormGroup;  
  @Input() categoryId!: number;

  @Output() formSubmit = new EventEmitter<void>(); 

  vendorList$: any;


  styleControl!: AbstractControl | null;
  skuControl!: AbstractControl | null;
  delvVehControl!: AbstractControl | null;
  custNbrControl!: AbstractControl | null;
  seqNbrControl!: AbstractControl | null;
  supplierControl!: AbstractControl | null; 

  constructor(private backendService: BackendService) { }

  ngOnInit(): void {
    this.vendorList$ = this.backendService.getVendorDtoList(true, this.categoryId);
    this.styleControl = this.headerForm.get('styleNbr');
    this.skuControl = this.headerForm.get('skuNbr');
    this.delvVehControl = this.headerForm.get('deliveryVehicleNbr');
    this.custNbrControl = this.headerForm.get('customerNbr');
    this.seqNbrControl = this.headerForm.get('sequenceNbr');
    this.supplierControl = this.headerForm.get('supplierId');
  }

  submit(): void {
    this.formSubmit.emit();
  }
}
