import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AdministrationService } from '../services/administration.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Category } from '../models/administration-models';
import { ComponentTableDialogComponent } from '../component-table-dialog/component-table-dialog.component';
import { ManageComponentDialogComponent } from '../manage-component-dialog/manage-component-dialog.component';


@Component({
  selector: 'app-admin-component-manage',
  templateUrl: './admin-component-manage.component.html',
  styleUrls: ['./admin-component-manage.component.scss'],
})
export class AdminComponentManageComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<Category>([]);
  dataColumns: String[] = ['id', 'name', 'referenceId', 'seqNbr', 'active'];
  displayedColumns = this.dataColumns;

  constructor(
    private adminService: AdministrationService,
    public dialog: MatDialog,
  ) { }

  ngAfterViewInit(): void {
    this.gettingCategoryList();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  gettingCategoryList() {
    this.adminService.getCategoryList().subscribe((response: any) => { 
      this.dataSource.data = response;
    });
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openCompTableDialog() {
    this.dialog.open(ComponentTableDialogComponent);
  }

  openCompManageDialog(data: Category) {
    this.dialog.open(ManageComponentDialogComponent, {
      data: {
        categoryId: data.id,
        categoryName: data.name,
        active: data.active,
      }
    });
  }
}
