import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';

import { SpecDTO } from 'src/app/core/models/spec-models';
import { SpecHistoryDto } from '../models/spec-history.models';
import { ConfigModel } from 'src/app/core/models/config-model';
@Injectable()
export class SpecHistoryService {

  constructor(private httpClient: HttpClient) { }

  public getSpecHistoryBySpecId(specId: number): Observable<SpecHistoryDto[]> {
    return this.httpClient.get<SpecHistoryDto[]>(`${ConfigModel.API_URL}/v1/spec/${specId}/history`);
  }

  public getSpecDetailHistory(id: number): Observable<SpecDTO> {
    return this.httpClient.get<SpecDTO>(`${ConfigModel.API_URL}/v1/spec/history/${id}`);
  }

  public getSpecDetailHistoryBySpecId(specId: number): Observable<SpecDTO> {
    return this.httpClient.get<SpecDTO>(`${ConfigModel.API_URL}/v1/spec/${specId}/history/payload`);
  }

  public getRecentSpecHistory(specId: number): Observable<SpecHistoryDto> {
    return this.httpClient.get<SpecHistoryDto>(`${ConfigModel.API_URL}/v1/spec/${specId}/history/recent`);
  }
}
