import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  styles: [`
    .icon-align {
      font-size: 50px; 
      display: inline-flex;
      vertical-align: top;
    }
  `], 
  template: `
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-20">
        <div fxFlex="100" fxLayoutAlign="center">
            <h1 style="margin-bottom: 5px; font-size: 50px;">
                <mat-icon color="warn" class="icon-align" [inline]="true">report_problem</mat-icon>Unauthorized
            </h1>
        </div>    
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="mt-20">
        <div fxFlex="100" fxLayoutAlign="center">
            <h3>You are not authorized to use this application. Please contact support if you feel you are authorized for this application.</h3>
        </div>    
    </div>
  `
})
export class UnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
