import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { AdministrationService } from '../services/administration.service';

@Component({
  selector: 'app-update-qfg-dialog',
  templateUrl: './update-qfg-dialog.component.html',
  styleUrls: ['./update-qfg-dialog.component.scss'],
  providers: [AdministrationService]
})
export class UpdateQfgDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UpdateQfgDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adminService: AdministrationService,
              private snackService: SnackBarService,
  ) { }

  ngOnInit(): void {
  }

  updateQfg(form: NgForm) {
    this.adminService.updateQuantityFactorGrid(form.value).subscribe((response: any) => {
      this.snackService.success('Quantity Factor Grid was successfully updated.');
    },
    (e: any) => {
      this.snackService.error('An error occurred while updating quantity factor grid. If problem persist, please contact support.')
    },
    () => {
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
