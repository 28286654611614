import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject, forkJoin } from "rxjs";
import { takeUntil } from "rxjs/internal/operators";

import { QuantityService } from "../services/quantity.service";
import { BackendService } from "src/app/core/services/back-end.service";
import { ComponentGroup } from "src/app/core/models/common-models";
import { Swatch, SwatchPricing } from "src/app/core/models/spec-models";
import { SwatchComponentVendors, SwatchVendorPrice } from "../models/swatch-routing.models";
import { Category } from "src/app/admin/models/administration-models";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: 'app-swatch-routing',
  templateUrl: './swatch-routing.component.html',
  styleUrls: ['./swatch-routing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwatchRoutingComponent implements OnInit, OnDestroy {
  @Input() category!: Category;

  private readonly destroy$: Subject<void> = new Subject();
  readonly userSupplierId$ = this.userService.getUser().supplierId;

  displayColumns = ['swatchType', 'name', 'uom', 'qty'];
  unitPriceColumns = ['unitPrice', 'emptyFooter', 'emptyFooter', 'emptyFooter'];
  totalPriceColumns = ['totalPrice', 'emptyFooter', 'emptyFooter', 'emptyFooter'];

  swatchData: SwatchPricing[] = [];
  vendors: SwatchComponentVendors[] = [];
  minPricing: Swatch[] = [];
  qtyTier!: { min: number, max: number } | undefined;
  qty!: number | null;

  constructor(
    private qtyService: QuantityService,
    private backend: BackendService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    const componentList$ = this.backend.getComponentGroupListByCategoryId(this.category.id);
    const vendors$ = this.whichVendorService();

    forkJoin([componentList$, vendors$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([components, vendors]) => {
        this.vendors = vendors.map(vendor => this.initSwatchVendor(vendor.id, vendor.name));

        this.vendors.forEach((_, idx) => {
          this.displayColumns.push(idx.toString()); 
          this.unitPriceColumns.push('upc-' + idx); 
          this.totalPriceColumns.push('tpc-' + idx); 
        })

        if (components.minPrice && components.minPrice.listValues) {
          this.minPricing = components.minPrice.listValues.map(comp => {
            return { id: comp.id, name: comp.value, uom: comp.uom, description: comp.description }
          });
        }

        this.swatchData = this.swatchData.concat(
          this.mapSwatch(components.swatchType),
          this.mapSwatch(components.cutAddOns),
          this.mapSwatch(components.finishing),
          this.mapSwatch(components.boxingPackaging)
        );
      });

    this.qtyService.quantity$
      .pipe(takeUntil(this.destroy$))
      .subscribe(qty => this.qtyChange(qty));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  whichVendorService() {
    if(this.userSupplierId$ > 0) {
      const vendors$ = this.backend.getVendorDtoList(true);
      return vendors$
    }
    else {
      const vendors$ = this.backend.getVendorDtoList(true, this.category.id);
      return vendors$
    }
  }

  updatePricing(swatch: SwatchPricing, isCalculatePricing: boolean = true): void {
    this.vendors.forEach(vendor => {
      swatch.pricing?.set(vendor.vendorId, {price: undefined, isMissingBid: false, isNoBid: false, priceStatus: ''});
      if(swatch.quantity && swatch.quantity > 0) {        
        let price = vendor.compPricing.get(swatch.id);      

        if(!price) {
          swatch.pricing?.set(vendor.vendorId, {price: undefined, isMissingBid: true, isNoBid: false, priceStatus: 'Missing Bid'}); 
        } else if(!price.compPrice) {
          swatch.pricing?.set(vendor.vendorId, {price: undefined, isMissingBid: false, isNoBid: true, priceStatus: 'No Bid'}); 
        } else {
          swatch.pricing?.set(vendor.vendorId, {price: price.compPrice * swatch.quantity, isMissingBid: false, isNoBid: false, priceStatus: ''}); 
        }
      }
    }); 

    if(isCalculatePricing) {
      this.calculatePricing(); 
    }
  }

  calculatePricing(): void {
    const selectedSwatches = this.swatchData.filter(swatch => swatch.quantity && swatch.quantity > 0); 
    const minPricing = this.minPricing && this.minPricing.length > 0 ? this.minPricing[0] : undefined; 

    for(let vendor of this.vendors) {
      vendor.priceText = '-'; 
      vendor.unitPrice = undefined; 
      vendor.totalPrice = undefined; 

      let vendorPricing = selectedSwatches.map(swatch => swatch.pricing?.get(vendor.vendorId)); 
      let isNoBids = vendorPricing.filter(price => price?.isNoBid).length > 0; 
      let isMissingBids = vendorPricing.filter(price => price?.isMissingBid).length > 0; 

      if(isNoBids || isMissingBids) {
        vendor.priceText = isNoBids ? 'No Bid' : isMissingBids ? 'Missing Bid' : '-'; 
        continue;
      }

      vendorPricing.forEach(price => {
        vendor.unitPrice = (vendor.unitPrice || 0) + (price?.price || 0);
      });

      if(minPricing) {
        let minPrice = vendor.compPricing.get(minPricing.id)?.compPrice; 
        if(minPrice && minPrice > 0 && vendor.unitPrice) {
          vendor.unitPrice = Math.max(vendor.unitPrice, minPrice);
        }
      }

      if(this.qty && vendor.unitPrice && vendor.unitPrice > 0) {
        vendor.totalPrice = vendor.unitPrice * this.qty; 
      }
    }

    this.calculateMinUnitPrice(); 
  }

  qtyChange(qty: number | null): void {
    this.qty = qty; 

    //Need to reset everything when there is no qty. Component quantites aren't being reset when we have no WO qty. 

    if(!qty) {
      this.qtyTier = undefined; 
      this.vendors = this.vendors.map(vendor => this.initSwatchVendor(vendor.vendorId, vendor.vendorName));
      this.swatchData.filter(swatch => swatch.quantity && swatch.quantity > 0).map(swatch => {
        swatch.quantity = undefined; 
        swatch.pricing = this.initSwatchPricingMap(); 
      }); 
      return;
    }

    if( this.qtyTier && ((qty >= this.qtyTier.min && qty <= this.qtyTier.max) || (qty >= this.qtyTier.min && this.qtyTier.max === 0)) ) {
      this.calculatePricing();
      return; 
    }

    this.backend.getVendorQtyPricingByCategoryId(this.category.id, qty)
      .subscribe(pricing => {
        this.qtyTier = {min: pricing.minQty, max: pricing.maxQty}; 

        //This whole function is to reset each vendor's pricing and set component pricing to the new tier pricing for each component
        this.vendors = pricing.pricing.map(vendor => {          
          let tempVendor = this.initSwatchVendor(vendor.vendorId, vendor.vendorName); //Create new vendor with no pricing
          //Interate over current vendor pricing and move it to tempVendor
          Object.entries(vendor.pricing).forEach(([key, value]) => tempVendor.compPricing.set(+key, {compPrice: value, totalCompPrice: undefined, textPrice: '', isNobid: !value})); 
          return tempVendor;
        });

        this.swatchData.filter(swatch => swatch.quantity && swatch.quantity > 0)
            .forEach(swatch => this.updatePricing(swatch, false)); 

        this.calculatePricing(); 
      });
  }

  //Used to help update the *ngFor in the HTML table
  trackByFn(i: number, item: SwatchComponentVendors) {
    return `${item.vendorId}-${i}`; 
  }

  private calculateMinUnitPrice(): void {
    let minUnitPrice = Math.min(...this.vendors.map(vendor => (vendor.unitPrice || 0)).filter(price => price > 0)); 
    let minTotalPrice = Math.min(...this.vendors.map(vendor => (vendor.totalPrice || 0)).filter(price => price > 0)); 

    this.vendors.forEach(vendor => {
      vendor.diffUnitPrice = vendor.unitPrice && vendor.unitPrice != minUnitPrice ? (vendor.unitPrice - minUnitPrice) : undefined; 
      vendor.diffTotalPrice = vendor.totalPrice && vendor.totalPrice != minTotalPrice ? (vendor.totalPrice - minTotalPrice) : undefined; 
    }); 
  }

  private initSwatchVendor(id: number, name: string): SwatchComponentVendors {
    return <SwatchComponentVendors>{
      vendorId: id,
      vendorName: name,
      unitPrice: undefined,
      totalPrice: undefined,
      diffUnitPrice: undefined,
      diffTotalPrice: undefined,
      priceText: '-',
      compPricing: new Map<number, SwatchVendorPrice>()
    }
  }

  private mapSwatch(group: ComponentGroup | undefined): SwatchPricing[] {
    if (!group || !group.listValues) { return []; }

    return group.listValues.map(comp => {
      return {
        id: comp.id,
        description: comp.description,
        name: comp.value,
        uom: comp.uom,
        type: group.name,
        quantity: undefined,
        pricing: this.initSwatchPricingMap()
      };
    });
  }

  private initSwatchPricingMap() {
    return new Map(this.vendors.map(vendor => [vendor.vendorId, { price: undefined, isMissingBid: false, isNoBid: false, priceStatus: '' }])); 
  }
}