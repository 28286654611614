<form [formGroup]="headerForm" (keydown.enter)="submit()">
    <mat-card-content fxLayout="row wrap" fxLayoutAlign="center" class="center-text">

        <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>SKU Number</mat-label>
            <input matInput positiveNumber [allowDecimal]="false" style="text-transform: uppercase;" maxlength="5" autocomplete="off" formControlName="skuNbr" required>
            <mat-error *ngIf="skuControl && skuControl.invalid && (skuControl.dirty || skuControl.touched)">
                <app-form-error [control]="skuControl"></app-form-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>Style Number</mat-label>
            <input type="text" matInput style="text-transform: uppercase;" maxlength="5" autocomplete="off" formControlName="styleNbr" required>
            <mat-error *ngIf="styleControl && styleControl.invalid && (styleControl.dirty || styleControl.touched)">
                <app-form-error [control]="styleControl"></app-form-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>Delivery Vehicle Number</mat-label>
            <input matInput style="text-transform: uppercase;" maxlength="3" autocomplete="off" formControlName="deliveryVehicleNbr" required>
            <mat-error *ngIf="delvVehControl && delvVehControl.invalid && (delvVehControl.dirty || delvVehControl.touched)">
                <app-form-error [control]="delvVehControl"></app-form-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>Customer Number</mat-label>
            <input matInput style="text-transform: uppercase;" maxlength="7" autocomplete="off" formControlName="customerNbr" [leftPad]="7" positiveNumber [allowDecimal]="false">
            <mat-error *ngIf="custNbrControl && custNbrControl.invalid && (custNbrControl.dirty || custNbrControl.touched)">
                <app-form-error [control]="custNbrControl"></app-form-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>Sequence Number</mat-label>
            <input matInput positiveNumber [allowDecimal]="false" max="99" min="0" type="text" maxLength="2" autocomplete="off" formControlName="sequenceNbr">
            <mat-error *ngIf="seqNbrControl && seqNbrControl.invalid && seqNbrControl.hasError('max')">
                <app-form-error [control]="seqNbrControl"></app-form-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="supplierControl" fxFlex="19" fxFlex.md="32" fxFlex.lt-md="100" appearance="outline" style="margin-right: 8px;">
            <mat-label>Vendor</mat-label>
            <mat-select formControlName="supplierId" required>
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let vendor of vendorList$ | async" [value]="vendor.id">{{ vendor.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="supplierControl && supplierControl.invalid && (supplierControl.touched || supplierControl.dirty)">
                <app-form-error [control]="supplierControl"></app-form-error>
            </mat-error>
        </mat-form-field>

    </mat-card-content>
</form>