import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';
import { SpecLibraryPricingReportService } from '../services/spec-library-pricing-report.service';

@Component({
  selector: 'app-exclude-vendors',
  template: `
    <div mat-dialog-content style="overflow: hidden;">
      <div fxLayout="row wrap" *ngFor="let vendor of reportService.vendors">
        <div fxFlex="100">
          <section>
            <mat-checkbox class="ml-1 mr-1" [(ngModel)]="vendor.selected" (change)="checkboxChange()">{{ vendor.name }}</mat-checkbox>
          </section>
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class ExcludeVendorsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExcludeVendorsComponent>,
    public reportService: SpecLibraryPricingReportService,
  ) { }

  ngOnInit(): void {}

  checkboxChange(): void {
    this.reportService.excludeVendorChange();
  }

}
