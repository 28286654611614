import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { ComponentLists } from 'src/app/core/models/common-models';
import { SpecRequestDto } from 'src/app/core/models/spec-models';
import { CommonService } from 'src/app/core/services/common.service';
import { PhotopackFormValue } from '../../core/models/photopack.model';
import { FolderFormValue } from '../../core/models/print-folder.model';
import { SpecBackendService } from '../../core/services/spec-backend.service';
import { PhotoPackService } from '../../photopack/services/photo-pack.service';
import { FolderService } from '../services/folder.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  providers: [FolderService, PhotoPackService]
})
export class CreateFolderComponent implements OnInit, OnDestroy {
  @Input() categoryId: number = 0;

  folderLists: ComponentLists = {} as ComponentLists;
  photopackLists: ComponentLists = {} as ComponentLists;

  private destroy$: Subject<void> = new Subject<void>();
  private photopackCatId: number = 0;

  constructor(
    private specService: SpecBackendService,
    private common: CommonService,
    private photoService: PhotoPackService,
    public folderService: FolderService
  ) { }

  ngOnInit(): void {
    this.photopackCatId = this.common.getCategoryIdByRefId('photopack');

    forkJoin([this.specService.getComponetListByCategoryId(this.categoryId), this.specService.getComponetListByCategoryId(this.photopackCatId)])            
      .pipe(takeUntil(this.destroy$))
      .subscribe( ([componentLists, photopackLists]) => {
        this.folderLists = componentLists;
        this.folderService.setFinishingOptions(this.folderLists.finishing);
        this.folderService.setCountListId(this.folderLists.flipUpCount);
        this.photopackLists = photopackLists;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(): void { 
    if(!this.folderLists.photoPack) {
      return;
    }

    const photopackRequests: SpecRequestDto[] = [];

    if(this.folderService.isPhotopack) {
      const photopack = <PhotopackFormValue>this.photoService.detailForm.value;

      photopack.photopacks.forEach(pack => {
        let request = new SpecRequestDto(this.folderService.headerForm.value);
        if(request.categoryId === 0) {
          request.categoryId = this.photopackCatId;
        }

        request.setPhotopackDetails(this.photoService.detailForm.value);
        request.setPhotopack(pack);
        photopackRequests.push(request);
      });
    }

    const request = new SpecRequestDto(this.folderService.headerForm.value);
    const photopackId = this.folderLists.photoPack[0].id;

    if(request.categoryId === 0) {
      request.categoryId = this.categoryId; 
    }

    request.setFolderDetails(<FolderFormValue>this.folderService.detailForm.value);

    this.specService.createSpecWithChildrenRequest(photopackId, photopackRequests, request);
  }

}
