import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SpecActions } from "src/app/core/models/common-models";

@Component({
    selector: 'app-swatch-complist-dialog',
    template: `
        <h1 mat-dialog-title style="color: red;">WARNING</h1>
        <div mat-dialog-content>
            <p>
                There are existing assembly specs that use the component list value that you are attempting to deactivate.
                If you continue, the component list item will be deactived and removed from the specs it is currently applied to. 
            </p>
        </div>
        <div mat-dialog-actions align="end">
            <button type="button" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
            <button type="button" mat-raised-button color="warn" style="margin-left: 5px;" (click)="confirm()">Remove from spec(s)</button>
        </div>
    `, 
    styles: []
})
export class SwatchComplistDialog implements OnInit {

    constructor(public dialogRef: MatDialogRef<SwatchComplistDialog>) { }

    ngOnInit(): void { }

    cancel(): void {
        this.dialogRef.close(SpecActions.Cancelled); 
    }

    confirm(): void {
        this.dialogRef.close(SpecActions.Updated);
    }
}