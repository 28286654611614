<mat-toolbar style="width: 100%;" color="primary">
    <span>{{ data.name }} Update</span>
    <span class="example-spacer"></span>
    <div fxFlex="4" fxLayoutAlign="end" >
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<br>
<form class="example-form dialog-update" color="accent" #categoryForm="ngForm" (ngSubmit)="updateCategory(categoryForm)">
    <div mat-dialog-content>
        <input type="hidden" [ngModel]="data.id" name="id">
            <mat-card-content fxLayout="column" fxLayoutAlign="center" class="center-text">
             <mat-form-field appearance="outline">
                 <mat-label>Change Category Name</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.name" name="name">
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Change Category Ref ID</mat-label>
                 <input matInput autocomplete="off" [ngModel]="data.referenceId" name="referenceId" >    
             </mat-form-field>
             <mat-form-field appearance="outline">
                <mat-label>Change Category Sequence Number</mat-label>
                <input matInput type="number" min="1" max={{this.data.listLength}} autocomplete="off" [ngModel]="data.seqNbr" name="seqNbr" ngModel required>    
             </mat-form-field>
             <div fxLayout="row wrap" fxLayoutAlign="center" class="center-text">
                <mat-checkbox class='pr-3' color="primary" [ngModel]="data.assembly" color="accent" name="assembly">Assembly</mat-checkbox>
                <mat-checkbox class='pl-3' color="primary" [ngModel]="data.bindery" color="accent" name="bindery">Bindery</mat-checkbox>
                <mat-checkbox class='pl-3' color="primary" [ngModel]="data.active" color="accent" name="active">Active</mat-checkbox>
             </div>
            </mat-card-content>
    </div>

    <div mat-dialog-actions align="end">
      <button mat-dialog-close type="submit" mat-raised-button color="accent" [disabled]="!categoryForm.valid">Update</button>
    </div>
</form>

