import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpecActions } from 'src/app/core/models/common-models';

@Component({
  selector: 'app-confirm-delete-user',
  template: `
    <h1 mat-dialog-title>WARNING</h1>
    <div mat-dialog-content>
      <p>WARNING: You are about to delete user {{ data.userName }}. Are you sure you want to delete this user?</p>
    </div>
    <div mat-dialog-actions align="end">
      <button type="button" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
      <button type="button" mat-raised-button color="warn" style="margin-left: 5px;" (click)="delete()">Delete</button>
    </div>
  `,
  styles: [
  ]
})
export class ConfirmDeleteUserComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteUserComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,     
  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(SpecActions.Cancelled); 
  }

  delete(): void {
    this.dialogRef.close(SpecActions.Deleted); 
  }

}
