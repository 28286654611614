import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { BackendService } from 'src/app/core/services/back-end.service';

@Component({
  selector: 'app-swatching-header',
  templateUrl: './swatching-header.component.html'
})
export class SwatchingHeaderComponent implements OnInit {
  @Input() headerForm!: FormGroup;
  @Input() styleNumber: string = 'Style Number';
  @Input() categoryId!: number;
  vendorList$: any;

  constructor(private backendService: BackendService) { }

  styleControl!: AbstractControl | null;
  delvVehControl!: AbstractControl | null;
  custNbrControl!: AbstractControl | null;
  seqNbrControl!: AbstractControl | null;
  supplierControl!: AbstractControl | null; 

  ngOnInit(): void {
    this.vendorList$ = this.backendService.getVendorDtoList(true, this.categoryId);
    this.styleControl = this.headerForm.get('styleNbr');
    this.delvVehControl = this.headerForm.get('deliveryVehicleNbr');
    this.custNbrControl = this.headerForm.get('customerNbr');
    this.seqNbrControl = this.headerForm.get('sequenceNbr');

    if(this.headerForm.get('supplierId')) {
      this.supplierControl = this.headerForm.get('supplierId');
    }
  }
}
